import React,{Component} from 'react';
import logo from '../assets/images/logo.png';
import { ProductContext } from '../Context/Product';

export default class SplashScreen extends Component{

    static contextType = ProductContext;

    componentDidMount() {

        let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail   = JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail   = JSON.parse(localStorage.getItem('stewarddetail'));
		let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));
        let eventid         = "";

		if(tabledetail || captiondetail || kotedetail || stewarddetail)    
        {
          if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
          {
            eventid		= tabledetail.eventid;
          }
          else if(stewarddetail !== null && (Number(stewarddetail.eventid) > 0 && stewarddetail.eventid !== null))
          {
            eventid		= stewarddetail.eventid;
          }
          else if(captiondetail !== null && (Number(captiondetail.eventid) > 0  && captiondetail.eventid !== null))
          {
            eventid		= captiondetail.eventid;
          }
          if(this.context.isloggedin === "true" && eventid > 0)
          {
            this.props.history.push("/menu");
          }
          else
          {
            this.props.history.push("/login");
          }
        }
        else
        {
            this.props.history.push("/login");
        }
    }

    render() {
        return (
            <div id="splash-screen">
                <div className="center">
                    <div className="logo">
                        <img width="128" src={logo} alt="logo"/>
                    </div>
                    <div className="spinner-wrapper">
                        <div className="spinner">
                            <div className="inner">
                                <div className="gap"/>
                                <div className="left">
                                    <div className="half-circle"/>
                                </div>
                                <div className="right">
                                    <div className="half-circle"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}