import React,{ Component } from 'react';
import { ProductConsumer } from '../Context/Product';
import Productwithrightimage from './Productwithrightimage';
import Productwithleftimage from './Productwithleftimage';

export default class GroupProductList extends Component{

	render(){
        const {menuid, menuname} = this.props.menu;

        return(
            <ProductConsumer>
            {(value) => {

                const{showimageatleft, products, itemcategories, theposition, eventmenus} = value;

                let tempCategories   = [];

                const tempcategories = [...itemcategories];

                tempCategories	= tempcategories.filter(category => category.menuid === menuid);

                if(Number(Object.keys(tempCategories).length) > 0)
                {
                    return(
                        <section className="parrentmenubox" id={`eventmenudiv${menuid}`} key={menuid}>
                            {
                                menuname !== "" && menuname !== null && menuname !== "null" && Object.keys(eventmenus).length > 1 ? (<h4 className={`categorytitle eventmenutitle${theposition < 100 ? ' withsmallads':''}`}>{menuname}</h4>):``
                            }
                            {
                                tempCategories.map((category, categoryloop)=>{

                                    let tempCatProducts   = [];

                                    const tempproducts = [...products];
                    
                                    tempCatProducts     = tempproducts.filter(tempitem => tempitem.catid === category.id);
                                    const itemcounts    = Number(Object.keys(tempCatProducts).length)-1;

                                    return(
                                        <section key={`${menuid}-${categoryloop}`} className="parrentmenubox" id={`div${category.id}`}>
                                            <h4 className={`categorytitle${menuname !== "" && menuname !== null && menuname !== "null" && Object.keys(eventmenus).length > 1 ? ``:` withouteventmenu`}`}>{category.title}</h4>
                                            <div className="categorytitlesep"></div>
                                            <div className="NsDyX">
                                                {
                                                    tempCatProducts.map((item, index)=>{
                                                        if(showimageatleft)
                                                        {
                                                            return <Productwithleftimage key={`${menuid}-${categoryloop}-${index}`} product={item} productindex={index} itemcounts={itemcounts}/>;
                                                        }
                                                        else
                                                        {
                                                            return <Productwithrightimage key={`${menuid}-${categoryloop}-${index}`} product={item} productindex={index} itemcounts={itemcounts}/>;
                                                        }
                                                    })
                                                }
                                            </div>
                                            <div className="lcsmJJ"></div>
                                        </section>
                                    )
                                })
                            }
                        </section>
                    );
                }

            }}
            </ProductConsumer>
        );
	}
}