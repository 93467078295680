import React,{Component, Fragment} from 'react';
import Skeleton from 'react-loading-skeleton';
import { ProductConsumer } from '../Context/Product';

import {
    StarFilled,
    StarBlank,
    Remove,
    Plus,
    PinkPlus,
}  from '../constants';

class Productwithrightimage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render(){

        const {productindex, itemcounts} = this.props;

        const{hasimage, title, hindititle, img, inCart, hastag, hasvote, ispricingavailable, id, isvegfood, count, showvegicon, isselfservice, tempinstock, islivecounter, islargepreview, largeimg, iscustomization, price, containsegg, busy, subtitle, showitempreview} = this.props.product;

		var foodsymbolcolorborder   = "nonveg";
		var foodsymbolcolor         = "roundnonvegtype";

		if(isvegfood)
		{
            foodsymbolcolorborder   = "veg";
			foodsymbolcolor         = "roundvegtype";
		}

        if(isvegfood && containsegg)
        {
            foodsymbolcolorborder   = "veg containegg";
            foodsymbolcolor         = "roundvegtype containegg";
        }

        return (
            <ProductConsumer>
            {(productvalue) => {

                const{additionalimageurl, loggedintabledetail, miscdetail} = productvalue;

                const{increment, decrement, addToCart, toggleItemOption, toggleItemRepeatOption, toggleRemoveItemOption, toggleLargeProductPreview, removeItem} = productvalue;

                return (
                    <div>
                        <div className="productblock" style={{marginBottom:`${itemcounts - productindex ? '2rem':'0rem'}`}}>
                            <div className={`${islargepreview ? 'gahQYm':'smallpreviewbox'}`} style={{borderBottom:`${itemcounts - productindex ? '1px solid #e8e5e5':'0px solid #e8e5e5'}`}}>
                                <div className="iteminfo">
                                    <div className="iteminfoch">
                                        <div className="iteminfochin">
                                            {
                                                showvegicon ? (
                                                    <div className={`${foodsymbolcolorborder} itemtypesymbol`}>
                                                        <div className={`${foodsymbolcolor}`}></div>
                                                    </div>
                                                ):null
                                            }
                                            <h4 className="itemtitle" onClick={()=>{
                                                if(!isselfservice)
                                                {
                                                    if(inCart)
                                                    {
                                                        if(iscustomization)
                                                        {
                                                            toggleItemRepeatOption(id);
                                                        }
                                                        else
                                                        {
                                                            increment(id);
                                                        }
                                                    }
                                                    else
                                                    {
                                                        if(iscustomization)
                                                        {
                                                            toggleItemOption(id);
                                                        }
                                                        else
                                                        {
                                                            addToCart(id, false);
                                                        }
                                                    }
                                                }
                                            }}>{title}</h4>
                                            <h4 className={`itemtitle ${Number(loggedintabledetail.isdemoevent) > 0 ?`demohindi`:``}`} onClick={()=>{
                                                if(!isselfservice)
                                                {
                                                    if(inCart)
                                                    {
                                                        if(iscustomization)
                                                        {
                                                            toggleItemRepeatOption(id);
                                                        }
                                                        else
                                                        {
                                                            increment(id);
                                                        }
                                                    }
                                                    else
                                                    {
                                                        if(iscustomization)
                                                        {
                                                            toggleItemOption(id);
                                                        }
                                                        else
                                                        {
                                                            addToCart(id, false);
                                                        }
                                                    }
                                                }
                                            }}>{hindititle}</h4>
                                            {
                                                subtitle ? (
                                                    <h4 className="itemtitle" style={{fontSize:'1.2rem',fontStyle:'italic',fontWeight:'normal'}}>{subtitle}</h4>
                                                ):null
                                            }
                                    {
                                        islivecounter ? (<h4 className="itemtitle livecounter">*Live Cooking, Delivery might take time</h4>):null
                                    }
                                    {
                                        hastag ? (
                                            <div className="itemtagbox">
                                                <div className="itemtag">
                                                    <div type="tag" fontSize="1rem" className="orangetagtitle">BESTSELLER</div>
                                                </div>
                                                <div className="itemtag">
                                                    <div type="tag" fontSize="1rem" className="bluetagtitle">MUST TRY</div>
                                                </div>
                                            </div>
                                        ):null
                                    }
                                    {
                                        hasvote ? (
                                            <div className="votebox voteboxmargin">
                                                <div className="voteboxchild">
                                                    <StarFilled />
                                                    <StarFilled />
                                                    <StarFilled />
                                                    <StarBlank />
                                                </div>
                                                <span className="voteavg">68 votes</span>
                                            </div>
                                        ):null
                                    }
                                    {
                                        ispricingavailable && price > 0 ? (
                                            <div className="pricebox priceboxmargin">
                                                <span className="offerprice"><i className="fa fa-inr"></i> {price.toFixed(2)}</span>
                                            </div>
                                        ):null
                                    }
                                        </div>
                                    </div>
                                </div>
                                {
                                    loggedintabledetail.showitemimages ? (<Fragment>
                                        {
                                            showitempreview ? (<Fragment>
                                                {
                                                    hasimage ?(
                                                    <div className={`${islargepreview ? 'foGWxa':'smallpreviewchildbox'}`}>
                                                        {img ? (<Fragment><div className={`${islargepreview ? 'largebox1 largebox2':'smallimagebox previewbox2'}`} onClick={()=>{
                                                            if(!miscdetail.enlargeview)
                                                            {
                                                                if(!isselfservice)
                                                                {
                                                                    if(inCart)
                                                                    {
                                                                        if(iscustomization)
                                                                        {
                                                                            toggleItemRepeatOption(id);
                                                                        }
                                                                        else
                                                                        {
                                                                            increment(id);
                                                                        }
                                                                    }
                                                                    else
                                                                    {
                                                                        if(iscustomization)
                                                                        {
                                                                            toggleItemOption(id);
                                                                        }
                                                                        else
                                                                        {
                                                                            addToCart(id, false);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else
                                                            {
                                                              toggleLargeProductPreview(largeimg, title, subtitle);
                                                            }
                                                        }}>
                                                            <div className="imagesepbox"></div>
                                                            <img alt={`${title} - Small Preview`} src={`${additionalimageurl}${img}`} loading="lazy" className="menupreview" />
                                                        </div>
                                                        <div className={`${islargepreview ? 'dYbSLL previewbox2':'largebox1 largebox2'}`} onClick={()=>{
                                                            if(!miscdetail.enlargeview)
                                                            {
                                                                if(!isselfservice)
                                                                {
                                                                    if(inCart)
                                                                    {
                                                                        if(iscustomization)
                                                                        {
                                                                            toggleItemRepeatOption(id);
                                                                        }
                                                                        else
                                                                        {
                                                                            increment(id);
                                                                        }
                                                                    }
                                                                    else
                                                                    {
                                                                        if(iscustomization)
                                                                        {
                                                                            toggleItemOption(id);
                                                                        }
                                                                        else
                                                                        {
                                                                            addToCart(id, false);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            else
                                                            {
                                                                toggleLargeProductPreview(largeimg, title, subtitle);
                                                            }
                                                        }}>
                                                            <div className="lrgclr"></div>
                                                            <img alt={`${title} - Large Preview`} src={`${additionalimageurl}${largeimg}`} loading="lazy" className="menupreview" />
                                                        </div>
                                                        </Fragment>):null
                                                        }
                                                        <Fragment>

                                                            <div className={`qtychild${iscustomization ?` withcustomization`:` withoutcustomization`}${img ?``:` noproductimgnomargin`}${isselfservice?` selfserviceable`:``}`}>
                                                                {
                                                                    !isselfservice ? (
                                                                        <Fragment>
                                                                            {
                                                                                tempinstock ? (
                                                                                    <Fragment>
                                                                                    {
                                                                                        inCart ? (
                                                                                            <div style={{position:'relative',display:'flex',alignItems:'center'}}>
                                                                                            <span className="offerprice" style={{fontSize:'2.4rem',color:'#ED5A6B'}} onClick={()=>removeItem(id)}><i className="fa fa-trash"></i></span>
                                                                                            &nbsp;
                                                                                            <div className="qtychildin">
                                                                                            {
                                                                                                iscustomization ? (
                                                                                                <div className="cMipmx huikLB">
                                                                                                    <div className="hTzRFw" onClick={()=>toggleRemoveItemOption(id)}>
                                                                                                        <Remove />
                                                                                                    </div>
                                                                                                    <div className="iQCkqv">
                                                                                                        <span className="qtytitle" style={{
                                                                                                            color:'#FFF6F7'
                                                                                                        }}>{count}</span>
                                                                                                    </div>
                                                                                                    <div className="hTzRFw" onClick={()=>toggleItemRepeatOption(id)}>
                                                                                                        <Plus />
                                                                                                    </div>
                                                                                                </div>
                                                                                                ):(
                                                                                                <div className="cMipmx huikLB">
                                                                                                    <div className="hTzRFw" onClick={()=>decrement(id)}>
                                                                                                        <Remove />
                                                                                                    </div>
                                                                                                    <div className="iQCkqv">
                                                                                                        <span className="qtytitle" style={{
                                                                                                            color:'#FFF6F7'
                                                                                                        }}>{count}</span>
                                                                                                    </div>
                                                                                                    <div className="hTzRFw" onClick={()=>increment(id)}>
                                                                                                        <Plus />
                                                                                                    </div>
                                                                                                </div>
                                                                                                )
                                                                                            }

                                                                                            </div>
                                                                                            {
                                                                                                busy ? <div className="qty_slider_animation_white"></div>:null
                                                                                            }
                                                                                            </div>
                                                                                        ):(<Fragment>
                                                                                    {
                                                                                        iscustomization ? (
                                                                                            <Fragment>
                                                                                            <div className="qtyadd" onClick={()=>{toggleItemOption(id)}}>
                                                                                                <span className="qtytitle">Add</span>
                                                                                                <PinkPlus />
                                                                                            </div>

                                                                                            </Fragment>
                                                                                                ):(
                                                                                            <div style={{position:'relative'}}>
                                                                                                <div className="qtyadd" onClick={()=>{addToCart(id, false)}}>
                                                                                                    <span className="qtytitle">Add</span>
                                                                                                    <PinkPlus />
                                                                                                </div>
                                                                                                {
                                                                                                    busy ? <div className="qty_slider_animation_pink"></div>:null
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                        </Fragment>
                                                                                        )
                                                                                    }
                                                                                    </Fragment>
                                                                                ):null
                                                                            }
                                                                        </Fragment>
                                                                    ):``
                                                                }
                                                                {
                                                                    tempinstock ? null:(<h4 className="itemtitle stockout" style={{color:'#ff0000'}}>out of stock</h4>)
                                                                }
                                                                {
                                                                    iscustomization && !isselfservice ? (
                                                                        <span className="customizabletitle">customizable</span>
                                                                    ):null
                                                                }
                                                            </div>
                                                        </Fragment>
                                                    </div>
                                                    ):null
                                                }
                                            </Fragment>):(<Skeleton width={90} height={90} />)
                                        }
                                    </Fragment>):null
                                }
                            </div>
                        </div>
                    </div>
                );
            }}
            </ProductConsumer>
        )
    }
}

export default Productwithrightimage;