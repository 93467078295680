import React,{Component,Fragment} from 'react';
import SplashScreen from './SplashScreen';
import QrReader from 'react-qr-reader';
import { ProductContext } from '../Context/Product';
import scanlogo from '../assets/images/scanlogo.png';

export default class Rescan extends Component{

  static contextType = ProductContext;

  constructor(props) {
      super(props);
      this.state = {
          scanresult:'No result',
          ispageload: false
      };
  }

  componentDidMount() {

    let cartdata	= [...this.context.cart];
    let cartNum		= Object.keys(cartdata).length;

    if(Number(cartNum) < 1)
    {
      this.props.history.push("/menu");
    }

    this.setState({
      ispageload: true
    });    
      if(document.getElementsByClassName('page-content').length > 0)
      {
          document.getElementsByClassName("page-content")[0].classList.remove("footercartopen");
      }
  }

  componentDidUpdate(prevProps) {

      if(this.context.isorderadded)
      {
          if(this.context.isorderadded === true)
          {
              this.props.history.push("/orderthanks/"+this.context.orlo_order_id);
          }
      }
      else if(this.context.isreorderadded)
      {
          if(this.context.isreorderadded === true)
          {
              this.props.history.push("/reorderthanks/"+this.context.re_orlo_order_id);
          }
      }
  }

    handleScan = data => {
        if(this.context.internetstatus === "offline")
        {
            return false;
        }        
        if (data) {
          this.setState({
            scanresult: data,
            tablenumber: data
          },
          ()=>{
            const restables   = this.context.tables;
            const tabledetail = restables.find(restables => Number(restables.code) === Number(this.state.scanresult));

            const orgtabledetail		= JSON.parse(localStorage.getItem('tabledetail'));

            let showerror = false;

            if(tabledetail)
            {
              if(tabledetail.eventid !== orgtabledetail.eventid)
              {
                showerror = true;
              }
            }
            else
            {
              showerror = true;
            }

            if(showerror === true)
            {
              if(document.getElementsByClassName("seaterrormsg").length > 0)
              {
                document.getElementsByClassName("seaterrormsg")[0].innerText = "Entered Orlo ID is Invalid.";
              }
      
              setTimeout(() => {
                if(document.getElementsByClassName("seaterrormsg").length > 0)
                {
                  document.getElementsByClassName("seaterrormsg")[0].innerText = '';
                }
              }, 4800);
              return false;
            }
      
            if(tabledetail)
            {
              localStorage.setItem("tabledetail",JSON.stringify(tabledetail));
              this.context.plaeOrder();
            }
            else
            {
              if(document.getElementsByClassName("scanerrormsg").length > 0)
              {
                document.getElementsByClassName("scanerrormsg")[0].innerText = "Invalid QR Code, Please re-scan a new code.";
              }

              setTimeout(() => {

                if(document.getElementsByClassName("scanerrormsg").length > 0)
                {
                  document.getElementsByClassName("scanerrormsg")[0].innerText = '';
                }
                
              }, 4800);
              
            }
          });
        }
    }
    handleError = err => {
        console.error(err)
    }
    handleSeatIDFocus = (e) => {
      document.getElementsByClassName("scannerwrapper")[0].classList.add("hide");
    }
    handleSeatIDBlur = (e) => {
      document.getElementsByClassName("scannerwrapper")[0].classList.remove("hide");
      if(this.state.tablenumber !== undefined && this.state.tablenumber !== "")
      {
        this.handleCodeClick();
      }
    }
    handleChangeEvent = (e) => {
        this.setState({ tablenumber: e.target.value });
    };
    handleCodeClick = (e) => {
      
      if(this.context.internetstatus === "offline")
      {
        if(document.getElementsByClassName("seaterrormsg").length > 0)
        {
          document.getElementsByClassName("seaterrormsg")[0].innerText = "Please connect to internet to process";
          document.getElementById('seatcode').focus();
        }
          return false;
      }
      const restables   = this.context.tables;

      const tabledetail	= restables.find(restables => Number(restables.code) === Number(this.state.tablenumber));

      let showerror = false;

      if(tabledetail)
      {
        const orgtabledetail		= JSON.parse(localStorage.getItem('tabledetail'));

        if(tabledetail.eventid !== undefined && (tabledetail.eventid !== orgtabledetail.eventid))
        {
          showerror = true;
        }
      }
      else
      {
        showerror = true;
      }

      if(showerror === true)
      {
        if(document.getElementsByClassName("seaterrormsg").length > 0)
        {
          document.getElementsByClassName("seaterrormsg")[0].innerText = "Entered Orlo ID is Invalid.";
        }

        setTimeout(() => {
          if(document.getElementsByClassName("seaterrormsg").length > 0)
          {
            document.getElementsByClassName("seaterrormsg")[0].innerText = '';
          }
        }, 4800);
        return false;
      }

      if(this.state.tablenumber === undefined)
      {
        if(document.getElementsByClassName("seaterrormsg").length > 0)
        {
          document.getElementsByClassName("seaterrormsg")[0].innerText = "Please enter Orlo ID 'or' scan qr code";
        }

        setTimeout(() => {

          if(document.getElementsByClassName("seaterrormsg").length > 0)
          {
            document.getElementsByClassName("seaterrormsg")[0].innerText = '';
          }
          
        }, 4800);

        return false;
      }

      if(tabledetail)
      {
        localStorage.setItem("tabledetail",JSON.stringify(tabledetail));
        this.context.plaeOrder();
      }
      else
      {
        if(document.getElementsByClassName("seaterrormsg").length > 0)
        {
          document.getElementsByClassName("seaterrormsg")[0].innerText = "Please enter a valid orlo ID.";
          document.getElementById('seatcode').focus();
        }

        setTimeout(() => {
          if(document.getElementsByClassName("seaterrormsg").length > 0)
          {
            document.getElementsByClassName("seaterrormsg")[0].innerText = '';
            document.getElementById('seatcode').focus();
          }
        }, 4800);
      }
    }
	render(){

		return (
      <Fragment>
      {
        this.state.ispageload ? (<div>
          <div className="row nomargin">
            <div className="col-sm nomargin">
            &nbsp;
            </div>
          </div>
          <div className="row nomargin">
            <div className="col-sm nomargin scan-logo-wrapper">
              <img src={scanlogo} className="scan-logo" alt="logo" />
            </div>
          </div>
          <div className="row seatcodewrapper">
            <div className="col-sm">
              <div className="seaterrormsg"></div>
            </div>
          </div>
          <div className="row nomargin seatcodewrapper">
            <div className="col-sm nomargin nopadding">
              <input type="number" name="seatcode" id="seatcode" className="floatleft" value={this.state.tablenumber || ''} onChange={this.handleChangeEvent} onFocus={this.handleSeatIDFocus} onBlur={this.handleSeatIDBlur} autoComplete="off" placeholder="Enter Orlo ID"/>
              <input type="button" name="enterseatcode" id="enterseatcode" className="ripplepink floatleft" value="Go" onClick={this.handleCodeClick}/>
            </div>
          </div>
          <div className="nopadding scannerwrapper" style={{marginTop:'25px'}}>
            <div className="row nomargin">
              <div className="col-sm nomargin nopadding">
                <div className="additionaloptiontitle">OR</div>
                <div className="scanerrormsg"></div>
              </div>
            </div>
            <div className="row nomargin mt20">
              <div className="col-sm nomargin nopadding">
                <span className="scancodetitle">Scan QR Code</span>
                <span className="scancodesubtitle">Placed on your table to order food</span>
              </div>
            </div>
            <div className="row nomargin marginauto">
              <div className="col-sm qrcodeqrapper">
                <QrReader delay={1} onError={this.handleError} onScan={this.handleScan} className="qrcodebox" />
              </div>
            </div>
            <br />
          </div>
        </div>):(<SplashScreen/>)
      }
      </Fragment>
      );
	}
}