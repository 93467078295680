import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';

class KoteLiteRemoveRunnerConfirmPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
        };
    }

    render() {

        return (
            <div>
            {
                this.props.isRunnerPopActive ?(
                    <div className="catalog__modal copy_catalog_container">
                        <div className="catalog__modal-body catalog__modal-body--dialog">
                            <div className="catalog__index__copy font15 centertext">
                                <br />Runner : <span>{this.props.runnername}</span>
                            </div>
                            <div className="catalog__modal-body--dialog-footer">
                                <button className="btn font15 btn-outline-warning" onClick={()=>{this.props.markRunnerAbsent(this.props.runnerid)}}>Mark Absent</button>
                                &nbsp;
                                <button className="btn font15 btn-outline-danger" onClick={()=>{this.props.removeRunner(this.props.runnerid)}}>Remove</button>
                                &nbsp;
                                <button className="btn font15 btn-outline-primary" onClick={this.props.closePopup}>close</button>
                            </div>
                        </div>
                    </div>
                ):null
            }
            </div>
        );
    }
}

export default withRouter(KoteLiteRemoveRunnerConfirmPopup);