import React, { Component, Fragment } from 'react';
import Loggedinmenu from './Loggedinmenu';
import { ProductConsumer} from '../Context/Product';
import Category from './Category';

class Popupmenumanage extends Component {

    constructor(props) {
        super(props);
        this.initialState = {
          openmobilemenu: "",
        };
        this.state = this.initialState;
    }

    render() {

        let className = '';
        if (this.props.isActive) {
          className += ' opened';
        }

        return(
            <ProductConsumer>{(value)=>{

                const {allitemcategories} = value;

                return (
                    <Fragment>
                        <div className={`mobile-menu-wrapper${className}`}>            
                            <div className="menu-overlay menu-closer waves-effect waves-light" onClick={this.props.closePopup}></div>
                            <div className="mobile-menu">
                                <ul>
                                <Loggedinmenu />
                                {
                                    allitemcategories.map(category => {
                                        return <Category key={category.id} product={category} value={value} closePopup={this.props.closePopup}/>;
                                    })
                                }
                                </ul>
                            </div>
                        </div>            
                    </Fragment>
                );
            }}</ProductConsumer>
        )
    }
}

export default Popupmenumanage;