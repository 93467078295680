import React,{Component} from 'react';
import { ProductContext } from '../Context/Product';
import axios from "axios";
import scanlogo from '../assets/images/scanlogo.png';
import {CircularProgress, Button} from '@material-ui/core';
import {Link} from "react-router-dom";

class Tableselection extends Component {

    static contextType = ProductContext;

    constructor (props) {
        super(props);
        this.state = {
            processing: false,
            processingself: false,
            isvip: false,
            tableid: '',
            showdelivertoself:false,
            loggedininfo: {},
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){

        let temploggedininfo    = {};
        let showdelivertoself   = false;

        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

        if(captiondetail || stewarddetail)
        {
            if(Number(stewarddetail.eventid) > 0)
            {
                temploggedininfo	= stewarddetail;
                if(Number(stewarddetail.tabletype) === 2)
                {
                    showdelivertoself   = true;
                    this.props.history.push("/menu");
                }
                else
                {
                    showdelivertoself   = true;
                }
            }
            else if(Number(captiondetail.eventid) > 0)
            {
                temploggedininfo	= captiondetail;
                showdelivertoself   = false;
            }
        }

        this.setState({
            loggedininfo:temploggedininfo,
            showdelivertoself:showdelivertoself,
        })
    }

    handleUserInput = (e) => {
        const name  = e.target.name;
        const value = e.target.value;

        const{logintype} = this.state;

        switch(name) {
        case "tracktablet":
            this.setState(()=>{
                return{
                    tracktablet:!this.state.tracktablet
                }
            })
            break;
        case "showitemimages":
            this.setState(()=>{
                return{
                    showitemimages:!this.state.showitemimages
                }
            })
            break;
        case "showbanners":
            this.setState(()=>{
                return{
                    showbanners:!this.state.showbanners
                }
            })
            break;
        case "isvip":
            this.setState(()=>{
                return{
                    isvip:!this.state.isvip
                }
            })
            break;
        case "logintype":
            if(value !== logintype)
            {
                this.setState(()=>{
                    return{
                        [name]: value,
                        loginid:'',
                        password:'',
                    }
                })
            }
            break;
        default:
            this.setState(()=>{
                return{
                    [name]: value
                }
            })
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        const{tableid} = this.state;

        let message = "";

        if((tableid === "" || tableid === null || tableid === undefined) && message === "")
        {
            message = `Please enter Table Number`;
        }

        if(message !== "")
        {
            this.context.toastMessage(`${message}`,`error`);
            return; 
        }
        this.setState(()=>{
            return{
                processing:true
            }
        },()=>{
            setTimeout(() => {

                this.validateTableAndLogin(tableid, false);

            }, 500);
        })
    }

    validateTableAndLogin=(tablenumber, delivertosteward)=>{

        let message = "";

        let loggedininfo  = {};

        let loggedineventid = 0;
        let isstewardlogin  = 0;

        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

        if(captiondetail || stewarddetail)
        {
            if(Number(stewarddetail.eventid) > 0)
            {
                loggedineventid	= Number(stewarddetail.eventid);
                loggedininfo    = stewarddetail;
                isstewardlogin  = 1;
            }
            else if(Number(captiondetail.eventid) > 0)
            {
                loggedineventid	= Number(captiondetail.eventid);
                loggedininfo    = captiondetail;
            }
        }

        if((loggedineventid === "" || loggedineventid === null || loggedineventid === undefined) && message === "")
        {
            message = `Please Relogin to verify.`;
        }

        if(isstewardlogin > 0)
        {
            const temptabletype   = Number(loggedininfo.tabletype);
            const stewardtable    = loggedininfo.stewardtable;

            if(Number(temptabletype) === 1)
            {
                if(!this.context.devInArray(tablenumber, stewardtable) && !delivertosteward && message === "")
                {
                    message = `Table #${tablenumber} not assigned to you.`;
                }
            }
        }

        if(message !== "")
        {
            this.setState(()=>{
                return{
                    tableid:'',
                    processing:false,
                    processingself:false,
                }
            },()=>{
                this.context.toastMessage(`${message}`,`error`);
            })

            return false;
        }

        let tempprocessingself  = false;
        let tempprocessing      = false;

        if(delivertosteward)
        {
            tablenumber         = Number(tablenumber) + 1000;
            tempprocessingself  = true;
        }
        else
        {
            tempprocessing      = false;
        }

        this.setState(()=>{
            return{
                processing:tempprocessing,
                processingself:tempprocessingself
            }
        },()=>{
            const bodyFormData = new FormData();
            bodyFormData.set('Mode', 'VerifyTable');
            bodyFormData.set('tableid', Number(tablenumber));
            bodyFormData.set('eventid', Number(loggedineventid));
            /*bodyFormData.set('loggedininfo', JSON.stringify(loggedininfo));*/
    
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}applogin.php?t=${Date.now()}`,
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{
    
                this.setState(()=>{
                    return{
                        processing:false,
                        processingself:false,
                    }
                },()=>{
                    if(response.data.success !== true)
                    {
                        this.context.toastMessage(`${response.data.msg}`,`error`);
                    }
                    else
                    {    
                        let selectedtable = {...response.data.tabledetail, isvip:Number(this.state.isvip)};
                        
                        loggedininfo  = {...loggedininfo, selectedtable:selectedtable};
    
                        if(Number(stewarddetail.eventid) > 0)
                        {
                            localStorage.setItem("stewarddetail",JSON.stringify(loggedininfo));
                        }
                        else if(Number(captiondetail.eventid) > 0)
                        {
                            localStorage.setItem("captiondetail",JSON.stringify(loggedininfo));
                        }
    
                        setTimeout(() => {
                            this.context.resetAllPopup();
                            this.props.history.push("/menu");
                        }, 500);
                    }
                })
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
        })
    }

    render() {

      const{processing, tableid, loggedininfo, isvip, processingself, showdelivertoself} = this.state;
      
      return (
        <div className="limiter">
            <div className="container-login100 loginform">
                <div className="wrap-login100" style={{textAlign:'center',justifyContent:'center',fontWeight:'bold',fontSize:'2rem'}}>
                  <div className="login100-pic js-tilt" style={{marginBottom:'20px'}}>
                    <img src={scanlogo} alt="Login Icon" />
                  </div>
                  <br/><br/><br/><br/>
                    <div className="ordermessage runnermsg" style={{textAlign:'center',justifyContent:'center',fontWeight:'bold',fontSize:'2rem'}}>Select Table</div>

                  <form className="loginform login100-form validate-form" id="loginform" name="loginform" onSubmit={this.handleSubmit}>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',maxWidth:'300px'}}>
                            <div className="wrap-input100 validate-input" style={{marginRight:'10px',marginBottom:'0px'}}>
                                <input type="number" className={`input100`} style={{color:'#000000',fontSize:'25px',fontWeight:'bold',height:'70px', border:'2px solid #b1aeae'}} name="tableid" id="tableid" value={tableid} onChange={this.handleUserInput} placeholder="Table Number" autoFocus/>
                            </div>
                            <button type="submit" className="login100-form-btn" style={{width:'100px', height:'70px', fontSize:'25px'}} disabled={processing}>
                            {
                                processing ? <CircularProgress color='inherit' />:`Go`
                            }
                            </button>
                        </div>
                    </div>
                    <div className="squared" style={{
                        margin:'auto',
                        marginLeft:'5px',
                        marginRight:'5px',
                        marginTop:'10px',
                        textAlign:'center',
                        }}>
                        <input type="checkbox" name="isvip" id="isvip" value="1" checked={Number(isvip) === 1} onChange={this.handleUserInput}/>
                        <label htmlFor="isvip">Mark As <span style={{color:'#ff0000'}}>VIP</span> Order</label>
                    </div>
                  </form>
                  {
                    showdelivertoself ? (
                        <div>
                            <button type="button" className="login100-form-btn" style={{width:'300px', height:'70px', fontSize:'25px'}} onClick={()=>{
                                this.validateTableAndLogin(loggedininfo.stewardid, true);
                            }} disabled={processingself}>
                            {
                                processingself ? <CircularProgress color='inherit' />:`Deliver To Self`
                            }
                            </button>
                        </div>
                    ):null
                  }
                </div>
                {
                    loggedininfo.recenttablelist && loggedininfo.recenttablelist !== null && loggedininfo.recenttablelist !== undefined && Object.keys(loggedininfo.recenttablelist).length > 0 ? (
                        <div className='selecttable'>
                            <div className="ordermessage runnermsg" style={{textAlign:'center',justifyContent:'center',fontWeight:'bold',fontSize:'2rem'}}>Recent Table</div>
                            <br/>
                            <div>
                            {
                                loggedininfo.recenttablelist.filter(temptable => Number(temptable.ordertableseatid) < 1000).map((recenttable, tableloop)=>{
                                    return(
                                        <div  key={tableloop} className="runneridbox" style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:'25px',minHeight:'60px',background:'#E3465D', minWidth:'60px',marginRight:'5px',marginLeft:'5px'}} onClick={()=>{
                                            this.validateTableAndLogin(recenttable.ordertableid, false);
                                        }}>
                                        {recenttable.ordertableseatid}
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    ):null
                }
                {
                    JSON.parse(localStorage.getItem('captiondetail')).eventid > 0 ? (
                        <div>
                            <Link to={`/runnerattendance`} style={{textDecoration:'none'}}><Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize',marginBottom:'5px'}} variant="contained" size="small">Runner Attendance</Button></Link>
                            &nbsp;
                            <Link to={`/managerunner`} style={{textDecoration:'none'}}><Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize',marginBottom:'5px'}} variant="contained" size="small">Manage Runner</Button></Link>
                            &nbsp;
                            <Link to={`/releaserunner`} style={{textDecoration:'none'}}><Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize',marginBottom:'5px'}} variant="contained" size="small">Release Runner</Button></Link>
                            &nbsp;
                            <Link to={`/managemenu`} style={{textDecoration:'none'}}><Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize',marginBottom:'5px'}} variant="contained" size="small">Manage Menu</Button></Link>
                            &nbsp;
                            <Link to={`/addmenu`} style={{textDecoration:'none'}}><Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize',marginBottom:'5px'}} variant="contained" size="small">Quick Add Items</Button></Link>
                            &nbsp;
                            <Link to={`/managebanner`} style={{textDecoration:'none'}}><Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize',marginBottom:'5px'}} variant="contained" size="small">Manage Banner</Button></Link>
                            &nbsp;
                            <Link to={`/addbanner`} style={{textDecoration:'none'}}><Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize',marginBottom:'5px'}} variant="contained" size="small">Add Banner</Button></Link>
                            &nbsp;
                            <Link to={`/kotelogin`} style={{textDecoration:'none'}}><Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize',marginBottom:'5px'}} variant="contained" size="small">Kote Lite</Button></Link>
                            &nbsp;
                        </div>
                    ):``
                }
            </div>
        </div>
      );
  }
}

export default Tableselection;