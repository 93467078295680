import React, { Component } from 'react';

class KoteLiteInProgressItem extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            tokenitems:[] 
        };
    }

    componentDidMount(){
        const{tokenitems} = this.props;

        let tempItems 	= [];

        let item;
        for(item in tokenitems)
        {
            const singleItem = tokenitems[item];

            tempItems = [...tempItems, singleItem];
        }

        this.setState({
            tokenitems:tempItems
        });
    }

    render() {
        return (<div className="row ordereditemwrapper nopadding">
            {
                this.state.tokenitems.map((item) => {
                    return (
                        <div key={item.index} className="ordereditem">
                            <span className="itemname">{item.product_name}</span> <span>X {item.qty}</span>
                        </div>
                    )
                })
            }            
        </div>);
    }
}

export default KoteLiteInProgressItem;