import React,{Component} from 'react';
import axios from "axios";
/*import ReactNotification,{store} from 'react-notifications-component';*/
import {store} from 'react-notifications-component';
import { toast } from 'react-toastify';

const KoteContext = React.createContext();
/*let websocket = new WebSocket("ws://192.168.0.105:8090/portlistener/php-event-socket.php");*/
let websocket	= "";
//Provider
//Consumer

class KoteProvider extends Component{

	state = {
		totalrunner:0,
		engagedrunner:0,
		availablerunner:0,
		showpendingprint:false,
		pendingprint:0,
		isautoprintactive:false,
		lastupdatetime:'',		
		engagedrunners:[],
		eventrunners:[],
		pendingbachs:[],
		acceptedbatchs:[],
		pendingorders:[],
		acceptedorders:[],
		recentdispatchtoken:[],
		runnersummaries:[],
		itemsummaries:[],
		tableseatsummaries:[],
		canprintorder:false,
		showspoolingerror:false,
		spoolingerrortime:'',
		nextorderid:'',
		nextrecordid:'',
		isActiveNextOrder:false,
		isdefaultevent:false,
		showguestcomplain:false,
		totalguestcomplain:0,
		complaindetails:[],
		pendingcomplaindetails:[],
		resolvedcomplaindetails:[],
		error : null,
		isLoaded : false,
		isOrderavailable : false,
		canrefresh:true,
		batches:[],
		orders : [],
		acceptedbatchorders:[],
		pendingbatchcount:0,
		acceptedbatchcount:0,
		isActive:false,
		isactivepopup:false,
		pendingcomplaintclass:' btn-selected',
		isactivereassignrunner:false,
		nexttokenid:'',
		resolvedcomplaintclass:'',
		type:'pending',
		isconfirmed_kote:false,
		isdialogopen_kote:false,
		confirmmessage_kote:'',
		confirmrecordid_kote:'',
		confirmrecordid2_kote:'',
		cbfunctionname_kote:'',
		isautopilot:Number(localStorage.getItem('isautopilot')) === 1 ? true:false,
	}

	componentDidMount(){

		this.SyncUpcomingOrders();

	}

	SyncUpcomingOrders=()=>{

		let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
		
		let kotid = 0;
		let eventid = 0;
		
		if(kotedetail !== null)
		{
			kotid	= kotedetail.kotid;
			eventid	= kotedetail.eventid;
		}
		if(Number(kotid) < 1 || Number(eventid) < 1)
		{
			return false;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'SyncUpcomingOrders');
		bodyFormData.set('kotid', kotid);
		bodyFormData.set('eventid', eventid);
		
        axios({
			method: 'post',
			url: process.env.REACT_APP_API_URL+'order.php',
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{		  
			if(response.data.success === true)
			{
				let tempComplainDetails	= [];

				if(this.state.type === 'pending')
				{
					tempComplainDetails	= response.data.pendingcomplaindetails;
				}
				else
				{
					tempComplainDetails	= response.data.resolvedcomplaindetails;
				}

				this.setState(()=>{
					return{
						totalrunner:response.data.totalrunner,
						engagedrunner:response.data.engagedrunner,
						availablerunner:response.data.availablerunner,
						showpendingprint:response.data.showpendingprint,
						pendingprint:response.data.pendingprint,
						isautoprintactive:response.data.isautoprintactive,
						lastupdatetime:response.data.lastupdatetime,
						showspoolingerror:response.data.showspoolingerror,
						spoolingerrortime:response.data.spoolingerrortime,
						isdefaultevent:response.data.isdefaultevent,
						isautopilot:Number(localStorage.getItem('isautopilot')) === 1 ? true:false,
						showguestcomplain:response.data.showguestcomplain,
						totalguestcomplain:response.data.totalguestcomplain,
						complaindetails:tempComplainDetails,
						isLoaded:true,
						batches:response.data.eventbatchs,
						acceptedbatchorders:response.data.eventacceptedbatchs,
						lastfivetoken:response.data.lastfivetoken,
						pendingbatchcount:response.data.pendingbatchcount,
						acceptedbatchcount:response.data.acceptedbatchcount,
						canprintorder:localStorage.getItem("canprintorder")
					}
				});
			}
			else{
                this.setState({
                    isLoaded: true,
                    isOrderavailable: false,
					isdefaultevent:false,
					isautopilot:Number(localStorage.getItem('isautopilot')) === 1 ? true:false,
                })
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
	  	});
	}

	MarkOrderPrintDone=(orderids)=>{

		let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

		const kotid		= kotedetail.kotid;
		const eventid	= kotedetail.eventid;

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'MarkOrderPrintDone');
		bodyFormData.set('kotid', kotid);
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('ids', orderids);

        axios({
			method: 'post',
			url: process.env.REACT_APP_API_URL+'order.php',
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{		  
			if(response.data.success === true)
			{
				/*localStorage.setItem("canprintorder",this.state.isautoprintactive);*/
				localStorage.setItem("canprintorder",false);

				this.setState(()=>{
					return{
						canprintorder:localStorage.getItem("canprintorder")
					}
				});
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
	  	});
	}

	UpdatePrintStatus=(status)=>{

		localStorage.setItem("canprintorder",status);

		this.setState(()=>{
			return{
				canprintorder:localStorage.getItem("canprintorder")
			}
		});

	}

    showMessage = (message, msgtype) =>{
        store.addNotification({
            title: "",
            message: message,
            type: msgtype,
            insert: "top",
            container: "top-right",
            width:350,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            fade: {
                duration: 4800,
                timingFunction: 'ease-out',
                delay: 0
            },
            dismiss: {
                duration: 4000,
                pauseOnHover: true
            }
        });
	}
	
	ProcessNextOrder=()=>{

		if(this.state.isActiveNextOrder === false)
		{
			let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

			const kotid		= kotedetail.kotid;
			const eventid	= kotedetail.eventid;
	
			if(Number(kotid) < 1 || Number(eventid) < 1)
			{
				return false;
			}
	
			const bodyFormData = new FormData();
			bodyFormData.set('Mode', 'ProcessNextPendingOrder');
			bodyFormData.set('kotid', kotid);
			bodyFormData.set('eventid', eventid);
			
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_URL+'order.php',
				data: bodyFormData,
				config: { headers: {'Content-Type': 'multipart/form-data' }}
			})
			.then(response=>{		  
				if(response.data.success === true)
				{
					this.setState(()=>{
						return{
							nextorderid:response.data.orderdetail.orderid,
							nextrecordid:response.data.orderdetail.recordid,
							isActiveNextOrder:true
						}
					});
				}
				else
				{
					this.setState(()=>{
						return{
							nextorderid:'',
							nextrecordid:'',
							isActiveNextOrder:false
						}
					},()=>{
						if(Number(this.state.isautopilot) < 1)
						{
							this.toastMessage(`No pending order found to process`,`error`);
						}
					});
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
		}
		else
		{
			this.setState(()=>{
				return{
					nextorderid:'',
					nextrecordid:'',
					isActiveNextOrder:false
				}
			});
		}
	}

    sendOrderInPendingList = (orderid, ispopup) =>{

		let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

		const kotid		= kotedetail.kotid;
		const eventid	= kotedetail.eventid;

        if(window.confirm("Are you sure? This order will be moved in new pending batch"))
        {
            const bodyFormData = new FormData();
            bodyFormData.set('Mode', 'SendOrderInPendingList');
            bodyFormData.set('kotid', kotid);
            bodyFormData.set('eventid', eventid);
            bodyFormData.set('orderid', orderid);
            bodyFormData.set('batchid', this.state.batchid);
    
            axios({
                    method: 'post',
                    url: process.env.REACT_APP_API_URL+'order.php',
                    data: bodyFormData,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{
                    
                if(response.data.success === true)
                {
                    if(ispopup === true)
                    {
                        this.togglePopup();
                    }
                    this.props.getOrders();
                }
                else
                {
					this.toastMessage(response.data.msg,`error`);
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
        }
	}
	
	wholenumbersonly = (myfield, e, dec) =>{

		var key;
		var keychar;
		
		if (window.event)
		key = window.event.keyCode;
		else if (e)
		key = e.which;
		else
		return true;
		keychar = String.fromCharCode(key);
		
		// control keys
		if ((key===null) || (key===0) || (key===8) || (key===9) || (key===13) || (key===27) )
		return true;
		
		// numbers
		else if ((("0123456789").indexOf(keychar) > -1))
		return true;
		
		// decimal point jump
		else if (dec && (keychar === "."))
		{
			myfield.form.elements[dec].focus();
			return false;
		}
		else
		return false;
	}

	SetDefaultEvent = () =>{

		let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

		const kotid		= kotedetail.kotid;
		const eventid	= kotedetail.eventid;

		let canprecess	= false;

		if(this.state.isdefaultevent === true)
		{
			if(window.confirm("Are you sure? You want to turn off default event, this action will loggout all loggedin phone."))
			{
				canprecess	= true;
			}
		}
		else
		{
			canprecess	= true;
		}

		if(canprecess)
		{
			this.setState(()=>{
				return{
					isdefaultevent:!this.state.isdefaultevent
				}
			},()=>{
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'SetDefaultEvent');
				bodyFormData.set('kotid', kotid);
				bodyFormData.set('eventid', eventid);
				bodyFormData.set('isdefault', this.state.isdefaultevent);
		
				axios({
						method: 'post',
						url: process.env.REACT_APP_API_URL+'settings.php',
						data: bodyFormData,
						config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
		
					if(response.data.success === true)
					{
						this.setState({isdefaultevent:response.data.isdefaultevent});
					}
					else
					{
						this.toastMessage(response.data.msg,`error`);
					}
				})
				.catch(function (response) {
					//handle error
					console.log(response);
				});
			});
		}
	}

    updateRunnerStatus=(runnerid, type)=>{

		let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

		const kotid		= kotedetail.kotid;
		const eventid	= kotedetail.eventid;

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'UpdateRunnerStatus');
		bodyFormData.set('type', type);
		bodyFormData.set('runnerid', runnerid);
		bodyFormData.set('kotid', kotid);
		bodyFormData.set('eventid', eventid);

		axios({
			method: 'post',
			url: process.env.REACT_APP_API_URL+'runner.php',
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{
			
			if(response.data.success === true)
			{
				this.SyncUpcomingOrders();
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}
	
    resolveComplain=(complainid, isrunnerguilty)=>{

		let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

		const eventid	= kotedetail.eventid;

		if(window.confirm("Are you sure? You want to mark complain resolve."))
		{
			const bodyFormData = new FormData();
			bodyFormData.set('Mode', 'MarkResolveComplaint');
			bodyFormData.set('complainid', complainid);
			bodyFormData.set('isrunnerguilty', Number(isrunnerguilty));
			bodyFormData.set('eventid', eventid);
	
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_URL+'order.php',
				data: bodyFormData,
				config: { headers: {'Content-Type': 'multipart/form-data' }}
			})
			.then(response=>{
				
				if(response.data.success === true)
				{
					this.SyncUpcomingOrders();
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
		}
	}

    reOpenComplain=(complainid)=>{

		let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

		const eventid	= kotedetail.eventid;

		if(window.confirm("Are you sure? You want to mark complain resolve."))
		{
			const bodyFormData = new FormData();
			bodyFormData.set('Mode', 'ReOpenComplaint');
			bodyFormData.set('complainid', complainid);
			bodyFormData.set('eventid', eventid);
	
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_URL+'order.php',
				data: bodyFormData,
				config: { headers: {'Content-Type': 'multipart/form-data' }}
			})
			.then(response=>{
				
				if(response.data.success === true)
				{
					this.SyncUpcomingOrders();
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response);
			});
		}
	}

	toggleActivePopup=()=>{
		this.setState({
			isactivepopup:!this.state.isactivepopup
		});
	}

	reciveDataUsingSocket = () =>{
		websocket.onmessage = async (event)=> {

			let resdata = JSON.parse(event.data);

			const request_type	= resdata.request_type;

			if(request_type === "updateitem")
			{
				let tempCart		= [];

				const cartdetails	= await this.state.db.fetchAllCartItem();
	
				if(cartdetails)
				{
					cartdetails.forEach(item => {
						const singleCartItem = {...item, tempinstock:true};
		
						tempCart = [...tempCart, singleCartItem];
					});
				}
								
				let canshowitem			= false;

				const item_id			= resdata.request_data.item_id;
				const item_name			= resdata.request_data.item_name;
				const item_name_hindi	= resdata.request_data.item_name_hindi;
				const isselfservice		= resdata.request_data.isselfservice;
				const islivecounter		= resdata.request_data.islivecounter;
				const instock			= resdata.request_data.instock;

				let cartProduct		= tempCart.find(cartitem => cartitem.id === item_id);
				let isstockout		= false;

				if(instock === false)
				{
					isstockout = true;
				}

				let tempProducts	= [...this.state.products];
				const tempProducts2	= tempProducts.find(product => Number(product.id) === Number(item_id));

				if(tempProducts2)
				{
					tempProducts2.title			= item_name;
					tempProducts2.hindititle	= item_name_hindi;
					tempProducts2.isselfservice	= isselfservice;
					tempProducts2.islivecounter	= islivecounter;
					tempProducts2.instock		= instock;
	
					if(cartProduct)
					{
						isstockout = false;
					}
	
					if(isstockout === true)
					{
						canshowitem = false;
					}
	
					if(canshowitem === false)
					{
						tempProducts	= tempProducts.filter(product => Number(product.id) !== Number(item_id));
					}
				}
				else
				{
					console.log(resdata.request_data);
				}

				this.setState(()=>{
					return{
						products:tempProducts
					}
				});
			}
			else if(request_type === "categorychange" || request_type === "updatestockout" || request_type === "addnewitem")
			{
				this.setProducts();
			}
			else if(request_type === "updatetabledetail")
			{
				this.setEventTables();
			}
			else if(request_type === "releaserunner")
			{
				this.GetEngagedRunners();
			}
			else if(request_type === "updatesuspendedrunner")
			{
				this.GetEventRunner('manage');
			}
		};
	}

	changeGuestComplain = (type)=>{

        let pendingcomplaintclass	= "";
		let resolvedcomplaintclass	= "";
		let complaindetails			= [];
		
		if(type === 'pending')
		{
			pendingcomplaintclass	= " btn-selected";
			complaindetails			= this.state.pendingcomplaindetails;
		}
		else if(type === 'resolved')
		{
			resolvedcomplaintclass	= " btn-selected";
			complaindetails			= this.state.resolvedcomplaindetails;
		}

		this.setState(()=>{
			return{
				type:type,
				pendingcomplaintclass:pendingcomplaintclass,
				resolvedcomplaintclass:resolvedcomplaintclass,
				complaindetails:complaindetails
			}
		},()=>{
			this.SyncUpcomingOrders();
		})
	}

	ReassignRunner = () =>{

		if(this.state.isactivereassignrunner === false)
		{
			this.setState({
				isactivereassignrunner:true,
				nexttokenid:''
			})
		}
		else
		{
			this.setState({
				isactivereassignrunner:false,
				nexttokenid:''
			})
		}
	}

	toastMessage=(msg, type)=>{
		if(type === 'success')
		{
			toast.success(`${msg}`, {
				position: "bottom-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
		else if(type === 'error')
		{
			toast.error(`${msg}`, {
				position: "bottom-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	}

	toggleConfirm_kote=(recordid_kote, recordid2_kote, message_kote, cbfunctionname_kote)=>{

		this.setState(()=>{
			return{
				isconfirmed_kote:false,
				isdialogopen_kote:!this.state.isdialogopen_kote,
				confirmmessage_kote:message_kote,
				confirmrecordid_kote:recordid_kote,
				confirmrecordid2_kote:recordid2_kote,
				cbfunctionname_kote:cbfunctionname_kote,
			}
		},()=>{
			if(!this.state.isdialogopen_kote)
			{
				this.setState(()=>{
					return{
						isconfirmed_kote:false,
						confirmmessage_kote:'',
						confirmrecordid_kote:'',
						confirmrecordid2_kote:'',
						cbfunctionname_kote:'',
					}
				})
			}
		})
	}

	SetAutoPilot = () =>{

		let promotlogout = prompt("Please enter password to change auto pilot status", "");

		if(promotlogout !== null)
		{
			if(Number(promotlogout) !== Number(process.env.REACT_APP_AUTOPILOTPASSWORD))
			{
				this.toastMessage(`Invalid Password`,`error`);
			}
			else
			{
				this.setState(()=>{
					return{
						isautopilot:!this.state.isautopilot
					}
				},()=>{
					localStorage.setItem('isautopilot', Number(this.state.isautopilot));
				});
			}
		}
	}

	render(){
		return (
			<KoteContext.Provider value={{
			...this.state,
				showMessage:this.showMessage,
				MarkOrderPrintDone:this.MarkOrderPrintDone,
				UpdatePrintStatus:this.UpdatePrintStatus,
				ProcessNextOrder:this.ProcessNextOrder,
				sendOrderInPendingList:this.sendOrderInPendingList,
				wholenumbersonly:this.wholenumbersonly,
				SetDefaultEvent:this.SetDefaultEvent,
				updateRunnerStatus:this.updateRunnerStatus,
				resolveComplain:this.resolveComplain,
				toggleActivePopup:this.toggleActivePopup,
				SyncUpcomingOrders:this.SyncUpcomingOrders,
				changeGuestComplain:this.changeGuestComplain,
				reOpenComplain:this.reOpenComplain,
				ReassignRunner:this.ReassignRunner,
				toastMessage:this.toastMessage,
				toggleConfirm_kote:this.toggleConfirm_kote,
                SetAutoPilot:this.SetAutoPilot,
			}}
			>
			{this.props.children}
			</KoteContext.Provider>
		);
	}
}

const KoteConsumer = KoteContext.Consumer;

export {KoteProvider, KoteConsumer, KoteContext};