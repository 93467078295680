import React,{Fragment,Component} from 'react';
import { ProductConsumer, ProductContext } from '../../Context/Product';
import {Search}  from '../../constants';

export default class MenuSearch extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {

        };
    }

	render(){

        return (
            <ProductConsumer>
            {(value) => {

                const{showsearch, searchkeyword} = value;
                const{showHideSearch, handleChange} = value;

                return (
                    <Fragment>
                    {
                        showsearch ? (
                            <section className="searwrapper">
                                <section label="Search within menu" value="" color="white" autoComplete="off" type="text" className="searchbx">
                                    <section className="firstfindchild" style={{display:'flex',justifyContent:'center',alignItems:'center',border:'0.1rem solid #1A73E8',borderRadius:'0.6rem'}}>
                                        <div className="findchildsub">
                                            <Search />
                                        </div>
                                        <input autoComplete="off" type="text" className="searchinput" style={{border:'unset',backgroundColor:'unset'}} name="searchkeyword" id="searchkeyword" value={searchkeyword} placeholder="Search within menu" onChange={handleChange} autoFocus/>
                                        <div className="closefindboxnew" style={{color:'#ffffff',lineHeight:'30px',borderRadius:'5px',paddingLeft:'5px',paddingRight:'5px',backgroundColor:'#ed5a6b'}} onClick={()=>{
                                                showHideSearch();
                                            }}>{
                                                searchkeyword !== "" && searchkeyword !== undefined && searchkeyword !== null ? `Clear Search`:`Close Search`
                                            }</div>
                                    </section>
                                </section>
                            </section>
                        ):(
                            <div className="newfilter" onClick={()=>{
                                showHideSearch();
                            }}>
                                <div style={{
                                    display:'flex',
                                    position:'initial'
                                }}>
                                    <i className="iconblk" size="15" color="#ed5a6b"><svg xmlns="http://www.w3.org/2000/svg" fill="#ed5a6b" width="17" height="17" viewBox="0 0 20 20" aria-labelledby="icon-svg-title- icon-svg-desc-" role="img" className="iconsc"><title>Search</title><path d="M19.78 19.12l-3.88-3.9c1.28-1.6 2.080-3.6 2.080-5.8 0-5-3.98-9-8.98-9s-9 4-9 9c0 5 4 9 9 9 2.2 0 4.2-0.8 5.8-2.1l3.88 3.9c0.1 0.1 0.3 0.2 0.5 0.2s0.4-0.1 0.5-0.2c0.4-0.3 0.4-0.8 0.1-1.1zM1.5 9.42c0-4.1 3.4-7.5 7.5-7.5s7.48 3.4 7.48 7.5-3.38 7.5-7.48 7.5c-4.1 0-7.5-3.4-7.5-7.5z"></path></svg></i>
                                </div> 
                                &nbsp;Search
                            </div>
                        )
                    }
                    </Fragment>
                );
            }}
            </ProductConsumer>
        );
	}
}