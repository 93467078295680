import React,{Component,Fragment} from 'react';
import axios from "axios";
import { KoteConsumer, KoteContext } from '../Context/Kote';
import Captainfooterbar from '../components/Captainfooterbar';
import RunnerSearchKote from '../components/RunnerSearchKote';
import KoteLiteOrderpopup from '../components/KoteLiteOrderpopup';
import KoteLiteTitle from '../components/KoteLiteTitle';
import KoteLiteOrderbatches from '../components/KoteLiteOrderbatches';
import KoteLiteAcceptedbatch from '../components/KoteLiteAcceptedbatch';
import KoteLiteReassignTokenPopup from '../components/KoteLiteReassignTokenPopup';
import ReportsummaryPopup from '../components/ReportsummaryPopup';
import {Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions} from '@material-ui/core';

export default class KoteDashboard extends Component{

    static contextType = KoteContext;

    constructor(props) {
        super(props);
        this.state = {
            scanresult:'No result',
            error : null,
            isLoaded : false,
            isOrderavailable : false,
            orders : [],
            batches:[],
            acceptedbatchorders:[],
            lastfivetoken:[],
            pendingbatchcount:0,
            acceptedbatchcount:0,
            pendingorderscount:0,
            canrefresh:true,
            isActiveReport: false,
            runnertype: null,
            isActive: false,
            totalorders:'',
            totalitems:'',
            totaluserdrunners:'',
            engaggedrunner:[],
            breakrunner:[],
            isengagerunner:false,
            isbreakrunner:false,
            lastordertime:'',
            hasordertime:false,
            engagerunnerchecktime:'',
            engagetabletchecktime:'',
            isinactivetablet:false,
            inactivetablet:[],
            suspendedrunnercount:0,
            upcommingrunners:[],
            unfinishcartchecktime:'',
            isunfinishcarttablet:false,
            unfinishedcarttablet:[],
            loadbalancingdetail:{id:0, orlo_order_id:0, runnerid:0, assigningitemcount:0},
        };
    }

    componentDidMount(){

        if(document.getElementsByClassName('page-content').length > 0)
        {
            document.getElementsByClassName("page-content")[0].classList.remove("footercartopen");
        }

        this.getPendingOrders();

        this.refreshIntervalId = setInterval(() => {

            this.getPendingOrders();

        }, 5000);
    }

    componentWillUnmount(){
        clearInterval(this.refreshIntervalId);
    }

    getPendingOrders() {

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

        const kotid		= kotedetail.kotid;
        const eventid	= kotedetail.eventid;
    
        if(Number(kotid) < 1 || Number(eventid) < 1)
        {
          return false;
        }

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetKotPendingOrders');
        bodyFormData.set('kotid', Number(kotid));
        bodyFormData.set('eventid', Number(eventid));
        bodyFormData.set('isautopilot', Number(this.context.isautopilot));

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{
              
              if(response.data.success === true)
              {
                this.setState({
                  isLoaded : true,
                  isOrderavailable : true,
                  orders : response.data.eventorders,
                  batches: response.data.eventbatchs,
                  acceptedbatchorders:response.data.eventacceptedbatchs,
                  lastfivetoken:response.data.lastfivetoken,
                  pendingbatchcount:response.data.pendingbatchcount,
                  acceptedbatchcount:response.data.acceptedbatchcount,
                  pendingorderscount:response.data.pendingorderscount,
                  totalorders:response.data.totalorders,
                  totalitems:response.data.totalitems,
                  totaluserdrunners:response.data.totaluserdrunners,
                  engaggedrunner:response.data.engaggedrunner,
                  breakrunner:response.data.breakrunner,
                  isengagerunner:response.data.isengagerunner,
                  isbreakrunner:response.data.isbreakrunner,
                  lastordertime:response.data.lastordertime,
                  hasordertime:response.data.hasordertime,
                  engagerunnerchecktime:response.data.engagerunnerchecktime,
                  engagetabletchecktime:response.data.engagetabletchecktime,
                  isinactivetablet:response.data.isinactivetablet,
                  inactivetablet:response.data.inactivetablet,
                  suspendedrunnercount:response.data.suspendedrunnercount,
                  availablerunnercount:response.data.availablerunnercount,
                  loadbalancingdetail:response.data.loadbalancingdetail,
                  upcommingrunners:response.data.upcommingrunner,
                  unfinishcartchecktime:response.data.unfinishcartchecktime,
                  isunfinishcarttablet:response.data.isunfinishcarttablet,
                  unfinishedcarttablet:response.data.unfinishedcarttablet,
                },()=>{
                    
                    if(Number(this.context.isautopilot) > 0 && Number(this.state.isOrderavailable) > 0 && (Number(this.state.availablerunnercount) > 0 || Number(this.state.loadbalancingdetail.assigningitemcount) > 0))
                    {
                      this.context.ProcessNextOrder(this.state.loadbalancingdetail);
                    }
                });
              }
              else
              {
                this.setState({
                    isLoaded: true,
                    isOrderavailable: false,
                    availablerunnercount:0,
                    loadbalancingdetail:response.data.loadbalancingdetail,
                    upcommingrunners:[],
                    unfinishcartchecktime:'',
                    isunfinishcarttablet:false,
                    unfinishedcarttablet:[],
                })
              }
            })
            .catch(function () {
              //handle error
          });
    }

    acceptbatchorders=(id, colorindex)=>{

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

        const kotid		= kotedetail.kotid;
        const eventid	= kotedetail.eventid;
    
        if(Number(kotid) < 1 || Number(eventid) < 1)
        {
          return false;
        }

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'AcceptKotOrder');
        bodyFormData.set('batchid', id);
        bodyFormData.set('colorindex', colorindex);
        bodyFormData.set('kotid', Number(kotid));
        bodyFormData.set('eventid', Number(eventid));

        axios({
				method: 'post',
				url: process.env.REACT_APP_API_URL+'order.php',
				data: bodyFormData,
				config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{
              
              if(response.data.success === true)
              {
                  let tempBatchOrders = this.state.acceptedbatchorders;

                  if(response.data.eventacceptedbatchs)
                  {
                    response.data.eventacceptedbatchs.forEach(item => {
                      const singleBatchItem = {...item};

                      tempBatchOrders = [...tempBatchOrders, singleBatchItem];
                    });
                  }

                  this.setState({
                    acceptedbatchorders:tempBatchOrders
                  });
              }
              else
              {
				this.context.toastMessage(response.data.msg, 'error');
              }
            })
            .catch(function (response) {
              //handle error
              console.log(response);
          });
    }

    rejectbatchorders=(id)=>{

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

        const kotid		= kotedetail.kotid;
        const eventid	= kotedetail.eventid;
    
        if(Number(kotid) < 1 || Number(eventid) < 1)
        {
          return false;
        }

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'RejectKotOrder');
        bodyFormData.set('batchid', id);
        bodyFormData.set('kotid', Number(kotid));
        bodyFormData.set('eventid', Number(eventid));

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{
              
              if(response.data.success === true)
              {
                let tempBatchOrders = [...this.state.acceptedbatchorders];
                const acceptedorder = this.state.acceptedbatchorders.find(item => item.batchid === id);
                const index         = tempBatchOrders.indexOf(acceptedorder);

                let tempPendingBatches  = this.state.batches;
                tempPendingBatches      = [...tempPendingBatches, acceptedorder];

                if(index !== -1)
                {
                    tempBatchOrders.splice(index, 1);
                    this.setState({
                        acceptedbatchorders: tempBatchOrders,
                        batches:tempPendingBatches
                    });
                }
              }
              else
              {
				this.context.toastMessage(response.data.msg, 'error');
              }
            })
            .catch(function (response) {
              //handle error
              console.log(response);
          });
    }

    toggleReportPopup() {
      this.setState({
          isActiveReport: !this.state.isActiveReport
      });
    }

    togglePopup() {
        this.setState({
            isActive: !this.state.isActive
        });
    }

	render(){

    const {batches, acceptedbatchorders, acceptedbatchcount, pendingorderscount, totalorders, totalitems, totaluserdrunners, breakrunner, isbreakrunner, lastordertime, hasordertime, engagetabletchecktime, isinactivetablet, inactivetablet, upcommingrunners, unfinishcartchecktime, isunfinishcarttablet, unfinishedcarttablet} = this.state;

    return (
        <Fragment>
          <KoteConsumer>
          {(value) => {
              
            const {ProcessNextOrder, sendOrderInPendingList, ReassignRunner, toggleConfirm_kote, SetAutoPilot} = value;
            const {nextorderid, isActiveNextOrder, isactivereassignrunner, nexttokenid, isdialogopen_kote, confirmmessage_kote, isautopilot} = value;

              return (
                <Fragment>
                  <div className="view-cart-wrapper">
                      <div className="local-cart-container selecttable engagedrunner">
                          <div className="ordermessage runnermsg">Orlo SOS KOT</div>
                          <div className="searchfilter mb-2">
                              <RunnerSearchKote />
                          </div>
                          <div className="separator"></div>
                          <div className="searchbox" style={{display:'flex', justifyContent:'space-between'}}>
                            <div className="managewrapper defaultevent"><strong style={{fontWeight:'bold'}}>Auto Pilot :</strong> <input type="checkbox" name="isautopilot" id="isautopilot" value="1" checked={Number(isautopilot) === 1} onChange={()=>{SetAutoPilot()}}/><label htmlFor="isautopilot">Auto Pilot</label></div>
                            <div>
                                <button className="releaserunnerbtn smallbutton acceptnext" style={{width:'150px',float:'right',marginRight:'5px'}} onClick={()=>ProcessNextOrder()}>Accept Order</button>
                                <button className="releaserunnerbtn smallbutton" style={{float:'right',marginRight:'5px'}} onClick={()=>{this.setState({reporttype:'dashboardsummary'},this.toggleReportPopup.bind(this))}}>Report</button>
                            </div>
                          </div>

                          <div className="separator"></div>

                          <div className="container-fluid">
                            {
                                hasordertime ? (
                                    <div
                                    style={{
                                        textAlign:'center',
                                        paddingBottom:'5px',
                                        fontSize:'16px',
                                    }}>
                                        (Last order received <span className="errormsg">{lastordertime}</span> ago)
                                    </div>
                                ):(
                                    <div
                                    style={{
                                        textAlign:'center',
                                        paddingBottom:'20px',
                                        fontSize:'16px',
                                    }}>
                                        (No order received yet)
                                    </div>
                                )
                            }
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div 
                                style={{
                                    display:'inline-block',
                                    paddingRight:'10px',
                                }}>
                                Total Order : <button 
                                    type="button" 
                                    className="disablerunner-btn"
                                    style={{
                                        background:'#389885',
                                        paddingLeft:'15px',
                                        paddingRight:'15px',
                                        width:'50px',
                                    }}>
                                    {Number(totalorders)}
                                </button></div>{` `}
                                <div 
                                style={{
                                    display:'inline-block',
                                    paddingRight:'10px',
                                }}>
                                Total Items : <button 
                                    type="button" 
                                    className="disablerunner-btn"
                                    style={{
                                        background:'#389885',
                                        paddingLeft:'15px',
                                        paddingRight:'15px',
                                        width:'50px',
                                    }}>
                                    {Number(totalitems)}
                                </button></div>{` `}
                                <div 
                                style={{
                                    display:'inline-block',
                                }}>
                                Engaged Runner : <button 
                                    type="button" 
                                    className="disablerunner-btn"
                                    style={{
                                        background:'#389885',
                                        paddingLeft:'15px',
                                        paddingRight:'15px',
                                        width:'50px',
                                    }}>
                                    {Number(totaluserdrunners)}
                                </button>
                                </div>{` `}
                            </div>
                            {
                                isinactivetablet ? (
                                    <div style={{
                                            textAlign:'center',
                                            fontWeight:'bold',
                                            paddingTop:'10px',
                                        }}
                                    >Inactive Tablet Summary {engagetabletchecktime ? (<span>Over <span style={{color:'#ff0000'}}>{engagetabletchecktime} minutes</span></span>):``}</div>
                                ):null
                            }
                            {
                                isinactivetablet ? (
                                    <div className="runnersearchresult"
                                    style={{
                                        position:'unset',
                                        width:'100%'
                                    }}>
                                    <ul className="resultList" style={{marginTop:'5px'}}>
                                        {
                                            inactivetablet.map((tablet)=>{
                                                return (
                                                    <li key={tablet.index} title={`${tablet.seatname} Inactive since ${tablet.inactivestatus}`} style={{float:'left',width:'80px',clear:'unset',listStyle:'none',borderBottom:'0px solid #f5f5f5',textAlign:'center',marginBottom:'5px',marginLeft:'5px',color:`${tablet.color}`,backgroundColor:`${tablet.bgcolor}`,borderRadius:'5px',cursor:'pointer'}}>
                                                    {tablet.seatname}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                ):null
                            }

                            {
                                isunfinishcarttablet ? (
                                    <div style={{
                                            textAlign:'center',
                                            fontWeight:'bold',
                                            paddingTop:'10px',
                                        }}
                                    >Unfinished Cart Summary {unfinishcartchecktime ? (<span>Over <span style={{color:'#ff0000'}}>{unfinishcartchecktime} seconds</span></span>):``}</div>
                                ):null
                            }
                            {
                                isunfinishcarttablet ? (
                                    <div className="runnersearchresult"
                                    style={{
                                        position:'unset',
                                        width:'100%'
                                    }}>
                                    <ul className="resultList" style={{marginTop:'5px'}}>
                                        {
                                            unfinishedcarttablet.map((carttable, inactivecartloop)=>{
                                                return (
                                                    <li key={inactivecartloop} title={`${carttable.seatname} Inactive since ${carttable.inactivestatus}`} style={{float:'left',width:'80px',clear:'unset',listStyle:'none',borderBottom:'0px solid #f5f5f5',textAlign:'center',marginLeft:'5px',marginBottom:'5px',color:`${carttable.color}`,backgroundColor:`${carttable.bgcolor}`,borderRadius:'5px',cursor:'pointer'}}>
                                                    {carttable.seatname}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                ):null
                            }

                            {
                                isbreakrunner ? (
                                    <div
                                        style={{
                                            textAlign:'center',
                                            fontWeight:'bold',
                                            paddingTop:'10px'
                                        }}
                                    >Runner Break Summary</div>
                                ):null
                            }
                            {
                                isbreakrunner ? (
                                    <div className="runnersearchresult"
                                    style={{
                                        position:'unset',
                                        width:'100%'
                                    }}>
                                    <ul className="resultList" style={{marginTop:'5px'}}>
                                        {
                                            breakrunner.map((runner)=>{
                                                return (
                                                    <li key={runner.runnerid} title={`Runner ${runner.runnerid} is on break since ${runner.breakstatus}`} style={{float:'left',width:'80px',clear:'unset',listStyle:'none',borderBottom:'0px solid #f5f5f5',textAlign:'center',marginLeft:'5px',color:`${runner.color}`,backgroundColor:`${runner.bgcolor}`,borderRadius:'5px',cursor:'pointer'}}>
                                                    Runner - {runner.runnerid}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                ):null
                            }
                          </div>

                          {(() => {
                          if(batches.length > 0 ){
                              return (<div>
                              <div className="separator"></div>
                              <KoteLiteTitle title="Pending Orders" total={pendingorderscount}/>
                              <div className="container-fluid">
                                  {
                                      batches?(
                                        batches.map(batch => (
                                          <KoteLiteOrderbatches key={batch.batchid} batch = {batch} acceptbatchorders = {this.acceptbatchorders}/>
                                        ))
                                      ):null
                                  }
                              </div>
                              </div>)
                          }
                          })()}

                          {(() => {
                              if(acceptedbatchorders.length > 0 ){
                              return (<div>
                                  <div className="separator"></div>
                                  <KoteLiteTitle title="Accepted Batches" total={acceptedbatchcount}/>
                                  <div className="separator"></div>
                                  <div className="horizontal-container_org">
                                  {
                                  acceptedbatchorders?(
                                      acceptedbatchorders.map(acceptedbatchorder => (
                                      <KoteLiteAcceptedbatch key={acceptedbatchorder.batchid} acceptedbatchorder = {acceptedbatchorder} rejectbatchorders = {this.rejectbatchorders} getPendingOrderList={this.getPendingOrders}/>
                                      ))
                                  ):null
                                  }
                                  </div>
                              </div>)
                              }
                          })()}
                      </div>
                  </div>
                  {
                      isActiveNextOrder?(<KoteLiteOrderpopup closePopup={ProcessNextOrder.bind(this)} isActive={isActiveNextOrder} orderid={nextorderid} sendOrderInPendingList={sendOrderInPendingList}/>):null
                  }
                  {
                      isactivereassignrunner?(<KoteLiteReassignTokenPopup closePopup={ReassignRunner.bind(this)} isActive={isactivereassignrunner} orderid={nexttokenid} sendOrderInPendingList={sendOrderInPendingList}/>):null
                  }
                  {
                      this.state.isActiveReport?(<ReportsummaryPopup closeReportPopup={this.toggleReportPopup.bind(this)} isActiveReport={this.state.isActiveReport} reporttype={this.state.reporttype}/>):null
                  }

                  <Dialog
                    open={isdialogopen_kote}
                    onClose={toggleConfirm_kote}
                    aria-labelledby="siteconfirm_kotlite"
                    aria-describedby="sitedescription_kotlite"
                    >
                    <DialogTitle id="siteconfirm_kotlite" style={{color:'#000000',fontWeight:'bold'}}>{`Confirm`}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="sitedescription_kotlite" style={{color:'#000000'}}>
                      {confirmmessage_kote}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button color="secondary" style={{background:'#363636',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{toggleConfirm_kote()}}>No</Button>
                      <Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
                        /*if(cbfunctionname_kote === 'cancelOrderItem')
                        {
                          cancelOrderItem(confirmrecordid)
                        }*/
                      }} autoFocus>Yes</Button>
                    </DialogActions>
                  </Dialog>

                  {
                    Object.keys(upcommingrunners).length > 0 ? (<Fragment><br/><br/><br/><br/><br/></Fragment>):(<Fragment><br/></Fragment>)
                  }

                {
                    Object.keys(upcommingrunners).length > 0 ? (<div className='upcommingrunners'><div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}><span style={{fontWeight:'bold'}}>Next Runner :</span>{` `}
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            {
                                upcommingrunners.map((runner, loop)=>{
                                    return(
                                    <button key={loop}
                                        type="button" 
                                        className="disablerunner-btn"
                                        style={{
                                            background:runner.bgcolor,
                                            color:runner.color,
                                            paddingLeft:'15px',
                                            paddingRight:'15px',
                                            marginLeft:'5px',
                                        }}>
                                        {runner.name}
                                    </button>)
                                })
                            }
                        </div>
                    </div></div>):null
                }

                </Fragment>
              );
          }}
          </KoteConsumer>
          <Captainfooterbar />
        </Fragment>
    );
	}
}