import React,{Component,Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { ProductContext, ProductConsumer } from '../Context/Product';
import BannerThanks from '../components/BannerThanks';
import logo from '../assets/images/thankyou.webp';

import excellent from '../assets/images/smileys/excellent.jpg';
import good from '../assets/images/smileys/good.jpg';
import medium from '../assets/images/smileys/medium.jpg';
import poor from '../assets/images/smileys/poor.jpg';
import verybad from '../assets/images/smileys/verybad.jpg';

import {CircularProgress} from '@material-ui/core';

class OrderThanks extends Component {

    static contextType = ProductContext;

    constructor(props) {
        super(props);

        this.state = {
            orderid:'',
            stockoutproducts:'',
            isstockoutfound:false,
            stockouttextlength:0,
            seatname:'',
            lefttimetoredirect:'',
            redirectiontime:30,
            processing: false,
            feedbackid: '',
            rating: '',
            phone: '',
            ratingsarr: [{'title':'excellent','description':'You rated us Excellent and made our day!'},{'title':'good','description':'You rated us Good, we will try to do better!'},{'title':'medium','description':'We will try better next time!'},{'title':'poor','description':'We messed up this time. We will improve in future.'},{'title':'verybad','description':'Extremely sorry for the bad experience. We will try to improve next time.'}],
            isnumbershared: false,
        }
        
        this.refreshIntervalId  = null;
        this.menuTicker         = null;
    }

    static contextType = ProductContext;

    componentDidMount(){

        this.context.setEventTables();
        this.context.ResetOrderProcess();
        this.context.setEventThanksBanner();

        let temploggedininfo    = {};
        let tempcanchangetable  = false;

        let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

        if(tabledetail || captiondetail || stewarddetail)
        {
            if(tabledetail !== null && Number(tabledetail.eventid) > 0)
            {
                temploggedininfo    = tabledetail;
            }
            else if(Number(stewarddetail.eventid) > 0)
            {
                temploggedininfo	= stewarddetail.selectedtable;
                if((Number(stewarddetail.tabletype) !== 2 && Object.keys(stewarddetail.stewardtable).length > 1) || Number(stewarddetail.tabletype) === 0)
                {
                    tempcanchangetable  = true;
                }
            }
            else if(Number(captiondetail.eventid) > 0)
            {
                temploggedininfo	= captiondetail.selectedtable;
                tempcanchangetable  = true;
            }
        }

        let tempthanksinterval  = 30;

        if(this.context.eventdetail !== undefined)
        {
            tempthanksinterval  = this.context.eventdetail.thanksinterval;
        }

        this.setState({
            orderid:this.props.match.params.orderid,
            loggedininfo:temploggedininfo,
            canchangetable:tempcanchangetable,
            redirectiontime:tempthanksinterval
        });

        this.getStockOutName();

        this.thanksTimer();
    }

    componentWillUnmount(){
        clearInterval(this.refreshIntervalId);
        clearInterval(this.menuTicker);
    }

    getStockOutName = () => {
    
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

        let locationid     = "";

        if(tabledetail || captiondetail || stewarddetail)
        {
			if(tabledetail !== null && (Number(tabledetail.locationid) > 0 && tabledetail.locationid !== null))
			{
				locationid	= tabledetail.locationid;
			}
			else if(captiondetail !== null && (Number(captiondetail.locationid) > 0  && captiondetail.locationid !== null))
			{
				locationid	= captiondetail.locationid;
			}
			else if(stewarddetail !== null && (Number(stewarddetail.locationid) > 0 && stewarddetail.locationid !== null))
			{
				locationid	= stewarddetail.locationid;
			}
        }

        let menuredirectiontime = this.state.redirectiontime;

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetStockOutName');
        bodyFormData.set('locationid', locationid);
        bodyFormData.set('order_id', this.props.match.params.orderid);
    
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{          
            if(response.data.success === true)
            {
                this.setState({
                    isstockoutfound:response.data.isstockoutfound,
                    stockoutproducts:response.data.stockoutproducts,
                    stockouttextlength:response.data.stockouttextlength,
                    seatname:response.data.seatname
                });
            }

            this.refreshIntervalId = setTimeout(() => {

                let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
                let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
                let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));
        
                if(captiondetail || stewarddetail)
                {
                    if(captiondetail !== null && Number(captiondetail.eventid) > 0)
                    {
                        this.props.history.push("/tableselection");
                    }
                    else if(stewarddetail !== null && Number(stewarddetail.eventid) > 0)
                    {
                        if(Number(stewarddetail.tabletype) === 2 || Object.keys(stewarddetail.stewardtable).length === 1)
                        {
                            this.props.history.push("/menu");
                        }
                        else
                        {
                            this.props.history.push("/tableselection");
                        }
                    }
                    else
                    {
                        if(tabledetail.ordernotification)
                        {
                            this.context.toggleOrderStatus();
                        }                       
                        this.props.history.push("/menu");
                    }
                }
                else
                {
                    if(tabledetail.ordernotification)
                    {
                        this.context.toggleOrderStatus();
                    }                       
                    this.props.history.push("/menu");
                }

            }, Number(menuredirectiontime) * 1000);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    handleClick=()=>{
        this.context.setCaptainTable();

        let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

        if(captiondetail || stewarddetail)
        {
            if(captiondetail !== null && Number(captiondetail.eventid) > 0)
            {
                this.props.history.push("/tableselection");
            }
            else if(stewarddetail !== null && Number(stewarddetail.eventid) > 0)
            {
                if(Number(stewarddetail.tabletype) === 2 || Object.keys(stewarddetail.stewardtable).length === 1)
                {
                    this.props.history.push("/menu");
                }
                else
                {
                    this.props.history.push("/tableselection");
                }
            }
            else
            {
                if(tabledetail.ordernotification)
                {
                    this.context.toggleOrderStatus();
                }                       
                this.props.history.push("/menu");
            }
        }
        else
        {
            if(tabledetail.ordernotification)
            {
                this.context.toggleOrderStatus();
            }                       
            this.props.history.push("/menu");
        }
    }
    
    handleLeaveFeedback=()=>{
        this.props.history.push("/feedback");
    }

    handleUserInput = (e) => {
        const name  = e.target.name;
        const value = e.target.value;

        switch(name) {
            case "phone":

            let tempphone = value;
            let mobile    = tempphone.replace(/ /g,'');
            
            if(tempphone.slice(0,1) === '+')
            {
              mobile = mobile.substring(3);
            }

            if(tempphone.slice(0,1) === '0')
            {
              mobile = mobile.substring(1);
            }

            if(mobile.length > 10)
            {
              return;
            }

            this.setState(()=>{
                return{
                    phone:mobile
                }
            })
            break;
            default:
            this.setState(()=>{
                return{
                    [name]: value
                }
            })
        }
    }

    leaveFeedback=()=>{

        const{eventid,seatid, seattype} = this.state.loggedininfo;

        this.setState(()=>{
            return{
                processing:true
            }
        },()=>{
            setTimeout(() => {
                const bodyFormData = new FormData();
                bodyFormData.set('Mode', 'LeaveFeedback');
                bodyFormData.set('eventid', Number(eventid));
                bodyFormData.set('seatid', Number(seatid));
                bodyFormData.set('seattype', seattype);
                bodyFormData.set('rating', this.state.rating);

                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}feedback.php?t=${Date.now()}`,
                    data: bodyFormData,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                .then(response=>{
        
                    this.setState(()=>{
                        return{
                            processing:false,
                        }
                    },()=>{
                        if(response.data.success === true && Number(response.data.feedbackid) > 0)
                        {
                            this.setState(()=>{
                                return{
                                    feedbackid:response.data.feedbackid,
                                    redirectiontime:30
                                }
                            },()=>{
                                clearInterval(this.menuTicker);
                                this.thanksTimer();
                                /*this.feedbackTimer();*/
                            })
                        }
                    })
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
            }, 800);
        })
    }

    shareYourPhone=(e)=>{
        e.preventDefault();

        const{eventid,seatid, seattype} = this.state.loggedininfo;
        const{phone, feedbackid} = this.state;

        let message = "";

        if((phone === "" || phone === null || phone === undefined) && message === "")
        {
          message = `Please enter your phone number`;
        }
        else if(phone.length < 10 && message === "")
        {
          message = `Phone number should be 10 digit`;
        }
        if(message !== "")
        {
          this.context.toastMessage(`${message}`,`error`);
          return; 
        }

        this.setState(()=>{
            return{
                processing:true
            }
        },()=>{
            setTimeout(() => {
                const bodyFormData = new FormData();
                bodyFormData.set('Mode', 'ShareNumber');
                bodyFormData.set('eventid', Number(eventid));
                bodyFormData.set('seatid', Number(seatid));
                bodyFormData.set('seattype', seattype);
                bodyFormData.set('phone', phone);
                bodyFormData.set('feedbackid', feedbackid);

                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}feedback.php?t=${Date.now()}`,
                    data: bodyFormData,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                .then(response=>{

                    let success = response.data.success;
        
                    this.setState(()=>{
                        return{
                            processing:false,
                            isnumbershared:true,
                        }
                    },()=>{

                        setTimeout(() => {

                            if(success === true)
                            {
                                this.setState({
                                    lefttimetoredirect:'',
                                    feedbackid:'',
                                    phone:'',
                                    rating:'',
                                    isnumbershared:false,
                                    redirectiontime:30
                                },()=>{
                                    clearInterval(this.menuTicker);

                                    this.props.history.push("/menu");
                                })
                            }
                            else
                            {
                                this.setState({
                                    lefttimetoredirect:'',
                                    feedbackid:'',
                                    phone:'',
                                    rating:'',
                                    isnumbershared:false,
                                    redirectiontime:30
                                },()=>{
                                    clearInterval(this.menuTicker);

                                    this.props.history.push("/menu");
                                })
                            }
                            
                        }, 3000);
                    })
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
            }, 800);
        })
    }

    thanksTimer=()=>{

        const{redirectiontime} = this.state;

        let menuredirectiontime = redirectiontime;

        this.menuTicker = setInterval(()=>{
          if(menuredirectiontime <= 0)
          {
            this.setState({
                lefttimetoredirect:'',
                feedbackid:'',
                phone:'',
                rating:'',
                redirectiontime:30,
                isnumbershared:false
            },()=>{
                clearInterval(this.menuTicker);
                if(this.state.loggedininfo !== undefined)
                {
                    if(!this.state.loggedininfo.isfeedbacklogin)
                    {
                        this.props.history.push("/menu");
                    }
                }
            })
          }
          else
          {
            this.setState({
                lefttimetoredirect:menuredirectiontime
            })
          }
          menuredirectiontime -= 1;
        }, 1000);        
    }

    resetCounter=()=>{
        this.setState(()=>{
            return{
                redirectiontime:60
            }
        },()=>{
            clearInterval(this.menuTicker);
            this.thanksTimer();
        })
    }

    render() {
        
        const{stockoutproducts,isstockoutfound,stockouttextlength,lefttimetoredirect, feedbackid, processing, phone, rating, ratingsarr, isnumbershared} = this.state;

        let additionalclass = '';

        if(stockouttextlength > 56)
        {
            additionalclass = 'scrollablestockoutitem';
        }

        return(
            <ProductConsumer>
            {(value)=>{

                const {isThanksBannerLoaded, eventthanksbanner, iscaptionlogin, eventdetail, loggedintabledetail, miscdetail} = value;

                return (
                    <Fragment>
                    <div className="row nomargin lightbluebg">
                        {
                            feedbackid ? (<Fragment>
                                {
                                    isnumbershared ? (<Fragment>
                                        <div style={{display:'flex',justifyContent:'center',flexDirection:'column',textAlign:'center'}}>
                                            <div className={`d-flex`} style={{justifyContent:'center',flexDirection:'column',textAlign:'center',marginBottom:'10px',width:'100%'}}>
                                                <img src={logo} className="app-logo" alt="logo" style={{width:'350px'}}/>
                                            </div>
                                            {/*<div style={{fontSize:'8rem',marginBottom:'10px'}}>Thank You!</div>*/}
                                            <div>
                                                <i className="fa fa-check-circle" style={{fontSize:'18rem',color:'green'}} aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <br/><br/>
                                    </Fragment>):(<Fragment>
                                        <form className="loginform login100-form validate-form" id="loginform" name="loginform" onSubmit={this.shareYourPhone}>
                                            <div className='container'>
                                                <div className={`d-flex`} style={{justifyContent:'center',flexDirection:'column',textAlign:'center',marginBottom:'10px'}}>
                                                    <img src={logo} className="app-logo" alt="logo" style={{maxWidth:'70%',margin:'auto'}}/>
                                                </div>
                                                {
                                                    feedbackid ? (<div style={{textAlign:'center',fontSize:'2.5rem'}}><br/>{ratingsarr.find(item => item.title === rating).description}</div>):``
                                                }
                                                <br/>
                                                <div className="wrap-input100 validate-input">
                                                    <input type="number" className={`input100`} name="phone" id="phone" value={phone} onChange={this.handleUserInput} placeholder="Leave us your mobile number" onFocus={()=>{
                                                        this.resetCounter();
                                                    }}/>
                                                </div>
                                                <button type="submit" className="login100-form-btn" disabled={processing}>
                                                {
                                                    processing ? <CircularProgress color='inherit' />:`Call me back`
                                                }
                                                </button>
                                                <br />
                                                <button type="button" className="login100-form-btn" style={{backgroundColor:'#363636'}} onClick={()=>this.handleClick()}>
                                                    Go back
                                                </button>
                                                {
                                                    lefttimetoredirect !== "" ? (
                                                        <div style={{
                                                            marginTop:'10px',
                                                            fontWeight:'500',
                                                            fontSize:'1.8rem',
                                                            textAlign:'center'
                                                        }}>Showing feedback screen in <span style={{
                                                            color:'#ff0000'
                                                        }}>{lefttimetoredirect}</span> seconds...</div>
                                                    ):null
                                                }
                                            </div>
                                        </form>
                                    </Fragment>)
                                }
                            </Fragment>):(<Fragment>
                                <div className="col-sm" style={{paddingLeft:'0',paddingRight:'0'}}>
                                    <div className="loadingwrapper">
                                        <div style={{paddingLeft:'1rem',paddingRight:'1rem'}}>
                                        <br />
                                        {
                                            loggedintabledetail.showbanners ? (<Fragment>
                                                {
                                                    isThanksBannerLoaded && eventthanksbanner !== null && eventthanksbanner !== undefined && Object.keys(eventthanksbanner).length > 0 ? (<Fragment>
                                                        {
                                                            iscaptionlogin ?(<Fragment>{
                                                                eventdetail.showbannerandvideotocaptain ? (<h1 style={{color:'#ed5a6b',fontSize:'5rem'}}>Thanks!</h1>):<img src={logo} className="app-logo" alt="logo" />
                                                            }</Fragment>):<h1 style={{color:'#ed5a6b',fontSize:'5rem'}}>Thanks!</h1>
                                                        }
                                                    </Fragment>):<img src={logo} className="app-logo" alt="logo" />
                                                }
                                            </Fragment>):<img src={logo} className="app-logo" alt="logo" />
                                        }
                                        <div className="notfoundsubtitle"> <br />Your food will be served at your seat with in few minutes.</div>
                                        {
                                            isstockoutfound ? (
                                                <div className="stockoutitemlist">
                                                    <div>Following item is out of stock:</div>
                                                    <div className={`stockoutproduct ${additionalclass}`}>{stockoutproducts}</div>
                                                </div>
                                            ):null
                                        }
                                        {
                                            miscdetail.isfeedback ? (<Fragment>
                                                {
                                                    feedbackid ? ``:(<div style={{textAlign:'center',fontSize:'2.5rem'}}><br/>How was your experience?</div>)
                                                }
                                                <div className={`d-flex`} style={{justifyContent:'center'}}>
                                                    <div style={{padding:'20px',paddingLeft:'40px',textAlign:'center'}} onClick={()=>{
                                                        this.setState(()=>{
                                                            return{
                                                                rating:'excellent'
                                                            }
                                                        },()=>{
                                                            this.leaveFeedback();
                                                        })
                                                    }}>
                                                        <img src={excellent} alt="Excellent" style={{width:'100%'}}/>
                                                    </div>
                                                    <div style={{padding:'20px',textAlign:'center'}} onClick={()=>{
                                                        this.setState(()=>{
                                                            return{
                                                                rating:'good'
                                                            }
                                                        },()=>{
                                                            this.leaveFeedback();
                                                        })
                                                    }}>
                                                        <img src={good} alt="Good" style={{width:'100%'}}/>
                                                    </div>
                                                    <div style={{padding:'20px',textAlign:'center'}} onClick={()=>{
                                                        this.setState(()=>{
                                                            return{
                                                                rating:'medium'
                                                            }
                                                        },()=>{
                                                            this.leaveFeedback();
                                                        })
                                                    }}>
                                                        <img src={medium} alt="Medium" style={{width:'100%'}}/>
                                                    </div>
                                                    <div style={{padding:'20px',textAlign:'center'}} onClick={()=>{
                                                        this.setState(()=>{
                                                            return{
                                                                rating:'poor'
                                                            }
                                                        },()=>{
                                                            this.leaveFeedback();
                                                        })
                                                    }}>
                                                        <img src={poor} alt="Poor" style={{width:'100%'}}/>
                                                    </div>
                                                    <div style={{padding:'20px',paddingRight:'40px',textAlign:'center'}} onClick={()=>{
                                                        this.setState(()=>{
                                                            return{
                                                                rating:'verybad'
                                                            }
                                                        },()=>{
                                                            this.leaveFeedback();
                                                        })
                                                    }}>
                                                        <img src={verybad} alt="Very Bad" style={{width:'100%'}}/>
                                                    </div>
                                                </div>
                                                {
                                                    processing ? <div className={`d-flex mt-5`} style={{justifyContent:'center'}}><CircularProgress color='inherit' /></div>:``
                                                }
                                                <div className='container'>
                                                    <br/><br/>
                                                    <p style={{textAlign:'center'}}>Your feedback helps us in improving our service. An excellent rating boosts our confidence and makes our day!</p>
                                                </div>
                                            </Fragment>):``
                                        }
                                        <div className="placeneworderbtn"><button className="jomat" onClick={()=>{this.handleClick()}}>Place Another Order</button></div>
                                    </div>
                                    {
                                        lefttimetoredirect !== "" ? (
                                            <div style={{
                                                marginTop:'5px',
                                                fontWeight:'500',
                                                fontSize:'1.8rem'
                                            }}>Showing menu in <span style={{
                                                color:'#ff0000'
                                            }}>{lefttimetoredirect}</span> seconds...</div>
                                        ):null
                                    }
                                    {
                                        loggedintabledetail.showbanners ? (<Fragment>
                                            {
                                                iscaptionlogin ?(<Fragment>{
                                                    eventdetail.showbannerandvideotocaptain ? (<div style={{width:'100%',margin:'auto',marginTop:'1rem'}}>
                                                    <BannerThanks />
                                                </div>):null
                                                }</Fragment>):(<div style={{width:'100%',margin:'auto',marginTop:'1rem'}}>
                                                <BannerThanks />
                                            </div>)
                                            }
                                        </Fragment>):null
                                    }
                                    </div>
                                </div>
                            </Fragment>)
                        }
                    </div>
                  </Fragment>
                );
            }}
            </ProductConsumer>
        )
    }
}

export default withRouter(OrderThanks);