import React,{Component,Fragment} from 'react';

import {Dialog, DialogTitle, DialogContent, Button, Divider} from '@material-ui/core';

import { ProductConsumer, ProductContext } from '../Context/Product';
import Captainfooterbar from '../components/Captainfooterbar';
import RunnerSearch from '../components/RunnerSearch';
import Popuptokendetails from '../components/Popuptokendetails';

export default class Releaserunner extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            scanresult:'No result'
        };
    }
    componentDidMount(){

        if(document.getElementsByClassName('page-content').length > 0)
        {
            document.getElementsByClassName("page-content")[0].classList.remove("footercartopen");
        }

        this.context.GetEngagedRunners();

        this.refreshIntervalId = setInterval(() => {
            this.context.GetEngagedRunners();
        }, 5000);
    }

    componentWillUnmount(){
        clearInterval(this.refreshIntervalId);
    }

	render(){

        return (
            <Fragment>
            <ProductConsumer>
            {(value) => {
                const {engagedrunners, isActiveDetail, showbreakpopup, searchrunnerid, showcancelpopup, runneritemdetail, showsplitorderpopup, numberofsplit, catsummary, upcommingrunners, runnersystemrequired} = value;
                const {handleShowHideBreak, viewDetail, handleChange, breakRunner, handleShowHideCancel, handleSelectDeselectItem, /*cancelSelectedItem,*/ selectdeselectitem, handleShowHideSplitOrder, splitRunnerOrder, toggleConfirm} = value;
                
                return (
                    <div className="view-cart-wrapper">
                        <Dialog onClose={handleShowHideCancel} aria-labelledby="simple-dialog-title" open={showcancelpopup}>
                            <DialogTitle id="cancel-runner-order" style={{paddingBottom:'0'}}><b>Cancel Items</b></DialogTitle>
                            <DialogContent style={{margin:'auto',marginBottom:'10px',textAlign:'center'}}>
                                <div className="local-cart-container" style={{width:'100%',minWidth:'245px',minHeight:'20vh'}}>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        <button className="linkstyle" onClick={()=>{
                                            selectdeselectitem(true);
                                        }}>Check All</button>
                                        &nbsp;||&nbsp;
                                        <button className="linkstyle" onClick={()=>{
                                            selectdeselectitem(false);
                                        }}>Un-Check All</button>
                                    </div>
                                    <Divider />
                                    <ul className="cart-products-list">
                                    {runneritemdetail.map(item=>{
                                        return(
                                            <li key={item.detailid} style={{padding:'0px 0px 5px 0px'}}>
                                                <div className="product-item-row" style={{minHeight:'50px'}}>
                                                    <div className="product-item-detail-wrapper" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                                        <div style={{width:'10%'}}><input type="checkbox" name="selecteditem[]" id={`selecteditem${item.detailid}`} value="1" checked={Number(item.ischecked) === 1} onChange={()=>{handleSelectDeselectItem(item.detailid)}}/>
                                                        </div>
                                                        <div className="product-title-wrapper clearfix" style={{width:'90%'}}>
                                                            <div className={`product-title-english`} style={{fontSize:'17px',textAlign:'left'}}>
                                                            {item.itemname} X {item.qty}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                    </ul>
                                </div>
								{/*<Button
                                    style={{width:'70%'}}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{cancelSelectedItem()}}>
                                    Cancel
                                </Button>*/}
								<Button
                                    style={{width:'70%'}}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{toggleConfirm('', '', 'Are you sure?', 'cancelSelectedItem')}}>
                                    Cancel
                                </Button>
                            </DialogContent>
                        </Dialog>
                        <Dialog onClose={handleShowHideBreak} aria-labelledby="simple-dialog-title" open={showbreakpopup}>
                            <DialogTitle id="break-runner"><b>Break Runner</b></DialogTitle>
                            <DialogContent style={{margin:'auto',marginBottom:'10px',textAlign:'center'}}>
                                <input type="number" 
                                name="searchrunnerid" 
                                id="searchrunnerid" 
                                value={searchrunnerid} 
                                onChange={handleChange} 
                                placeholder="Search Runner" 
                                autoComplete="off" 
                                autoFocus 
                                style={{width:'70%', border:'1px solid #000000',marginBottom:'15px',borderRadius:'5px',padding:'5px 2px'}}/>
                                <Button
                                    style={{width:'70%'}}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{breakRunner()}}>
                                    Break
                                </Button>
                            </DialogContent>
                        </Dialog>
                        <Dialog onClose={handleShowHideSplitOrder} aria-labelledby="splitorder-dialog-title" open={showsplitorderpopup}>
                            <DialogTitle id="splitorder-runner"><b>Split Order</b></DialogTitle>
                            <DialogContent style={{margin:'auto',marginBottom:'10px',textAlign:'center',lineHeight:'30px'}}>
                                <div style={{fontWeight:'bold',width:'100%',minWidth:'280px'}}>
                                    Split <span style={{color:'#ff0000'}}>Runner #{searchrunnerid}</span> orders
                                    <br />
                                    <b>Summary :</b> {catsummary}
                                </div>
                                <br />
                                <input type="number" 
                                name="numberofsplit" 
                                id="numberofsplit" 
                                value={numberofsplit} 
                                onChange={handleChange} 
                                placeholder="Number Of Split" 
                                autoComplete="off"
                                autoFocus
                                style={{width:'70%', border:'1px solid #000000',marginBottom:'15px',borderRadius:'5px',padding:'5px 2px'}}/>
                                <div>
                                <Button
                                    style={{width:'70%'}}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>{splitRunnerOrder(1)}}>
                                    Split & Print
                                </Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                        <div className="local-cart-container selecttable engagedrunner">
                            <div className="ordermessage runnermsg">Release Runner</div>
                            <div className="searchfilter mb-2">
                                <RunnerSearch />
                            </div>
                            {
                                engagedrunners ? (<div className="row nomargin">
                                {
                                    engagedrunners.map((runner)=>{
                                    return (
                                        <div key={runner.index} className="col-sm-2 appwidth50">
                                            <div className="table" style={{
                                                background:runner.bgcolor
                                            }}>
                                                <div className="runnerleftbox">
                                                    <div className="runneridbox" style={{
                                                        background:runner.runneridboxcolor,
                                                        color:runner.runneridtextcolor
                                                    }}>{runner.appename}</div>
                                                    <div className="runnernamebox" style={{
                                                        color:runner.color
                                                    }}>{runner.seatname}</div>
                                                </div>
                                                <div className="runnerrightbox">
                                                    <div className="tokendetail">
                                                        <button style={{
                                                          display:'inline',width:'auto',margin:'0',padding:'0',color:'#092147'
                                                        }} onClick={()=>viewDetail(runner.runnerid,runner.tokenno, true)}><i className="fa fa-info-circle" style={{fontSize:'2.8rem', color:runner.color}}></i></button>
                                                    </div>
                                                    <div className="engagedstatus" style={{
                                                        color:runner.engagetimecolor
                                                    }} onClick={()=>viewDetail(runner.runnerid,runner.tokenno, true)}>{runner.appestatus}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                  })
                                }
                                </div>):null
                            }
                        </div>
                        <Popuptokendetails closeOrderStatus={viewDetail.bind(this)} isActiveDetail={isActiveDetail}/>
                        
                        {
                            Object.keys(upcommingrunners).length > 0 && runnersystemrequired ? (<div className='upcommingrunners'><div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}><span style={{fontWeight:'bold'}}>Next Runner :</span>{` `}
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    {
                                        upcommingrunners.map((runner, loop)=>{
                                            return(
                                            <button key={loop}
                                                type="button" 
                                                className="disablerunner-btn"
                                                style={{
                                                    background:runner.bgcolor,
                                                    color:runner.color,
                                                    paddingLeft:'15px',
                                                    paddingRight:'15px',
                                                    marginLeft:'5px',
                                                }}>
                                                {runner.name}
                                            </button>)
                                        })
                                    }
                                </div>
                            </div></div>):null
                        }
                    </div>
                );
            }}
            </ProductConsumer>
            <Captainfooterbar />
            </Fragment>
        );
	}
}