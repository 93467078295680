import React, { Component, Fragment } from 'react';
import Popupmenumanage from './Popupmenumanage';
import {ProductContext} from '../Context/Product';

class MenufilterManage extends Component {

    static contextType = ProductContext;

    constructor(props)
    {
        super(props);
        this.state = { showPopup: false };
    }

    togglePopup() {  
        this.setState({  
            showPopup: !this.state.showPopup,
            isActive: !this.state.isActive
        });
    }

    render() {
        const {iscaptionlogin} = this.context;

        let className = '';

        if(iscaptionlogin === true)
        {
            className   += 'captionmenu';
        }        

        return (
            <Fragment>
                <div className={`_387x7 ${className}`}>
                    <div className="G8xPo _1lmiV _3Pq_u">
                        <div className="_3Vj63">
                            <div className="_3LVcW _2oCc6" onClick={this.togglePopup.bind(this)}>
                                <span className="_2La9v icon-menu"></span>Menu
                            </div>
                        </div>
                    </div>
                </div>
                <Popupmenumanage closePopup={this.togglePopup.bind(this)} isActive={this.state.isActive}/>
            </Fragment>
        );
    }
}

export default MenufilterManage;