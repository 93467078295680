import React,{Component,Fragment} from 'react';
import axios from "axios";
/*import Loggedinmenu from './Loggedinmenu';*/
import { ProductConsumer, ProductContext } from '../Context/Product';

class RunnerSearch extends Component {

    static contextType = ProductContext;

    constructor(props) {
    super(props);
        this.state = {
            result: null
        };

        this.keyworkFunction = this.keyworkFunction.bind(this);
    }

    keyworkFunction = (e) =>{
        if(e.keyCode === 13) {
            //Do whatever when enter is pressed
            /*this.releaseRunner();*/
            this.context.releaseRunnerConfirmation();
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.keyworkFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.keyworkFunction, false);
    }

    handleChangeEvent = (e) => {
        this.setState({ result: e.target.value },this.searchRunner);
    }
    
    searchRunner = () => {
        this.context.applySearchRunner(this.state.result);
    }

    handleSearchFocus = (e) => {
		if(document.getElementsByClassName("_387x7").length > 0)
		{
			document.getElementsByClassName("_387x7")[0].style.visibility = 'hidden';
		}
    }

    handleSearchBlur = (e) => {
		if(document.getElementsByClassName("_387x7").length > 0)
		{
			document.getElementsByClassName("_387x7")[0].style.visibility = 'unset';
		}
    }

    handleclose = () => {
        this.setState(()=>{
            return{result: ''};
        },
        ()=>{
            this.searchRunner();
        });
    }

    releaseRunner=()=>{
        this.context.releaseRunner();
    }

    cancelOrder=()=>{
        let id      = this.state.result;
        let name    = this.state.result;

        if(this.state.result === null || this.state.result === undefined || this.state.result === "")
        {
			this.context.toastMessage(`Please enter runner id to cancel order`,'error');
            document.getElementById("searchinput").focus();
        }
        else
        {
            this.context.cancelRunnerOrder(id, name);
            this.setState(()=>{
                return{
                    result:''
                }
            },()=>{
                this.searchRunner();
                /*this.context.GetEngagedRunners();*/
                document.getElementById("searchinput").focus();
            });
        }
    }

    breakRunner=()=>{
        let eventid = "";

        if(this.state.result === null || this.state.result === undefined || this.state.result === "")
        {
			this.context.toastMessage(`Please enter runner id to change status`,'error');
            document.getElementById("searchinput").focus();
            return false;
        }
	  
        let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let kotedetail   	= JSON.parse(localStorage.getItem('appe_kotedetail'));
  
        if(tabledetail || captiondetail || kotedetail)
        {
            if(Number(tabledetail.eventid) > 0)
            {
                eventid	= tabledetail.eventid;
            }
            else if(Number(captiondetail.eventid) > 0)
            {
                eventid	= captiondetail.eventid;
            }
            else if(Number(kotedetail.eventid) > 0)
            {
                eventid	= kotedetail.eventid;
            }
        }
  
        if(eventid < 1)
        {
          return false;
        }

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'UpdateRunnerStatus');
        bodyFormData.set('type', 'absent');
        bodyFormData.set('type2', 'release');
        bodyFormData.set('runnerid', this.state.result);
        bodyFormData.set('eventid', Number(eventid));
  
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'runner.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{

            this.setState(()=>{
                return{
                    result:''
                }
            },()=>{
                this.context.GetEngagedRunners();
            })
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });        
    }

    render() {
        return (
            <ProductConsumer>
			{(value) => {
                
                const {isloggedin, searchrunnerid} = value;
                const {handleChange, handleRunnerSearchFocus, handleRunnerSearchBlur, handlecloseRunnerSearch, /*releaseRunner,*/ releaseRunnerConfirmation, /*handleShowHideBreak, handleShowHideCancel, ReassignRunnerToOrder*/} = value;

                let className = '';
                if (isloggedin === "true") {
                  className += 'loggedin_org';
                }

                return (
                    <Fragment>
                        <div className={`searchbox runnersearchwrapper ${className}`} style={{display:'flex',justifyContent:'space-between'}}>
                            <div className="runnerinputwrapper kote_org" style={{width:'85%'}}>
                                <input type="number" name="searchrunnerid" id="searchrunnerid" value={searchrunnerid} onChange={handleChange} onFocus={handleRunnerSearchFocus} onBlur={handleRunnerSearchBlur} placeholder="Search Runner" autoComplete="off" className="searchrunnerinput" autoFocus style={{width:'90%'}}/>
                                <span className="closesearch" onClick={()=>{handlecloseRunnerSearch()}}>
                                {
                                    searchrunnerid !== "" ? (<span className="closesearchicon"></span>):null
                                }
                                </span>
                            </div>
                            <button className="releaserunnerbtn smallbutton" style={{width:'80px'}} onClick={()=>{releaseRunnerConfirmation();document.getElementById("searchrunnerid").focus();}}>Release</button>
                            {/*<button className="releaserunnerbtn smallbutton" style={{width:'65px'}} onClick={()=>{handleShowHideBreak();document.getElementById("searchrunnerid").focus();}}>Break</button>*/}
                            {/*<button className="releaserunnerbtn smallbutton" style={{width:'68px'}} onClick={()=>{handleShowHideCancel();document.getElementById("searchrunnerid").focus();}}>Cancel</button>
                            <button className="releaserunnerbtn smallbutton" style={{width:'105px'}} onClick={()=>{handleShowHideSplitOrder();document.getElementById("searchrunnerid").focus();}}>Split Order</button>
                            <button className="releaserunnerbtn smallbutton" style={{width:'105px'}} onClick={()=>{ReassignRunnerToOrder();document.getElementById("searchrunnerid").focus();}}>Reassign</button>*/}
                        </div>
                    </Fragment>
                );
			}}
            </ProductConsumer>
        );
    }
}

export default RunnerSearch;