import React,{Fragment, Component} from 'react';
import { ThemeConsumer } from '../Context/Theme';
import { ProductContext, ProductConsumer } from '../Context/Product';
import BannerNew from '../components/BannerNew';
import BannerSmall from '../components/BannerSmall';
import cutlery from '../assets/images/cutlery.png';
import logo404 from '../assets/images/logo_404_wbg.png';

import {Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions} from '@material-ui/core';

import {MenuSearch, CustomOption, RepeatCustomOption, RemoveCustomOption, CaptionMenu, ListTable, OrderStatus, NotDeliveredItemDetail, GroupProductList, Footer, FilterMenu, VegFilter, EggFilter, NonVegFilter, ItemLoading, OrderStatusButton }  from '../NewComponents';

class NewMenu extends Component {
    
    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            showsearch:false,
            isitemreloading:false,
            theposition:180,
            canchangetable: false,
            loggedininfo: {title:''},
        };

        this.refreshIntervalId  = null;
        this.refreshIntervalId2 = null;
    }
    componentDidMount(){

        this.context.setEventTables();

        if(!this.state.isitemreloading)
        {
            this.setState(()=>{
                return{
                    isitemreloading:true
                }
            },()=>{
                this.refreshIntervalId = setInterval(() => {
    
                    this.context.checkAndRefreshEvent();
        
                }, 15000);
            })
        }

        let temploggedininfo    = {};
        let tempcanchangetable  = false;

        let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

        if(tabledetail || captiondetail || stewarddetail)
        {
            if(tabledetail !== null && Number(tabledetail.eventid) > 0)
            {
                temploggedininfo    = tabledetail;
            }
            else if(Number(stewarddetail.eventid) > 0)
            {
                temploggedininfo	= stewarddetail.selectedtable;
                if((Number(stewarddetail.tabletype) !== 2 && Object.keys(stewarddetail.stewardtable).length > 1) || Number(stewarddetail.tabletype) === 0)
                {
                    tempcanchangetable  = true;
                }
            }
            else if(Number(captiondetail.eventid) > 0)
            {
                temploggedininfo	= captiondetail.selectedtable;
                tempcanchangetable  = true;
            }
        }

        this.setState({
            loggedininfo:temploggedininfo,
            canchangetable:tempcanchangetable
        },()=>{
            if(this.state.loggedininfo === undefined)
            {
                this.props.history.push("/tableselection");
            }
        })

        window.addEventListener('scroll', this.context.listenToScroll);
    }

    componentWillUnmount(){

        this.setState(()=>{
            return{
                isitemreloading:false
            }
        });

        clearInterval(this.refreshIntervalId);
        clearInterval(this.refreshIntervalId2);

        window.removeEventListener('scroll', this.context.listenToScroll);
    }

    componentDidUpdate(prevProps) {

        if(this.context.iscustomeroptionopen)
        {
            if(this.state.isitemreloading)
            {
                this.setState(()=>{
                    return{
                        isitemreloading:false
                    }
                },()=>{
                    clearInterval(this.refreshIntervalId);
                    clearInterval(this.refreshIntervalId2);
                })
            }
        }
        else
        {
            if(!this.state.isitemreloading)
            {
                this.setState(()=>{
                    return{
                        isitemreloading:true
                    }
                },()=>{
                    this.refreshIntervalId2 = setInterval(() => {
        
                        this.context.setEventTables();
            
                    }, 15000);
                })
            }
        }

        if(this.context.isloggedin === "true")
        {
            if(this.context.isorderadded)
            {
                if(this.context.isorderadded === true)
                {
                    this.props.history.push("/orderthanks/"+this.context.orlo_order_id);
                }
            }
            else if(this.context.isreorderadded)
            {
                if(this.context.isreorderadded === true)
                {
                    this.props.history.push("/reorderthanks/"+this.context.re_orlo_order_id);
                }
            }
        }
    }

    render(){

        const{loggedininfo, canchangetable} = this.state;

        setTimeout(() => {
            if(loggedininfo === undefined)
            {
                this.props.history.push("/tableselection");
            }
        }, 500);

        return (
            <ThemeConsumer>
            {(value) => {

                const{ ismenuopen, iscaptionmenuopen } = value;
                const{ toggleMenu, toggleCaptionMenu } = value;

                return (
                    <Fragment>
                    <ProductConsumer>
                    {(value) => {
        
                        const{ showsearch, isProductLoaded, iscartopen, cartTotalItem, canuseoption, canrepeatoption, singlecustomizableitem, canremoveoption, iscaptionlogin, istableopen, isorderstatusopen, isactivenotdelivereditem, miscdetail, products, haspendingitem, theposition, showhaveyloadmessage, eventmenus, haseventproduct, eventdetail, loggedintabledetail, eventbanner } = value;
                        const{ toggleCartSummary, showHideHeavyLoadMessage, placeOrder } = value;

                        return (
                            <Fragment>
                                {
                                    Object.keys(eventbanner).length > 0 && loggedintabledetail.showbanners ? (<Fragment>
                                    {
                                        iscaptionlogin ?(<Fragment>{
                                            eventdetail.showbannerandvideotocaptain ? (<Fragment>{
                                                theposition < 100 ? <BannerSmall />:<BannerNew />
                                            }</Fragment>):null
                                        }</Fragment>):(<Fragment>{
                                            theposition < 100 ? <BannerSmall />:<BannerNew />
                                        }</Fragment>)
                                    }
                                    </Fragment>):null
                                }
                                <section className={`mainwrapper ${Number(cartTotalItem) > 0 ? 'cartshowing':''}`}>
                                    <div className="iQdbEQ">
                                        <div className={`${iscartopen || ismenuopen || iscaptionmenuopen ? 'caijwq':'fwOqjK'}`} onClick={()=>{
                                            if(iscartopen)
                                            {
                                                toggleCartSummary();
                                            }
                                            else if(ismenuopen)
                                            {
                                                toggleMenu(!ismenuopen);
                                            }
                                            else if(iscaptionmenuopen)
                                            {
                                                toggleCaptionMenu(!iscaptionmenuopen);
                                            }
                                        }}></div>
                                    </div>
                                    <section className={`filterwrapper${theposition < 100 ? ' withsmallads':''}`}>
                                    {
                                        !showsearch ? <div className={`newfilter filtertitle filtertitle${Number(miscdetail.filtercount)}`} style={{justifyContent:'flex-start',alignItems:'center'}}>
                                            {
                                                loggedininfo !== undefined ? (<Fragment>{loggedininfo.title} {loggedininfo.tablename !== "" && loggedininfo.tablename !==undefined ? `(${loggedininfo.tablename})`:null} {Number(loggedininfo.isvip) === 1 ? `(VIP)`:``}</Fragment>):``
                                            }
                                            {canchangetable ? <span style={{marginLeft:'5px'}} onClick={()=>{
                                            this.props.history.push("/tableselection");
                                        }}><i className='fa fa-edit' style={{fontSize:'25px', color:'#ed5a6b'}}></i></span>:null}</div>:null
                                    }
                                    {
                                        !showsearch ? (
                                            <div className={`newfiltertoggle newfiltertoggle${miscdetail.filtercount}`} style={{justifyContent:'flex-end'}}>
                                                {Number(miscdetail.filtercount) > 1 && miscdetail.hasvegitem ? <VegFilter />:''}
                                                {Number(miscdetail.filtercount) > 1 && miscdetail.haseggitem ? <EggFilter />:''}
                                                {Number(miscdetail.filtercount) > 1 && miscdetail.hasnonvegitem ? <NonVegFilter />:''}
                                                <MenuSearch />
                                            </div>
                                        ):<MenuSearch />
                                    }
                                    </section>
                                    <div style={{
                                            padding:'0px 0px 4rem 0px'
                                        }}>
                                        {
                                            isProductLoaded ? (
                                                <Fragment>
                                                {
                                                    !haseventproduct ? (<Fragment>
                                                        <div className="gQyQJp">
                                                            <div className="bAgJbS">
                                                                <div className="dqsEmh"></div>
                                                                <img alt="404 preview" src={logo404} loading="lazy" className="cNjMLA" />
                                                            </div>
                                                            <br />
                                                            <p>Oops! No items available for the event.</p>
                                                        </div>
                                                    </Fragment>):(<Fragment>
                                                    {
                                                        Number(products.length) > 0 ? (
                                                            <Fragment>
                                                            {
                                                                eventmenus.map((menu, menuloop)=>{
                                                                    return <GroupProductList key={menuloop} menu={menu} value={value} />
                                                                })
                                                            }
                                                            </Fragment>
                                                        ):(
                                                        <div className="gQyQJp">
                                                            <div className="bAgJbS">
                                                                <div className="dqsEmh"></div>
                                                                <img alt="404 preview" src={cutlery} loading="lazy" className="cNjMLA" />
                                                            </div>
                                                            <p>No items found that match your search/filter.</p>
                                                        </div>
                                                        )
                                                    }
                                                    </Fragment>)
                                                }
                                                </Fragment>
                                            ):<ItemLoading />
                                        }
                                    </div>
                                    {
                                        iscaptionlogin ? <CaptionMenu />:null
                                    }
                                    <FilterMenu />
                                    {
                                        haspendingitem ? <OrderStatusButton />:null
                                    }
                                </section>
                                <Footer />
                                {
                                    canuseoption ? <CustomOption productdetail={singlecustomizableitem} />:null
                                }
                                {
                                    canrepeatoption ? <RepeatCustomOption productdetail={singlecustomizableitem} />:null
                                }
                                {
                                    canremoveoption ? <RemoveCustomOption productdetail={singlecustomizableitem} />:null
                                }
                                {
                                    istableopen ? <ListTable />:null
                                }
                                {
                                    isorderstatusopen ? <OrderStatus />:null
                                }
                                {
                                    isactivenotdelivereditem ? <NotDeliveredItemDetail />:null
                                }
                                <Dialog
                                    open={showhaveyloadmessage}
                                    onClose={showHideHeavyLoadMessage}
                                    aria-labelledby="heavy-load-alert"
                                    aria-describedby="heavy-load-description"
                                    >
                                    <DialogTitle id="heavy-load-alert">{`Confirm`}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="heavy-load-description" style={{color:'#ff0000'}}>
                                        Due to high order volume, there might be delay in order delivery. Are you sure that you want to place an order?<br /><span style={{color:'#000000'}}>ज्यादा आर्डर वॉल्यूम के कारण ऑर्डर मिलने में थोड़ी देरी हो सकती है क्या आप निश्चित है कि आप आर्डर देना चाहेंगे?</span>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button size="small" variant="outlined" color="primary" onClick={()=>{showHideHeavyLoadMessage()}}>No</Button>
                                        <Button size="small" variant="contained" color="primary" onClick={()=>{placeOrder()}} autoFocus>Yes</Button>
                                    </DialogActions>
                                </Dialog>
                            </Fragment>
                        );
                    }}
                    </ProductConsumer>
                    </Fragment>
                )
            }}
            </ThemeConsumer>
        )
    }
}

export default NewMenu;