import React,{Component,Fragment} from 'react';
import axios from "axios";
/*import Loggedinmenu from './Loggedinmenu';*/
import { ProductConsumer, ProductContext } from '../Context/Product';

class RunnerSearchKote extends Component {

    static contextType = ProductContext;

    constructor(props) {
    super(props);
        this.state = {
            result: null,
            searchrunnerlist:[]
        };

        this.keyworkFunction = this.keyworkFunction.bind(this);
    }

    keyworkFunction = (e) =>{
        if(e.keyCode === 13) {
            //Do whatever when enter is pressed
            this.releaseRunner();
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.keyworkFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.keyworkFunction, false);
    }

    handleChangeEvent = (e) => {
        this.setState({ result: e.target.value },this.searchRunner);
    }
    
    searchRunner = () => {
        this.context.applySearchRunner(this.state.result);
    }

    handleSearchFocus = (e) => {
		if(document.getElementsByClassName("_387x7").length > 0)
		{
			document.getElementsByClassName("_387x7")[0].style.visibility = 'hidden';
        }
        this.handleRunnerSearchFocus();
    }

    handleSearchBlur = (e) => {
		if(document.getElementsByClassName("_387x7").length > 0)
		{
			document.getElementsByClassName("_387x7")[0].style.visibility = 'unset';
        }
        
        this.handleRunnerClearSearch();
    }

    handleclose = () => {
        this.setState(()=>{
            return{result: '',searchrunnerlist:[]};
        },
        ()=>{
            this.searchRunner();
        });
    }

    releaseRunner=()=>{
        const id      = this.state.result;
        const name    = id;

        if(id === null || id === undefined || id === "")
        {
			this.context.toastMessage(`Please enter runner id to release`,'error');
            document.getElementById("searchinput").focus();
        }
        else
        {
            this.context.releaseRunnerByID(id, name);
            this.setState(()=>{
                return{
                    result:''
                }
            },()=>{
                this.searchRunner();
                document.getElementById("searchinput").focus();
            });
        }
    }

    cancelOrder=()=>{
        let id      = this.state.result;
        /*let name  = this.state.result;*/

        if(this.state.result === null || this.state.result === undefined || this.state.result === "")
        {
			this.context.toastMessage(`Please enter runner id to cancel order`,'error');
            document.getElementById("searchinput").focus();
        }
        else
        {
            /*this.context.cancelRunnerOrderByID(id);*/
            this.context.toggleConfirm(id, '', 'Are you sure? you want cancel order', 'cancelRunnerOrderByID');
            this.setState(()=>{
                return{
                    result:''
                }
            },()=>{
                this.searchRunner();
                document.getElementById("searchinput").focus();
            });
        }
    }

    handleRunnerSearchFocus = (e) => {

        /*let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));*/

        this.setState({
            searchrunnerlist:[]
        });

        /*return false;
  
        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'FetchAllEngagedRunner');
        bodyFormData.set('kotid', Number(kotedetail.kotid));
        bodyFormData.set('eventid', Number(kotedetail.eventid));
  
        axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+'runner.php',
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{
              
              if(response.data.success === true)
              {
                  this.setState({
                    searchrunnerlist:response.data.result
                  });
              }
            })
            .catch(function (response) {
              //handle error
              console.log(response);
        });*/
    }

    handleRunnerSearchBlur = (e) =>{

        this.setState({
            searchrunnerlist:[]
        });
    }

    handleClickReleaseRunner = (runnerid) =>{

      let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

      const bodyFormData = new FormData();
      bodyFormData.set('Mode', 'ReleaseRunner');
      bodyFormData.set('runnerid', runnerid);
      bodyFormData.set('kotid', Number(kotedetail.kotid));
      bodyFormData.set('eventid', Number(kotedetail.eventid));

      axios({
              method: 'post',
              url: process.env.REACT_APP_API_URL+'runner.php',
              data: bodyFormData,
              config: { headers: {'Content-Type': 'multipart/form-data' }}
          })
          .then(response=>{

            this.setState({
                isprocessing:false
              },()=>{
                document.getElementById("searchinput").focus();
            });
            
            if(response.data.success === true)
            {
                this.handleRunnerSearchFocus();
                
                this.setState({
                    runnerid:''
                });
            }
            else
            {
				this.context.toastMessage(response.data.msg,'error');
            }
          })
          .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    handleRunnerClearSearch = (e) =>{
      setTimeout(() => {
        this.handleRunnerSearchBlur();
      }, 150);
    }

    handleReprintRunner=()=>{

        if(this.state.result === null || this.state.result === undefined || this.state.result === "")
        {
			this.context.toastMessage(`Please enter runner id to reprint`,'error');
            document.getElementById("searchinput").focus();
            return false;
        }

        document.querySelector('#searchinput').focus();
    
        this.setState({
            isprocessing:true
        });

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
 
        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'ReprintRunnerToken');
        bodyFormData.set('runnerid', this.state.result);
        bodyFormData.set('kotid', Number(kotedetail.kotid));
        bodyFormData.set('eventid', Number(kotedetail.eventid));
  
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{

            this.setState({
                isprocessing:false
            },()=>{
                document.querySelector('#searchinput').focus();
            });
            
            if(response.data.success === true)
            {
				this.context.toastMessage(response.data.msg,'success');
                
                this.setState({
                    runnerid:''
                });
            }
            else
            {
				this.context.toastMessage(response.data.msg,'error');
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    breakRunner=()=>{

        if(this.state.result === null || this.state.result === undefined || this.state.result === "")
        {
			this.context.toastMessage(`Please enter runner id to change status`,'error');
            document.getElementById("searchinput").focus();
            return false;
        }

        document.querySelector('#searchinput').focus();
    
        this.setState({
            isprocessing:true
        });

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
 
        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'UpdateRunnerStatus');
        bodyFormData.set('type', 'absent');
        bodyFormData.set('type2', 'release');
        bodyFormData.set('runnerid', this.state.result);
        bodyFormData.set('kotid', Number(kotedetail.kotid));
        bodyFormData.set('eventid', Number(kotedetail.eventid));
  
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'runner.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{

            this.setState({
                isprocessing:false
            },()=>{
                document.querySelector('#searchinput').focus();
            });
            
            if(response.data.success === true)
            {
                this.context.toastMessage(response.data.msg,'success');
                
                this.setState({
                    runnerid:''
                });
            }
            else
            {
				this.context.toastMessage(response.data.msg,'error');
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    ReassignRunnerToOrder=()=>{

        if(this.state.result === null || this.state.result === undefined || this.state.result === "")
        {
			this.context.toastMessage(`Please enter runner id to reassign order`,'error');
            document.querySelector('#searchinput').focus();
            return false;
        }

        document.querySelector('#searchinput').focus();
    
        this.setState({
            isprocessing:true
        });

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
 
        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'ReassignNewRunner');
        bodyFormData.set('kotid', Number(kotedetail.kotid));
        bodyFormData.set('eventid', Number(kotedetail.eventid));
        bodyFormData.set('engagedrunnerid', this.state.result);
        bodyFormData.set('isreprint', Number(1));
        bodyFormData.set('sendrunneronbreak', Number(1));

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{

            this.setState({
                isprocessing:false
            },()=>{
                document.querySelector('#searchinput').focus();
            });
            
            if(response.data.success === true)
            {
				this.context.toastMessage(response.data.msg,'success');
                
                this.setState({
                    runnerid:''
                });
            }
            else
            {
				this.context.toastMessage(response.data.msg,'error');
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    render() {
        return (
            <ProductConsumer>
			{(value) => {
                const {isloggedin} = value;

                let className = '';
                if (isloggedin === "true") {
                  className += 'loggedin_org';
                }

                return (
                    <Fragment>
                        <div className={`searchbox runnersearchwrapper ${className}`} style={{display:'flex',justifyContent:'space-between'}}>
                            <div className="runnerinputwrapper kote_org" style={{width:'50%'}}>
                                <input type="number" name="s" id="searchinput" value={this.state.result || ''} onChange={this.handleChangeEvent} onFocus={this.handleSearchFocus} onBlur={this.handleSearchBlur} placeholder="Search Runner" autoComplete="off" className="searchrunnerinput" style={{width:'90%'}}/>
                                <span className="closesearch" onClick={()=>{this.handleclose()}}>
                                {
                                    this.state.result || this.state.searchrunnerlist.length > 0 ? (<span className="closesearchicon"></span>):null
                                }
                                </span>
                                {
                                    this.state.searchrunnerlist ? (<div className="runnersearchresult">
                                    <ul className="resultList">
                                    {
                                        this.state.searchrunnerlist.map((runner)=>{
                                            return (
                                                <li key={runner.index}>
                                                <div className="detail">{runner.name} {runner.status}</div>
                                                <input type="button" name="release" id="release" className="ripplepink" value="Release" onClick={()=>{this.handleClickReleaseRunner(runner.runnerid)}}/>
                                                </li>
                                            )
                                        })
                                    }
                                    </ul>
                                    </div>):null
                                }
                            </div>
                            <div>
                                <button className="releaserunnerbtn smallbutton" style={{width:'75px'}} onClick={()=>{this.releaseRunner()}}>Release</button>
                                {/*<button className="releaserunnerbtn smallbutton" style={{width:'65px'}} onClick={()=>{this.breakRunner()}}>Break</button>*/}
                                <button className="releaserunnerbtn smallbutton" style={{width:'85px'}} onClick={()=>{this.ReassignRunnerToOrder()}}>Reassign</button>
                                <button className="releaserunnerbtn smallbutton" style={{width:'70px'}} onClick={()=>{this.handleReprintRunner()}}>Reprint</button>
                                <button className="releaserunnerbtn smallbutton" style={{width:'65px'}} onClick={()=>{this.cancelOrder()}}>Cancel</button>
                            </div>
                        </div>
                    </Fragment>            
                );
			}}
            </ProductConsumer>
        );
    }
}

export default RunnerSearchKote;