import React,{ Component } from 'react';
import { ProductConsumer } from '../Context/Product';

import {
	PinkRemove,
	PinkPlus,
	BlackPlus,
}  from '../constants';

class CustomOption extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render(){

        const{ id, title, itemoptions, customitemqty, ispricingavailable, price, largeimg, haslargeimage2} = this.props.productdetail;

            return (
                <ProductConsumer>
                {(value) => {
                    
                    const{ additionalimageurl, canuseoption } = value;
				const{ toggleItemOption, handleOptionSelection, incrementCustomItem, decrementCustomItem, addToCart} = value;

                    return (
						<div className={`fgjele`} style={{
							height:canuseoption ? '100%':''
						}} aria-hidden="false" role="dialog">
                            <div style={{display:'flex',justifyContent:'center',position:'relative'}}>
                                <div className="dfUaKd" style={{
                                    position:'absolute',
                                    top:canuseoption ?'4.5rem':'0px',
                                }}>
                                    <div className="close-button" onClick={()=>{
										toggleItemOption(id);
									}}></div>
                                </div>
                            </div>
							<div tabIndex="-1" className="hcdmGE" onClick={()=>{
                                toggleItemOption(id);
                            }}></div>
							<div mode="light" type="default" tabIndex="0" className="hyQzCb">
								{
									haslargeimage2 ? (
										<div className={`dYbSLL previewbox2`} style={{
											borderRadius:'0.8rem 0.8rem 0 0',
											height:'22rem',
										}}>
											<div className="lrgclr"></div>
											<img alt={`${title} - Large Preview`} src={`${additionalimageurl}${largeimg}`} loading="lazy" className="menupreview" />
										</div>
									):null
								}
								<div style={{
									padding:'1rem'
								}}>
									<section className="eggzWm">
										<h1 className="gAkCTa">{title}</h1>
									</section>
									<section className="oRYSe">
										<div className="idQvOD" id={`itemoptiongroupcontainer`} style={{scrollBehavior:'smooth'}}>
											<div className="hPCrWT"></div>
											{
												itemoptions.map((optioncat, optcatloop)=>{
													return (
															<div className="wQCFM" id={`optiongroup${optioncat.customcatid}`} key={optcatloop}>
																<div className="idFvRo">
																	<div className="gyBWSg" style={{display:'flex',justifyContent:'space-between'}}><div className="optioncatname" style={{color:`${optioncat.showerror ? `#ff0000`:``}`}} onClick={()=>{
																		
																	}}>{optioncat.name}</div> {Number(optioncat.isrequired) === 1 ? (<span className='itemooptioncatrequired'>REQUIRED</span>):``}</div>
																{
																	((Number(optioncat.isrequired) === 0 && optioncat.isnotrequiredchecked) || Number(optioncat.isrequired) === 1) ? (<div className="gfWojX">
																		<div className="ktfTIz" style={{color:`${optioncat.showerror ? `#ff0000`:``}`}}>
																		{
																			Number(optioncat.customizationtype) === 1 ? `You can choose any 1 option`:`You can choose upto ${optioncat.options.length} options`
																		}
																		</div>
																		<div className="izyrQJ"></div>
																	</div>):null
																}
																</div>
																<div className="fABuGy">
															{
																((Number(optioncat.isrequired) === 0) || Number(optioncat.isrequired) === 1) && optioncat.options.map((option, optloop)=>{
																	return(
																		<div className={`bmBDqp${option.checked ?' selected':''}`} key={optloop}>
																			<div className="dUGYrj" onClick={()=>{
																						handleOptionSelection(id, option.categoryid, option.optionid, `${Number(optioncat.customizationtype) === 1 ? 'radio':'checkbox'}`);
																					}}>
																				<p className="fkUtKH" style={{
																					fontWeight:`${option.checked ?'600':'500'}`
																				}}>{option.name}</p>
																				{
																					option.ispricingavailable ? (
																						<div className="ggJkFu">
																							<div className="jiHnaU" style={{
																								fontWeight:`${option.checked ?'600':'500'}`,
																								display:Number(option.optionprice) ? 'flex':'contents',
																								alignItems:'center',
																								minWidth:'6rem',
																								justifyContent:'space-between'
																							}}>{Number(option.optionprice) > 0 ? <BlackPlus />:null}{Number(option.optionprice) > 0 ? <i className="fa fa-inr"></i>:null} {option.optionprice}</div>
																						</div>
																					):null
																				}
																			</div>
																			<section className="irrWnt">
																		{
																			Number(optioncat.customizationtype) === 1 ? (
																				<label className="jrHtYr">
																					<input name="itemoption" className="jlsszO hwTXNN" type="radio" value={1} onChange={()=>{
																						handleOptionSelection(id, option.categoryid, option.optionid, 'radio');
																					}} checked={Number(option.checked) === 1}/>
																					{
																						option.checked ? (
																							<svg viewBox="0 0 20 20" id="circle" className="bIPSGt"><circle cx="10" cy="10" r="8"  className="kTfLuB hwTXNN"></circle><circle cx="10" cy="10" r="5" className="cmcsiK hwTXNN"></circle></svg>
																						):(
																							<svg viewBox="0 0 20 20" id="circle" className="bIPSGt"><circle cx="10" cy="10" r="8" className="iszoyV hwTXNN"></circle></svg>
																						)
																					}
																					<span className="jUrwkH"></span>
																				</label>
																			):(
																				<label name={`itemoption${option.optionid}`} value={option.optionid} color="#ED5A6B" className="oQgkb kIsyOb">
																					<input type="checkbox" aria-checked="true" color="#ED5A6B" className="iPRmnw" value={option.optionid} checked={Number(option.checked) === 1} onChange={()=>{
																						handleOptionSelection(id, option.categoryid, option.optionid, 'checkbox');
																					}}/>
																				</label>
																			)
																		}
																			</section>
																		</div>
																	)
																})
															}
																</div>
															</div>
														)
													})
											}
											<div className="kesDMu"></div>
										</div>
									</section>
								</div>
							</div>
							<div className="teiDS">
								<div className="fJNrek HdtOH">
									<div className="ipHtgH">
										<div className="cMipmx huikLB">
											<div className="hTzRFw" onClick={()=>{
												decrementCustomItem(id)
											}}>
												<PinkRemove />
											</div>
											<div className="iQCkqv" style={{
												color:'#000000',
												background:'#FFF6F7',
											}}>
												<span className="fTsfFl">{customitemqty}</span>
											</div>
											<div className="hTzRFw" onClick={()=>{
												incrementCustomItem(id)
											}}>
												<PinkPlus />
											</div>
										</div>
									</div>
									<span className="fuGMDN">customizable</span>
								</div>
								<button tabIndex="0" aria-disabled="false" className="elxuhW" onClick={()=>{
									addToCart(id, true)
								}}>
									<span tabIndex="-1" className="bXdRxo">
										<span className="dkwpEa">
											<div className="eYrDjb">
												<span>Add</span>
											{
												ispricingavailable && price > 0 ? (
													<div className="btgzzv"><i className="fa fa-inr"></i> {(price * customitemqty)}</div>
												):null
											}
											</div>
										</span>
									</span>
								</button>
							</div>
							<div tabIndex="0" className="ckiDDQ"></div>
						</div>
                    );
                }}
                </ProductConsumer>
            )
    }
}

export default CustomOption;