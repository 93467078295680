import React,{ Component } from 'react';
import { ProductConsumer } from '../Context/Product';

class RepeatCustomOption extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    render(){

        const{ id } = this.props.productdetail;

            return (
                <ProductConsumer>
                {(value) => {
                    
                    const{ toggleItemOption, toggleItemRepeatOption, increment } = value;

                    const{ cart, canrepeatoption } = value;

				    let selectedCartProduct	= [];

					const customizationTempCart	= cart.filter(tempcartitem => tempcartitem.id === id);

					customizationTempCart.forEach((customizeitem)=>{

						const singlecustomizeitem = {...customizeitem};

						selectedCartProduct	= singlecustomizeitem;
					});                    
                    
                    const{ /*tempcartid, hindititle,*/ title, itemoptions } = selectedCartProduct;

                    return (
                        <div className="hOPtIO">
                            <div className="fgjele modalWrapper" aria-hidden="false" role="dialog">
                                <div tabIndex="-1" className="hcdmGE" onClick={()=>{
                                  toggleItemRepeatOption(id);
                                }}></div>
                                <div className="iBlUrl"></div>
                                <div mode="light" type="medium" tabIndex="0" className="gzwZLJ">
                                    <div style={{display:'flex',justifyContent:'center',position:'relative'}}>
                                        <div className="dfUaKd" style={{
                                            position:'absolute',
                                            bottom:canrepeatoption ?'4rem':'0px',
                                        }}>
                                            <div className="close-button" onClick={()=>{
                                                toggleItemRepeatOption(id);
                                            }}></div>
                                        </div>
                                    </div>
                                    <section className="eggzWm">
                                        <h2 mode="light" className="cInTtE">Repeat last used customisation</h2>
                                        <span onClick={()=>{
                                            toggleItemRepeatOption(id);
                                        }}><i style={{fontSize:'2.6rem'}} className="fa fa-angle-down" aria-hidden="true"></i></span>
                                    </section>
                                    <section className="oRYSe">
                                        <div className="sc-eetwQk kCdFWE"></div>
                                        <div className="sc-goFBvh ItLLI">
                                            <div className="sc-feWbDf bbjjqK">{title}</div>
                                            {
                                                itemoptions.map((optioncat, catloop)=>{
                                                    
                                                    if(optioncat.selected)
                                                    {
                                                        return(
                                                            <div className="sc-emjYpo enzpKS" key={catloop}>
                                                                {optioncat.name} : {` `}
                                                                {
                                                                    optioncat.options.map((option, optionloop)=>{

                                                                        if(option.checked)
                                                                        {
                                                                            return <span key={optionloop} className="optionname">{option.name}</span>
                                                                        }
                                                                        else
                                                                        {
                                                                            return null;
                                                                        }

                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    else
                                                    {
                                                        return null;
                                                    }
                                                })
                                            }
                                            <div className="cMipmx sc-iKiVwC yDyrk">
                                                <div className="HTAAf">
                                                    <button tabIndex="0" aria-disabled="false" className="elxuhW" onClick={()=>toggleItemOption(id)}>
                                                        <span tabIndex="-1" className="haMdOJ">
                                                            <span className="dkwpEa">Add new</span>
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="dKmxez">
                                                    <button tabIndex="0" aria-disabled="false" className="elxuhW" onClick={()=>{
                                                        increment(id)
                                                    }}>
                                                        <span tabIndex="-1" className="bXdRxo">
                                                            <span className="dkwpEa">Repeat Last</span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div tabIndex="0" className="re4bd0-2 ckiDDQ"></div>
                            </div>
                        </div>
                    );
                }}
                </ProductConsumer>
            )

    }
}

export default RepeatCustomOption;