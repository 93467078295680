import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';
import {ProductContext, ProductConsumer} from '../Context/Product';
import logout from '../assets/images/logout.svg';

class Loggedinmenu extends Component {

	static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {  };
    }

    Logout=()=>{

		if(this.context.iscaptionlogin !== true && this.context.isstewardlogin !== true)
		{
			let promotlogout = prompt("Please enter event code to logout", "");

			if(promotlogout !== null)
			{
				if(Number(promotlogout) !== Number(process.env.REACT_APP_PASSWORD))
				{
					this.context.toastMessage(`Invalid Password`,'error');
				}
				else
				{
					this.context.resetMenuSelection();
					this.context.setLoggedOutGuestUser();

					localStorage.setItem("guestid",'');
					localStorage.setItem("tabledetail",null);
					localStorage.setItem("captiondetail",null);
					localStorage.setItem("stewarddetail",null);
					localStorage.setItem("isloggedin",false);
					localStorage.setItem("appe_kotedetail",null);
					localStorage.setItem("orlo_menudetail",null);
					localStorage.setItem("isautopilot",false);

					this.props.history.push("/login");
				}
			}
		}
		else
		{
			this.context.resetMenuSelection();
			this.context.setLoggedOutGuestUser();

			localStorage.setItem("guestid",'');
			localStorage.setItem("tabledetail",null);
			localStorage.setItem("captiondetail",null);
			localStorage.setItem("stewarddetail",null);
			localStorage.setItem("isloggedin",false);
			localStorage.setItem("appe_kotedetail",null);
			localStorage.setItem("orlo_menudetail",null);
			localStorage.setItem("isautopilot",false);

			this.props.history.push("/login");
		}
    }
	render(){
		return (
			<ProductConsumer>
			{(value) => {
				const {isloggedin,loggedinname} = value;

				if(isloggedin)
				{
					return(
						<li className="clearfix loggedinbox">
							<span className="logintitle">{loggedinname}</span>
							<div className="logged-in-wrapper">
								<div className="account-logout-button">
									<button className="logoutbtn" onClick={() =>{this.Logout()}}><img src={logout} alt="Logout" /> Logout</button>
								</div>
							</div>
						</li>
                    );
				}
			}}
			</ProductConsumer>
		);
	}
}

export default withRouter(Loggedinmenu);