import React,{Component} from 'react';
import { ThemeConsumer } from '../Context/Theme';

class SingleCategoryFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }

    render(){

        const{id, menuid, title, newcount}  = this.props.category;
        const{eventmenuscount}  = this.props;

        return (
            <ThemeConsumer>
            {(value) => {

                const{ scrollToView } = value;

                return (
                    <div className="singlecategorybox" onClick={()=>{
                        scrollToView(`eventmenudiv${menuid}`,`div${id}`,eventmenuscount);
                    }}>
                        <h6 title={`${title}`} color="#1C1C1C" className="singlecategorytitle">{title}</h6>
                        <h6 color="#696969" className="singlecategoryqty">{newcount}</h6>
                    </div>
                );
            }}
            </ThemeConsumer>
        )
    }
}

export default SingleCategoryFilter;