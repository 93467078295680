import React,{Component,Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { ProductContext } from '../Context/Product';
import logo from '../assets/images/logo_404_wbg.png';

class ReOrderThanks extends Component {

    static contextType = ProductContext;

    constructor(props) {
        super(props);

        this.state = {
            orderid:'',
            stockoutproducts:'',
            isstockoutfound:false,
            stockouttextlength:0,
            seatname:''
        }
    }

    static contextType = ProductContext;

    componentDidMount(){
        document.body.classList.add('orderthanks');

        this.context.ResetOrderProcess();

        this.setState({
            orderid:this.props.match.params.orderid
        });

        this.getStockOutName();
    }

    componentWillUnmount(){
        clearInterval(this.refreshIntervalId);
    }

    getStockOutName = () => {
    
		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

        let eventid     = "";

        if(tabledetail || captiondetail || stewarddetail)
        {
			if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
			{
				eventid	= tabledetail.eventid;
			}
			else if(captiondetail !== null && (Number(captiondetail.eventid) > 0  && captiondetail.eventid !== null))
			{
				eventid	= captiondetail.eventid;
			}
			else if(stewarddetail !== null && (Number(stewarddetail.eventid) > 0 && stewarddetail.eventid !== null))
			{
				eventid	= stewarddetail.eventid;
			}
        }

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetStockOutName');
        bodyFormData.set('eventid', eventid);
        bodyFormData.set('order_id', this.props.match.params.orderid);
    
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{          
            if(response.data.success === true)
            {
                this.setState({
                    isstockoutfound:response.data.isstockoutfound,
                    stockoutproducts:response.data.stockoutproducts,
                    stockouttextlength:response.data.stockouttextlength,
                    seatname:response.data.seatname
                },()=>{
                    this.refreshIntervalId = setTimeout(() => {
                        this.props.history.push("/menu");
                    }, 30000);
                });
            }
        })
        .catch(function (response) {
        //handle error
        console.log(response);
        });
    }

    setRedirect = () => {
        this.setState({
          redirect: true
        })
    }

    handleClick=()=>{
        this.context.setCaptainTable();
        this.context.toggleOrderStatus();
        this.props.history.push("/menu");
    }

    render() {
        
        const{stockoutproducts,isstockoutfound,stockouttextlength,seatname} = this.state;

        let additionalclass = '';

        if(stockouttextlength > 56)
        {
            additionalclass = 'scrollablestockoutitem';
        }

        return (
            <Fragment>
            <div className="row nomargin lightbluebg">
                <div className="col-sm">
                    <div className="loadingwrapper">
                        <img src={logo} className="app-logo" alt="logo" />
                        <br />
                        <div className="notfoundsubtitle" style={{fontSize:"4rem"}}>Sorry!</div>
                        <div className="notfoundsubtitle">Sorry to disappoint you. We are looking into this. <br />Your order has been placed.<br />Your Order # <b>{this.state.orderid}</b> for <b>{seatname}</b>{/*<br />For bookings call us @ 9811168031*/}</div>
                        {
                            isstockoutfound ? (
                                <div className="stockoutitemlist">
                                    <div>Following item is out of stock:</div>
                                    <div className={`stockoutproduct ${additionalclass}`}>{stockoutproducts}</div>
                                </div>
                            ):null
                        }
                        <div className="placeneworderbtn"><button className="jomat" onClick={()=>{this.handleClick()}}>Place Another Order</button></div>
                    </div>
                </div>
            </div>
		  </Fragment>            
        );
    }
}

export default withRouter(ReOrderThanks);