import React,{Component,Fragment} from 'react';
import axios from "axios";
import { ProductContext } from '../Context/Product';
import Captainfooterbar from '../components/Captainfooterbar';
import { Button, Input, Label, Form, FormGroup } from 'reactstrap';

export default class Editbanner extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            scanresult:'No result',
            recordid:this.props.match.params.recordid,
            message:'',
            alertcolor:'',
            isprocessing:false,
            currentbanner:'',
            imagefile:'',
            orderby:'',
            bannerstatus:1,
            bannerarea:1,
            bannertype:1,
            orgbannertype:1,
            isbannerexists:0,
            videofile:'',
            smallimagefile:'',
            isfloatingbannerexists:0,
            floatingbannerurl:'',
        };
    }
    componentDidMount(){
        this.getDetail();
    }

	getDetail=()=>{

        let eventid			= "";

        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

        if(captiondetail)
        {
            if(Number(captiondetail.eventid) > 0)
            {
                eventid	= captiondetail.eventid;
            }
        }

        if(eventid < 1)
        {
            return false;
        }

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetBannerDetail');
        bodyFormData.set('eventid', eventid);
        bodyFormData.set('recordid', this.state.recordid);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}banners.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success !== true)
            {
				this.context.toastMessage(`Unable to process your request, Please try latter.`, 'error');
            }
            else
            {
                this.setState(()=>{
                    return{
                        currentbanner:response.data.detail.bannerimage,
                        imagefile:response.data.detail.imagefile,
                        orderby:Number(response.data.detail.orderby),
                        bannerstatus:Number(response.data.detail.status),
                        bannertype:Number(response.data.detail.bannertype),
                        orgbannertype:Number(response.data.detail.bannertype),
                        bannerarea:Number(response.data.detail.bannerarea),
                        isbannerexists:Number(response.data.detail.isbannerexists),
                        videofile:response.data.detail.videofile,
                        smallimagefile:response.data.detail.smallimagefile,
                        isfloatingbannerexists:response.data.detail.isfloatingbannerexists,
                        floatingbannerurl:response.data.detail.floatingbannerurl,
                    };
                })
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }
    
    handleUserInput = (e) =>{
		const name	= e.target.name;
		const value	= e.target.value;

        this.setState(()=>{
            return{
                [name]:value
            }
        })
    }
    
	saveDetail = (e) =>{
		e.preventDefault();

        const {bannerarea, bannertype}	= this.state;

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        let message = "";

		let filedata	= "";
		let VideoFile	= "";
		let SmallImage	= "";

		if(Number(bannertype) === 1)
		{
			filedata		= document.getElementById('imagefile').files[0];
		}
		else
		{
			VideoFile		= document.getElementById('VideoFile').files[0];
            if(Number(bannerarea) === 1)
            {
                SmallImage	= document.getElementById('SmallImage').files[0];
            }
		}

		if(Number(bannertype) === 1)
		{
			if((filedata === "" || filedata ===undefined) && this.state.currentbanner === "" && message === "")
			{
				message = "Please select an image to upload";
			}
			else if((filedata !== "" && filedata !== undefined) && message === "")
			{
				let fileext = filedata.name.split('.').pop().toLowerCase();
				if(fileext !=='jpg' && fileext !=='jpeg')
				{
					message = "Please select a valid image";
				}
			}
		}
		else
		{
            if(Number(bannerarea) === 1)
            {
                if((SmallImage !== "" && SmallImage !== undefined) && message === "")
                {
                    let fileext = SmallImage.name.split('.').pop().toLowerCase();
                    if(fileext !=='jpg' && fileext !=='jpeg')
                    {
                        message = "Please select a valid image";
                    }
                }
            }
			if((VideoFile === "" || VideoFile === undefined) && this.state.videofile === "" && message === "")
			{
				message = "Please select an video to upload";
			}
			else if((VideoFile !== "" && VideoFile !== undefined) && message === "")
			{
				let fileext = VideoFile.name.split('.').pop().toLowerCase();
				if(fileext !=='mp4')
				{
					message = "Please select a valid video";
				}
			}
		}

		if(message !== "")
		{
			this.context.toastMessage(message, 'error');
			return false;
		}

        if(message === "")
        {
            this.setState({
                message:'Processing...',
                alertcolor:'primary',
                isprocessing:true
            },()=>{
                setTimeout(()=>{
                    const bodyFormData = new FormData();
                    bodyFormData.set('Mode', 'EditEventBanner');
                    bodyFormData.set('recordid', this.state.recordid);
                    bodyFormData.set('eventid', eventid);
                    bodyFormData.set('imagefile',filedata);
                    bodyFormData.set('orgimagefile',this.state.imagefile);
                    bodyFormData.set('orderby', Number(this.state.orderby));
                    bodyFormData.set('status', Number(this.state.bannerstatus));
					bodyFormData.set('SmallImage',SmallImage);
					bodyFormData.set('VideoFile',VideoFile);
					bodyFormData.set('bannerarea', Number(bannerarea));
					bodyFormData.set('bannertype', Number(bannertype));
                    bodyFormData.set('orgvideofile',this.state.videofile);
                    bodyFormData.set('orgsmallimagefile',this.state.smallimagefile);
        
                    axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}banners.php?t=${Date.now()}`,
                        data: bodyFormData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(response=>{

                        this.setState({
                            message:'',
                            isprocessing:false
                        });
        
                        let color   = "error";
        
                        if(response.data.success === true)
                        {
                            this.context.setEventBanner();
                            color   = "success";

                            this.props.history.push("/managebanner");
                        }
                        this.context.toastMessage(response.data.msg, color);
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
                },1500);
            })
        }
	}    

	render(){

        const {orderby, bannerstatus, currentbanner, /*message, alertcolor,*/ isprocessing, bannerarea, bannertype, orgbannertype, isbannerexists, isfloatingbannerexists, floatingbannerurl} = this.state;

        return (
            <Fragment>
            <div className="view-cart-wrapper">
                <div className="local-cart-container selecttable engagedrunner stockmanagement" style={{marginBottom:'unset'}}>
                    <div className="ordermessage runnermsg">Edit Banner</div>
                    <br />
                    <div style={{paddingLeft:'10px',paddingRight:'10px',textAlign:'left'}}>
                    <Form id="addbannerfrm" name="addbannerfrm" onSubmit={this.saveDetail}>
                        <FormGroup tag="fieldset">
                            <legend style={{
                                fontSize:'15px',
                                fontWeight:'bold',
                            }}>Banner Area</legend>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="bannerarea" id="bannerarea1" value="1" checked={Number(bannerarea) === 1} onChange={this.handleUserInput}/>
                                    &nbsp;Home Page
                                </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Label check>
                                    <Input type="radio" name="bannerarea" id="bannerarea0" value="0" checked={Number(bannerarea) === 0} onChange={this.handleUserInput}/>
                                    &nbsp;Thanks Page
                                </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </FormGroup>
                        </FormGroup>
                        <FormGroup tag="fieldset">
                            <legend style={{
                                fontSize:'15px',
                                fontWeight:'bold',
                            }}>Banner Type</legend>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="bannertype" id="bannertype1" value="1" checked={Number(bannertype) === 1} onChange={this.handleUserInput}/>
                                    &nbsp;Image
                                </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Label check>
                                    <Input type="radio" name="bannertype" id="bannertype0" value="0" checked={Number(bannertype) === 0} onChange={this.handleUserInput}/>
                                    &nbsp;Video
                                </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </FormGroup>
                        </FormGroup>
                {
                    isbannerexists && Number(orgbannertype) === 1 ? (
                        <FormGroup>
                            <Label for="currimagefile"><b>Current Banner</b></Label>
                            <div>
                            {
                                Number(orgbannertype) === 1 ? (<img src={currentbanner} alt={`Event Banner`} style={{width:'250px'}}/>):null
                            }
                            </div>
                        </FormGroup>
                    ):null
                }

                {
                    Number(bannertype) === 1 ? (
                    <FormGroup>
                        <Label for="imagefile"><b>Upload Banner (jpg,jpeg)</b></Label>
                        <Input type="file" name="imagefile" id="imagefile" onChange={this.handleUserInput}/>
                    </FormGroup>
                    ):(
                        <div>

                        {
                            isfloatingbannerexists && Number(bannerarea) === 1 ? (
                                <FormGroup>
                                    <Label for="currimagefile"><b>Current Floating Banner</b></Label>
                                    <div>
                                    <img src={floatingbannerurl} alt={`Event Floating Banner`} style={{width:'200px'}}/>
                                    </div>
                                </FormGroup>
                            ):null
                        }

                        {
                            Number(bannerarea) === 1 ? (
                            <FormGroup>
                                <Label for="SmallImage"><b>Upload Floating Banner (jpg,gif)</b></Label>
                                <Input type="file" name="SmallImage" id="SmallImage" onChange={this.handleUserInput}/>
                            </FormGroup>
                            ):null
                        }
                            <FormGroup>
                                <Label for="VideoFile">
                                <b>Upload Video (mp4)</b>
                                &nbsp;&nbsp;
                                {
                                    isbannerexists && Number(orgbannertype) === 0 ? (
                                        <Button type="button" color="primary" onClick={()=>{
                                            window.open(currentbanner,"eventeditbanner","width=550px,height=600px, scrollbars=0, resizable=0, top=0, left=0");
                                        }}>View Video</Button>
                                    ):null
                                }

                                </Label>
                                <Input type="file" name="VideoFile" id="VideoFile" onChange={this.handleUserInput}/>
                            </FormGroup>
                        </div>
                    )
                }
                        <FormGroup>
                            <Label for="orderby"><b>Order By</b></Label>
                            <Input type="number" name="orderby" id="orderby" placeholder="Order By" value={orderby} onChange={this.handleUserInput}/>
                        </FormGroup>
                        <FormGroup tag="fieldset">
                            <legend style={{
                                fontSize:'15px',
                                fontWeight:'bold',
                            }}><b>Status</b></legend>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="bannerstatus" id="bannerstatus1" value="1" checked={Number(bannerstatus) === 1} onChange={this.handleUserInput}/>
                                    &nbsp;Active
                                </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Label check>
                                    <Input type="radio" name="bannerstatus" id="bannerstatus0" value="0" checked={Number(bannerstatus) === 0} onChange={this.handleUserInput}/>
                                    &nbsp;In-Active
                                </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </FormGroup>
                        </FormGroup>
                        <Button type="submit" color="primary" disabled={isprocessing}>Save Now</Button>{' '}{isprocessing ?<span style={{fontSize:'3rem',color:'#007bff'}}><i className={`fa fa-refresh fa-spin`} aria-hidden="true"></i></span>:null}
                    </Form>
                    </div>
                    <div className="quickmanagestockbox" onClick={()=>{
                        this.props.history.push("/managebanner")
                    }}><i className="fa fa-indent" aria-hidden="true"></i></div>
                </div>
            </div>
            <Captainfooterbar />
            </Fragment>
        );
	}
}