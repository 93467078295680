import React,{Component, Fragment} from 'react';
import { KoteContext } from '../Context/Kote';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import KoteLiteOrdereditem from './KoteLiteOrdereditem';
import KoteLiteRemoveRunnerConfirmPopup from './KoteLiteRemoveRunnerConfirmPopup';
import closepopup from '../assets/images/closepopup.png';
import xperia from '../assets/sound/xperia.mp3';
/*import {Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions} from '@material-ui/core';*/

class KoteLiteOrderpopup extends Component {

    static contextType = KoteContext;

    constructor(props) {
        super(props);
        this.state = { 
            error : null,
            isLoaded : false,
            isrunnermatched:false,
            isfetchingrunner:false,
            orderdetail:[],
            itemdetails:[],
            runnerdetails:[],
            totalitems:0,
            requiredrunnernum:0,
            isRunnerPopActive:false,
            activerunnerid:'',
            activerunnername:'',
            assigningrunnerid:'',
            assigningrunneridavailable:'',
            assignedremovedrunner:[],
            availrunner:[],
            hasrunner:false,
            runnertobeassign:'',
            voices:[],
            isrunnerannounce:false,
            isloadbalancing:0,
            ordercount:2,
            isorderaccepted:true,
            runnersummary:{},
        };

        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event){

        if(event.keyCode === 27) {
            //Do whatever when esc is pressed

            /*let runnerNum	= Object.keys(this.state.runnerdetails).length;

            if(runnerNum > 0)
            {
                this.saveOrder();
            }*/
            this.props.closePopup();
        }
        /*else if(event.keyCode === 65) {
            //Do whatever when a is pressed
            this.AddMoreRunner();
        }*/
        else if(event.keyCode === 83) {
            //Do whatever when s is pressed
            this.saveOrder();
        }
        else if(event.keyCode === 90) {
            //Do whatever when z is pressed

            let runnerdetails = [...this.state.runnerdetails];

            let lastassignedrunner  = "";

            let runner;
            for(runner in runnerdetails)
            {
                const singleRunner = runnerdetails[runner];

                lastassignedrunner  = singleRunner.suggestedrunnerid;
            }

            if(lastassignedrunner > 0)
            {
                let tempRunners     = [...this.state.runnerdetails];
                const orderrunners  = this.state.runnerdetails.find(runner => runner.suggestedrunnerid === lastassignedrunner);
                const index         = tempRunners.indexOf(orderrunners);
        
                if(index !== -1)
                {
                    tempRunners.splice(index, 1);
                    this.setState({
                        runnerdetails: tempRunners
                    },()=>{
                        let runnerNum	= Object.keys(this.state.runnerdetails).length;
        
                        if(runnerNum < 1)
                        {
                            this.setState({
                                isrunnermatched:false
                            })
                        }
                    });
                }
            }
        }
        else if(event.keyCode === 65) {
            //Do whatever when a is pressed
            this.acceptOrder();
        }
    }

    componentDidMount(){

        document.addEventListener("keydown", this.escFunction, false);

        this.getOrderDetail();

    }

    componentWillUnmount(){

        document.removeEventListener("keydown", this.escFunction, false);
    }

    acceptOrder = () => {

        if(this.state.isorderaccepted === true)
        {
            this.AddMoreRunner();
            return false;
        }

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'MarkOrderAccepted');
        bodyFormData.set('id', this.props.orderid);
        bodyFormData.set('eventid', Number(kotedetail.eventid));
        bodyFormData.set('kotid', Number(kotedetail.kotid));
        bodyFormData.set('loadbalancingdetail', JSON.stringify(this.context.loadbalancingdetail));

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
                this.getOrderDetail();
            }

        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    speak_org=(wordtospeak)=>{

        const synth = window.speechSynthesis;
        
        const allVoicesObtained = new Promise(function(resolve, reject) {

            let voices  = synth.getVoices();

            if(voices.length !== 0)
            {
                resolve(voices);
            }
            else
            {
                synth.addEventListener("voiceschanged", function() {
                    voices = synth.getVoices();
                    resolve(voices);
                });
            }
        });

        const audio = new Audio(xperia);

        audio.play();

        audio.addEventListener('ended', () =>{

            allVoicesObtained.then((voices)=>{

                if (synth.speaking) {
                    console.error('speechSynthesis.speaking');
                    return;
                }
                if (wordtospeak !== '') {
                    const utterance = new SpeechSynthesisUtterance(wordtospeak);
                    utterance.onend = (event)=> {
                        console.log('SpeechSynthesisUtterance.onend');
                    }
                    utterance.onerror = (event)=> {
                        console.error('SpeechSynthesisUtterance.onerror');
                    }

                    utterance.voice = voices[1];
                    utterance.pitch = '0.9';
                    utterance.rate  = '0.5';
                
                    synth.speak(utterance);
                }
            });

        });
    }

    getOrderDetail = () => {

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetOrderDetail');
        bodyFormData.set('id', this.props.orderid);
        bodyFormData.set('eventid', Number(kotedetail.eventid));
        bodyFormData.set('kotid', Number(kotedetail.kotid));
        bodyFormData.set('loadbalancingdetail', JSON.stringify(this.context.loadbalancingdetail));

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
                const { availrunner, runnertobeassign, hasrunner, isloadbalancing, orderdetail, isrunnermatched, requiredrunnernum, totalitems, runnerdetails, runnersummary, isorderaccepted } = response.data.dataset;

                this.setState({
                    isLoaded:true,
                    orderdetail:orderdetail,
                    isrunnermatched:isrunnermatched,
                    runnerdetails:runnerdetails,
                    isfetchingrunner:false,
                    totalitems:totalitems,
                    requiredrunnernum:requiredrunnernum,
                    availrunner:availrunner,
                    runnertobeassign:runnertobeassign,
                    hasrunner:hasrunner,
                    isloadbalancing:isloadbalancing,
                    ordercount:Object.keys(orderdetail).length,
                    runnersummary:runnersummary,
                    isorderaccepted:isorderaccepted,
                },()=>{

                    if(Number(this.context.isautopilot) > 0)
                    {
                        setTimeout(()=>{

                            this.acceptOrder();

                        },1500);
                    }
                });
            }
            else
            {
                this.props.closePopup();
                this.props.getOrders();
				this.context.toastMessage(response.data.msg,`error`);
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    saveOrderAfterAnnouncement=()=>{

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
        
        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'AssignRunnerAndSave');
        bodyFormData.set('id', this.props.orderid);
        bodyFormData.set('eventid', Number(kotedetail.eventid));
        bodyFormData.set('kotid', Number(kotedetail.kotid));
        /*bodyFormData.set('eventid', this.state.orderdetail.event_id);*/
        bodyFormData.set('isautopilot', Number(this.context.isautopilot));
        bodyFormData.set('loadbalancingdetail', JSON.stringify(this.context.loadbalancingdetail));
        let runnerNum	= Object.keys(this.state.runnerdetails).length;

        bodyFormData.set('totalrunner', runnerNum);

        let runnerdetails = [...this.state.runnerdetails];

        let runner;
        for(runner in runnerdetails)
        {
            const singleRunner = runnerdetails[runner];

            bodyFormData.set('orderrunner_'+runner, singleRunner.suggestedrunnerid);
        }

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            if(response.data.success === true)
            {
                this.props.closePopup();
                this.props.getOrders();
                /*window.open("/kote/printorder/"+this.props.orderid, "_blank");*/
            }
            else
            {
                if(response.data.closeorder === true)
                {
                    this.props.closePopup();
                    this.props.getOrders();
                }
                this.context.toastMessage(response.data.msg,`error`);
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    saveOrder=()=>{

        /*const synth = window.speechSynthesis;*/
        let runnerNum	= Object.keys(this.state.runnerdetails).length;

        if(runnerNum > 0)
        {
            this.saveOrderAfterAnnouncement();
            return true;
            /*
            let tempRunnerDetails = [...this.state.runnerdetails];

            const runnerdetails2	= tempRunnerDetails.filter(singlerunner => singlerunner.isannounce === false);

            const runnerNum2	= Object.keys(runnerdetails2).length;

            
            if(runnerNum2 < 1)
            {
                this.saveOrderAfterAnnouncement();
            }

            let runner;
            for(runner in runnerdetails2)
            {
                const singleRunner = runnerdetails2[runner];

                let wordtospeak = `Runner, ${singleRunner.suggestedrunnerid}`;

                const audio = new Audio(xperia);

                audio.play();
        
                audio.addEventListener('ended', () =>{
        
                    if (synth.speaking) {
                        console.error('speechSynthesis.speaking');
                        return;
                    }
                    if (wordtospeak !== '') {
                        const utterance = new SpeechSynthesisUtterance(wordtospeak);
                        utterance.onend = (event)=> {
                            console.log('SpeechSynthesisUtterance.onend');

                            const temprunner	= tempRunnerDetails.find(runner => runner.suggestedrunnerid === singleRunner.suggestedrunnerid);

                            temprunner.isannounce   = true;

                            this.setState(()=>{
                                return{
                                    runnerdetails:tempRunnerDetails
                                }
                            },()=>{
                                this.saveOrder();
                            })
                        }
                        utterance.onerror = (event)=> {
                            console.error('SpeechSynthesisUtterance.onerror');
                        }
        
                        utterance.pitch = '0.9';
                        utterance.rate  = '0.5';
                    
                        synth.speak(utterance);
                    }
                });
            }*/
        }
    }

    cancelOrder=()=>{

        if(window.confirm("Are you sure? This order will be cancel"))
        {
            let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

            const bodyFormData = new FormData();
            bodyFormData.set('Mode', 'CancelOrder');
            bodyFormData.set('id', this.props.orderid);
            bodyFormData.set('eventid', Number(kotedetail.eventid));
            bodyFormData.set('kotid', Number(kotedetail.kotid));
    
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+'order.php',
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{
                
                if(response.data.success === true)
                {
                    this.props.getOrders();
                    this.props.closePopup();
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
        }
    }

    AddMoreRunner=()=>{

        let runnerNum	      = Object.keys(this.state.runnerdetails).length;
        /*let runnerNum2	  = Object.keys(this.state.assignedremovedrunner).length;
        let itemNum           = this.state.totalitems;*/
        let requiredrunnernum   = this.state.requiredrunnernum;

        if(Number(runnerNum) >= Number(requiredrunnernum))
        {
			this.context.toastMessage(`No more runner required for this order.`,`error`);
            document.querySelector('#runner').focus();
            return false;
        }

        if((this.state.assigningrunnerid === '' || this.state.assigningrunnerid === undefined) && (this.state.assigningrunneridavailable === '' || this.state.assigningrunneridavailable === undefined) && ((this.state.runnertobeassign === '' || this.state.runnertobeassign === undefined) && Number(this.context.isautopilot) > 0))
        {
			this.context.toastMessage(`Please enter runner to assign.`,`error`);
            document.querySelector('#runner').focus();
            return false;
        }

        this.setState({
            isfetchingrunner:true
        },()=>{

            let tempassigningrunnerid   = this.state.assigningrunnerid;
            
            if(this.state.assigningrunneridavailable !== '' && this.state.assigningrunneridavailable !== undefined)
            {
                tempassigningrunnerid   = this.state.assigningrunneridavailable;
            }

            if((this.state.runnertobeassign !== '' && this.state.runnertobeassign !== undefined) && Number(this.context.isautopilot) > 0)
            {
                tempassigningrunnerid   = this.state.runnertobeassign;
            }

            let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

            const bodyFormData = new FormData();
            bodyFormData.set('Mode', 'GetAvailableRunner');
            bodyFormData.set('id', this.props.orderid);
            bodyFormData.set('eventid', Number(kotedetail.eventid));
            bodyFormData.set('kotid', Number(kotedetail.kotid));
            bodyFormData.set('assigningrunnerid', tempassigningrunnerid);
            bodyFormData.set('isautopilot', Number(this.context.isautopilot));
            bodyFormData.set('total', runnerNum);
            bodyFormData.set('loadbalancingdetail', JSON.stringify(this.context.loadbalancingdetail));

            let runnerdetails = [...this.state.runnerdetails];
    
            let runner;
            for(runner in runnerdetails)
            {
                const singleRunner = runnerdetails[runner];
                bodyFormData.set('orderrunner_'+runner, singleRunner.suggestedrunnerid);
            }
            
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+'runner.php',
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{
                
                if(response.data.success === true)
                {
                  let tempRunnerDetails     = this.state.runnerdetails;
                  let tempRunnerDetails2    = this.state.assignedremovedrunner;
   
                  if(response.data.asktoconfirm)
                  {
                    if(window.confirm(`Are you sure? ${response.data.msg}`))
                    {
                        let tempRunnerDetails     = this.state.runnerdetails;
                        let tempRunnerDetails2    = this.state.assignedremovedrunner;
          
                        if(response.data.orderdetail.runnerdetails)
                        {
                          response.data.orderdetail.runnerdetails.forEach(runner => {
                            const singleRunner = {...runner};
            
                            tempRunnerDetails = [...tempRunnerDetails, singleRunner];
                            tempRunnerDetails2 = [...tempRunnerDetails2, singleRunner];
                          });
          
                          this.setState({
                              runnerdetails:tempRunnerDetails,
                              assignedremovedrunner:tempRunnerDetails2,
                              isrunnermatched:response.data.orderdetail.isrunnermatched,
                              isfetchingrunner:false,
                              assigningrunnerid:'',
                              assigningrunneridavailable:'',
                          },()=>{
                              document.querySelector('#runner').focus();
                          });
                        }
                    }
                    else
                    {
                        this.setState({
                            isfetchingrunner:false,
                            assigningrunnerid:'',
                            assigningrunneridavailable:'',
                        },()=>{
                            document.querySelector('#runner').focus();
                        })
                    }
                  }
                  else
                  {
                    if(response.data.orderdetail.runnerdetails)
                    {                    
                      response.data.orderdetail.runnerdetails.forEach(runner => {
                        const singleRunner = {...runner, isannounce:false};
        
                        tempRunnerDetails = [...tempRunnerDetails, singleRunner];
                        tempRunnerDetails2 = [...tempRunnerDetails2, singleRunner];
                      });
      
                      this.setState({
                        runnerdetails:tempRunnerDetails,
                        assignedremovedrunner:tempRunnerDetails2,
                        isrunnermatched:response.data.orderdetail.isrunnermatched,
                        isfetchingrunner:false,
                        assigningrunnerid:'',
                        assigningrunneridavailable:'',
                      },()=>{
                          
                          const runnerNum	= Object.keys(this.state.runnerdetails).length;
  
                          if(runnerNum > 0 && Number(this.context.isautopilot) > 0)
                          {
                            setTimeout(()=>{

                            this.saveOrder();
    
                            },1500);
                          }
  
                          document.querySelector('#runner').focus();
                      });
                    }
                  }
                }
                else if(response.data.engaged === true)
                {
                    if(Number(this.context.loadbalancingdetail.isloadbalancing) < 1)
                    {
                        if(window.confirm(`Are you sure? ${response.data.msg}`))
                        {
                            let tempRunnerDetails     = this.state.runnerdetails;
                            let tempRunnerDetails2    = this.state.assignedremovedrunner;
              
                            if(response.data.orderdetail.runnerdetails)
                            {
                              response.data.orderdetail.runnerdetails.forEach(runner => {
                                const singleRunner = {...runner};
                
                                tempRunnerDetails = [...tempRunnerDetails, singleRunner];
                                tempRunnerDetails2 = [...tempRunnerDetails2, singleRunner];
                              });
              
                              this.setState({
                                runnerdetails:tempRunnerDetails,
                                assignedremovedrunner:tempRunnerDetails2,
                                isrunnermatched:response.data.orderdetail.isrunnermatched,
                                isfetchingrunner:false,
                                assigningrunnerid:'',
                                assigningrunneridavailable:'',
                              },()=>{
                                document.querySelector('#runner').focus();
                              });
                            }
                        }
                        else
                        {
                            this.context.toastMessage(response.data.msg,`error`);
    
                            this.setState({
                                isfetchingrunner:false,
                                assigningrunnerid:'',
                                assigningrunneridavailable:'',
                            },()=>{
                                document.querySelector('#runner').focus();
                            });
                        }
                    }
                    else
                    {
                        let tempRunnerDetails     = this.state.runnerdetails;
                        let tempRunnerDetails2    = this.state.assignedremovedrunner;
          
                        if(response.data.orderdetail.runnerdetails)
                        {
                          response.data.orderdetail.runnerdetails.forEach(runner => {
                            const singleRunner = {...runner};
            
                            tempRunnerDetails = [...tempRunnerDetails, singleRunner];
                            tempRunnerDetails2 = [...tempRunnerDetails2, singleRunner];
                          });
          
                          this.setState({
                            runnerdetails:tempRunnerDetails,
                            assignedremovedrunner:tempRunnerDetails2,
                            isrunnermatched:response.data.orderdetail.isrunnermatched,
                            isfetchingrunner:false,
                            assigningrunnerid:'',
                            assigningrunneridavailable:'',
                          },()=>{
                          
                            const runnerNum	= Object.keys(this.state.runnerdetails).length;
    
                            if(runnerNum > 0 && Number(this.context.isautopilot) > 0)
                            {
                              setTimeout(()=>{
  
                              this.saveOrder();
      
                              },1500);
                            }
    
                            document.querySelector('#runner').focus();
                        });
                        }
                    }
                }
                else
                {
					this.context.toastMessage(response.data.msg,`error`);

                    this.setState({
                        isfetchingrunner:false,
                        assigningrunnerid:'',
                        assigningrunneridavailable:'',
                    },()=>{
                        document.querySelector('#runner').focus();
                    });
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
        });
    }

    removeRunner=(id)=>{

        let tempRunners     = [...this.state.runnerdetails];
        const orderrunners  = this.state.runnerdetails.find(runner => runner.suggestedrunnerid === id);
        const index         = tempRunners.indexOf(orderrunners);

        if(index !== -1)
        {
            tempRunners.splice(index, 1);
            this.setState({
                runnerdetails: tempRunners
            });
        }
    }

    markRunnerAbsent=(id)=>{

        this.setState({
            isfetchingrunner:true,
            isrunnermatched:false
        },()=>{

            let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
            
            const bodyFormData = new FormData();
            bodyFormData.set('Mode', '');
            bodyFormData.set('type', 'absent');
            bodyFormData.set('runnerid', id);
            bodyFormData.set('kotid', localStorage.getItem('kotloginid'));
            /*bodyFormData.set('eventid', this.state.orderdetail.event_id);*/
            bodyFormData.set('eventid', Number(kotedetail.eventid));
            bodyFormData.set('kotid', Number(kotedetail.kotid));

            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+'runner.php',
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{
    
                if(response.data.success === true)
                {
                    this.removeRunner(id);
                    this.AddMoreRunner();
                }
                else
                {
					this.context.toastMessage(response.data.msg,`error`);

                    this.setState({
                        isfetchingrunner:false
                    });
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });            
        });
    }

    handleChange=(event)=>{
        this.setState({assigningrunnerid:event.target.value});
    }
    
    handleKeyPress=(event)=>{
        if(event.key === 'Enter')
        {
            if(Number(event.target.value) < 1 || event.target.value === undefined)
            {
				this.context.toastMessage(`Please enter runner id to assign`,`error`);
            }
            else
            {
                this.setState({
                    assigningrunnerid:event.target.value
                },()=>{
                    this.AddMoreRunner();
                });
            }
        }
    }

    toggleRunnerPopup() {

        this.setState({
            isRunnerPopActive: !this.state.isRunnerPopActive
        });
    }

    closePopup=()=>{
        let runnerNum	= Object.keys(this.state.runnerdetails).length;

        if(runnerNum > 0)
        {
            this.saveOrder();
        }
        this.props.closePopup();
    }

    render() {
        const {isLoaded, orderdetail, runnerdetails, isfetchingrunner, isrunnermatched, hasrunner, availrunner, ordercount, isorderaccepted, runnersummary, isRunnerPopActive, activerunnername} = this.state;

        return (
            <div>
            {
                this.props.isActive ? (
                    <div className={`catalog__search search-modal ${this.context.isautopilot ? `autopilot`:``}`}>
                        <div className={`search-modal__container container-fluid ${this.context.isautopilot ? `autopilot`:``}`}>
                        {
                            Number(ordercount) > 1 ? (
                                <div className="search-modal__header detailheader">
                                    <div className="headingbox noborder nopadding">Order Detail - ( <span style={{color:'#ff0000', fontSize:'16px'}}> {Number(ordercount)} orders</span> )</div>
                                    <span className="closepopup" onClick={this.closePopup}><img src={closepopup} alt="close" /></span>
                                </div>
                            ):(<Fragment>
                                {
                                    orderdetail.map((order, orderloop)=>{
                                        return(
                                            <div className="search-modal__header detailheader" key={orderloop}>
                                                <div className="headingbox noborder nopadding">Order Detail :</div>{order.captionid?(<div className="headingbox">{order.captionname}</div>):null}
                                                <div className="headingbox">{order.orderseat}</div>
                                                <div className="headingbox">Order Number - {order.orlo_order_id}</div>
                                                <div className="headingbox">Batch Number - {order.batchname}</div>
                                                <div className="headingbox">Orlo ID - {order.orlo_id}</div>
                                                <div className="headingbox noborder">{order.time}<div className="pendingtimetitle">({order.pendingtime})</div></div>
                                                <span className="closepopup" onClick={this.closePopup}><img src={closepopup} alt="close" /></span>
                                            </div>
                                        )
                                    })
                                }
                            </Fragment>)
                        }
                        <div className={`search-modal__body ${this.context.isautopilot ? `autopilot`:``}`}>
                            {
                                isLoaded ? (<Fragment>
                                    {
                                        Number(ordercount) > 0 ? (<Fragment>
                                            <div>
                                            {
                                                orderdetail.map((singleorder, orderloop)=>{
                                                    return(<div key={orderloop} style={{display:'block',marginBottom:'10px'}}>
                                                        {
                                                            Number(ordercount) > 1 ? <div style={{fontWeight:'bold', backgroundColor:'#9DDAED',paddingLeft:'5px',textAlign:'center'}}>Table : {singleorder.orderseat} / Order # {singleorder.orlo_order_id} / Time : {singleorder.time}</div>:null
                                                        }
                                                        <div>
                                                        {
                                                            singleorder.itemdetails.map((singlegroup, grouploop)=>{
                                                                return(
                                                                    <div key={grouploop} className="bottomborder">
                                                                    <div className="row zeromargin greenbg">
                                                                        <div className="orderedcategory">
                                                                            <div className="col-sm nopadding">
                                                                                {singlegroup.catname} - {Object.keys(singlegroup.catitems).length}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <KoteLiteOrdereditem key={singlegroup.catid} catitems={singlegroup.catitems}/>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        </div>
                                                    </div>)
                                                })
                                            }
                                            </div>
                                        </Fragment>):(<Fragment>
                                            <div>Order detail not avaliable</div>
                                        </Fragment>)
                                    }
                                    <br />
                                    <br />
            
                                    {
                                        isorderaccepted ? (
                                            <div className="orderedrunner" style={{display:'flex',justifyContent:'space-between'}}>
                                                <div className={`assignrunnerbox ${this.context.isautopilot ? `autopilot`:``}`}>
                                                    <div className="row nopadding manageorderwrapper">
                                                        <div className="col-sm-10 nopadding runnerheading">Assign Runner(s)</div>
                                                    </div>
                                                    <div className="row nopadding">
                                                        {
                                                            isrunnermatched ?(<div className={`col-sm-2 nopadding pr5 assign-runner-wrapper ${this.context.isautopilot ? `autopilot`:``}`}>
                                                            <input type="number" name="runner" id="runner" className={`assignrunnername ${this.context.isautopilot ? `autopilot`:``}`} placeholder="Enter Runner To Assign" onChange={this.handleChange} onKeyPress={this.handleKeyPress} value={this.state.assigningrunnerid} autoFocus disabled={isfetchingrunner}/>&nbsp;<button className={`addmore-runner-btn go-btn ${this.context.isautopilot ? `autopilot`:``}`} onClick={() =>{this.AddMoreRunner()}} disabled={isfetchingrunner}>Go</button>
                                                            {isfetchingrunner ?(<span className="font15">&nbsp;<i className="fa fa-spinner fa-spin"></i></span>):null}
                                                            </div>):(<div className="col-sm-2"><button className="addmore-runner-btn" onClick={() =>{this.AddMoreRunner()}} disabled={isfetchingrunner}>Recheck</button>
                                                            {isfetchingrunner ?(<span className="font15">&nbsp;<i className="fa fa-spinner fa-spin"></i></span>):null}
                                                            </div>) 
                                                        }
                                                        {isrunnermatched ?(
                                                            runnerdetails.map((runner) => {
                                                                return (
                                                                    <div key={runner.suggestedrunnerid} className={`${this.context.isautopilot ? `col-sm-4`:`col-sm-2`} runnername borderedrunner`}>{runner.suggestedrunnername}&nbsp; - &nbsp;<i className="fa fa-user-times pinkcolor" aria-hidden="true" title="Remove Runner" onClick={()=>{this.removeRunner(runner.suggestedrunnerid)}}></i>&nbsp;&nbsp;<i className="fa fa-ban pinkcolor" aria-hidden="true" title="Mark Runner Absent" onClick={()=>{this.markRunnerAbsent(runner.suggestedrunnerid)}}></i></div>
                                                                )
                                                            })
                                                        ):(<div className="col-sm-2 runnername borderedrunner norunner">No runner available</div>)}
                                                    </div>
                                                    <div className="row nopadding">
                                                    <div className="col-sm-12 runnername summaryname">( Total: {runnersummary.total}, Engaged: {runnersummary.engaged}, Available: {runnersummary.available} )</div>
                                                    </div>
                                                    {
                                                        !this.context.isautopilot && hasrunner ? (
                                                            <div>
                                                                <div className="row nopadding manageorderwrapper">
                                                                    <div className="col-sm-10 nopadding runnerheading">Available Runners</div>
                                                                </div>
                                                                {
                                                                    availrunner.map((runner) => {
                                                                        return (
                                                                            <div key={runner.runnerid} className={`btn btn-outline-primary ${runner.ispriority ?`priority`:null}`} style={{marginRight:'5px'}} onClick={()=>{
                                                                                this.setState({
                                                                                    assigningrunneridavailable:runner.runnerid
                                                                                },()=>{
                                                                                    this.AddMoreRunner();
                                                                                })
                                                                            }}>{runner.name}</div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        ):null
                                                    }
                                                </div>
                                                {
                                                    this.context.isautopilot && hasrunner ? (
                                                        <div style={{width:'30%'}}>
                                                            <div className="row nopadding manageorderwrapper">
                                                                <div className="col-sm-10 nopadding runnerheading">Available Runners</div>
                                                            </div>
                                                            {
                                                                availrunner.map((runner) => {
                                                                    return (
                                                                        <div key={runner.runnerid} className={`btn btn-outline-primary ${runner.ispriority ?`priority`:null}`} style={{marginRight:'5px'}} onClick={()=>{
                                                                            this.setState({
                                                                                assigningrunneridavailable:runner.runnerid
                                                                            },()=>{
                                                                                this.AddMoreRunner();
                                                                            })
                                                                        }}>{runner.name}</div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    ):null
                                                }
                                            </div>
                                        ):null
                                    }
                                </Fragment>):(<div>Loading....</div>)
                            }
                            <div>
                            {
                                isRunnerPopActive?(<KoteLiteRemoveRunnerConfirmPopup closePopup={this.toggleRunnerPopup.bind(this)} removeRunner={this.removeRunner} markRunnerAbsent={this.markRunnerAbsent} isRunnerPopActive={isRunnerPopActive} runnerid={this.state.activerunnerid} runnername={activerunnername}/>):null
                            }
                            </div>
                        </div>
                        <div className={`search-modal__footer ${this.context.isautopilot ? `autopilot`:``}`}>
                            {
                                isorderaccepted ? null:(
                                    <div className="leftbutton">
                                        <button className="accept-order-btn" style={{padding:'0 5px'}} onClick={this.acceptOrder}><i className="fa fa-check printorder" aria-hidden="true"></i>&nbsp;<u>A</u>ccept Order</button>
                                    </div>
                                )
                            }
                            <div className="rightbutton">
                                <button className="print-order-btn" style={{padding:'0 5px'}} onClick={this.saveOrder} disabled={!isorderaccepted || isfetchingrunner === true}><i className="fa fa-save printorder" aria-hidden="true"></i>&nbsp;<u>S</u>ave</button>
                                &nbsp;&nbsp;
                                <button className="print-order-btn" style={{padding:'0 5px'}} onClick={()=>{this.props.sendOrderInPendingList(this.props.orderid,true)}}><i className="fa fa-times printorder" aria-hidden="true"></i>&nbsp;Mark Pending</button>
                                &nbsp;&nbsp;
                                <button className="print-order-btn" style={{padding:'0 5px'}} onClick={this.cancelOrder}><i className="fa fa-times" aria-hidden="true"></i>&nbsp;Cancel Order</button>
                                &nbsp;&nbsp;
                                <button className="cancel-btn" style={{padding:'0 5px'}} onClick={this.closePopup}>Close</button>
                            </div>
                        </div>
                        </div>
                    </div>
                ):null
            }
            </div>
        );
    }
}

export default withRouter(KoteLiteOrderpopup);