import React,{Component} from 'react';
import { ProductConsumer } from '../Context/Product';
import {SwipeableDrawer,CircularProgress} from '@material-ui/core';
import { Button, Input, Label, Form, FormGroup, Alert } from 'reactstrap';

export default class QuickAddCategory extends Component{

	render(){

        return (
            <ProductConsumer>
            {(value) => {
                
                const {alertcolor, message, isprocessing, menuid, name, orderby, status, allmenus, modalstatus} = value;
                
                const {handleUserInput, handleAddEventCategory, toggleItemManagementModel} = value;

                return (
                    <SwipeableDrawer
                        anchor="bottom"
                        open={modalstatus.addcategorymodal}
                        onClose={()=>toggleItemManagementModel(`addcategorymodal`, !modalstatus.addcategorymodal)}
                        onOpen={()=>toggleItemManagementModel(`addcategorymodal`, !modalstatus.addcategorymodal)}
                        >
                        <div className="_2KabA nopadding">
                            <div className="viewcarttitle">
                                Add Category
                            </div>
                            <div className="closecartpopup" onClick={()=>toggleItemManagementModel(`addcategorymodal`, !modalstatus.addcategorymodal)}></div>
                        </div>
                        <div style={{minHeight:'60vh', paddingLeft:'15px', paddingRight:'15px', paddingTop:'15px'}}>
                            {
                                message !== "" ? (
                                    <Alert color={alertcolor}>{message}</Alert>
                                ):null
                            }
                            <Form id="addattendancefrm" name="addattendancefrm" onSubmit={handleAddEventCategory}>
                            <div className="form-group">
                                <label className="control-label col-sm-2 fullwidth" htmlFor="menuid">Menu</label>
                                <div className="col-sm-10 fullwidth" style={{paddingLeft:'0',paddingRight:'0'}}>
                                    <select name="menuid" id="menuid" className={`form-control`} value={menuid} onChange={handleUserInput}>
                                        <option value="">Select</option>
                                        {
                                            allmenus.map((menu)=>{
                                                return (
                                                    <option key={menu.id} value={menu.id}>{menu.title}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="name" id="name" placeholder="Name" value={name} onChange={handleUserInput}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="mobilenumber">Order By</Label>
                                <Input type="number" name="orderby" id="orderby" placeholder="Order By" value={orderby} onChange={handleUserInput}/>
                            </FormGroup>
                            <FormGroup tag="fieldset">
                                <legend style={{
                                    fontSize:'15px',
                                    fontWeight:'bold',
                                }}>Status</legend>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="status" id="status1" value="1" checked={Number(status) === 1} onChange={handleUserInput}/>
                                        &nbsp;Active
                                    </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Label check>
                                        <Input type="radio" name="status" id="status0" value="0" checked={Number(status) === 0} onChange={handleUserInput}/>
                                        &nbsp;In-Active
                                    </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </FormGroup>
                            </FormGroup>
                            <br />
                            <Button type="submit" color="primary" disabled={isprocessing}>Save Now</Button>{' '}{isprocessing ?<CircularProgress size="sm" color="primary" />:null}
                        </Form>
                        </div>
                    </SwipeableDrawer>
                );
            }}
            </ProductConsumer>
        )
	}
}