import React,{Component} from 'react';
import { ProductConsumer } from '../Context/Product';
import {SwipeableDrawer,CircularProgress} from '@material-ui/core';
import { Button, Input, Label, Form, FormGroup, Alert } from 'reactstrap';

export default class QuickEditMenu extends Component{

	render(){

        return (
            <ProductConsumer>
            {(value) => {
                
                const {alertcolor, message, isprocessing, name, orderby, status, modalstatus} = value;
                
                const {handleUserInput, handleEditEventMenu, toggleItemManagementModel} = value;

                return (
                    <SwipeableDrawer
                        anchor="bottom"
                        open={modalstatus.editeventmenumodal}
                        onClose={()=>toggleItemManagementModel(`editeventmenumodal`, !modalstatus.editeventmenumodal)}
                        onOpen={()=>toggleItemManagementModel(`editeventmenumodal`, !modalstatus.editeventmenumodal)}
                        >
                        <div className="_2KabA nopadding">
                            <div className="viewcarttitle">
                                Edit Menu
                            </div>
                            <div className="closecartpopup" onClick={()=>toggleItemManagementModel(`editeventmenumodal`, !modalstatus.editeventmenumodal)}></div>
                        </div>
                        <div style={{minHeight:'60vh', paddingLeft:'15px', paddingRight:'15px', paddingTop:'15px'}}>
                        {
                            message !== "" ? (
                                <Alert color={alertcolor}>{message}</Alert>
                            ):null
                        }
                        <Form id="addattendancefrm" name="addattendancefrm" onSubmit={handleEditEventMenu}>
                            <FormGroup>
                                <Label for="runnername">Name</Label>
                                <Input type="text" name="name" id="name" placeholder="Name" value={name} onChange={handleUserInput}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="mobilenumber">Order By</Label>
                                <Input type="number" name="orderby" id="orderby" placeholder="Order By" value={orderby} onChange={handleUserInput}/>
                            </FormGroup>
                            <FormGroup tag="fieldset">
                                <legend style={{
                                    fontSize:'15px',
                                    fontWeight:'bold',
                                }}>Status</legend>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="status" id="status1" value="1" checked={Number(status) === 1} onChange={handleUserInput}/>
                                        &nbsp;Active
                                    </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Label check>
                                        <Input type="radio" name="status" id="status0" value="0" checked={Number(status) === 0} onChange={handleUserInput}/>
                                        &nbsp;In-Active
                                    </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </FormGroup>
                            </FormGroup>
                            <br />
                            <Button type="submit" color="primary" disabled={isprocessing}>Save Now</Button>{' '}{isprocessing ?<CircularProgress size={30} />:null}
                        </Form>
                        </div>
                    </SwipeableDrawer>
                );
            }}
            </ProductConsumer>
        )
	}
}