import React,{Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class MenuLoadScreen extends Component{
    render() {

        return (
            <div>
                <div className="titlewrapper categorywrapper">
                    <h3><span className="categorytitleloading"><Skeleton width="50" count={1} /></span><span className="categoryquantityloading"><Skeleton width="50" count={1} /></span></h3>
                </div>
                <div className="product-listing-wrapper">
                    <ul className="products-list">
                        <li>
                            <div className="product-item-row">
                                <div className="product-item-thumb-wrapper">
                                    <div className="product-thumb">
                                        <Skeleton width={90} height={90} />
                                    </div>
                                </div>
                                <div className="product-item-detail-wrapper">
                                    <div className="product-title-wrapper clearfix">
                                        <div className="product-title-english">
                                            <span className="icon-foodsymbol"><Skeleton width={10} height={10} /></span>
                                            <span className="loadingitemtitle"><Skeleton count={1} /></span>
                                        </div>
                                        <div className="product-title-hindi"><Skeleton count={1} /></div>
                                    </div>
                                </div>
                                <div className="product-qty-add-wrapper loading">
                                    <Skeleton count={1} />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="product-item-row">
                                <div className="product-item-thumb-wrapper">
                                    <div className="product-thumb">
                                        <Skeleton width={90} height={90} />
                                    </div>
                                </div>
                                <div className="product-item-detail-wrapper">
                                    <div className="product-title-wrapper clearfix">
                                        <div className="product-title-english">
                                            <span className="icon-foodsymbol"><Skeleton width={10} height={10} /></span>
                                            <span className="loadingitemtitle"><Skeleton count={1} /></span>
                                        </div>
                                        <div className="product-title-hindi"><Skeleton count={1} /></div>
                                    </div>
                                </div>
                                <div className="product-qty-add-wrapper loading">
                                    <Skeleton count={1} />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="product-item-row">
                                <div className="product-item-thumb-wrapper">
                                    <div className="product-thumb">
                                        <Skeleton width={90} height={90} />
                                    </div>
                                </div>
                                <div className="product-item-detail-wrapper">
                                    <div className="product-title-wrapper clearfix">
                                        <div className="product-title-english">
                                            <span className="icon-foodsymbol"><Skeleton width={10} height={10} /></span>
                                            <span className="loadingitemtitle"><Skeleton count={1} /></span>
                                        </div>
                                        <div className="product-title-hindi"><Skeleton count={1} /></div>
                                    </div>
                                </div>
                                <div className="product-qty-add-wrapper loading">
                                    <Skeleton count={1} />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="product-item-row">
                                <div className="product-item-thumb-wrapper">
                                    <div className="product-thumb">
                                        <Skeleton width={90} height={90} />
                                    </div>
                                </div>
                                <div className="product-item-detail-wrapper">
                                    <div className="product-title-wrapper clearfix">
                                        <div className="product-title-english">
                                            <span className="icon-foodsymbol"><Skeleton width={10} height={10} /></span>
                                            <span className="loadingitemtitle"><Skeleton count={1} /></span>
                                        </div>
                                        <div className="product-title-hindi"><Skeleton count={1} /></div>
                                    </div>
                                </div>
                                <div className="product-qty-add-wrapper loading">
                                    <Skeleton count={1} />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="product-item-row">
                                <div className="product-item-thumb-wrapper">
                                    <div className="product-thumb">
                                        <Skeleton width={90} height={90} />
                                    </div>
                                </div>
                                <div className="product-item-detail-wrapper">
                                    <div className="product-title-wrapper clearfix">
                                        <div className="product-title-english">
                                            <span className="icon-foodsymbol"><Skeleton width={10} height={10} /></span>
                                            <span className="loadingitemtitle"><Skeleton count={1} /></span>
                                        </div>
                                        <div className="product-title-hindi"><Skeleton count={1} /></div>
                                    </div>
                                </div>
                                <div className="product-qty-add-wrapper loading">
                                    <Skeleton count={1} />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}