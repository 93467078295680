import React,{Component,Fragment} from 'react';
import SplashScreen from './SplashScreen';
import Skeleton from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';
import { ProductContext } from '../Context/Product';
import QrReader from 'react-qr-reader';
import scanlogo from '../assets/images/scanlogo.png';

class Scan extends Component{

    static contextType = ProductContext;

    constructor(props) {
      super(props);

      this.state = {
        scanresult: 'No result',
        ispageload: false,
        islogoloaded:false,
        isentercodeloaded:false
      }
    }

    UNSAFE_componentWillMount(){
      this.setState({
        ispageload:true,
        isentercodeloaded:true
      })
    }
  
    componentDidUpdate(prevProps) {

        let tabledetail   = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));
        let eventid       = "";

        if(tabledetail || captiondetail || stewarddetail)
        {
          if(tabledetail !== null && (Number(tabledetail.eventid) > 0 && tabledetail.eventid !== null))
          {
            eventid	= tabledetail.eventid;
          }
          else if(captiondetail !== null && (Number(captiondetail.eventid) > 0  && captiondetail.eventid !== null))
          {
            eventid	= captiondetail.eventid;
          }
          else if(stewarddetail !== null && (Number(stewarddetail.eventid) > 0 && stewarddetail.eventid !== null))
          {
            eventid	= stewarddetail.eventid;
          }
          if(this.context.isloggedin === "true" && eventid > 0)
          {
            this.props.history.push("/menu");
          }
        }
    }

    handleScan = data => {
        if(this.context.internetstatus === "offline")
        {
            return false;
        }

        let message       = "";
        let canlogin      = false;

        if (data) {
          this.setState({
            scanresult: data,
            tablenumber: data
          },
          ()=>{
            const restables   = this.context.tables;
            const tabledetail = restables.find(restables => Number(restables.code) === Number(this.state.scanresult));

            if(tabledetail)
            {
              canlogin  = true;
              this.context.clearCart();
              localStorage.setItem("tabledetail",JSON.stringify(tabledetail));
              localStorage.setItem("isloggedin",false);
              this.context.setGuestUser();
              this.props.history.push("/menu");
            }
            else
            {
              message = "Invalid QR Code, Please re-scan a new code.";
            }
          });
        }

        if(canlogin === false)
        {
          this.context.showError("scanerrormsg",message);
          return false;
        }
    }

    handleError = err => {
        console.error(err)
    }

    handleSeatIDFocus = (e) => {
      document.getElementsByClassName("scannerwrapper")[0].classList.add("hide");
    }

    handleSeatIDBlur = (e) => {
      document.getElementsByClassName("scannerwrapper")[0].classList.remove("hide");
      if(this.state.tablenumber !== undefined && this.state.tablenumber !== "")
      {
        this.handleCodeClick();
      }
    }

    handleChangeEvent = (e) => {
        this.setState({ tablenumber: e.target.value });
    }

    handleCodeClick = (e) => {

      let message       = "";
      let canlogin      = false;

      if(this.context.internetstatus === "offline")
      {
        message = "Please connect to internet to process";
      }
      const restables   = this.context.tables;

      const tabledetail	= restables.find(restables => Number(restables.code) === Number(this.state.tablenumber));

      if(this.state.tablenumber === undefined)
      {
        message = "Please enter Orlo ID 'or' scan qr code";
      }

      if(tabledetail)
      {
        canlogin  = true;
        this.context.clearCart();
        localStorage.setItem("tabledetail",JSON.stringify(tabledetail));
        localStorage.setItem("isloggedin",false);
        this.context.setGuestUser();
        this.props.history.push("/menu");
      }
      else
      {
        message = "Please enter a valid orlo ID.";
      }

      if(canlogin === false)
      {
        this.context.showError("seaterrormsg",message);
        return false;
      }
    }

    handleClick=()=>{
      this.props.history.push("/login");
    }
	render(){
		return (
      <Fragment>
      {
        this.state.ispageload ? (<div>
          <div className="row nomargin">
            <div className="col-sm nomargin">
            <span className="loginbtnwrapper"><span className="loginicon" onClick={()=>{this.handleClick()}}></span>{/*<button type="button" className="login100-form-btn" onClick={()=>{this.handleClick()}}>Login</button>*/}</span>
            </div>
          </div>
          <div className="row nomargin">
            <div className="col-sm nomargin scan-logo-wrapper">
              {this.state.islogoloaded ? false :
                <Skeleton width={90} height={90} />
              }
              <img className="scan-logo" style={this.state.islogoloaded ? {} : {display: 'none'}} src={scanlogo} alt="logo" onLoad={() => this.setState({islogoloaded: true})} />
            </div>
          </div>
          <div className="row seatcodewrapper">
            <div className="col-sm">
              <div className="seaterrormsg"></div>
            </div>
          </div>
          <div className="row nomargin seatcodewrapper">
            <div className="col-sm nomargin nopadding">
              {this.state.isentercodeloaded ? false :
                <Skeleton height={40} />
              }
              <input type="number" name="seatcode" id="seatcode" className="floatleft" value={this.state.tablenumber || ''} onChange={this.handleChangeEvent} onFocus={this.handleSeatIDFocus} onBlur={this.handleSeatIDBlur} autoComplete="off" placeholder="Enter Orlo ID" style={this.state.isentercodeloaded ? {} : {display: 'none'}} />
              <input type="button" name="enterseatcode" id="enterseatcode" className="ripplepink floatleft" value="Go" onClick={this.handleCodeClick} style={this.state.isentercodeloaded ? {} : {display: 'none'}}/>
            </div>
          </div>
          <div className="nopadding scannerwrapper" style={{marginTop:'25px'}}>
            <div className="row nomargin">
              <div className="col-sm nomargin nopadding">
                <div className="additionaloptiontitle">OR</div>
                <div className="scanerrormsg"></div>
              </div>
            </div>
            <div className="row nomargin mt20">
              <div className="col-sm nomargin nopadding">
                {this.state.isentercodeloaded ? false :
                  <div><span className="scancodetitle"><Skeleton height={33} width={200}/></span>
                  <span className="scancodesubtitle"><Skeleton height={27} width={300}/></span></div>
                }
                <span className="scancodetitle" style={this.state.isentercodeloaded ? {} : {display: 'none'}}>Scan QR Code</span>
                <span className="scancodesubtitle" style={this.state.isentercodeloaded ? {} : {display: 'none'}}>Placed on your table to order food</span>
              </div>
            </div>
            <div className="qrcodeloading">
            {this.state.isentercodeloaded ? false :
              <Skeleton height={280} />
            }
            </div>
            <div className="row nomargin marginauto" style={this.state.isentercodeloaded ? {} : {display: 'none'}}>
              <div className="col-sm qrcodeqrapper">
                <QrReader delay={1} onError={this.handleError} onScan={this.handleScan} className="qrcodebox" />
              </div>
            </div>
            <br />
          </div>
        </div>):(<SplashScreen/>)
      }
      </Fragment>
      );
	}
}

export default withRouter(Scan);