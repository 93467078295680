import React,{Component} from 'react';
import { ProductConsumer } from '../Context/Product';
import {SwipeableDrawer,CircularProgress} from '@material-ui/core';
import { Input, Label, Form, FormGroup } from 'reactstrap';

export default class QuickEditCategory extends Component{

	render(){

        return (
            <ProductConsumer>
            {(value) => {
                
                const {isprocessing, menuid, categoryname, orderby, status, allmenus, modalstatus} = value;
                
                const {viewEditMenuDetail, handleUserInput, handleEditCategory, toggleItemManagementModel} = value;

                return (
                    <SwipeableDrawer
                        anchor="bottom"
                        open={modalstatus.editcategorymodal}
                        onClose={()=>toggleItemManagementModel(`editcategorymodal`, !modalstatus.editcategorymodal)}
                        onOpen={()=>toggleItemManagementModel(`editcategorymodal`, !modalstatus.editcategorymodal)}
                        >
                        <div className="_2KabA nopadding">
                            <div className="viewcarttitle">
                                Edit Category
                            </div>
                            <div className="closecartpopup" onClick={()=>toggleItemManagementModel(`editcategorymodal`, !modalstatus.editcategorymodal)}></div>
                        </div>
                        <div style={{minHeight:'70vh', paddingLeft:'15px', paddingRight:'15px', paddingTop:'15px'}}>
                        {/*
                            message !== "" ? (
                                <Alert color={alertcolor}>{message}</Alert>
                            ):null
                        */}
                        <Form id="addattendancefrm" name="addattendancefrm" onSubmit={handleEditCategory}>
                            <div className="form-group">
                                <label className="control-label col-sm-2 fullwidth" htmlFor="menuid">Menu</label>
                                <div className="col-sm-10 fullwidth" style={{display:'flex',justifyContent:'space-between',paddingLeft:'0',paddingRight:'0'}}>
                                    <select name="menuid" id="menuid" className={`form-control`} onChange={handleUserInput} value={menuid}>
                                        <option value="">Select</option>
                                        {
                                            allmenus.map((menu)=>{
                                                return (
                                                    <option key={menu.id} value={menu.id}>{menu.title}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        Number(menuid) > 0 ? <button type="button" className="btn btn-default savebutton width45px menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',marginLeft:'5px'}} onClick={()=>{viewEditMenuDetail(menuid)}}>
                                        <i className="fa fa-edit"></i>
                                        </button>:null
                                    }
                                    <button type="button" className="btn btn-default savebutton width45px menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',width:'100px',marginLeft:'5px'}} onClick={()=>{toggleItemManagementModel(`addeventmenumodal`, !modalstatus.addeventmenumodal)}}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                </div>
                            </div>                            
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="categoryname" id="categoryname" placeholder="Name" value={categoryname} onChange={handleUserInput}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="mobilenumber">Order By</Label>
                                <Input type="number" name="orderby" id="orderby" placeholder="Order By" value={orderby} onChange={handleUserInput}/>
                            </FormGroup>
                            <FormGroup tag="fieldset">
                                <legend style={{
                                    fontSize:'15px',
                                    fontWeight:'bold',
                                }}>Status</legend>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="status" id="status1" value="1" checked={Number(status) === 1} onChange={handleUserInput}/>
                                        &nbsp;Active
                                    </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Label check>
                                        <Input type="radio" name="status" id="status0" value="0" checked={Number(status) === 0} onChange={handleUserInput}/>
                                        &nbsp;In-Active
                                    </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </FormGroup>
                            </FormGroup>
                            <div className="form-group">
                                <div className="col-sm-offset-2 col-sm-10 fullwidth" style={{paddingLeft:'0',paddingRight:'0'}}>
                                    <button type="submit" className="btn btn-default savebutton" disabled={isprocessing}>{isprocessing ?<CircularProgress size={25} />:`Save Now`}</button>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-offset-2 col-sm-10 fullwidth" style={{paddingLeft:'0',paddingRight:'0'}}>
                                    <button type="button" className="btn btn-default savebutton menuitem" disabled={isprocessing} onClick={()=>toggleItemManagementModel(`editcategorymodal`, !modalstatus.editcategorymodal)}>Cancel</button>
                                </div>
                            </div>
                        </Form>
                        </div>
                    </SwipeableDrawer>
                );
            }}
            </ProductConsumer>
        )
	}
}