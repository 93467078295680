import React,{Component, Fragment} from 'react';
import { Button } from 'reactstrap';
import {ProductConsumer} from '../Context/Product';
import {CircularProgress} from '@material-ui/core';
/*import Skeleton from 'react-loading-skeleton';
import ImageZoom from 'react-medium-image-zoom';*/

class ProductManage extends Component{

	constructor(){
		super();
		this.state = {
			loaded: false,
			isZoomed:false,
			setIsZoomed:false
		};
	}

	handleImgLoad=()=>{
		this.setState({
			setIsZoomed:true
		});
	}

	handleZoomChange =(shouldZoom)=>{
		this.setState({
			setIsZoomed:shouldZoom
		});
	}
	
	render(){

		const {id, sku, title, img, isvegfood, showvegicon, instock, islivecounter, isselfservice, zonenumber, iszoneediting, zonenumber2, tempzonenumber, isimageavailable, isimageremoving, iszoneupdating, isinstockupdating, islivecounterupdating, isselfserviceupdating, isorderupdating, isorderediting, temporderby, orderby, orderby2} = this.props.product;

		var foodsymbolcolor = " non-veg-food";

		if(isvegfood)
		{
			foodsymbolcolor = " veg-food";
		}

		var novegicon = "";

		if(!showvegicon)
		{
			novegicon = " noicon";
		}

		return(
			<ProductConsumer>{(value)=>{

				const{updateProductStatus, viewEditItemDetail, showEditZoneNumber, saveZoneNumber, handleZoneNumberInput, showEditOrderBy, saveOrderBy, handleOrderByInput, removeImage} = value;

				return(
					<li>
						<div className="product-item-row">
							<div className="product-item-thumb-wrapper">
								<div className="product-thumb">
									<img src={`${img}`} alt={sku} />
								</div>
								{
									isimageavailable ? <div style={{textAlign:'center',marginTop:'5px'}}>
									{
										isimageremoving ? <CircularProgress size={30} />:<Button color="danger" onClick={()=>{removeImage(id)}}>Remove Image</Button>
									}
									</div>:null
								}
							</div>
							<div className="product-item-detail-wrapper">
								<div className="product-title-wrapper clearfix">
									<div className={`product-title-english${novegicon} manageitem${instock ? ``:` stockout`}`}>
									{
										showvegicon ? (<span className={"icon-foodsymbol"+foodsymbolcolor}><i className="fa fa-circle"></i></span>) : null
									}
									{
										showvegicon ? (<div style={{width:"80%",float:'left'}}>{title}</div>):(<div style={{width:"90%",float:'left'}}>{title}</div>)
									}
									<div style={{width:"10%",float:'left'}}><i className="fa fa-edit" onClick={()=>{viewEditItemDetail(id)}}></i></div>
									</div>
								</div>
								<div className="manageitemwrapper">
									<div className="manageitem">
										<div className={`title${instock ? ``:` stockout`}`}>In Stock ?</div>
										{
											isinstockupdating ? (<CircularProgress size={30} style={{float:'right'}}/>):(<Fragment><input type="checkbox" name={`instock_${id}`} id={`instock_${id}`} onChange={()=>updateProductStatus(id,'instock')} checked={instock}/><label htmlFor={`instock_${id}`}>In Stock</label></Fragment>)
										}
									</div>
									<div className="manageitem">
										<div className={`title${instock ? ``:` stockout`}`}>Live Counter ?</div>
										{
											islivecounterupdating ? (<CircularProgress size={30} style={{float:'right'}}/>):(<Fragment><input type="checkbox" name={`islivecounter_${id}`} id={`islivecounter_${id}`} onChange={()=>updateProductStatus(id,'islivecounter')} checked={islivecounter}/><label htmlFor={`islivecounter_${id}`}>Is Live Counter</label></Fragment>)
										}
									</div>
									<div className="manageitem">
										<div className={`title${instock ? ``:` stockout`}`}>Self Service ?</div>
										{
											isselfserviceupdating ? (<CircularProgress size={30} style={{float:'right'}}/>):(<Fragment><input type="checkbox" name={`isselfservice_${id}`} id={`isselfservice_${id}`} onChange={()=>updateProductStatus(id,'isselfservice')} checked={isselfservice}/><label htmlFor={`isselfservice_${id}`}>Is Self Service</label></Fragment>)
										}
									</div>
									<div className={`manageitem${instock ? ``:` stockout`}`} style={{display:'flex',width:'65%',float:'right',justifyContent:'space-between',alignItems:'center',marginBottom:'0'}}>
										<div className={`title${instock ? ``:` stockout`}`}>Counter Number</div>
										{
											iszoneediting ? (
												<div style={{width:'150px'}}>
												<input type="number" className={`form-control`} name={`tempzonenumber_${id}`} id={`tempzonenumber_${id}`} value={tempzonenumber} onChange={(e)=>handleZoneNumberInput(e, id)} style={{width:'50%',display:'inline-block'}}/>
												&nbsp;
												{
													iszoneupdating ? <CircularProgress size={30} />:<button type="button" className="btn btn-default savebutton menuitem width45px" onClick={()=>saveZoneNumber(id)}>Go</button>
												}
												</div>
											):(<div style={{width:'30%',height:'4rem',textAlign:'center'}}>{zonenumber ? `${zonenumber}`:`${zonenumber2}`}&nbsp;&nbsp;<i className="fa fa-edit" onClick={()=>{showEditZoneNumber(id)}}></i></div>)
										}
									</div>
									<div className={`manageitem${instock ? ``:` stockout`}`} style={{display:'flex',width:'65%',float:'right',justifyContent:'space-between',alignItems:'center',marginBottom:'0'}}>
										<div className={`title${instock ? ``:` stockout`}`}>Order By</div>
										{
											isorderediting ? (
												<div style={{width:'150px'}}>
												<input type="number" className={`form-control`} name={`temporderby_${id}`} id={`temporderby_${id}`} value={temporderby} onChange={(e)=>handleOrderByInput(e, id)} style={{width:'50%',display:'inline-block'}}/>
												&nbsp;
												{
													isorderupdating ? <CircularProgress size={30} />:<button type="button" className="btn btn-default savebutton menuitem width45px" onClick={()=>saveOrderBy(id)}>Go</button>
												}
												</div>
											):(<div style={{width:'30%',height:'4rem',textAlign:'center'}}>{orderby ? `${orderby}`:`${orderby2}`}&nbsp;&nbsp;<i className="fa fa-edit" onClick={()=>{showEditOrderBy(id)}}></i></div>)
										}
									</div>
								</div>
							</div>
						</div>
					</li>
				)

			}}</ProductConsumer>
		)
	}
}

export default ProductManage;