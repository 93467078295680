import React,{Fragment, Component} from 'react';
import {ProductContext, ProductConsumer} from '../Context/Product';
import MenuLoadScreen from '../components/MenuLoadScreen';
import Itemnotfound from '../components/Itemnotfound';
import GroupProductListManage from '../components/GroupProductListManage';
import MenufilterManage from '../components/MenufilterManage';
import Captainfooterbar from '../components/Captainfooterbar';
import Popupedititemdetails from '../components/Popupedititemdetails';
import Searchfiltermanage from '../components/Searchfiltermanage';
import{QuickAddMenu, QuickAddCategory, QuickEditCategory, QuickEditMenu} from '../NewComponents';

class ManageMenu extends Component {

    static contextType = ProductContext;

    componentDidMount(){
        document.body.classList.remove('orderthanks');

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
        let eventid      = "";

		if(kotedetail)
		{
			if(Number(kotedetail.eventid) > 0)
			{
				eventid = kotedetail.eventid;
			}
			if(eventid < 1 || kotedetail.eventid === undefined || kotedetail.eventid === "")
			{
                Promise.all([localStorage.setItem("appe_koteurl",'/managemenu')])
                .then(() => {
                    this.props.history.push("/kotelogin");
                })
			}
			else
			{
                this.context.setKoteProducts();
			}
		}
		else
		{
            Promise.all([localStorage.setItem("appe_koteurl",'/managemenu')])
            .then(() => {
                this.props.history.push("/kotelogin");
            })
		}
    }

    componentWillUnmount(){

        this.context.unloadKoteProducts();
        
    }

    goToAddMenu=()=>{
        this.props.history.push("/addmenu");
    }
    
    render(){

        return(
            <ProductConsumer>{(value)=>{

                const {isAllProductLoaded,isActiveEditItemDetail, eventallmenus} = value;
                const {viewEditItemDetail} = value;

                return (
                    <div className="view-cart-wrapper">
                        <div className="local-cart-container-org engagedrunner" style={{textAlign:'center',fontWeight:'bold'}}>
                            <div className="ordermessage runnermsg">Manage Menu</div>
                            <div className="separator mt-0"></div>
                            <div className="stockmanagement">
                                {
                                    !isAllProductLoaded ? <MenuLoadScreen />:(<Fragment>
                                        <Searchfiltermanage />
                                    {
                                        Object.keys(eventallmenus).length > 0 ? (<Fragment>
                                        {
                                            eventallmenus.map((menu, menuloop)=>{
                                                return <GroupProductListManage key={menuloop} menu={menu} value={value} />
                                            })
                                        }
                                        </Fragment>):<Itemnotfound />
                                    }
                                    </Fragment>)
                                }
                                <div className="quickmanagestockbox" onClick={this.goToAddMenu}><i className="fa fa-plus" aria-hidden="true"></i></div>
                                <MenufilterManage />
                                <Captainfooterbar />
                            </div>
                        </div>
                        <Popupedititemdetails closeEditItemDetail={viewEditItemDetail.bind(this)} isActiveEditItemDetail={isActiveEditItemDetail}/>
                        <QuickAddMenu />
                        <QuickEditMenu />
                        <QuickAddCategory />
                        <QuickEditCategory />
                    </div>
                )                

            }}</ProductConsumer>
        )
    }
}

export default ManageMenu;