import React,{Component} from 'react';
import {Link} from "react-router-dom";
import { ThemeConsumer } from '../Context/Theme';

class SingleCategoryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }

    render(){

        const{url, title}  = this.props;

        return (
            <ThemeConsumer>
            {(value) => {
                
                const{ scrollToViewCaptionItem } = value;

                return (
                    <Link to={`/${url}`}>
                    <div className="singlecategorybox" onClick={()=>{
                        scrollToViewCaptionItem();
                    }}>
                        <h6 title={`${title}`} color="#1C1C1C" className="singlecategorytitle" style={{
                            width:'100%',
                            maxWidth:'100%',
                        }}>{title}</h6>
                        {/*<h6 color="#696969" className="singlecategoryqty">{newcount}</h6>*/}
                    </div>
                    </Link>
                );
            }}
            </ThemeConsumer>
        )
    }
}

export default SingleCategoryItem;