import React,{Component} from 'react';
import { ProductConsumer } from '../Context/Product';

class OrderStatusButton extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }

    render(){

        return (
            <ProductConsumer>
            {(value) => {

                const{toggleOrderStatus} = value;
                const{cartTotalItem, isorderstatusopen, miscdetail, isitemmarkdeliveredbyguest, recentdeliverrunnerid} = value;

                return (
                    <div className="categoryfilterbox menu-nav-fab-button">
                        <div className={Number(cartTotalItem) > 0 ? `menubtnwitcartstatus orderstatus ${miscdetail.servicestatus === 0 || (!isitemmarkdeliveredbyguest && recentdeliverrunnerid) ?`servicedisablemsg`:``}`:`menubtnwitoutcartstatus orderstatus ${miscdetail.servicestatus === 0 || (!isitemmarkdeliveredbyguest && recentdeliverrunnerid) ?`servicedisablemsg`:``}`}>
                            <button className="menubtn caption" style={{
                                margin:'auto'
                            }} onClick={()=>{
                                toggleOrderStatus();
                            }}>
                                <div className={`${isorderstatusopen ?'menuclose':'menulines'}`}>
                                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                </div>
                                <span className="menutitle">Order Status</span>
                            </button>
                        </div>
                    </div>
                );
            }}
            </ProductConsumer>
        )
    }
}

export default OrderStatusButton;