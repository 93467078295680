import React,{Component, Fragment} from 'react';
import { ProductConsumer, ProductContext } from '../../Context/Product';
import {CrossSearch}  from '../../constants';

export default class VegFilter extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }

	render(){

        return (
            <ProductConsumer>
            {(value) => {

                const{isvegonly} = value;
                const{handleVegNonVeg} = value;

                return (
                    <Fragment>
                        <div className={`newfilter ${isvegonly ?'activeveg':''}`} onClick={()=>handleVegNonVeg()}>
                            <div className={`veg itemtypesymbol`} style={{
                                display:'flex',
                                position:'initial'
                            }}>
                                <div className={`roundvegtype`}></div>
                            </div> 
                            &nbsp;Veg
                            {
                                isvegonly ? <CrossSearch />:null
                            }
                            
                        </div>
                        &nbsp;
                    </Fragment>
                );
            }}
            </ProductConsumer>
        );
	}
}