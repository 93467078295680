import React,{Component,Fragment} from 'react';
import { ProductConsumer, ProductContext } from '../Context/Product';
import Captainfooterbar from '../components/Captainfooterbar';
import RunnerSearch from '../components/RunnerSearch';
import Popuptokendetails from '../components/Popuptokendetails';

export default class Editmenu extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            scanresult:'No result'
        };
    }
    componentDidMount(){

        if(document.getElementsByClassName('page-content').length > 0)
        {
            document.getElementsByClassName("page-content")[0].classList.remove("footercartopen");
        }

        this.context.GetEngagedRunners();

        this.refreshIntervalId = setInterval(() => {
            this.context.GetEngagedRunners();
        }, 5000);
    }

    componentWillUnmount(){
        clearInterval(this.refreshIntervalId);
    }

	render(){

        return (
            <Fragment>
            <ProductConsumer>
            {(value) => {
                const {engagedrunners,releaserunner,viewDetail,isActiveDetail} = value;
                return (
                    <div className="view-cart-wrapper">
                        <div className="local-cart-container selecttable engagedrunner">
                            <div className="ordermessage runnermsg">Release Runner</div>
                            <div className="searchfilter mb-2">
                                <RunnerSearch />
                            </div>
                            {
                                engagedrunners ? (<div className="row nomargin">
                                {
                                    engagedrunners.map((runner)=>{
                                    return (
                                        <div key={runner.index} className="col-sm-2 appwidth50">
                                            <div className="table">
                                                <div className="runnerleftbox">
                                                    <div className="runneridbox" onClick={()=>releaserunner(runner.runnerid,runner.appename)}>{runner.appename}</div>
                                                    <div className="runnernamebox" onClick={()=>releaserunner(runner.runnerid,runner.appename)}>{runner.appetitle}</div>
                                                </div>
                                                <div className="runnerrightbox">
                                                    <div className="tokendetail">
                                                        <button style={{
                                                          display:'inline',width:'auto',margin:'0',padding:'0',color:'#092147'
                                                        }} onClick={()=>viewDetail(runner.runnerid,runner.tokenno, true)}><i className="fa fa-info-circle" style={{fontSize:'2.8rem'}}></i></button>
                                                    </div>
                                                    <div className="engagedstatus" onClick={()=>viewDetail(runner.runnerid,runner.tokenno, true)}>{runner.appestatus}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                  })
                                }
                                </div>):null
                            }
                        </div>
                        <Popuptokendetails closeOrderStatus={viewDetail.bind(this)} isActiveDetail={isActiveDetail}/>
                    </div>
                );
            }}
            </ProductConsumer>
            <Captainfooterbar />
            </Fragment>
        );
	}
}