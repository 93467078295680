import React,{Fragment,Component} from 'react';
import axios from "axios";
import { ProductContext } from '../Context/Product';
import scanlogo from '../assets/images/scanlogo.png';

class Kotelogin extends Component {

    static contextType = ProductContext;

      constructor (props) {
          super(props);
          this.state = {
              operatorid: '1',
              password: '',
              formErrors: {operatorid: '1', password: ''},
              operatoridValid: true,
              passwordValid: true,
              formValid: false,
              alloperator:[{id:1,title:'Operator 1'},{id:2,title:'Operator 2'}]
          }

          this.handleSubmit = this.handleSubmit.bind(this);
      }

      UNSAFE_componentWillMount(){
        this.setState({
          ispageload:true
        },()=>{
          let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
          let eventid      = "";
  
          if(kotedetail)
          {
            if(Number(kotedetail.eventid) > 0)
            {
              eventid = kotedetail.eventid;
            }
            if(eventid > 0)
            {
              let koteurl = "";

              if(localStorage.getItem('appe_koteurl') === "" || localStorage.getItem('appe_koteurl') === null || localStorage.getItem('appe_koteurl') === undefined)
              {
                koteurl = "/kotedashboard";
              }
              else
              {
                koteurl = localStorage.getItem('appe_koteurl');
              }
              Promise.all([localStorage.setItem("appe_koteurl",'')])
              .then(() => {
                this.props.history.push(`${koteurl}`);
              })
            }
          }
        });
      }

    componentDidUpdate(prevProps) {

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
        let eventid      = "";

        if(kotedetail)
        {
          if(Number(kotedetail.eventid) > 0)
          {
            eventid = kotedetail.eventid;
          }
          if(eventid > 0)
          {
            let koteurl = "";

            if(localStorage.getItem('appe_koteurl') === "" || localStorage.getItem('appe_koteurl') === null || localStorage.getItem('appe_koteurl') === undefined)
            {
              koteurl = "/kotedashboard";
            }
            else
            {
              koteurl = localStorage.getItem('appe_koteurl');
            }
            Promise.all([localStorage.setItem("appe_koteurl",'')])
            .then(() => {
              this.props.history.push(`${koteurl}`);
            })
          }
        }
    }

    handleUserInput = (e) => {
      const name  = e.target.name;
      const value = e.target.value;
      
      this.setState({[name]: value},
                    () => { this.validateField(name, value);
                      if(name === 'logintype' && (value === 'table' || value === 'caption'))
                      {
                        document.querySelector('#operatorid').focus();
                      }
                      else if(name === 'logintype' && value === 'eventsync')
                      {
                        document.querySelector('#eventcode').focus();
                      }
                    });
    }

    validateField(fieldName, value) {
      let fieldValidationErrors   = this.state.formErrors;
      let operatoridValid         = this.state.operatoridValid;
      let passwordValid           = this.state.passwordValid;
  
      switch(fieldName) {
        case 'operatorid':
            operatoridValid = value.length >= 1;
            fieldValidationErrors.operatorid = operatoridValid ? '' : ' is invalid';
            break;
        case 'password':
            passwordValid = value.length >= 1;
            fieldValidationErrors.password = passwordValid ? '': ' is too short';
            break;
        default:
            break;
      }
      this.setState({formErrors: fieldValidationErrors,
                      operatoridValid: operatoridValid,
                      passwordValid: passwordValid
                    }, this.validateForm);
    }

    validateForm() {
      this.setState({formValid: this.state.operatoridValid && this.state.passwordValid});
    }

    errorClass(error) {
      return(error.length === 0 ? '' : 'has-error');
    }

    handleClear = () => {
      this.setState({
        operatorid: "",
        password:"",
        formValid:false
      });
    }

    handleSubmit(e) {
        e.preventDefault();

        let message       = "";

        if((this.state.operatorid === undefined || this.state.operatorid === null || this.state.operatorid === "") && message === "")
        {
            message   = "Please select a operator.";
        }

        if((this.state.password === undefined || this.state.password === null || this.state.password === "") && message === "")
        {
            message   = "Please enter Password.";
        }
        this.context.showError("seaterrormsg",message);

        if(message === "")
        {
            if(document.getElementsByClassName("eventerrormsg").length > 0)
            {
                document.getElementsByClassName("eventerrormsg")[0].innerText = "Processing...";
            }

            const bodyFormData = new FormData();
            bodyFormData.set('Mode', 'KotLogin');
            bodyFormData.set('userid', this.state.operatorid);
            bodyFormData.set('eventid', this.state.password);
            bodyFormData.set('loginarea', `kotelite`);
            
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}applogin.php?t=${Date.now()}`,
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{

                if(document.getElementsByClassName("eventerrormsg").length > 0)
                {
                    document.getElementsByClassName("eventerrormsg")[0].innerText = "";
                }

                if(response.data.success === true)
                {
                    this.context.showError("seaterrormsg",response.data.msg);

                    let kotedetail  = {iskotlogin:1,kotid:response.data.kotid,eventid:response.data.eventid,name:response.data.name,phone:response.data.phone,logintime:response.data.logintime};

                    localStorage.setItem("appe_kotedetail",JSON.stringify(kotedetail));

                    let koteurl = "";

                    if(localStorage.getItem('appe_koteurl') === "" || localStorage.getItem('appe_koteurl') === null || localStorage.getItem('appe_koteurl') === undefined)
                    {
                      koteurl = "/kotedashboard";
                    }
                    else
                    {
                      koteurl = localStorage.getItem('appe_koteurl');
                    }
                    Promise.all([localStorage.setItem("appe_koteurl",'')])
                    .then(() => {
                      this.props.history.push(`${koteurl}`);
                    })
                }
                else
                {
                    this.context.showError("seaterrormsg",response.data.msg);
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
        }
        return false;
    }

    handleClick=()=>{

    }

    render() {
        return (
          <Fragment>
              <div className="limiter">
                  <div className="row nomargin">
                    <div className="col-sm nomargin">
                      <span className="loginbtnwrapper width110"></span>
                    </div>
                  </div>
                  <div className="container-login100 loginform">
                      <div className="wrap-login100">
                        <div className="login100-pic js-tilt" data-tilt>
                          <img src={scanlogo} alt="Login Icon" />
                        </div>
                        <div className="row seatcodewrapper" style={{width:'100%'}}>
                          <div className="col-sm-12">
                            <div className="seaterrormsg"></div>
                            <div className="eventerrormsg"></div>
                          </div>
                        </div>
                        <form className="loginform login100-form validate-form" id="loginform" name="loginform" onSubmit={this.handleSubmit}>
                            <div className="wrap-input100 validate-input">
                                <select name="operatorid" id="operatorid" className={`form-control`} onChange={this.handleUserInput}>
                                    {
                                        this.state.alloperator.map((operator)=>{
                                            return (
                                                <option key={operator.id} value={operator.id}>{operator.title}</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="focus-input100"></span>
                            </div>
                            <div className="wrap-input100 validate-input" data-validate = "Password is required">
                                <input type="password" className={`form-control ${this.state.passwordValid}`} name="password" id="password" value={this.state.password} onChange={this.handleUserInput} placeholder="Password"/>
                                <span className="focus-input100"></span>
                            </div>
                            <div className="container-login100-form-btn">
                                <button type="submit" className="login100-form-btn" disabled={!this.state.formValid}>Login</button>
                            </div>
                        </form>
                      </div>

                    </div>
              </div>
            </Fragment>
        );
    }
}

export default Kotelogin;