import React,{Fragment, Component} from 'react';
import { ProductConsumer } from '../Context/Product';
import SingleCategoryItem from './SingleCategoryItem';

class CaptionMenuList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount(){

    }

    render(){

        return (
            <ProductConsumer>
            {(value) => {

                return (
                    <Fragment>
                        <SingleCategoryItem id={`1`} url={`runnerattendance`} title={`Runner Attendance`}/>
                        <SingleCategoryItem id={`2`} url={`managerunner`} title={`Manage Runner`}/>
                        <SingleCategoryItem id={`3`} url={`releaserunner`} title={`Release Runner`}/>
                        <SingleCategoryItem id={`4`} url={`managemenu`} title={`Manage Menu`}/>
                        <SingleCategoryItem id={`5`} url={`addmenu`} title={`Quick Add Items`}/>
                        <SingleCategoryItem id={`6`} url={`managebanner`} title={`Manage Banner`}/>
                        <SingleCategoryItem id={`7`} url={`addbanner`} title={`Add Banner`}/>
                        <SingleCategoryItem id={`8`} url={`kotelogin`} title={`Kote Lite`}/>
                    </Fragment>
                );
                
            }}
            </ProductConsumer>
        )
    }
}

export default CaptionMenuList;