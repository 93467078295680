import React,{ Component } from 'react';
import { ProductConsumer } from '../Context/Product';

import CartItem from './CartItem';

class RemoveCustomOption extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    render(){

        const{ id/*, title, hindititle, itemoptions, customitemqty*/ } = this.props.productdetail;

        return (
            <ProductConsumer>
            {(value) => {
                
                const{ cart, canremoveoption } = value;
                const{ toggleRemoveItemOption } = value;

                const tempcart  = cart.filter(cartitem => cartitem.id === id);

                return (
                    <div className="fgjele modalWrapper " aria-hidden="false" role="dialog">
                        <div tabIndex="-1" className="hcdmGE" onClick={()=>{
                            toggleRemoveItemOption(id);
                        }}></div>
                        <div mode="light" type="default" tabIndex="0" className="hSfokC">
                            <div style={{display:'flex',justifyContent:'center',position:'relative'}}>
                                <div className="dfUaKd" style={{
                                    position:'absolute',
                                    bottom:canremoveoption ?'4rem':'0px',
                                }}>
                                    <div className="close-button" onClick={()=>{
                                        toggleRemoveItemOption(id);
                                    }}></div>
                                </div>
                            </div>
                            <section className="eggzWm">
                                <h2 className="cInTtE">Remove your items</h2>
                                <span onClick={()=>{
                                    toggleRemoveItemOption(id);
                                }}><i style={{fontSize:'2.6rem'}} className="fa fa-angle-down" aria-hidden="true"></i></span>
                            </section>
                            <section className="oRYSe">
                                <div className="idQvOD">
                                    <hr className="gnHEvw" />
                                    {/*<div className="hPCrWT"></div>*/}
                                    {tempcart.map(item=>{
                                        return <CartItem key={item.tempcartid} itemdetail={item} />
                                    })}
                                    <div className="kesDMu"></div>
                                </div>
                            </section>
                        </div>
                        <div tabIndex="0" className="ckiDDQ"></div>
                    </div>
                );
            }}
            </ProductConsumer>
        )
    }
}

export default RemoveCustomOption;