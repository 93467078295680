import React,{ Component } from 'react';
import { ProductConsumer } from '../Context/Product';

class ListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    render(){

            return (
                <ProductConsumer>
                {(value) => {
                    
                    const{ eventtables, isvipchecked, istableopen, miscdetail, isitemmarkdeliveredbyguest, iscaptionlogin, isstewardlogin } = value;
                    const{ toggleListTable, setOrderTableAndAddOrder, handleMarkVipOrder } = value;

                    return (
                        <div className="hOPtIO">
                            <div className="fgjele modalWrapper" aria-hidden="false" role="dialog" style={{height:miscdetail.servicestatus === 1 && !isitemmarkdeliveredbyguest && !iscaptionlogin && !isstewardlogin ? `90%`:`100%`}}>
                                <div tabIndex="-1" className="hcdmGE" onClick={()=>{
                                    toggleListTable();
                                }}></div>
                                <div className="iBlUrl"></div>
                                <div mode="light" type="medium" tabIndex="0" className="gzwZLJ">
                                    <div style={{display:'flex',justifyContent:'center',position:'relative'}}>
                                        <div className="dfUaKd" style={{
                                            position:'absolute',
                                            bottom:istableopen ?'4rem':'0px',
                                        }}>
                                            <div className="close-button" onClick={()=>{
                                                toggleListTable();
                                            }}></div>
                                        </div>
                                    </div>
                                    <section className="eggzWm">
                                        <h2 mode="light" className="cInTtE">Select Table</h2>
                                        <span onClick={toggleListTable}><i style={{fontSize:'2.6rem'}} className="fa fa-angle-down" aria-hidden="true"></i></span>
                                    </section>
                                    <section className="oRYSe">
                                        <div className="kCdFWE"></div>
                                        <div className="ItLLI selecttable" style={{
											maxHeight:'35rem',
											minHeight:'20rem',
											overflow:'auto',
										}}>
											{eventtables.map(table=>{
												return(
													<div key={table.id} className="appwidth50">
														<button className="table" style={{
															borderRadius:'5px',
                                                            maxWidth:'25rem',
														}} onClick={()=>{setOrderTableAndAddOrder(table.id,table.title,table.code,table.seatid)}}>{table.title}</button>
													</div>
												);
											})}
                                        </div>
										<div className="appelement" style={{
											marginTop:'2rem',
                                            backgroundColor:'rgb(237, 90, 107)',
										}}>
											<div className="squared" style={{
                                                marginLeft:'5px',
                                                marginRight:'5px',
                                                margin:'auto',
                                                textAlign:'center',
                                            }}>
												<input type="checkbox" name="markviporder" id="markviporder" value="1" checked={isvipchecked} onChange={handleMarkVipOrder}/>
												<label htmlFor="markviporder">Mark As VIP Order</label>
											</div>
										</div>
                                    </section>
                                </div>
                                <div tabIndex="0" className="ckiDDQ"></div>
                            </div>
                        </div>
                    );
                }}
                </ProductConsumer>
            )

    }
}

export default ListTable;