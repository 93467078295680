import React,{Component,Fragment} from 'react';
import { ProductConsumer, ProductContext } from '../Context/Product';
import Captainfooterbar from '../components/Captainfooterbar';
import { Button, Input, Label, Form, FormGroup } from 'reactstrap';

export default class Addbanner extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            scanresult:'No result'
        };
    }
    componentDidMount(){
        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
        let eventid      = "";

		if(kotedetail !== null && (Number(kotedetail.eventid) > 0 && kotedetail.eventid !== null))
		{
			if(Number(kotedetail.eventid) > 0)
			{
				eventid = kotedetail.eventid;
			}
			if(eventid < 1 || kotedetail.eventid === undefined || kotedetail.eventid === "")
			{
                Promise.all([localStorage.setItem("appe_koteurl",'/addbanner')])
                .then(() => {
                    this.props.history.push("/kotelogin");
                })
			}
		}
		else
		{
            Promise.all([localStorage.setItem("appe_koteurl",'/addbanner')])
            .then(() => {
                this.props.history.push("/kotelogin");
            })
		}
    }

	render(){

        return (
            <Fragment>
            <ProductConsumer>
            {(value) => {
                
                const {handleUserInput, handleAddBanner} = value;
                const {orderby, bannerstatus, isprocessing, bannerarea, bannertype} = value;

                return (
                    <div className="view-cart-wrapper">
                        <div className="local-cart-container selecttable engagedrunner stockmanagement" style={{marginBottom:'unset'}}>
                            <div className="ordermessage runnermsg">Add Banner</div>
                            <br />
                            <div style={{paddingLeft:'10px',paddingRight:'10px',textAlign:'left'}}>
                                <Form id="addbannerfrm" name="addbannerfrm" onSubmit={handleAddBanner}>
                                    <FormGroup tag="fieldset">
                                        <legend style={{
                                            fontSize:'15px',
                                            fontWeight:'bold',
                                        }}>Banner Area</legend>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="bannerarea" id="bannerarea1" value="1" checked={Number(bannerarea) === 1} onChange={handleUserInput}/>
                                                &nbsp;Home Page
                                            </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Label check>
                                                <Input type="radio" name="bannerarea" id="bannerarea0" value="0" checked={Number(bannerarea) === 0} onChange={handleUserInput}/>
                                                &nbsp;Thanks Page
                                            </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </FormGroup>
                                    </FormGroup>

                                    <FormGroup tag="fieldset">
                                        <legend style={{
                                            fontSize:'15px',
                                            fontWeight:'bold',
                                        }}>Banner Type</legend>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="bannertype" id="bannertype1" value="1" checked={Number(bannertype) === 1} onChange={handleUserInput}/>
                                                &nbsp;Image
                                            </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Label check>
                                                <Input type="radio" name="bannertype" id="bannertype0" value="0" checked={Number(bannertype) === 0} onChange={handleUserInput}/>
                                                &nbsp;Video
                                            </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </FormGroup>
                                    </FormGroup>
                                    {
                                        Number(bannertype) === 1 ? (
                                        <FormGroup>
                                            <Label for="imagefile">Upload Banner (jpg,jpeg)</Label>
                                            <Input type="file" name="imagefile" id="imagefile" onChange={handleUserInput} style={{border:'unset'}}/>
                                        </FormGroup>
                                        ):(
                                            <div>
                                        {
                                            Number(bannerarea) === 1 ? (
                                                <FormGroup>
                                                    <Label for="SmallImage">Upload Floating Banner (jpg,gif)</Label>
                                                    <Input type="file" name="SmallImage" id="SmallImage" onChange={handleUserInput} style={{border:'unset'}}/>
                                                </FormGroup>
                                            ):null
                                        }
                                                <FormGroup>
                                                    <Label for="VideoFile">Upload Video (mp4)</Label>
                                                    <Input type="file" name="VideoFile" id="VideoFile" onChange={handleUserInput} style={{border:'unset'}}/>
                                                </FormGroup>
                                            </div>
                                        )
                                    }
                                    <FormGroup>
                                        <Label for="orderby">Order By</Label>
                                        <Input type="number" name="orderby" id="orderby" placeholder="Order By" value={orderby} onChange={handleUserInput}/>
                                    </FormGroup>
                                    <FormGroup tag="fieldset">
                                        <legend style={{
                                            fontSize:'15px',
                                            fontWeight:'bold',
                                        }}>Status</legend>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="bannerstatus" id="bannerstatus1" value="1" checked={Number(bannerstatus) === 1} onChange={handleUserInput}/>
                                                &nbsp;Active
                                            </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Label check>
                                                <Input type="radio" name="bannerstatus" id="bannerstatus0" value="0" checked={Number(bannerstatus) === 0} onChange={handleUserInput}/>
                                                &nbsp;In-Active
                                            </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </FormGroup>
                                    </FormGroup>
                                    <Button type="submit" color="primary" disabled={isprocessing}>Save Now</Button>{' '}{isprocessing ?<span style={{fontSize:'3rem',color:'#007bff'}}><i className={`fa fa-refresh fa-spin`} aria-hidden="true"></i></span>:null}
                                </Form>
                                <div className="quickmanagestockbox" onClick={()=>{
                                    this.props.history.push("/managebanner")
                                }}><i className="fa fa-indent" aria-hidden="true"></i></div>
                            </div>
                        </div>
                    </div>
                );
            }}
            </ProductConsumer>
            <Captainfooterbar />
            </Fragment>
        );
	}
}