import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import closepopup from '../assets/images/closepopup.png';

class RunnerItemPopup extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            error : null,
            keywordsearch : '',
            isLoaded : false,
            runnerid : this.props.activerunnerid,
            type : null,
            reports : [],
        };

        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event){
        if(event.keyCode === 27) {
            //Do whatever when esc is pressed
            this.props.closeActiveItemPopup();
        }
    }

    componentDidMount(){

        this.getReportSummary();

        setInterval(() => {

            this.getReportSummary();
            
        }, 5000);

        document.addEventListener("keydown", this.escFunction, false);

    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleSearch = (e) => {
        const name      = e.target.name;
        const value     = e.target.value;
        let tempreports = [];

        this.setState({[name]: value},()=>{

            if(this.state.allreports.length > 0)
            {
                this.state.allreports.forEach(report => {
                    const singleReport	= {...report};
    
                    const id      = singleReport.id;
                    const name    = singleReport.name;
    
                    if(((name.toLowerCase().indexOf(this.state.keywordsearch.toLowerCase()) !== -1) || (id.toLowerCase().indexOf(this.state.keywordsearch.toLowerCase()) !== -1)))
                    {
                        tempreports = [...tempreports, singleReport];
                    }
                });
    
                this.setState({
                    reports:tempreports
                });
            }
        });
    }

    changeReportType=(reporttype)=>{
        if(reporttype !== this.state.reporttype)
        {
            this.setState({
                isLoaded:false,
                reporttype:reporttype
            },()=>{
                this.getReportSummary();
            });
        }
    }

    getReportSummary=()=>{

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetRunnerItemList');
        bodyFormData.set('runnerid', this.state.runnerid);
        bodyFormData.set('kotid', Number(kotedetail.kotid));
        bodyFormData.set('eventid', Number(kotedetail.eventid));

        this.setState({keywordsearch:''});

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'runner.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
                this.setState({
                    isLoaded:true,
                    reports:response.data.itemlist
                });
            }
            else
            {
                this.setState({
                    isLoaded:true,
                    allreports:[],
                    reports:null
                });
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    render() {
        const { isLoaded, reports } = this.state;

        return (
            <div>
            {
                this.props.isActiveItem ?(
                    <div className="catalog__search search-modal">
                        <div className="search-modal__container">
                            <div className="search-modal__header">
                                Item Report Summary<span className="closepopup" onClick={this.props.closeActiveItemPopup}><img src={closepopup} alt="close" /></span>
                            </div>
                            <br />
                            <div className="search-modal__body">
                                <div className="table-responsive catalog__search__results">
                                    {isLoaded?(<div className="reporttable">
                                        
                                        <div className="mt10">
                                            {
                                                reports ? (<div>
                                                    <table className="table table-bordered">
                                                        <thead className="thead-custom">
                                                        <tr>
                                                            <th scope="col">S. No</th>
                                                            <th scope="col">Item</th>
                                                            <th scope="col">Item Quantity</th>
                                                            <th scope="col">Token No</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            reports.map((report)=>{
                                                                return (
                                                                    <tr key={report.index}>
                                                                        <th scope="row">{report.index}</th>
                                                                        <td>{report.product_name} - ({report.product_name_hindi})</td>
                                                                        <td>{report.itemquantity}</td>
                                                                        <td>{report.tokenno}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>):(<div className="errormsg"><br /><br /><br /><br />No record found<br /><br /></div>)
                                            }
                                        </div>
                                    </div>):(<div>Loading ...</div>)}
                                </div>
                            </div>
                            <br />
                            {/*<div className="search-modal__footer">
                                <button className="btn btn-primary" onClick={this.props.closeReportPopup}>close</button>
                            </div>*/}
                        </div>
                    </div>                    
                ):null
            }
            </div>
        );
    }
}

export default withRouter(RunnerItemPopup);