import React from 'react';

export default function OrderedTokenItem({item}) {

    const{itemname, qty, status, statusclass, hasoption, itemoption} = item;

    return (
        <li>
            <div className="product-item-row ordertokendetail">
                <div className="product-item-detail-wrapper">
                    <div className="product-title-wrapper clearfix">
                        <div className="product-title-english ">{itemname} X {qty}</div>
                    </div>
                        {
                            hasoption ? (<div style={{textAlign:'left'}}><span className="optioname" style={{
                                color:'#000000'
                            }}>{
                                    itemoption.map((optioncat)=>{
                                        return( 
                                            <div key={optioncat.categoryid}><span style={{
                                                color:'#000000',
                                                marginLeft:'5px'
                                            }}><span style={{fontWeight:'bold',fontSize:'16px',textDecoration:'underline'}}>{optioncat.name} :</span>
                                            {` `}
                                            {
                                                optioncat.itemoption.map((option)=>{
                                                    return(
                                                        <span key={option.optionid} className="optioname"
                                                        style={{
                                                            fontStyle:'italic',
                                                            color:'#000000',
                                                            fontWeight:'normal',
                                                        }}
                                                        >
                                                        {option.name}
                                                        </span>
                                                    )
                                                })
                                            }
                                            </span></div>
                                        )
                                    })
                            }</span></div>):null
                        }
                </div>
                <div className="product-status-wrapper">
                    <div className={`product-status ${statusclass}`}>
                    {status}
                    </div>
                </div>
            </div>
        </li>
    );
}