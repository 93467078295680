import React,{Component, Fragment} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import {ProductConsumer} from '../Context/Product';
import Skeleton from 'react-loading-skeleton';

class BannerThanks extends Component{

    constructor(props) {
        super(props);

        this.state = {
        	loaded: false,
        }
	}
	
	render(){
		return (
			<Fragment>
			<ProductConsumer>
			{(value) => {
				const {isThanksBannerLoaded, eventthanksbanner, additionalimageurl, adstobeshown} = value;

				if(!isThanksBannerLoaded)
				{
					return <div className="bannerwrapper">
						<Skeleton height={170} className="bannerloadbox" />
					</div>
				}
				else if(eventthanksbanner)
				{
					return (
						<div style={{maxWidth:'80%',margin:'auto'}}>{
							<div className="bannerwrapper">
								{this.state.loaded ? false :
									<Skeleton height={170} />
								}
								<div className="flexcontainer" style={this.state.loaded ? {} : {display: 'none'}}>
								{eventthanksbanner ? (
									<Carousel showThumbs={false} autoPlay={true} interval="5000" infiniteLoop={true} showArrows={false} showIndicators={false} dynamicHeight={true} showStatus={false}>
                                        {(()=>{
                                            if(adstobeshown.type === 1)
                                            {
                                                return(
                                                    <div key={adstobeshown.id}>
                                                        <img src={`${additionalimageurl}${adstobeshown.image}`} alt={`Banner ${adstobeshown.id}`} onLoad={() => this.setState({loaded: true})}/>
                                                    </div>
                                                );
                                            }
                                            else
                                            {
                                                return(
                                                    <div key={adstobeshown.id}>
                                                        <video
															loop
															muted
															autoPlay
															src={`${additionalimageurl}${adstobeshown.image}`}
															preload={'auto'}
															type={'video/mp4'}
															onLoadedData={() => {
																this.setState({loaded: true});
															}}>
														</video>
                                                    </div>
                                                );
                                            }
                                        })()}
									</Carousel>
								):null}
								</div>
							</div>
						}</div>
					);
				}
			}}
			</ProductConsumer>
			</Fragment>
		);
	}
}

export default BannerThanks;