import React,{Component} from 'react';
import { ProductConsumer, ProductContext } from '../../Context/Product';

export default class ContainEggToggle extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }

	render(){

        return (
            <ProductConsumer>
            {(value) => {
                
                const{containegg} = value;
                const{handleContainEgg} = value;

                return (
                    <div className="newfirsttogglebox secondtogglebox">
                        <span className="tglspannew">
                        <input type="checkbox" name={`containegg`} id={`containegg`} className={`containegg`} onChange={()=>handleContainEgg()} checked={containegg}/><label htmlFor={`containegg`}>contains egg</label>
                        </span>
                        <h6 className="vegonlytitle">contains egg</h6>
                    </div>
                );
            }}
            </ProductConsumer>
        );
	}
}