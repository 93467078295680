import React,{Component,Fragment} from 'react';
import { ProductContext, ProductConsumer } from '../Context/Product';
import { Button } from 'reactstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class Popupedititemdetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            table: []
        };
    }

    static contextType = ProductContext;

    componentDidMount(){

        this.setState(()=>{
            return{
                table: JSON.parse(localStorage.getItem('tabledetail'))
            };
        });
    }

    render() {

        let className = '';
                
        if(this.props.isActiveEditItemDetail)
        {
            className += 'opened';

			document.body.classList.add("editcategoryopen");
        }
		else
		{
			document.body.classList.remove("editcategoryopen");
		}

        return (
            <Fragment>
                <div className={`popup ${className}`} onClick={this.props.closeEditItemDetail}></div>
                <div className={`_13lkS ${className}`}>
                    <ProductConsumer>
                    {value=>{

                        const {handleEditItem, handleUserInput, translateTextInHindi, addMoreCustomization, addMoreOption, handleCustomizationInput, handleOptionInput, onSelectFile, onImageLoaded, onCropComplete, onCropChange, searchImageFromGoogle, onSelectGoogleFile, clearGoogleImages, resetAttachedImage, viewEditMenuDetail, viewEditCategoryDetail, toggleItemManagementModel} = value;
                        
                        const {edititem_categoryid, edititem_itemtypeid, edititem_itemname, edititem_hindiname, edititem_islivecounter, edititem_isoutofstock, edititem_isselfservice, alleventcategory, eventitemtypes, alleventmenu, edititem_menuid, edititem_zonenumber, iscustomization, customization, crop, croppedImageUrl, src, googleimages, hasgoogleimages, issearchingiamge, hasimage, itempreview, modalstatus} = value;

                        return(
                            <Fragment>
                                <div className="_2KabA nopadding">
                                    <div className="viewcarttitle">
                                        Item Detail
                                    </div>
                                    <div className="closecartpopup" onClick={()=>{
                                        resetAttachedImage();
                                        this.props.closeEditItemDetail();
                                    }}></div>
                                </div>
                                <div className="view-cart-wrapper">
                                    <div className="container orderedlist">
                                        <div className="local-cart-container editcategory">
                                            <form className="form-horizontal" id="itemform" name="itemform" onSubmit={handleEditItem}>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-2 fullwidth" htmlFor="edititem_menuid">Menu</label>
                                                    <div className="col-sm-10 fullwidth" style={{display:'flex',justifyContent:'space-between'}}>
                                                        <select name="edititem_menuid" id="edititem_menuid" className={`form-control`} onChange={handleUserInput} value={edititem_menuid}>
                                                            <option value="">Select</option>
                                                            {
                                                                alleventmenu.map((menu)=>{
                                                                    return (
                                                                        <option key={menu.id} value={menu.id}>{menu.title}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {
                                                            Number(edititem_menuid) > 0 ? <button type="button" className="btn btn-default savebutton width45px menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',marginLeft:'5px'}} onClick={()=>{viewEditMenuDetail(edititem_menuid)}}>
                                                            <i className="fa fa-edit"></i>
                                                            </button>:null
                                                        }
                                                        <button type="button" className="btn btn-default savebutton width45px menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',width:'100px',marginLeft:'5px'}} onClick={()=>{toggleItemManagementModel(`addeventmenumodal`, !modalstatus.addeventmenumodal)}}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-2 fullwidth" htmlFor="edititem_categoryid">Category</label>
                                                    <div className="col-sm-10 fullwidth" style={{display:'flex',justifyContent:'space-between'}}>
                                                        <select name="edititem_categoryid" id="edititem_categoryid" className={`form-control`} onChange={handleUserInput} value={edititem_categoryid}>
                                                            <option value="">Select</option>
                                                            {
                                                                alleventcategory.map((category)=>{
                                                                    return (
                                                                        <option key={category.id} value={category.id}>{category.title}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {
                                                            Number(edititem_categoryid) > 0 ? <button type="button" className="btn btn-default savebutton width45px menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',marginLeft:'5px'}} onClick={()=>{viewEditCategoryDetail(edititem_categoryid)}}>
                                                            <i className="fa fa-edit"></i>
                                                            </button>:null
                                                        }
                                                        <button type="button" className="btn btn-default savebutton width45px menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',width:'100px',marginLeft:'5px'}} onClick={()=>{toggleItemManagementModel(`addcategorymodal`, !modalstatus.addcategorymodal)}}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-2 fullwidth" htmlFor="edititem_itemtypeid">Item Type</label>
                                                    <div className="col-sm-10 fullwidth">
                                                    <select name="edititem_itemtypeid" id="edititem_itemtypeid" className={`form-control`} onChange={handleUserInput} value={edititem_itemtypeid}>
                                                        <option value="">Select</option>
                                                        {
                                                            eventitemtypes.map((itemtype)=>{
                                                                return (
                                                                    <option key={itemtype.id} value={itemtype.id}>{itemtype.name}</option>
                                                                )
                                                            })                                            
                                                        }
                                                    </select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-2 fullwidth" htmlFor="edititem_itemname">Item Name</label>
                                                    <div style={{display:'flex'}}>
                                                        <div className="col-sm-6" style={{width:'60%'}}>
                                                            <input type="text" className={`form-control`} id="edititem_itemname" name="edititem_itemname" placeholder="Item Name" value={edititem_itemname} onChange={handleUserInput}/>
                                                        </div>
                                                        <div className="col-sm-4" style={{width:'40%',padding:'0px 15px 0px 5px'}}>
                                                            <button type="button" className="btn btn-default savebutton menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px'}} onClick={()=>{translateTextInHindi('edit')}}>Translate</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-2 fullwidth" htmlFor="edititem_hindiname">Hindi Name</label>
                                                    <div className="col-sm-10 fullwidth">
                                                        <input type="text" className={`form-control`} id="edititem_hindiname" name="edititem_hindiname" placeholder="Hindi Name" value={edititem_hindiname} onChange={handleUserInput}/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-2 fullwidth" htmlFor="edititem_zonenumber">Counter Number</label>
                                                    <div className="col-sm-10 fullwidth fullwidth">
                                                        <input type="number" className={`form-control`} name="edititem_zonenumber" id="edititem_zonenumber" placeholder="Counter Number" value={edititem_zonenumber} onChange={handleUserInput} style={{width:'40%'}}/>
                                                    </div>
                                                </div>
                                        {
                                            hasimage ? (
                                                <div className="form-group">
                                                    <label className="control-label col-sm-2 fullwidth" htmlFor="newitemname">Current Item Image</label>
                                                    <div className="col-sm-10 fullwidth fullwidth">
                                                        <img src={`${itempreview}`} alt={`${edititem_itemname}`} style={{width:'120px'}}/>
                                                    </div>
                                                </div>
                                            ):null
                                        }
                                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                                    <div style={{width:'50%',padding:'0px 15px 0px 5px'}}>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-2 fullwidth" htmlFor="imagefile">Upload image (jpg,jpeg)</label>
                                                            <div className="col-sm-10 fullwidth fullwidth">
                                                                <input type="file" name="imagefile" id="imagefile" accept="image/*" onChange={onSelectFile} style={{display:'block'}}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{width:'50%'}}>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-2 fullwidth" htmlFor="imagefile">Fetch From Google</label>
                                                            <div className="col-sm-10 fullwidth fullwidth googlecontronbtn">
                                                                {
                                                                    !hasgoogleimages ? (
                                                                        <button type="button" className="btn btn-default savebutton menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',width:'70% !important'}} disabled={issearchingiamge} onClick={()=>{searchImageFromGoogle('edititem')}}>{issearchingiamge ? `Searching...`:`Fetch`}</button>
                                                                    ):``
                                                                }
                                                                {` `}
                                                                {
                                                                    hasgoogleimages && Object.keys(googleimages).length > 0 ? (<button type="button" className="btn btn-default savebutton menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',width:'70% !important'}} onClick={()=>{clearGoogleImages()}}>Cancel</button>):``
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    !issearchingiamge && !src && Object.keys(googleimages).length > 0 ? (
                                                        <div className='row'>
                                                        {
                                                            googleimages.map((imageres, gimgloop)=>{
                                                                return(
                                                                    <div className="col-sm-4" key={gimgloop} style={{textAlign:'center',marginBottom:'10px'}} onClick={()=>{
                                                                        onSelectGoogleFile(imageres)
                                                                    }}>
                                                                        <img src={imageres.path} style={{width:'100%'}} alt={`Searched Item Preview`}/>
                                                                        <div>{imageres.witdh} px X {imageres.height} px</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        </div>
                                                    ):``
                                                }
                                                <div style={{display:'flex'}}>
                                                    <div className="imagepreviewblock">{src && (<ReactCrop src={src} crop={crop} ruleOfThirds onImageLoaded={onImageLoaded} onComplete={onCropComplete} onChange={onCropChange} />)}</div>
                                                    <div className="imagepreviewblock">{croppedImageUrl && (<img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />)}</div>
                                                </div>
                                                <div style={{display:'block'}}>
                                                    <div className="form-group">
                                                        <div className="col-sm-10 fullwidth squared lefttext">
                                                            <input type="checkbox" name={`edititem_isoutofstock`} id={`edititem_isoutofstock`} value="0" checked={Number(edititem_isoutofstock) === 0} onChange={handleUserInput}/><label htmlFor={`edititem_isoutofstock`}> In Stock ?</label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="col-sm-10 fullwidth squared lefttext">
                                                            <input type="checkbox" name={`edititem_islivecounter`} id={`edititem_islivecounter`} value="1" checked={Number(edititem_islivecounter)} onChange={handleUserInput}/><label htmlFor={`edititem_islivecounter`}> Live Counter ?</label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="col-sm-10 fullwidth squared lefttext">
                                                            <input type="checkbox" name={`edititem_isselfservice`} id={`edititem_isselfservice`} value="1" checked={Number(edititem_isselfservice)} onChange={handleUserInput}/><label htmlFor={`edititem_isselfservice`}> Self Service ?</label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="control-label col-sm-2 fullwidth" htmlFor="iscustomization">Is Customizable?</label>
                                                        <div className="col-sm-10 fullwidth squared lefttext">
                                                            <input type="checkbox" name={`iscustomization`} id={`iscustomization`} value="1" checked={iscustomization} onChange={handleUserInput}/><label htmlFor={`iscustomization`}> Yes</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        iscustomization ? (
                                                            <div style={{width:'98%',margin:'auto'}}>
                                                            {
                                                                customization.map((optioncategory, categoryloop)=>{
                                                                    return(
                                                                        <fieldset className="options" key={categoryloop}>
                                                                            <legend className="optiontitle">Customization #{optioncategory.categoryindex}</legend>
                                                                            <div className="form-group">
                                                                                <label className="control-label col-sm-2 fullwidth" htmlFor={`CustomizationName${optioncategory.categoryindex}`}>Category Name</label>
                                                                                <div className="col-sm-10 fullwidth fullwidth">
                                                                                    <input type="text" className={`form-control`} name={`CustomizationName${optioncategory.categoryindex}`} id={`CustomizationName${optioncategory.categoryindex}`} placeholder="Category Name" value={optioncategory.customizationname} onChange={(e)=>handleCustomizationInput(e, optioncategory.categoryindex, 'customizationname')}/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="control-label col-sm-2 fullwidth" htmlFor={`type${optioncategory.categoryindex}_2`}>Type</label>
                                                                                <div className="col-sm-10 fullwidth squared lefttext">
                                                                                    <input type="radio" name={`type${optioncategory.categoryindex}`} id={`type${optioncategory.categoryindex}_1`} value="1" onClick={(e)=>handleCustomizationInput(e, optioncategory.categoryindex, 'customizationtype')} defaultChecked={Number(optioncategory.customizationtype) === 1}/>
                                                                                    <label htmlFor={`type${optioncategory.categoryindex}_1`}>Choose One</label>
                                                                                    <input type="radio" name={`type${optioncategory.categoryindex}`} id={`type${optioncategory.categoryindex}_2`} value="2" onClick={(e)=>handleCustomizationInput(e, optioncategory.categoryindex, 'customizationtype')} defaultChecked={Number(optioncategory.customizationtype) === 2}/>
                                                                                    <label htmlFor={`type${optioncategory.categoryindex}_2`}>Choose Any</label>                      
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="control-label col-sm-2 fullwidth" htmlFor={`OrderBy${optioncategory.categoryindex}`}>Order By</label>
                                                                                <div className="col-sm-10 fullwidth fullwidth">
                                                                                    <input type="number" className={`form-control`} name={`OrderBy${optioncategory.categoryindex}`} id={`OrderBy${optioncategory.categoryindex}`} placeholder="Order By" value={optioncategory.orderby} onChange={(e)=>handleCustomizationInput(e, optioncategory.categoryindex, 'orderby')} style={{width:'40%'}}/>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                optioncategory.customoption.map((options, optionloop)=>{
                                                                                    return(
                                                                                        <div className="form-group" key={optionloop}>
                                                                                            <label className="control-label col-sm-2 fullwidth" htmlFor={`option${options.optionindex}`}>Option {options.optionindex}</label>
                                                                                            <div className="col-sm-10 fullwidth fullwidth">
                                                                                                <input type="text" className={`form-control`} name={`option${options.optionindex}`} id={`option${options.optionindex}`} placeholder="Option" value={options.option} onChange={(e)=>handleOptionInput(e, optioncategory.categoryindex, options.optionindex)}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <br />
                                                                            <Button color="warning" size="sm" style={{fontSize:'1.2rem',padding:'.25rem .6rem',display:'flex'}} onClick={()=>addMoreOption(optioncategory.categoryindex)}>Add More Option</Button>
                                                                        </fieldset>
                                                                    )
                                                                })
                                                            }
                                                            <br />
                                                            <Button color="warning" size="sm" style={{fontSize:'1.2rem',padding:'.25rem .6rem',display:'flex'}} onClick={()=>addMoreCustomization()}>Add More Customization</Button>
                                                            </div>
                                                        ):``
                                                    }
                                                    <br />
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-offset-2 col-sm-10 fullwidth">
                                                        <button type="submit" className="btn btn-default savebutton">Save Now</button>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="col-sm-offset-2 col-sm-10 fullwidth">
                                                        <button type="button" className="btn btn-default savebutton menuitem" onClick={()=>{
                                                            resetAttachedImage();
                                                            this.props.closeEditItemDetail();
                                                        }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }}
                    </ProductConsumer>
                </div>                
            </Fragment>            
        );
    }
}

export default Popupedititemdetails;