import React,{Component} from 'react';

class KoteLiteTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="ordertitle">
                    {this.props.title}
                    {
                        this.props.total ?(<span> - ({this.props.total})</span>):null
                    }
                </div>
            </div>
        );
    }
}

export default KoteLiteTitle;