import React,{Component,Fragment} from 'react';
import { ProductConsumer, ProductContext } from '../Context/Product';
import Captainfooterbar from '../components/Captainfooterbar';
import { Input, Label, FormGroup} from 'reactstrap';

export default class RunnerAttendance extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            scanresult:'No result'
        };
    }
    
    componentDidMount(){
        this.context.GetEventRunner();
        this.context.GetEventTable();
        document.querySelector('#batchrunnerid').focus();
    }

    goToManageMenu=()=>{
        this.props.history.push("/managemenu");
    }

	render(){

        return (
            <Fragment>
            <ProductConsumer>
            {(value) => {
                
                const {runnerid, runnername, mobilenumber, alleventrunners, tabletype, alleventtable} = value;
                const {handleUserInput, handleRunnerAttendance, getSingleRunnerDetail, handleSelectDeselectTable} = value;

                return (
                    <div className="view-cart-wrapper">
                        <div className="local-cart-container selecttable engagedrunner stockmanagement">
                            <div className="ordermessage runnermsg">Runner Attendance</div>
                            <br />
                            <form className="form-horizontal" id="form" name="form" onSubmit={handleRunnerAttendance}>
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="runnerid">Batch No. / Runner ID<span style={{color:'#ff0000'}}>*</span></label>
                                    <div className="col-sm-10 fullwidth fullwidth">
                                        <input type="number" className={`form-control`} name="runnerid" id="batchrunnerid" placeholder="Runner ID" value={runnerid} onChange={handleUserInput}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="runnername">Runner Name<span style={{color:'#ff0000'}}>*</span></label>
                                    <div className="col-sm-10 fullwidth fullwidth">
                                        <input type="text" className={`form-control`} name="runnername" id="runnername" placeholder="Runner Name" value={runnername} onChange={handleUserInput}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="mobilenumber">Mobile Number</label>
                                    <div className="col-sm-10 fullwidth fullwidth">
                                        <input type="number" className={`form-control`} name="mobilenumber" id="mobilenumber" placeholder="Mobile Number" value={mobilenumber} onChange={handleUserInput} maxLength="10"/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="tabletype0">Table Type</label>
                                    <div className="col-sm-10 fullwidth fullwidth">
                                        <FormGroup style={{float:'left'}} check>
                                            <Label check htmlFor={`tabletype0`}>
                                                <Input type="radio" name="tabletype" id="tabletype0" value="0" checked={Number(tabletype) === 0} onChange={handleUserInput}/>&nbsp;All Table
                                            </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Label check htmlFor={`tabletype1`}>
                                                <Input type="radio" name="tabletype" id="tabletype1" value="1" checked={Number(tabletype) === 1} onChange={handleUserInput}/>&nbsp;Selected Table
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </div>

                            {
                                Number(tabletype) === 1 ? (
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="mobilenumber">Available Tables</label>
                                    <div className="col-sm-10 fullwidth fullwidth">
                                    {
                                        alleventtable ? (
                                            <FormGroup check>
                                            {
                                                alleventtable.map((table, loop)=>{
                                                    return (
                                                        <div key={loop} style={{
                                                            width:'24%',
                                                            float:'left',
                                                            marginBottom:'5px',
                                                            marginLeft:'5px',
                                                            textAlign:'left'
                                                        }}>
                                                        <Label check htmlFor={`selectedtable${table.id}`}>
                                                            <Input type="checkbox" name="selectedtable[]" id={`selectedtable${table.id}`} value="1" checked={Number(table.isselected) === 1} onChange={()=>{
                                                                handleSelectDeselectTable(table.id)
                                                            }}/>&nbsp;{table.title}
                                                        </Label>
                                                        </div>
                                                    )
                                                })
                                            }
                                            </FormGroup>
                                        ):null
                                    }
                                    </div>
                                </div>
                                ):null
                            }
                                <div className="form-group" style={{clear:'both'}}>
                                    <div className="col-sm-offset-2 col-sm-10 fullwidth">
                                        <button type="submit" className="btn btn-default savebutton menuitem">Save Now</button>
                                    </div>
                                </div>
                            </form>
                            {
                                alleventrunners ? (
                                    <div className="col-sm-offset-2 col-sm-10 fullwidth" style={{textAlign:'left'}}>
                                    <b>Active Runners:</b><br />
                                    {
                                        alleventrunners.map((runner)=>{
                                            return (
                                                <div key={runner.index} className="btn btn-outline-primary" style={{marginRight:'5px',marginBottom:'5px'}} onClick={()=>{
                                                    getSingleRunnerDetail(runner.runnerid);
                                                }}>{runner.runnerid}</div>
                                            )
                                        })
                                    }
                                    </div>
                                ):null
                            }
                            <div className="quickmanagestockbox" onClick={this.goToManageMenu}><i className="fa fa-indent" aria-hidden="true"></i></div>
                        </div>
                    </div>
                );
            }}
            </ProductConsumer>
            <Captainfooterbar />
            </Fragment>
        );
	}
}