import React,{Component,Fragment} from 'react';
import {withRouter} from "react-router-dom";
import Captainmenu from './Captainmenu';

class Captainfooterbar extends Component{

    constructor(props)
    {
        super(props);
        this.state = { showPopup: false };
    }

    togglePopup() {
        this.setState({  
            showPopup: !this.state.showPopup,
            isActive: !this.state.isActive
        });
    }

    goToStore=()=>{
        this.props.history.push("/menu");
    }

	render(){
		return (
          <Fragment>
            <Captainmenu closePopup={this.togglePopup.bind(this)} isActive={this.state.isActive}/>
            <div className={`footer-caption-action-bar`}>
                <button className="MuiButtonBase-root MuiBottomNavigationAction-root Mui-selected" type="button">
                    {/*<span className="MuiBottomNavigationAction-wrapper">
                        <i className="MuiSvgIcon-root fa fa-user-times" aria-hidden="true"></i>
                        <span className="MuiBottomNavigationAction-label Mui-selected">Menu 1</span>
                    </span>*/}
                </button>
                <button className="MuiButtonBase-root MuiBottomNavigationAction-root" type="button" onClick={this.goToStore}>
                    <span className="MuiBottomNavigationAction-wrapper">
                        <i className="MuiSvgIcon-root fa fa-shopping-cart" style={{
                            top:'0',
                            left:'0',
                            position:'unset'
                        }} aria-hidden="true"></i>
                    </span>
                </button>
                <button className="MuiButtonBase-root MuiBottomNavigationAction-root" type="button" onClick={this.togglePopup.bind(this)}>
                    <span className="MuiBottomNavigationAction-wrapper">
                        <i className="MuiSvgIcon-root fa fa-bars" aria-hidden="true"></i>
                    </span>
                </button>
            </div>
		  </Fragment>
		);
	}
}
export default withRouter(Captainfooterbar);