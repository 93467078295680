import React,{ Component, Fragment } from 'react';
import { ProductConsumer } from '../Context/Product';

class OrderStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    render(){

        return (
            <ProductConsumer>
            {(value) => {
                
                const{notdelivereditemname, notdeliveredhindiitemname, notdelivereditemqty,notdelivereddetailid, notdeliveredproduct_id, notdeliveredhasoption, notdelivereditemoption, isactivenotdelivereditem, miscdetail, isitemmarkdeliveredbyguest, iscaptionlogin, isstewardlogin} = value;

                const{ viewNotDeliveredItemDetail, handleSaveNotDeliveredItem } = value;

                return (
                    <div className="hOPtIO">
                        <div className="fgjele modalWrapper" aria-hidden="false" role="dialog" style={{height:miscdetail.servicestatus === 1 && !isitemmarkdeliveredbyguest && !iscaptionlogin && !isstewardlogin ? `90%`:`100%`}}>
                            <div tabIndex="-1" className="hcdmGE" onClick={()=>{
                                viewNotDeliveredItemDetail(notdelivereddetailid,notdelivereditemname,notdeliveredhindiitemname,notdelivereditemqty,notdelivereddetailid,notdeliveredproduct_id);
                            }}></div>
                            <div className="iBlUrl"></div>
                            <div mode="light" type="medium" tabIndex="0" className="gzwZLJ">
                                <div style={{display:'flex',justifyContent:'center',position:'relative'}}>
                                    <div className="dfUaKd" style={{
                                        position:'absolute',
                                        bottom:isactivenotdelivereditem ?'4rem':'0px',
                                    }}>
                                        <div className="close-button" onClick={()=>{
                                            viewNotDeliveredItemDetail(notdelivereddetailid,notdelivereditemname,notdeliveredhindiitemname,notdelivereditemqty,notdelivereddetailid,notdeliveredproduct_id);
                                        }}></div>
                                    </div>
                                </div>
                                <section className="eggzWm">
                                    <h2 mode="light" className="cInTtE">Item Not Delivered ?</h2>
                                    <span onClick={()=>{
                                            viewNotDeliveredItemDetail(notdelivereddetailid,notdelivereditemname,notdeliveredhindiitemname,notdelivereditemqty,notdelivereddetailid,notdeliveredproduct_id);
                                        }}><i style={{fontSize:'2.6rem'}} className="fa fa-angle-down" aria-hidden="true"></i></span>
                                </section>
                                <section className="oRYSe" style={{
                                    minHeight:'5rem'
                                }}>
                                    <div className="kCdFWE"></div>
                                    <div className="ItLLI selecttable" style={{
                                        maxHeight:'45rem',
                                        minHeight:'20rem',
                                        overflow:'auto',
                                    }}>
                                        <div className="view-cart-wrapper">
                                            <div className="container orderedlist">
                                                <div className="local-cart-container notdeliveredpopup" style={{
                                                    minHeight:'60vh'
                                                }}>

                                                    <div className="form-group">
                                                        <div className="col-sm-offset-2 bolditem">
                                                            <div className="itemname">
                                                                <div style={{
                                                                    textAlign:"center",
                                                                    display:'inline-table',
                                                                }}>
                                                                {notdelivereditemname}
                                                                {
                                                                    notdeliveredhindiitemname !== "" ? (<Fragment>
                                                                        <br /><span className="itemhindiname">({notdeliveredhindiitemname})</span>
                                                                    </Fragment>):``
                                                                }
                                                                </div>
                                                                                                                                {
                                                                    notdeliveredhasoption ? (<div><span className="optioname" style={{
                                                                        color:'#000000'
                                                                    }}>{
                                                                            notdelivereditemoption.map((optioncat)=>{
                                                                                return( 
                                                                                    <div key={optioncat.categoryid}><span style={{
                                                                                        color:'#000000',
                                                                                        marginLeft:'5px'
                                                                                    }}><span style={{fontWeight:'bold',fontSize:'16px',textDecoration:'underline'}}>{optioncat.name} :</span>
                                                                                    {` `}
                                                                                    {
                                                                                        optioncat.itemoption.map((option)=>{
                                                                                            return(
                                                                                                <span key={option.optionid} className="optioname"
                                                                                                style={{
                                                                                                    fontStyle:'italic',
                                                                                                    color:'#000000',
                                                                                                    fontWeight:'normal',
                                                                                                }}
                                                                                                >
                                                                                                {option.name}
                                                                                                </span>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    </span></div>
                                                                                )
                                                                            })
                                                                        }</span></div>):null
                                                                }

                                                            </div>
                                                            <div className="itemqty center" style={{
                                                                lineHeight:'4rem',
                                                            }}> x <span style={{
                                                                color:'#ff0000',
                                                                fontSize:'2rem'
                                                            }}>{notdelivereditemqty}</span></div>
                                                        </div>
                                                    </div>

                                                    <div className="clearfix"></div>
                                                    <div className="form-group margintop30" style={{
                                                        marginTop:'5rem'
                                                    }}>
                                                        <div style={{marginLeft:'5px'}}>
                                                            <button type="submit" className="btn btn-default savebutton" style={{
                                                                backgroundColor:'#ed5a6b',
                                                                fontSize:'1.8rem'
                                                            }} onClick={()=>{handleSaveNotDeliveredItem(notdelivereddetailid, notdelivereditemqty, notdeliveredproduct_id, false)}}>Raise A Complaint Only</button>
                                                            &nbsp;&nbsp;
                                                            <button type="submit" className="btn btn-default savebutton" style={{
                                                                backgroundColor:'#ed5a6b',
                                                                fontSize:'1.8rem'
                                                            }} onClick={()=>{handleSaveNotDeliveredItem(notdelivereddetailid, notdelivereditemqty, notdeliveredproduct_id, true)}}>Raise A Complaint <br /> & <br /> Place A Fresh Order</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                   
                                    </div>
                                </section>
                            </div>
                            <div tabIndex="0" className="ckiDDQ"></div>
                        </div>
                    </div>
                );
            }}
            </ProductConsumer>
        )
    }
}

export default OrderStatus;