import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { ProductContext } from '../Context/Product';
import logo from '../assets/images/scanlogo.png';
import {CircularProgress} from '@material-ui/core';

class DemoLogin extends Component {

    static contextType = ProductContext;

    constructor (props) {
      super(props);
      this.state = {
        orloid: 1,
        formErrors: {orloid: 1, password: 8031},
        orloidValid: true,
        passwordValid: true,
        formValid: false,
        logintype:'table',
        stewardid:'',
        eventpassword:'',
        donotdownloadimage:false,
        tracktablet:true,
        showitemimages:true,
        showbanners:true,
        democode:'',
        loginid:1,
      }
    }

    componentDidMount(){

      this.setState(()=>{
        return{
          democode:this.props.match.params.democode.toUpperCase()
        }
      },()=>{

        setTimeout(() => {

          this.handleLogin();
  
        }, 1000);

      })

    }

    syncEvent=()=>{

      const{democode} = this.state;

      const bodyFormData = new FormData();
      bodyFormData.set('Mode', 'SyncData');
      bodyFormData.set('eventcode', democode);
      bodyFormData.set('eventpassword', democode);
      bodyFormData.set('democode', democode);
      bodyFormData.set('donotdownloadimage', 1);
      bodyFormData.set('isdemosync', 1);

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}syncdata.php?t=${Date.now()}`,
        data: bodyFormData,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
      })
      .then(response=>{

        if(response.data.success === true)
        {
          /*this.context.toastMessage(response.data.msg, 'success');*/

          this.handleLogin();

        }
        else
        {
          this.context.toastMessage(response.data.msg, 'error');
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    }

    handleLogin=()=>{

      const{loginid, democode, logintype, tracktablet, showitemimages, showbanners} = this.state;
  
      setTimeout(() => {
        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'Login');
        bodyFormData.set('loginid', loginid);
        bodyFormData.set('democode', democode);
        bodyFormData.set('isdemoevent', 1);
        bodyFormData.set('logintype', logintype);
        bodyFormData.set('tracktablet', Number(tracktablet));

        axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}applogin.php?t=${Date.now()}`,
          data: bodyFormData,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
    
          this.setState(()=>{
            return{
              processing:false,
            }
          },()=>{
            if(response.data.success !== true)
            {
              this.syncEvent();
            }
            else
            {
              const{logintype} = this.state;

              localStorage.setItem("isloggedin",true);
              localStorage.setItem("appe_kotedetail",null);
              localStorage.setItem("orlo_event_listed_ads",JSON.stringify([]));
              localStorage.setItem("tabledetail",JSON.stringify([]));
              localStorage.setItem("captiondetail",JSON.stringify([]));
              localStorage.setItem("stewarddetail",JSON.stringify([]));

              let logindetail  = response.data.logindetail;

              let newlogindetail  = {...logindetail, showitemimages:Number(showitemimages), showbanners:Number(showbanners)};

              if(logintype === 'table')
              {
                this.context.clearCart();  

                localStorage.setItem("tabledetail",JSON.stringify(newlogindetail));

                this.context.setGuestUser();

                let menudata	= {menutype:'all', eventmenus:[]};
                localStorage.setItem("democode",democode);
                localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));
  
                this.props.history.push("/menu");
              }
            }
          })
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
      }, 500);
    }

    render() {

      return (
        <div className="row nomargin lightbluebg">
          <div className="col-sm" style={{paddingLeft:'0',paddingRight:'0'}}>
            <div className="loadingwrapper">
              <div style={{paddingLeft:'1rem',paddingRight:'1rem'}}>
                <img src={logo} className="app-logo" alt="logo" />
                <div className="notfoundsubtitle">Please wait logging into event.</div>
                <br/>
                <CircularProgress />
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default withRouter(DemoLogin);