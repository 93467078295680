import React,{Component, Fragment} from 'react';
import RunnerItemPopup from './RunnerItemPopup';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
import closepopup from '../assets/images/closepopup.png';

class ReportsummaryPopup extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            error : null,
            keywordsearch : '',
            isLoaded : false,
            runnerid : 0,
            type : null,
            allreports : [],
            reports : [],
            runners : [],
            totalreporttoken : '',
            tokenreportitem : '',
            totalreporttimetaken : '',
            averagereporttimetaken : '',
            tokenreportorder : '',
            totalcomplaintcount : '',
            reporttype:this.props.reporttype,
            printreporttype:'',
            tokenreportitemqty : '',
            isActiveItem: false
        };

        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event){
        if(event.keyCode === 27) {
            //Do whatever when esc is pressed
            this.props.closeReportPopup();
        }
    }

    componentDidMount(){
        this.getReportSummary();
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleSearch = (e) => {
        const name      = e.target.name;
        const value     = e.target.value;
        let tempreports = [];

        this.setState({[name]: value},()=>{

            if(this.state.allreports.length > 0)
            {
                this.state.allreports.forEach(report => {
                    const singleReport	= {...report};
    
                    const id      = singleReport.id;
                    const name    = singleReport.name;
    
                    if(((name.toLowerCase().indexOf(this.state.keywordsearch.toLowerCase()) !== -1) || (id.toLowerCase().indexOf(this.state.keywordsearch.toLowerCase()) !== -1)))
                    {
                        tempreports = [...tempreports, singleReport];
                    }
                });
    
                this.setState({
                    reports:tempreports
                });
            }
        });
    }

    changeReportType=(reporttype)=>{
        if(reporttype !== this.state.reporttype)
        {
            this.setState({
                isLoaded:false,
                reporttype:reporttype
            },()=>{
                if(this.state.reporttype === 'available' || this.state.reporttype === 'engaged')
                {
                    this.getRunnerSummary();
                }
                else
                {
                    this.getReportSummary();
                }
            });
        }
    }

    getReportSummary=()=>{

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

        const kotid		= kotedetail.kotid;
        const eventid	= kotedetail.eventid;

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetRunnerReport');
        bodyFormData.set('type', this.state.reporttype);
        bodyFormData.set('EventID', Number(eventid));
        bodyFormData.set('kotid', Number(kotid));

        this.setState({keywordsearch:''});

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'runner.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
                this.setState({
                    isLoaded:true,
                    allreports:response.data.reports,
                    reports:response.data.reports,
                    totalreporttoken:response.data.totalreporttoken,
                    tokenreportitem:response.data.tokenreportitem,
                    totalcomplaintcount:response.data.totalcomplaintcount,
                    tokenreportorder:response.data.tokenreportorder,
                    totalreporttimetaken:response.data.totalreporttimetaken,
                    averagereporttimetaken:response.data.averagereporttimetaken,
                    tokenreportitemqty:response.data.tokenreportitemqty,
                    runners:null,
                });
            }
            else
            {
                this.setState({
                    isLoaded:true,
                    allreports:[],
                    reports:null,
                    totalreporttoken:null,
                    tokenreportitem:null,
                    totalcomplaintcount:null,
                    tokenreportorder:null,
                    totalreporttimetaken:null,
                    averagereporttimetaken:null,
                    tokenreportitemqty:null,
                    runners:null,
                });
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    handleChange=(e)=>{
        /*const name      = e.target.name;
        const value     = e.target.value;*/
        const orloid    = e.target.getAttribute('orloid');
  
        let tempReport    = [...this.state.reports];
        const tempReport2 = tempReport.find(report => Number(report.id) === Number(orloid));
  
        tempReport2.canonlineorder	= !tempReport2.canonlineorder;
  
        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'UpdateCanOnlineOrder');
        bodyFormData.set('eventid', localStorage.getItem('koteventid'));
        bodyFormData.set('kotid', localStorage.getItem('kotloginid'));
        bodyFormData.set('orloid', tempReport2.id);
        bodyFormData.set('canonlineorder', tempReport2.canonlineorder);
  
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'seatmanagement.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
              tempReport2.isupdated	= 'isupdated';
  
              this.setState(
                ()=>{
                  return{
                    reports:[...tempReport]
                  };
                },
                ()=>{
                  setTimeout(() => {
                    tempReport2.isupdated	= 'ideal';
                    return{
                      reports:[...tempReport]
                    };
                  }, 300);
                }
              );
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });  
    }

    handleChangeMarkAsvip=(e)=>{
        /*const name      = e.target.name;
        const value     = e.target.value;*/
        const orloid    = e.target.getAttribute('orloid');
  
        let tempReport    = [...this.state.reports];
        const tempReport2 = tempReport.find(report => Number(report.id) === Number(orloid));
  
        tempReport2.isviptable	= !tempReport2.isviptable;
  
        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'UpdateMarkAsVipTable');
        bodyFormData.set('eventid', localStorage.getItem('koteventid'));
        bodyFormData.set('kotid', localStorage.getItem('kotloginid'));
        bodyFormData.set('orloid', tempReport2.id);
        bodyFormData.set('isviptable', tempReport2.isviptable);
  
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'seatmanagement.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
              tempReport2.isupdated	= 'isupdated';
  
              this.setState(
                ()=>{
                  return{
                    reports:[...tempReport]
                  };
                },
                ()=>{
                  setTimeout(() => {
                    tempReport2.isupdated	= 'ideal';
                    return{
                      reports:[...tempReport]
                    };
                  }, 300);
                }
              );
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });  
    }

    printReportSummary=()=>{

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'PrintReportSummary');
        bodyFormData.set('type', this.state.printreporttype);
        bodyFormData.set('EventID', localStorage.getItem('koteventid'));
        bodyFormData.set('kotid', localStorage.getItem('kotloginid'));

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'report.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
            }
            else
            {
				toast.error(`Unable to print reprt`, {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    changePrintReportType=(reporttype)=>{
        if(reporttype !== this.state.printreporttype)
        {
            this.setState({
                printreporttype:reporttype
            },()=>{
                this.setState({printreporttype:''});
                this.printReportSummary();
            });
        }
    }

    showHideBreakLog = (runnerid) => {

        let tempreports = [];

        if(this.state.allreports.length > 0)
        {
            this.state.allreports.forEach(report => {
                const singleReport	= {...report};

                const id            = singleReport.id;

                if(Number(id) === Number(runnerid))
                {
                    singleReport.showbreaklog   = !singleReport.showbreaklog;
                }
                else
                {
                    singleReport.showbreaklog   = false;
                }
                tempreports = [...tempreports, singleReport];
            });

            this.setState({
                reports:tempreports
            });
        }
    }

    getRunnerSummary=()=>{

		let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetRunnerPopUpSummary');
        bodyFormData.set('type', this.state.reporttype);
        bodyFormData.set('kotid', Number(kotedetail.kotid));
        bodyFormData.set('eventid', Number(kotedetail.eventid));

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'runner.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
                this.setState({
                    isLoaded:true,
                    runners:response.data.runners,
                    reports:null,
                });
            }
            else
            {
              this.setState({
                  isLoaded:true,
                  runners:null,
                  reports:null,
              });
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    updateRunnerStatus=(runnerid, type)=>{

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

        this.setState({
            runnerid:runnerid,
            type:type
        },()=>{

            const bodyFormData = new FormData();
            bodyFormData.set('Mode', 'UpdateRunnerStatus');
            bodyFormData.set('type', this.state.type);
            bodyFormData.set('runnerid', this.state.runnerid);
			bodyFormData.set('kotid', Number(kotedetail.kotid));
			bodyFormData.set('eventid', Number(kotedetail.eventid));

            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+'runner.php',
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{
                
                if(response.data.success === true)
                {
                    this.setState({
                        isLoaded:true,
                        runners:response.data.runners,
                    },()=>{
                        this.getRunnerSummary();
                    });
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });

        });
    }

    handleRunnerChange=(e)=>{

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
  
        const runnerid  = e.target.getAttribute('runnerid');
  
        let tempRunner    = [...this.state.runners];
        const temprunner2 = tempRunner.find(runner => Number(runner.runnerid) === Number(runnerid));
  
        temprunner2.ispriority	= !temprunner2.ispriority;
  
        const bodyFormData = new FormData();
          bodyFormData.set('Mode', 'MarkRunnerAsPriority');
          bodyFormData.set('kotid', Number(kotedetail.kotid));
          bodyFormData.set('eventid', Number(kotedetail.eventid));
          bodyFormData.set('runnerid', temprunner2.runnerid);
          bodyFormData.set('ispriority', temprunner2.ispriority);
  
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'runner.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
              temprunner2.isupdated	= 'isupdated';
  
              this.setState(
                ()=>{
                  return{
                    runners:[...tempRunner]
                  };
                },
                ()=>{
                  setTimeout(() => {
                    temprunner2.isupdated	= 'ideal';
                    return{
                      runners:[...tempRunner]
                    };
                  }, 300);
                }
              );
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
  
    }

    showHideSuspendLog = (runnerid) => {

        let temprunners = [];

        if(this.state.runners.length > 0)
        {
            this.state.runners.forEach(runner => {
                const singlerunner	= {...runner};

                const id            = singlerunner.runnerid;

                if(Number(id) === Number(runnerid))
                {
                    singlerunner.showsuspendlog	= !singlerunner.showsuspendlog;
                }
                else
                {
                    singlerunner.showsuspendlog	= false;
                }
                temprunners	= [...temprunners, singlerunner];
            });

            this.setState({
                runners:temprunners
            });
        }
    }

    toggleActiveItemPopup() {
        this.setState({
          isActiveItem: !this.state.isActiveItem
        });
    }

    render() {
        const { isLoaded, reporttype, reports, totalreporttoken, totalcomplaintcount, tokenreportorder, runners, tokenreportitemqty} = this.state;

        let dashboardclass          = "";
        let runnerclass             = "";
        let itemclass               = "";
        let availablerunnerclass    = "";
        let engagedrunnerclass      = "";

        if(reporttype === 'dashboardsummary')
        {
            dashboardclass   = " btn-selected";
        }
        else if(reporttype === 'runner')
        {
            runnerclass      = " btn-selected";
        }
        else if(reporttype === 'item')
        {
            itemclass        = " btn-selected";
        }
        else if(reporttype === 'engaged')
        {
			engagedrunnerclass   = " btn-selected";
        }
        else if(reporttype === 'available')
        {
			availablerunnerclass = " btn-selected";
        }

        return (
            <div>
            {
                this.props.isActiveReport ?(
                    <div className="catalog__search search-modal">
                        <div className="search-modal__container">
                            <div className="search-modal__header">
                                Report Summary<span className="closepopup" onClick={this.props.closeReportPopup}><img src={closepopup} alt="close" /></span>
                            </div>
                            <br />
                            <div className="search-modal__body">
                                <div className="table-responsive catalog__search__results">
                                    {isLoaded?(<div className="reporttable">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="row ml-0 mr-0">
                                                            <div className="col-sm-12">
                                                                <button className={`btn btn-custom-bold${dashboardclass}`} onClick={()=>{this.changeReportType('dashboardsummary')}}>Report Dashboard</button>&nbsp;
                                                                <button className={`btn btn-custom-bold${runnerclass}`} onClick={()=>{this.changeReportType('runner')}}>Runner Summary</button>&nbsp;
                                                                <button className={`btn btn-custom-bold${itemclass}`} onClick={()=>{this.changeReportType('item')}}>Item Summary</button>&nbsp;
                                                                <button className={`btn btn-custom-bold${availablerunnerclass}`} onClick={()=>{this.changeReportType('available')}}>Available Runners</button>&nbsp;
                                                                <button className={`btn btn-custom-bold${engagedrunnerclass}`} onClick={()=>{this.changeReportType('engaged')}}>Engaged Runners</button>&nbsp;
                                                            </div>
                                                        </div>
                                                        <div className="separator mt-0"></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="mt10">
                                            {
                                                reports || runners ? (<div>
                                                    <table className="table table-bordered">
                                                        <thead className="thead-custom">
                                                        {(() => {
                                                            if(reporttype === 'runner') {
                                                              return (
                                                                <Fragment>
                                                                <tr>
                                                                    <th scope="col">S. No</th>
                                                                    <th scope="col">Runners</th>
                                                                    <th scope="col">Tokens</th>
                                                                    <th scope="col">Items</th>
                                                                    <th scope="col">Complaint</th>
                                                                    <th scope="col">Break Time</th>
                                                                </tr>
                                                                </Fragment>
                                                              )
                                                            } else if(reporttype === 'item') {
                                                              return (
                                                                <tr>
                                                                    <th scope="col">S. No</th>
                                                                    <th scope="col">Item</th>
                                                                    <th scope="col">Total Order</th>
                                                                </tr>
                                                              )
                                                            } else if(reporttype === 'dashboardsummary') {
                                                                return (
                                                                  <tr>
                                                                      <th scope="col">S. No</th>
                                                                      <th scope="col">Summary</th>
                                                                      <th scope="col">Total Count</th>
                                                                  </tr>
                                                                )
                                                            } else if(reporttype === 'engaged') {
                                                                return (
                                                                    <tr>
                                                                        <th scope="col">S. No</th>
                                                                        <th scope="col">Runners</th>
                                                                        <th scope="col"></th>
                                                                        <th scope="col"></th>
                                                                        <th scope="col">Suspend Time</th>
                                                                    </tr>
                                                                )
                                                            } else if(reporttype === 'available') {
                                                                return (
                                                                    <tr>
                                                                        <th scope="col">S. No</th>
                                                                        <th scope="col">Runners</th>
                                                                        <th scope="col"></th>
                                                                        <th scope="col">Suspend Time</th>
                                                                        <th scope="col">Token</th>
                                                                    </tr>
                                                                )
                                                            } else {
                                                                return (
                                                                <tr>
                                                                    <th scope="col">S. No</th>
                                                                    <th scope="col">Orlo ID</th>
                                                                    <th scope="col">Seat</th>
                                                                    <th scope="col">Total Order</th>
                                                                    <th scope="col">Total Token</th>
                                                                    <th scope="col">Total Item</th>
                                                                    <th scope="col">Can Online Order ?</th>
                                                                    <th scope="col">Mark as VIP Table</th>
                                                                </tr>
                                                                )
                                                              }
                                                        })()}
                                                        </thead>
                                                        {(() => {
                                                            if(reporttype === 'runner') {
                                                                return (
                                                                    <tbody>
                                                                    {
                                                                        reports.map((report)=>{
                                                                            return (
                                                                                <Fragment key={report.id}>
                                                                                <tr>
                                                                                    <th scope="row">{report.index}</th>
                                                                                    <td>{report.name}</td>
                                                                                    <td>{report.totaltokens}</td>
                                                                                    <td>{report.tokenitemsqty}</td>
                                                                                    <td>{report.complaintcount}</td>
                                                                                    {
                                                                                        report.hasbreaklog ? (
                                                                                            <td>{report.breaktimecount} ({report.breaktime})<br />
                                                                                            <button className="linkstyle" onClick={()=>{
                                                                                                this.showHideBreakLog(report.id);
                                                                                            }}>View Log</button>
                                                                                            </td>
                                                                                        ):(
                                                                                            <td>---</td>
                                                                                        )
                                                                                    }
                                                                                </tr>
                                                                                {
                                                                                    report.showbreaklog && report.hasbreaklog ? (
                                                                                        <tr>
                                                                                            <td colSpan={6}>
                                                                                                <table className="table table-bordered">
                                                                                                    <thead className="thead-custom">
                                                                                                        <tr>
                                                                                                            <td colSpan="5"><div style={{textAlign:'center'}}><b>Break Time Log</b></div></td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <th scope="col">S. No</th>
                                                                                                            <th scope="col">Start Time</th>
                                                                                                            <th scope="col">End Time</th>
                                                                                                            <th scope="col">Time Taken</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                    {
                                                                                                        report.breaklog.map((log)=>{
                                                                                                            return (
                                                                                                                <tr key={log.serial}>
                                                                                                                    <th scope="row">{log.serial}</th>
                                                                                                                    <td>{log.starttime}</td>
                                                                                                                    <td>{log.endtime}</td>
                                                                                                                    <td>{log.timetaken}</td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ):null
                                                                                }
                                                                                </Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        <tr>
                                                                            <th scope="row"></th>
                                                                            <td><b>Total</b></td>
                                                                            <td><b>{totalreporttoken}</b></td>
                                                                            <td><b>{tokenreportitemqty}</b></td>
                                                                            <td><b>{totalcomplaintcount}</b></td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>
                                                                    }
                                                                    </tbody>
                                                                  )
                                                            } else if(reporttype === 'item') {
                                                                return (
                                                                    <tbody>
                                                                    {
                                                                        reports.map((report)=>{
                                                                            return (
                                                                                <tr key={report.id}>
                                                                                    <th scope="row">{report.index}</th>
                                                                                    <td>{report.name}</td>
                                                                                    <td>{report.totalorder}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        <tr>
                                                                            <th scope="row"></th>
                                                                            <td><b>Total</b></td>
                                                                            <td><b>{tokenreportorder}</b></td>
                                                                        </tr>
                                                                    }
                                                                    </tbody>
                                                                  )
                                                            } else if(reporttype === 'dashboardsummary') {
                                                                return (
                                                                    <tbody>
                                                                    {
                                                                        reports.map((report)=>{
                                                                            return (
                                                                                <tr key={report.id}>
                                                                                    <th scope="row">{report.index}</th>
                                                                                    <td>{report.name}</td>
                                                                                    <td>{report.totalcount}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                  )
                                                            } else if(reporttype === 'available' || reporttype === 'engaged'){
                                                                return (
                                                                    <tbody>
                                                                    {
                                                                        runners.map((runner)=>{
                                                                            return (
                                                                                <Fragment key={runner.index}>
                                                                                <tr className={`runnerrow ${runner.isupdated}`}>
                                                                                    <th scope="row">{runner.index}</th>
                                                                                    <td data-title="Runners" style={{paddingLeft:'0.50rem',paddingRight:'0.50rem'}}>Runner #{runner.runnerid}</td>
                                                                                    {(() => {
                                                                                        if (reporttype === 'engaged') {
                                                                                          return (
                                                                                            <Fragment>
                                                                                            <td data-title="Status"><div>
                                                                                            <button className={`btn`} style={{padding:'0'}} onClick={()=>{this.updateRunnerStatus(runner.runnerid,'release')}}><span className="release">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Release</span></button> <br />{runner.engagedstatus} <br /> ({runner.itemquantity} Items) - <button className={`btn`} style={{paddingLeft:'0'}}  onClick={()=>{this.setState({activerunnerid:runner.runnerid},this.toggleActiveItemPopup.bind(this))}}><span className="viewitem">&nbsp;View</span></button>
                                                                                            </div></td>
                                                                                            <td style={{paddingLeft:'0.50rem',paddingRight:'0.50rem'}}>
                                                                                              <button className={`btn`} onClick={()=>{this.updateRunnerStatus(runner.runnerid,'suspend')}}>
                                                                                                  {(() => {
                                                                                                      if (runner.issuspended === true) {
                                                                                                        return (
                                                                                                          <span className="present">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extend</span>
                                                                                                        )
                                                                                                      } else {
                                                                                                        return (
                                                                                                          <span className="absent">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Suspend</span>
                                                                                                        )
                                                                                                      }
                                                                                                  })()}
                                                                                              </button>
                                                                                            </td>
                                                                                            {
                                                                                              runner.hassuspendlog ? (
                                                                                            <td>{runner.suspendcount} ({runner.suspendedtime})<br />
                                                                                            <button className="linkstyle" onClick={()=>{this.showHideSuspendLog(runner.runnerid);}}>View Log</button>
                                                                                            </td>
                                                                                              ):(
                                                                                            <td>---</td>
                                                                                              )
                                                                                            }
                                                                                            </Fragment>
                                                                                          )
                                                                                        } else if (reporttype === 'available') {
                                                                                          return (
                                                                                            <Fragment>
                                                                                                <td style={{paddingLeft:'0.50rem',paddingRight:'0.50rem'}}>
                                                                                                  <button className={`btn`} onClick={()=>{this.updateRunnerStatus(runner.runnerid,'suspend')}}>
                                                                                                      {(() => {
                                                                                                          if (runner.issuspended === true) {
                                                                                                            return (
                                                                                                              <span className="present">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extend</span>
                                                                                                            )
                                                                                                          } else {
                                                                                                            return (
                                                                                                              <span className="absent">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Suspend</span>
                                                                                                            )
                                                                                                          }
                                                                                                      })()}
                                                                                                  </button>
                                                                                                </td>
                                                                                                {
                                                                                                  runner.hassuspendlog ? (
                                                                                                <td>{runner.suspendcount} ({runner.suspendedtime})<br />
                                                                                                <button className="linkstyle" onClick={()=>{this.showHideSuspendLog(runner.runnerid);}}>View Log</button>
                                                                                                </td>
                                                                                                  ):(
                                                                                                <td>---</td>
                                                                                                  )
                                                                                                }
                                                                                                <td data-title="Token">{runner.totaltoken}</td>
                                                                                            </Fragment>
                                                                                          )
                                                                                        }
                                                                                      })()}
                                                                                </tr>
                                                                                {
                                                                                    runner.showsuspendlog && runner.hassuspendlog ? (
                                                                                        <Fragment>
                                                                                        {(()=>{
                                                                                          if (reporttype === 'engaged') {
                                                                                            return(
                                                                                                <tr>
                                                                                                    <td colSpan={6}>
                                                                                                        <table className="table table-bordered">
                                                                                                            <thead className="thead-custom">
                                                                                                                <tr>
                                                                                                                    <td colSpan="5"><div style={{textAlign:'center'}}><b>Suspend Time Log for <span style={{color:'#ff0000'}}>Runner #{runner.runnerid}</span></b></div></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th scope="col">S. No</th>
                                                                                                                    <th scope="col">Start Time</th>
                                                                                                                    <th scope="col">End Time</th>
                                                                                                                    <th scope="col">Time Taken</th>
                                                                                                                    <th scope="col">Reason</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                            {
                                                                                                                runner.suspendlog.map((log)=>{
                                                                                                                    return (
                                                                                                                        <tr key={log.serial}>
                                                                                                                            <th scope="row">{log.serial}</th>
                                                                                                                            <td>{log.starttime}</td>
                                                                                                                            <td>{log.endtime}</td>
                                                                                                                            <td>{log.timetaken}</td>
                                                                                                                            <td>{log.naration}</td>
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                          }
                                                                                          else if (reporttype === 'available'){
                                                                                            return(
                                                                                                <tr>
                                                                                                    <td colSpan={8}>
                                                                                                        <table className="table table-bordered">
                                                                                                            <thead className="thead-custom">
                                                                                                                <tr>
                                                                                                                    <td colSpan="5"><div style={{textAlign:'center'}}><b>Suspend Time Log for <span style={{color:'#ff0000'}}>Runner #{runner.runnerid}</span></b></div></td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th scope="col">S. No</th>
                                                                                                                    <th scope="col">Start Time</th>
                                                                                                                    <th scope="col">End Time</th>
                                                                                                                    <th scope="col">Time Taken</th>
                                                                                                                    <th scope="col">Reason</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                            {
                                                                                                                runner.suspendlog.map((log)=>{
                                                                                                                    return (
                                                                                                                        <tr key={log.serial}>
                                                                                                                            <th scope="row">{log.serial}</th>
                                                                                                                            <td>{log.starttime}</td>
                                                                                                                            <td>{log.endtime}</td>
                                                                                                                            <td>{log.timetaken}</td>
                                                                                                                            <td>{log.naration}</td>
                                                                                                                        </tr>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                          }
                                                                                        })()}
                                                                                        </Fragment>
                                                                                    ):null
                                                                                }
                                                                                </Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                )
                                                            } else {
                                                                return (
                                                                    <tbody>
                                                                    {
                                                                        reports.map((report)=>{
                                                                            return (
                                                                                <tr key={report.id} className={`reportrow ${report.isupdated}`}>
                                                                                    <th scope="row">{report.index}</th>
                                                                                    <td>{report.id}</td>
                                                                                    <td>{report.name}</td>
                                                                                    <td>{report.totalorder}</td>
                                                                                    <td>{report.totaltoken}</td>
                                                                                    <td>{report.totalitem}</td>
                                                                                    <td><input type="checkbox" name={`canonlineorder${report.id}`} id={`canonlineorder${report.id}`} value="1" checked={report.canonlineorder} orloid={`${report.id}`} onChange={this.handleChange}/><label htmlFor={`canonlineorder${report.id}`}> Yes</label></td>
                                                                                    <td><input type="checkbox" name={`isviptable${report.id}`} id={`isviptable${report.id}`} value="1" checked={report.isviptable} orloid={`${report.id}`} onChange={this.handleChangeMarkAsvip}/><label htmlFor={`isviptable${report.id}`}> Yes</label></td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                  )
                                                              }
                                                        })()}
                                                    </table>
                                                </div>):(<div className="errormsg"><br /><br /><br /><br />No record found<br /><br /></div>)
                                            }
                                        </div>
                                    </div>):(<div>Loading ...</div>)}
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>                    
                ):null
            }
            {
              this.state.isActiveItem?(<RunnerItemPopup closeActiveItemPopup={this.toggleActiveItemPopup.bind(this)} isActiveItem={this.state.isActiveItem} activerunnerid={this.state.activerunnerid}/>):null
            }
            </div>
        );
    }
}

export default withRouter(ReportsummaryPopup);