import React, { Component, Fragment } from 'react';
import {Link} from "react-router-dom";
import Loggedinmenu from './Loggedinmenu';

class Captainmenu extends Component {

    constructor(props) {
        super(props);
        this.initialState = {
          openmobilemenu: "",
        };
        this.state = this.initialState;
    }

    render() {

        let className = '';
        if (this.props.isActive) {
          className += ' opened';
        }

        return (
            <Fragment>
                <div className={`mobile-menu-wrapper${className}`}>
                    <div className="menu-overlay menu-closer waves-effect waves-light" onClick={this.props.closePopup}></div>
                    <div className="mobile-menu">
                        <ul>
                            <Loggedinmenu />
                            <li className="clearfix">
                                <Link to="/runnerattendance"><div className="categorytitle_old">Runner Attendance</div></Link>
                            </li>
                            <li className="clearfix">
                                <Link to="/managerunner"><div className="categorytitle_old">Manage Runner</div></Link>
                            </li>
                            <li className="clearfix">
                                <Link to="/releaserunner"><div className="categorytitle_old">Release Runner</div></Link>
                            </li>
                            {/*<li className="clearfix">
                                <Link to="/managecategory"><div className="categorytitle_old">Manage Category</div></Link>
                            </li>*/}
                            <li className="clearfix">
                                <Link to="/managemenu"><div className="categorytitle_old">Manage Menu</div></Link>
                            </li>
                            <li className="clearfix">
                                <Link to="/addmenu"><div className="categorytitle_old"><i className="fa fa-plus" aria-hidden="true"></i> Quick Add Items</div></Link>
                            </li>
                            <li className="clearfix">
                                <Link to="/managebanner"><div className="categorytitle_old">Manage Banner</div></Link>
                            </li>
                            <li className="clearfix">
                                <Link to="/addbanner"><div className="categorytitle_old"><i className="fa fa-plus" aria-hidden="true"></i> Add Banner</div></Link>
                            </li>
                            <li className="clearfix">
                                <Link to="/kotelogin"><div className="categorytitle_old">Kote Lite</div></Link>
                            </li>
							{/*<li className="clearfix">
                                <Link to="/syncdata"><div className="categorytitle_old">Sync Data</div></Link>
                            </li>
                            <li className="clearfix">
                                <Link to="/flushdata"><div className="categorytitle_old">Flush Data</div></Link>
                            </li>*/}
                        </ul>
                    </div>
                </div>            
            </Fragment>
        );
    }
}

export default Captainmenu;