import React,{Component,Fragment} from 'react';
/*import { toast } from 'react-toastify';*/
import { withRouter } from 'react-router-dom';
import { ProductContext, ProductConsumer } from '../Context/Product';
import logo from '../assets/images/scanlogo.png';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";

const useStyles = (theme) => ({
	backdrop: {
	  zIndex: theme.zIndex.drawer + 1,
	  color: '#fff',
	},
});

class EventMenu extends Component {

    static contextType = ProductContext;

    constructor(props) {
        super(props);

        this.state = {
			isprocessing:false,
			tabledetail:{title:''},
        }
    }

    componentDidMount(){

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
                let menudata	= {menutype:'all', eventmenus:[]};
                localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));
				this.props.history.push("/menu");
				return;
			}
		}

		if(tabledetail)
		{
			/*this.context.setEventTables();
			this.context.setProducts();*/
			this.context.setEventAssigningMenu();

			this.setState(()=>{
				return{
					tabledetail:tabledetail
				}
			},()=>{
				let menudetail    = JSON.parse(localStorage.getItem('orlo_menudetail'));

				if(menudetail !== null && menudetail !== undefined)
				{
				  if((menudetail.menutype === 'all') || (menudetail.menutype === 'selected' && Object.keys(menudetail.eventmenus.filter(menu => menu.ischecked === true)).length > 0))
				  {
					this.props.history.push("/menu");
					return;
				  }
				  /*this.context.setEventTables();*/
				  this.context.setEventAssigningMenu();
				}
			})
		}
    }

	assignMenuToTable=(menutype, listeventmenus)=>{

		let eventid	= "";
		let tableid	= "";

		let tabledetail		= JSON.parse(localStorage.getItem('tabledetail'));
		
		if(tabledetail)
		{
			eventid	= tabledetail.eventid;
			tableid	= tabledetail.code;
		}

		let menudata	= {menutype:menutype, eventmenus:listeventmenus.filter(menu => menu.ischecked === true)};

		if(menutype === 'selected' && Object.keys(listeventmenus.filter(menu => menu.ischecked === true)).length < 1)
		{
			this.context.toastMessage(`Please select atleast one menu to proceed`,'error');

			return false;
		}

		if(Number(eventid) < 1 || Number(tableid) < 1)
		{
			this.context.toastMessage(`Table detail not found! Please re-login to process`,'error');

			return false;
		}

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'AssignMenuToTable');
		bodyFormData.set('isapparea', Number(true));
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('tableid', tableid);
		bodyFormData.set('menutype', menutype);
		bodyFormData.set('alleventmenu', JSON.stringify(listeventmenus.filter(menu => menu.ischecked === true)));

		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}seatmanagement.php?t=${Date.now()}`,
			data: bodyFormData,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

			if(response.data.success === true)
			{
				this.context.resetMenuSelection();

				localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));
		
				this.props.history.push("/menu");
			}
			else
			{
				this.context.toastMessage(response.data.msg,'error');
				return false;
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
	}

	Logout=()=>{

		if(this.context.iscaptionlogin !== true && this.context.isstewardlogin !== true)
		{
			let promotlogout = prompt("Please enter event code to logout", "");

			if(promotlogout !== null)
			{
				if(Number(promotlogout) !== Number(process.env.REACT_APP_PASSWORD))
				{
					this.context.toastMessage(`Invalid Password.`,'error');
				}
				else
				{
					this.context.resetMenuSelection();
					this.context.setLoggedOutGuestUser();

					localStorage.setItem("guestid",'');
					localStorage.setItem("tabledetail",null);
					localStorage.setItem("captiondetail",null);
					localStorage.setItem("stewarddetail",null);
					localStorage.setItem("isloggedin",false);
					localStorage.setItem("appe_kotedetail",null);
					localStorage.setItem("orlo_menudetail",null);
					localStorage.setItem("isautopilot",false);

					this.props.history.push("/login");
				}
			}
		}
		else
		{
			this.context.setLoggedOutGuestUser();

			localStorage.setItem("guestid",'');
			localStorage.setItem("tabledetail",null);
			localStorage.setItem("captiondetail",null);
			localStorage.setItem("stewarddetail",null);
			localStorage.setItem("isloggedin",false);
			localStorage.setItem("appe_kotedetail",null);
            localStorage.setItem("orlo_menudetail",null);
            localStorage.setItem("isautopilot",false);

			this.props.history.push("/login");
		}
    }

    render() {

		/*const { classes } = this.props;*/
        
        const{/*isprocessing,*/ tabledetail} = this.state;

        return (
			<ProductConsumer>
			{(value) => {
				const{ menutype, /*eventmenus,*/ listeventmenus, selectdeselecttitle } = value;
				const{ handleMenuSelection, handleUserInput, selectDeselectAllMenu } = value;

				return(
                    <div className="view-cart-wrapper">
                        <div className="local-cart-container engagedrunner" style={{height:'100vh',maxHeight:'100vh'}}>
                            <div className="ordermessage runnermsg" style={{textAlign:'center'}}>Assign Menu {tabledetail.title ? `( ${tabledetail.title} )`:``}
							</div>
                            <br />
							<div className="row nomargin">
								<div className="col-sm">
									<div className="loadingwrapper">
										<img src={logo} className="app-logo" alt="logo" />
										<br /><br />
									</div>
								</div>
							</div>
							<div className="form-group">
								<div className="col-sm-10 fullwidth squared lefttext">
									<div className="loginform">
										<div className="wrap-input100 squared" style={{textAlign:'left'}}>
											<input type="radio" name="menutype" id="menutype1" value="all" onChange={handleUserInput} checked={menutype==='all'}/>
											<label htmlFor="menutype1">All Menu</label>
											<input type="radio" name="menutype" id="menutype2" value="selected" onChange={handleUserInput} checked={menutype==='selected'}/>
											<label htmlFor="menutype2">Select Menu</label>
										</div>
									</div>
								</div>
							</div>
							{
								menutype === 'selected' ? (
									<Fragment>
									<div className="form-group">
										<div className="col-sm-10 fullwidth squared lefttext">
											<button type="button" className="btn btn-primary btn-xs" onClick={()=>{selectDeselectAllMenu()}}>{selectdeselecttitle}</button>
											{/*<button className="linkstyle" style={{color:'#007bff',width:'unset',maxWidth:'unset',padding:'unset'}} onClick={()=>{selectDeselectAllMenu(true)}}>Select All</button>
											||
											<button className="linkstyle" style={{color:'#007bff',width:'unset',maxWidth:'unset',padding:'unset'}} onClick={()=>{selectDeselectAllMenu(false)}}>De-Select All</button>*/}
										</div>
									</div>
									<div style={{border:'1px solid #000000',width:'92%',margin:'auto',paddingTop:'5px',marginBottom:'5px',overflow:'auto',height:'200px'}}>
									{listeventmenus.map((menu, menuloop)=>{
										return(
											<div className="form-group" key={menuloop}>
												<div className="col-sm-10 fullwidth squared lefttext">
													<input type="checkbox" name={`eventmenu${menu.menuid}`} id={`eventmenu${menu.menuid}`} value={menu.menuid} checked={menu.ischecked} onChange={()=>handleMenuSelection(menu.menuid)}/><label htmlFor={`eventmenu${menu.menuid}`}> {menu.menuname}</label>
												</div>
											</div>
										)
									})}
									</div>
									</Fragment>
								):null
							}
                                <div className="form-group">
                                    <div className="col-sm-offset-2 col-sm-10 fullwidth">
                                        <button type="button" className="btn btn-default savebutton menuitem" onClick={()=>{this.assignMenuToTable(menutype, listeventmenus)}}>Assign And Proceed</button>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-offset-2 col-sm-10 fullwidth">
                                        <button type="button" className="btn btn-default savebutton menuitem" onClick={()=>{this.Logout()}}><i className="fa fa-power-off" aria-hidden="true"></i> Logout</button>
                                    </div>
                                </div>
                        </div>
                    </div>
				)
			}}
			</ProductConsumer>
        );
    }
}

export default withRouter(withStyles(useStyles, { withTheme: true })(EventMenu));