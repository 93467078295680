import React,{Component,Fragment} from 'react';
import { ProductConsumer, ProductContext } from '../Context/Product';
import Captainfooterbar from '../components/Captainfooterbar';

export default class Managecategory extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            scanresult:'No result'
        };
    }
    componentDidMount(){
        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
        let eventid      = "";

		if(kotedetail)
		{
			if(Number(kotedetail.eventid) > 0)
			{
				eventid = kotedetail.eventid;
			}
			if(eventid < 1 || kotedetail.eventid === undefined || kotedetail.eventid === "")
			{
                Promise.all([localStorage.setItem("appe_koteurl",'/managecategory')])
                .then(() => {
                    this.props.history.push("/kotelogin");
                })
			}
			else
			{
				this.context.GetEventCategory('manage');
			}
		}
		else
		{
            Promise.all([localStorage.setItem("appe_koteurl",'/managecategory')])
            .then(() => {
                this.props.history.push("/kotelogin");
            })
		}
    }

    goToManageMenu=()=>{
        this.props.history.push("/managemenu");
    }

	render(){

        return (
            <Fragment>
            <ProductConsumer>
            {(value) => {
                
                const {alleventcategory,updateFeaturedStatus,updateCategoryStatus,updateCategoryOrderBy,saveCategoryOrderBy} = value;

                return (
                    <div className="view-cart-wrapper">
                        <div className="local-cart-container selecttable engagedrunner stockmanagement">
                            <div className="ordermessage runnermsg">Manage Category</div>
                            {
                                alleventcategory ? (<div className="product-listing-wrapper">
                                    <ul className="products-list">
                                    {
                                        alleventcategory.map((category)=>{
                                            return (
                                                <li key={category.id}>
                                                    <div className="product-item-row categorywrapper">
                                                        <div className="product-item-detail-wrapper">
                                                            <div className="product-title-wrapper clearfix">
                                                                <div className="product-title-english noicon manageitem">{category.title}</div>
                                                            </div>
                                                            <div className="manageitemwrapper">
                                                                <div className="manageitem">
                                                                    <div className="managecategory">
                                                                        <div className="title">Is Featured ?</div>
                                                                        <input type="checkbox" name={`isfeatured_${category.id}`} id={`isfeatured_${category.id}`} checked={category.isfeatured} onChange={()=>updateFeaturedStatus(category.id)}/><label htmlFor={`isfeatured_${category.id}`}>Is Featured</label>
                                                                    </div>
                                                                </div>
                                                                <div className="manageitem">
                                                                    <div className="managecategory">
                                                                        <div className="title">Is Active ?</div>
                                                                        <input type="checkbox" name={`isactive_${category.id}`} id={`isactive_${category.id}`} checked={category.status} onChange={()=>updateCategoryStatus(category.id)}/><label htmlFor={`isactive_${category.id}`}>Is Active</label>
                                                                    </div>
                                                                </div>
                                                                <div className="manageitem">
                                                                    <div className="managecategory">
                                                                        <div className="title">Order By</div>
                                                                        <input type="number" className={`form-control orderby`} name={`orderby_${category.id}`} id={`orderby_${category.id}`} catid={category.id} value={category.orderby} onChange={updateCategoryOrderBy} onBlur={()=>{saveCategoryOrderBy(category.id)}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                                
                                                </li>
                                            )
                                          })
                                    }
                                    </ul>
                                </div>):null
                                /*alleventcategory.map((category)=>{
                                    return (
                                        <option key={category.id} value={category.id}>{category.title}</option>
                                    )
                                  })*/
                            }
                            <div className="quickmanagestockbox" onClick={this.goToManageMenu}><i className="fa fa-indent" aria-hidden="true"></i></div>
                        </div>
                    </div>
                );
            }}
            </ProductConsumer>
            <Captainfooterbar />
            </Fragment>
        );
	}
}