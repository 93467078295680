import React,{Component, Fragment} from 'react';
import axios from "axios";
import {ProductContext} from '../Context/Product';
import Captainfooterbar from '../components/Captainfooterbar';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@material-ui/core';
import { Button, FormGroup, Label, Input, Table } from 'reactstrap';

export default class Managebanner extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);

        this.state = {
			bannerdata:[],
			isloaded:false,
			hasdata:false,
			isconfirmed:false,
			isdialogopen:false,
			confirmmessage:'',
			confirmrecordid:'',
            sliderinterval:15,
            thanksinterval:30,
            isprocessing:false,
        }
    }

    componentDidMount(){

        document.body.classList.remove('orderthanks');

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
        let eventid      = "";

		if(kotedetail)
		{
			if(Number(kotedetail.eventid) > 0)
			{
				eventid = kotedetail.eventid;
			}
			if(eventid < 1 || kotedetail.eventid === undefined || kotedetail.eventid === "")
			{
                Promise.all([localStorage.setItem("appe_koteurl",'/managebanner')])
                .then(() => {
                    this.props.history.push("/kotelogin");
                })
			}
			else
			{
                this.fetchEventBanners();
			}
		}
		else
		{
            Promise.all([localStorage.setItem("appe_koteurl",'/managebanner')])
            .then(() => {
                this.props.history.push("/kotelogin");
            })
		}
    }

	fetchEventBanners = () =>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetAllEventBanner');
		bodyFormData.set('eventid', eventid);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}banners.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
				this.setState(()=>{
					return{
                        bannerdata:response.data.bannerdata,
                        sliderinterval:response.data.sliderinterval,
                        thanksinterval:response.data.thanksinterval,
                        isloaded:true,
                        hasdata:true,
					};
				})
			}
			else
			{
				this.setState(()=>{
					return{
                        bannerdata:[],
                        isloaded:true,
                        hasdata:false,
					};
				})
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }
    
    deletebanner=(id)=>{

        if(!window.confirm("Are you sure? This can not be undone."))
        {
            return false;
        }
        
		let eventid			= "";

		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'DeleteEventBanner');
		bodyFormData.set('eventid', eventid);
		bodyFormData.set('recordid', id);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}banners.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{

			this.setState(()=>{
				return{
					isdialogopen:false,
					isconfirmed:false,
					confirmmessage:'',
					confirmrecordid:'',
				}
			})

            if(response.data.success === true)
            {
                this.fetchEventBanners();
                this.context.setEventBanner();
			}
			else
			{
				this.context.toastMessage(response.data.msg,'error');
			}
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

	toggleConfirm=(recordid)=>{

		this.setState(()=>{
			return{
				isdialogopen:!this.state.isdialogopen,
				confirmmessage:'Are you sure? This can not be undone.',
				confirmrecordid:recordid,
			}
		},()=>{
			if(!this.state.isdialogopen)
			{
				this.setState(()=>{
					return{
						isconfirmed:false,
						confirmrecordid:'',
						confirmmessage:'',
					}
				})
			}
		})
	}

	changeBannerStatus=(id, status)=>{

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'ChangeEventBannerStatus');
        bodyFormData.set('eventid', eventid);
        bodyFormData.set('recordid', id);
        bodyFormData.set('status', status);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}banners.php?t=${Date.now()}`,
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
                this.fetchEventBanners();
                this.context.setEventBanner();
            }
            else
            {
                this.context.toastMessage(response.data.msg,`error`);
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    handleUserInput = (e) =>{
        
		const name	= e.target.name;
		const value	= e.target.value;

        this.setState(()=>{
            return{
                [name]:value
            }
        })
    }

    updateSliderInterval=()=> {

		const {sliderinterval, thanksinterval}	= this.state;

		let eventid			= "";
		
		let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));

		if(captiondetail)
		{
			if(Number(captiondetail.eventid) > 0)
			{
				eventid	= captiondetail.eventid;
			}
		}

		if(eventid < 1)
		{
			return false;
		}

		let message = "";

		if((sliderinterval === "" || sliderinterval === undefined || sliderinterval === null || Number(sliderinterval) < 1) && message === "")
		{
			message = "Please enter banner rotation time";
		}

		if((thanksinterval === "" || thanksinterval === undefined || thanksinterval === null || Number(thanksinterval) < 1) && message === "")
		{
			message = "Please enter thanks page time";
		}
			
        if(message !== "")
        {
			this.context.toastMessage(`${message}`,`error`);
        }

        if(message === "")
        {
            this.setState({
                isprocessing:true
            },()=>{
                setTimeout(()=>{
					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'UpdateSliderIntervalTime');
					bodyFormData.set('eventid', eventid);
					bodyFormData.set('sliderinterval', Number(sliderinterval));
					bodyFormData.set('thanksinterval', Number(thanksinterval));

                    axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}banners.php?t=${Date.now()}`,
                        data: bodyFormData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(response=>{

                        this.setState({
                            isprocessing:false
                        });

						let restype = 'error';

                        if(response.data.success === true)
                        {
							restype	= 'success';
                        }
						this.context.toastMessage(response.data.msg,`${restype}`);
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
                },1500);
            })
        }
	}

	render(){

        const{bannerdata, isloaded, hasdata, isdialogopen, confirmmessage, confirmrecordid, sliderinterval, thanksinterval, isprocessing} = this.state;

        return (
            <Fragment>
            <div className="view-cart-wrapper">
                <div className="local-cart-container selecttable1 engagedrunner stockmanagement" style={{marginBottom:'unset',height:'95vh',maxHeight:'95vh'}}>
                    <div className="ordermessage runnermsg" style={{textAlign:'center',fontSize:'1.3rem',fontWeight:'bold',position:'fixed',width:'100%',zIndex:'1'}}>Manage Banner</div>
                    <br /><br />
                    {
                        !isloaded ? (
                            <div>Banner is loading...</div>
                        ):(
                            <div style={{margin:'auto'}}>{
                                hasdata ? (
                                    <Fragment>
                                        <table alignitems="center" border="0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div style={{fontWeight:'bold'}}>Banner Rotation Time* (in second)</div>
                                                    </td>
                                                    <td>
                                                        <div style={{fontWeight:'bold'}}>Thanks Page Time* (in second)</div>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <FormGroup style={{display:'unset'}}>
                                                            <Input type="number" name="sliderinterval" id="sliderinterval" placeholder="Banner Rotation Time" value={sliderinterval} onChange={this.handleUserInput} style={{width:'100px',display:'unset'}}/>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <FormGroup style={{display:'unset'}}>
                                                            <Input type="number" name="thanksinterval" id="thanksinterval" placeholder="Thanks Page Time" value={thanksinterval} onChange={this.handleUserInput} style={{width:'100px',display:'unset'}}/>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <FormGroup>
                                                            <Button type="button" color="primary" disabled={isprocessing} onClick={()=>this.updateSliderInterval()}>Update Now</Button>{' '}{isprocessing ?<span style={{fontSize:'2rem',color:'#007bff'}}><i className={`fa fa-refresh fa-spin`} aria-hidden="true"></i></span>:null}
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th style={{minWidth:'120px'}}>Banner Area</th>
                                                    <th>Type</th>
                                                    <th style={{minWidth:'130px',textAlign:'center'}}>Floating Banner</th>
                                                    <th style={{textAlign:'center'}}>Banner / Video</th>
                                                    <th style={{width:'15%'}}>Status</th>
                                                    <th style={{minWidth:'175px'}}>Options</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                bannerdata.map((banner, bannerloop)=>{
                                                    return(
                                                        <tr key={bannerloop} style={{textAlign:'center'}}>
                                                            <th scope="row">{bannerloop+1}</th>
                                                            <td>{banner.areatype}</td>
                                                            <td>{banner.bannertypetext}</td>
                                                            <td>
                                                            {
                                                                Number(banner.bannerarea) === 1 && banner.smallbannerpreview !== "---" ? (<img src={banner.smallbannerpreview} alt={`small banner #${banner.id}`} style={{width:'120px'}}/>):`${banner.smallbannerpreview}`
                                                            }
                                                            </td>
                                                            <td>
                                                            {
                                                                banner.bannertype === 1 ? (<img src={banner.bannerimage} alt={`banner #${banner.id}`} style={{width:'150px'}}/>):(<Button type="button" color="primary" onClick={()=>{
                                                                    window.open(banner.videourl,"eventeditbanner","width=550px,height=600px, scrollbars=0, resizable=0, top=0, left=0");
                                                                }}>View</Button>)
                                                            }
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="radio" name={`status_${banner.id}`} id={`status_${banner.id}_1`} value="1" checked={Number(banner.status) === 1} onChange={()=>{
                                                                            this.changeBannerStatus(banner.id, 1)
                                                                        }}/>
                                                                        Active
                                                                    </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <Label check>
                                                                        <Input type="radio" name={`status_${banner.id}`} id={`status_${banner.id}_0`} value="0" checked={Number(banner.status) === 0} onChange={()=>{
                                                                            this.changeBannerStatus(banner.id, 0)
                                                                        }}/>
                                                                        In-Active
                                                                    </Label>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                            <div className="banneroption" style={{display:'flex',justifyContent:'space-between'}}>
                                                                <span className="edit" onClick={()=>{this.props.history.push(`/editbanner/${banner.id}/`)}}><i className="fa fa-pencil" aria-hidden="true"></i> Edit</span>
                                                                <span className="delete" onClick={()=>{this.deletebanner(banner.id)}}><i className="fa fa-trash" aria-hidden="true"></i> Delete</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                    </Fragment>
                                ):null
                            }</div>
                        )
                    }
                    <div className="quickmanagestockbox" onClick={()=>{
                        this.props.history.push("/addbanner")
                    }}><i className="fa fa-plus" aria-hidden="true"></i></div>
                </div>
            </div>
			<Dialog
				open={isdialogopen}
				onClose={this.toggleConfirm}
				aria-labelledby="siteconfirm_banner"
				aria-describedby="sitedescription_banner"
				>
				<DialogTitle id="siteconfirm_banner" style={{color:'#000000',fontWeight:'bold'}}>{`Confirm`}</DialogTitle>
				<DialogContent>
					<DialogContentText id="sitedescription_banner" style={{color:'#000000'}}>
					{confirmmessage}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="secondary" style={{background:'#363636',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{this.toggleConfirm()}}>No</Button>
					<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
						this.deletebanner(confirmrecordid)
					}} autoFocus>Yes</Button>
				</DialogActions>
			</Dialog>
            <Captainfooterbar />
            </Fragment>
        );
	}
}