import React,{Fragment, Component} from 'react';
import { ThemeConsumer } from '../Context/Theme';
import { ProductContext, ProductConsumer } from '../Context/Product';
import axios from "axios";
import BannerNew from '../components/BannerNew';
import BannerSmall from '../components/BannerSmall';
import excellent from '../assets/images/smileys/excellent.jpg';
import good from '../assets/images/smileys/good.jpg';
import medium from '../assets/images/smileys/medium.jpg';
import poor from '../assets/images/smileys/poor.jpg';
import verybad from '../assets/images/smileys/verybad.jpg';
import logo from '../assets/images/thankyou.webp';

import {CircularProgress} from '@material-ui/core';

import {Footer}  from '../NewComponents';

class Feedback extends Component {
    
    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            showsearch:false,
            isitemreloading:false,
            theposition:180,
            canchangetable: false,
            processing: false,
            feedbackid: '',
            rating: '',
            phone: '',
            lefttimetoredirect:'',
            redirectiontime:30,
            loggedininfo: {title:''},
            ratingsarr: [{'title':'excellent','description':'You rated us Excellent and made our day!'},{'title':'good','description':'You rated us Good, we will try to do better!'},{'title':'medium','description':'We will try better next time!'},{'title':'poor','description':'We messed up this time. We will improve in future.'},{'title':'verybad','description':'Extremely sorry for the bad experience. We will try to improve next time.'}],
            isnumbershared: false,
        };

        this.refreshIntervalId  = null;
    }
    componentDidMount(){

        this.context.setEventTables();

        let temploggedininfo    = {};
        let tempcanchangetable  = false;

        let tabledetail     = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

        if(tabledetail || captiondetail || stewarddetail)
        {
            if(tabledetail !== null && Number(tabledetail.eventid) > 0)
            {
                temploggedininfo    = tabledetail;
            }
            else if(Number(stewarddetail.eventid) > 0)
            {
                temploggedininfo	= stewarddetail.selectedtable;
                if((Number(stewarddetail.tabletype) !== 2 && Object.keys(stewarddetail.stewardtable).length > 1) || Number(stewarddetail.tabletype) === 0)
                {
                    tempcanchangetable  = true;
                }
            }
            else if(Number(captiondetail.eventid) > 0)
            {
                temploggedininfo	= captiondetail.selectedtable;
                tempcanchangetable  = true;
            }
        }

        this.setState({
            loggedininfo:temploggedininfo,
            canchangetable:tempcanchangetable
        },()=>{
            if(tabledetail)
            {
                if(!this.state.isitemreloading)
                {
                    this.setState(()=>{
                        return{
                            isitemreloading:true
                        }
                    },()=>{
                        this.refreshIntervalId = setInterval(() => {
            
                            this.context.updateTableLastActivityTime();
                
                        }, 15000);
                    })
                }
            }
        })

        /*this.feedbackTimer();

        window.addEventListener('scroll', this.context.listenToScroll);*/
    }

    componentWillUnmount(){

        this.setState(()=>{
            return{
                isitemreloading:false
            }
        });

        clearInterval(this.refreshIntervalId);

        /*window.removeEventListener('scroll', this.context.listenToScroll);*/
    }

    handleUserInput = (e) => {
        const name  = e.target.name;
        const value = e.target.value;

        switch(name) {
            case "phone":

            let tempphone = value;
            let mobile    = tempphone.replace(/ /g,'');
            
            if(tempphone.slice(0,1) === '+')
            {
              mobile = mobile.substring(3);
            }

            if(tempphone.slice(0,1) === '0')
            {
              mobile = mobile.substring(1);
            }

            if(mobile.length > 10)
            {
              return;
            }

            this.setState(()=>{
                return{
                    phone:mobile
                }
            })
            break;
            default:
            this.setState(()=>{
                return{
                    [name]: value
                }
            })
        }
    }

    leaveFeedback=()=>{

        const{eventid,seatid, seattype} = this.state.loggedininfo;

        this.setState(()=>{
            return{
                processing:true
            }
        },()=>{
            setTimeout(() => {
                const bodyFormData = new FormData();
                bodyFormData.set('Mode', 'LeaveFeedback');
                bodyFormData.set('eventid', Number(eventid));
                bodyFormData.set('seatid', Number(seatid));
                bodyFormData.set('seattype', seattype);
                bodyFormData.set('rating', this.state.rating);

                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}feedback.php?t=${Date.now()}`,
                    data: bodyFormData,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                .then(response=>{
        
                    this.setState(()=>{
                        return{
                            processing:false,
                        }
                    },()=>{
                        if(response.data.success === true && Number(response.data.feedbackid) > 0)
                        {
                            this.setState(()=>{
                                return{
                                    feedbackid:response.data.feedbackid
                                }
                            },()=>{
                                this.feedbackTimer();
                            })
                        }
                    })
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
            }, 800);
        })
    }

    shareYourPhone=(e)=>{
        e.preventDefault();

        const{eventid,seatid, seattype} = this.state.loggedininfo;
        const{phone, feedbackid} = this.state;

        let message = "";

        if((phone === "" || phone === null || phone === undefined) && message === "")
        {
          message = `Please enter your phone number`;
        }
        else if(phone.length < 10 && message === "")
        {
          message = `Phone number should be 10 digit`;
        }
        if(message !== "")
        {
          this.context.toastMessage(`${message}`,`error`);
          return; 
        }

        this.setState(()=>{
            return{
                processing:true
            }
        },()=>{
            setTimeout(() => {
                const bodyFormData = new FormData();
                bodyFormData.set('Mode', 'ShareNumber');
                bodyFormData.set('eventid', Number(eventid));
                bodyFormData.set('seatid', Number(seatid));
                bodyFormData.set('seattype', seattype);
                bodyFormData.set('phone', phone);
                bodyFormData.set('feedbackid', feedbackid);

                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}feedback.php?t=${Date.now()}`,
                    data: bodyFormData,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                .then(response=>{

                    let success = response.data.success;
        
                    this.setState(()=>{
                        return{
                            processing:false,
                            isnumbershared:true,
                        }
                    },()=>{

                        setTimeout(() => {

                            if(success === true)
                            {
                                this.setState({
                                    lefttimetoredirect:'',
                                    feedbackid:'',
                                    phone:'',
                                    rating:'',
                                    isnumbershared:false,
                                    redirectiontime:30
                                },()=>{
                                    clearInterval(this.menuTicker);
                                    if(this.state.loggedininfo !== undefined)
                                    {
                                        if(!this.state.loggedininfo.isfeedbacklogin)
                                        {
                                            this.props.history.push("/menu");
                                        }
                                    }
                                })
                            }
                            else
                            {
                                this.setState({
                                    lefttimetoredirect:'',
                                    feedbackid:'',
                                    phone:'',
                                    rating:'',
                                    isnumbershared:false,
                                    redirectiontime:30
                                },()=>{
                                    clearInterval(this.menuTicker);
                                    if(this.state.loggedininfo !== undefined)
                                    {
                                        if(!this.state.loggedininfo.isfeedbacklogin)
                                        {
                                            this.props.history.push("/menu");
                                        }
                                    }
                                })
                            }
                            
                        }, 3000);
                    })
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
            }, 800);
        })
    }

    feedbackTimer=()=>{

        const{redirectiontime} = this.state;

        let menuredirectiontime = redirectiontime;

        /*if(this.context.eventdetail)
        {
            const{thanksinterval}   = this.context.eventdetail;

            if(thanksinterval !== undefined && thanksinterval !== null && Number(thanksinterval) > 0)
            {
                menuredirectiontime = thanksinterval;
            }
        }*/

        this.menuTicker = setInterval(()=>{
          if(menuredirectiontime <= 0)
          {
            this.setState({
                lefttimetoredirect:'',
                feedbackid:'',
                phone:'',
                rating:'',
                redirectiontime:30,
                isnumbershared:false
            },()=>{
                clearInterval(this.menuTicker);
                if(this.state.loggedininfo !== undefined)
                {
                    if(!this.state.loggedininfo.isfeedbacklogin)
                    {
                        this.props.history.push("/menu");
                    }
                }
            })
          }
          else
          {
            this.setState({
                lefttimetoredirect:menuredirectiontime
            })
          }
          menuredirectiontime -= 1;
        }, 1000);        
    }

    resetCounter=()=>{
        this.setState(()=>{
            return{
                redirectiontime:60
            }
        },()=>{
            clearInterval(this.menuTicker);
            this.feedbackTimer();
        })
    }

    Logout=()=>{

		if(this.context.iscaptionlogin !== true && this.context.isstewardlogin !== true)
		{
			let promotlogout = prompt("Please enter event code to logout", "");

			if(promotlogout !== null)
			{
				if(Number(promotlogout) !== Number(process.env.REACT_APP_PASSWORD))
				{
					this.context.toastMessage(`Invalid Password`,'error');
				}
				else
				{
                    this.context.resetMenuSelection();
                    this.context.setLoggedOutGuestUser();

					localStorage.setItem("guestid",'');
					localStorage.setItem("tabledetail",null);
					localStorage.setItem("captiondetail",null);
					localStorage.setItem("stewarddetail",null);
					localStorage.setItem("isloggedin",false);
					localStorage.setItem("appe_kotedetail",null);
					localStorage.setItem("orlo_menudetail",null);
					localStorage.setItem("isautopilot",false);

					this.props.history.push("/login");
				}
			}
		}
		else
		{
            this.context.resetMenuSelection();
            this.context.setLoggedOutGuestUser();

			localStorage.setItem("guestid",'');
			localStorage.setItem("tabledetail",null);
			localStorage.setItem("captiondetail",null);
			localStorage.setItem("stewarddetail",null);
			localStorage.setItem("isloggedin",false);
			localStorage.setItem("appe_kotedetail",null);
            localStorage.setItem("orlo_menudetail",null);
            localStorage.setItem("isautopilot",false);

			this.props.history.push("/login");
		}
    }

    handleClick=()=>{
        this.setState({
            lefttimetoredirect:'',
            feedbackid:'',
            phone:'',
            rating:'',
            isnumbershared:false,
            redirectiontime:30
        },()=>{
            clearInterval(this.menuTicker);
            if(this.state.loggedininfo !== undefined)
            {
                if(!this.state.loggedininfo.isfeedbacklogin)
                {
                    this.props.history.push("/menu");
                }
            }
        })
    }

    render(){

        const{loggedininfo, canchangetable, feedbackid, processing, phone, lefttimetoredirect, rating, ratingsarr, isnumbershared} = this.state;

        return (
            <ThemeConsumer>
            {(value) => {

                return (
                    <Fragment>
                    <ProductConsumer>
                    {(value) => {
        
                        const{ theposition, loggedintabledetail, miscdetail } = value;

                        return (
                            <Fragment>
                            {
                                loggedintabledetail.showbanners && (feedbackid < 1 || isnumbershared) ? (<Fragment>
                                {
                                    theposition < 100 ? <BannerSmall />:<BannerNew />
                                }
                                </Fragment>):null
                            }
                                <section className={`filterwrapper${theposition < 100 ? ' withsmallads':''}`}>
                                
                                    <div className={`newfilter filtertitle filtertitle${Number(miscdetail.filtercount)}`} style={{justifyContent:'flex-start',alignItems:'center'}}>
                                        {
                                            loggedininfo !== undefined ? (<Fragment>Feedback Station {loggedininfo.seatid}</Fragment>):``
                                        }
                                        {canchangetable ? <span style={{marginLeft:'5px'}} onClick={()=>{
                                        this.props.history.push("/tableselection");
                                    }}><i className='fa fa-edit' style={{fontSize:'25px', color:'#ed5a6b'}}></i></span>:null}</div>
                                    <span style={{marginLeft:'5px'}} onClick={()=>{
                                        this.Logout();
                                    }}><i className='fa fa-power-off' style={{fontSize:'25px', color:'#ed5a6b'}}></i>&nbsp;</span>
                                </section>

                                <section className={`mainwrapper`}>
                                    {
                                        isnumbershared ? (<Fragment>
                                            <div style={{display:'flex',justifyContent:'center',flexDirection:'column',textAlign:'center'}}>
                                                <div style={{fontSize:'8rem',marginBottom:'10px'}}>Thank You!</div>
                                                <div>
                                                    <i className="fa fa-check-circle" style={{fontSize:'18rem',color:'green'}} aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <br/><br/>
                                        </Fragment>):(<Fragment>
                                        <br/>
                                        {
                                            feedbackid ? ``:(<div style={{textAlign:'center',fontSize:'2.5rem'}}><br/>How was your experience?</div>)
                                        }
                                        <br/>
                                        {
                                            feedbackid ? (<Fragment>
                                                <form className="loginform login100-form validate-form" id="loginform" name="loginform" onSubmit={this.shareYourPhone}>
                                                <div className='container'>
                                                    <div className={`d-flex`} style={{justifyContent:'center',flexDirection:'column',textAlign:'center',marginBottom:'10px'}}>
                                                        <img src={logo} className="app-logo" alt="logo" style={{maxWidth:'70%',margin:'auto'}}/>
                                                    </div>
                                                    {
                                                        feedbackid ? (<div style={{textAlign:'center',fontSize:'2.5rem'}}><br/>{ratingsarr.find(item => item.title === rating).description}</div>):``
                                                    }
                                                    <br/>
                                                    <div className="wrap-input100 validate-input">
                                                        <input type="number" className={`input100`} name="phone" id="phone" value={phone} onChange={this.handleUserInput} placeholder="Leave us your mobile number" onFocus={()=>{
                                                            this.resetCounter();
                                                        }}/>
                                                    </div>
                                                    <button type="submit" className="login100-form-btn" disabled={processing}>
                                                    {
                                                        processing ? <CircularProgress color='inherit' />:`Call me back`
                                                    }
                                                    </button>
                                                    <br />
                                                    <button type="button" className="login100-form-btn" style={{backgroundColor:'#363636'}} onClick={()=>this.handleClick()}>
                                                        Go back
                                                    </button>
                                                    {
                                                        lefttimetoredirect !== "" ? (
                                                            <div style={{
                                                                marginTop:'10px',
                                                                fontWeight:'500',
                                                                fontSize:'1.8rem',
                                                                textAlign:'center'
                                                            }}>Showing feedback screen in <span style={{
                                                                color:'#ff0000'
                                                            }}>{lefttimetoredirect}</span> seconds...</div>
                                                        ):null
                                                    }
                                                </div>
                                                </form>
                                            </Fragment>):(<Fragment>
                                                <div className={`d-flex`} style={{justifyContent:'center'}}>
                                                    <div style={{padding:'20px',paddingLeft:'40px',textAlign:'center'}} onClick={()=>{
                                                        this.setState(()=>{
                                                            return{
                                                                rating:'excellent'
                                                            }
                                                        },()=>{
                                                            this.leaveFeedback();
                                                        })
                                                    }}>
                                                        <img src={excellent} alt="Excellent" style={{width:'100%'}}/>
                                                    </div>
                                                    <div style={{padding:'20px',textAlign:'center'}} onClick={()=>{
                                                        this.setState(()=>{
                                                            return{
                                                                rating:'good'
                                                            }
                                                        },()=>{
                                                            this.leaveFeedback();
                                                        })
                                                    }}>
                                                        <img src={good} alt="Good" style={{width:'100%'}}/>
                                                    </div>
                                                    <div style={{padding:'20px',textAlign:'center'}} onClick={()=>{
                                                        this.setState(()=>{
                                                            return{
                                                                rating:'medium'
                                                            }
                                                        },()=>{
                                                            this.leaveFeedback();
                                                        })
                                                    }}>
                                                        <img src={medium} alt="Medium" style={{width:'100%'}}/>
                                                    </div>
                                                    <div style={{padding:'20px',textAlign:'center'}} onClick={()=>{
                                                        this.setState(()=>{
                                                            return{
                                                                rating:'poor'
                                                            }
                                                        },()=>{
                                                            this.leaveFeedback();
                                                        })
                                                    }}>
                                                        <img src={poor} alt="Poor" style={{width:'100%'}}/>
                                                    </div>
                                                    <div style={{padding:'20px',paddingRight:'40px',textAlign:'center'}} onClick={()=>{
                                                        this.setState(()=>{
                                                            return{
                                                                rating:'verybad'
                                                            }
                                                        },()=>{
                                                            this.leaveFeedback();
                                                        })
                                                    }}>
                                                        <img src={verybad} alt="Very Bad" style={{width:'100%'}}/>
                                                    </div>
                                                </div>
                                                {
                                                    processing ? <div className={`d-flex mt-5`} style={{justifyContent:'center'}}><CircularProgress color='inherit' /></div>:``
                                                }
                                                <div className='container'>
                                                    <br/><br/>
                                                    <p style={{textAlign:'center'}}>Your feedback helps us in improving our service. An excellent rating boosts our confidence and makes our day!</p>
                                                </div>
                                            </Fragment>)
                                        }
                                        </Fragment>)
                                    }
                                </section>
                                <Footer />
                            </Fragment>
                        );
                    }}
                    </ProductConsumer>
                    </Fragment>
                )
            }}
            </ThemeConsumer>
        )
    }
}

export default Feedback;