import React,{Component,Fragment} from 'react';
import axios from "axios";
import scanlogo from '../assets/images/scanlogo.png';
import { ProductConsumer, ProductContext } from '../Context/Product';
import {CircularProgress} from '@material-ui/core';
import { Button, Input, Label, Form, FormGroup, Alert } from 'reactstrap';

export default class Addsteward extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            isprocessing:false,
            message:'',
            alertcolor:'primary',
            stewardid:'',
            stewardname:'',
            mobilenumber:'',
            tabletype:2,
            logintype:'steward',
            tracktablet:true,
            showitemimages:true,
            showbanners:true,
            kotid:1,
        };
    }

    componentDidMount(){

        let tabledetail   = JSON.parse(localStorage.getItem('tabledetail'));
        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));
        let menudetail    = JSON.parse(localStorage.getItem('orlo_menudetail'));
    
        let eventid       = "";
    
        if(tabledetail || captiondetail|| stewarddetail)
        {
            if(tabledetail !== null && Number(tabledetail.eventid) > 0)
            {
                eventid		= tabledetail.eventid;
            }
            else if(captiondetail !== null && Number(captiondetail.eventid) > 0)
            {
                eventid		= captiondetail.eventid;
            }
            else if(stewarddetail !== null && Number(stewarddetail.eventid) > 0)
            {
                eventid	= stewarddetail.eventid;
            }
            if(eventid > 0)
            {
                if(menudetail !== null && menudetail !== undefined)
                {
                    if((menudetail.menutype === 'all') || (menudetail.menutype === 'selected' && Object.keys(menudetail.eventmenus.filter(menu => menu.ischecked === true)).length > 0))
                    {
                        this.props.history.push("/menu");
                    }
                    else
                    {
                        this.props.history.push("/eventmenu");
                    }
                }
                else
                {
                    this.props.history.push("/eventmenu");
                }
            }
        }
        else
        {
            this.setState({
                stewardid:this.props.match.params.stewardid
            })
        }
    }

	handleUserInput = (e) =>{
		const name	= e.target.name;
		const value	= e.target.value;

        this.setState(()=>{
            return{
                [name]:value
            }
        })
	}

	handleAddSteward=(e)=> {
		e.preventDefault();
		
		let message = "";

		if((this.state.stewardname === "" || this.state.stewardname === null || this.state.stewardname === undefined) && message === "")
		{
			message = "Please enter name";
		}
		
        if(message !== "")
        {
            this.context.toastMessage(`${message}`,`error`);
        }

        if(message === "")
        {
            this.setState({
                message:'Processing...',
                alertcolor:'primary',
                isprocessing:true
            },()=>{
                setTimeout(()=>{

					const bodyFormData = new FormData();
					bodyFormData.set('Mode', 'AddSteward');
					bodyFormData.set('stewardid', this.state.stewardid);
					bodyFormData.set('name', this.state.stewardname);
					bodyFormData.set('mobile', this.state.mobilenumber);
					bodyFormData.set('tabletype', this.state.tabletype);
					bodyFormData.set('alleventtable', JSON.stringify([]));
					bodyFormData.set('isviptable', 0);
					bodyFormData.set('operatorid', 1);
					bodyFormData.set('isstewardregistration', 1);

                    axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_URL}steward.php?t=${Date.now()}`,
                        data: bodyFormData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(response=>{

                        this.setState({
                            isprocessing:false,
							message:'',
                        });
        
                        let color   = "error";
        
                        if(response.data.success === true)
                        {
							color   = "success";
							
							this.setState(()=>{
								return{
									/*stewardid:'',*/
									stewardname:'',
									mobilenumber:'',
								  };
							},()=>{
                                this.handleLogin();
							})
                        }
                        else
                        {
                            this.context.toastMessage(`${response.data.msg}`,`${color}`);
                        }
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
                },1500);
            })
        }
	}

    handleLogin=()=>{

        const{stewardid, logintype, tracktablet, showitemimages, showbanners} = this.state;

        /*let message = "";*/

        this.setState(()=>{
            return{
                processing:true
            }
        },()=>{
            setTimeout(() => {
                const bodyFormData = new FormData();
                bodyFormData.set('Mode', 'Login');
                bodyFormData.set('loginid', stewardid);
                bodyFormData.set('logintype', logintype);
                bodyFormData.set('tracktablet', Number(tracktablet));

                axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}applogin.php?t=${Date.now()}`,
                    data: bodyFormData,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                .then(response=>{

                    this.setState(()=>{
                        return{
                            processing:false,
                        }
                    },()=>{
                        if(response.data.success !== true)
                        {
                            this.context.toastMessage(`${response.data.msg}`,`error`);
                        }
                        else
                        {
                            const{logintype} = this.state;

                            localStorage.setItem("isloggedin",true);

                            localStorage.setItem("appe_kotedetail",null);
                            localStorage.setItem("orlo_event_listed_ads",JSON.stringify([]));
                            localStorage.setItem("tabledetail",JSON.stringify([]));
                            localStorage.setItem("captiondetail",JSON.stringify([]));
                            localStorage.setItem("stewarddetail",JSON.stringify([]));

                            let logindetail  = response.data.logindetail;

                            let newlogindetail  = {...logindetail, showitemimages:Number(showitemimages), showbanners:Number(showbanners)};

                            if(logintype === 'table')
                            {
                                this.context.clearCart();  

                                localStorage.setItem("tabledetail",JSON.stringify(newlogindetail));
                                localStorage.setItem("orlo_menudetail",null);

                                this.context.setGuestUser();
                    
                                if(Number(response.data.menucount) > 1)
                                {
                                    this.props.history.push("/eventmenu");
                                }
                                else
                                {
                                    let menudata	= {menutype:'all', eventmenus:[]};
                                    localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));
                        
                                    this.props.history.push("/menu");
                                }
                            }
                            else if(logintype === 'caption')
                            {
                                this.context.clearCart();  

                                localStorage.setItem("captiondetail",JSON.stringify(newlogindetail));

                                this.context.setGuestUser();

                                let menudata	= {menutype:'all', eventmenus:[]};
                                localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));
                    
                                /*this.props.history.push("/menu");*/
                                this.props.history.push("/tableselection");
                            }
                            else if(logintype === 'steward')
                            {
                                /*console.log(Object.keys(newlogindetail.stewardtable).length);
                                return false;*/

                                this.context.clearCart();
                                localStorage.setItem("stewarddetail",JSON.stringify(newlogindetail));
                                localStorage.setItem("isloggedin",true);
                                this.context.setGuestUser();
                
                                let menudata	= {menutype:'all', eventmenus:[]};
                                localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));
                
                                /*this.props.history.push("/menu");*/
                                if(Number(newlogindetail.tabletype) === 2 || Object.keys(newlogindetail.stewardtable).length === 1)
                                {
                                    this.props.history.push("/menu");
                                }
                                else
                                {
                                    this.props.history.push("/tableselection");
                                }
                            }
                        }
                    })
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
            }, 500);
        })
    }

	render(){

        const {alertcolor, message, isprocessing, stewardname, mobilenumber, tabletype, stewardid} = this.state;
                
        /*const {handleUserInput, handleAddSteward} = value;*/

        return (
            <ProductConsumer>
            {(value) => {
                
                return (
                    <Fragment>

                        <div className="limiter">
                            <div className="container-login100 loginform">
                                <div className="wrap-login100" style={{textAlign:'left',justifyContent:'center',fontSize:'2rem',flexDirection:'column'}}>
                                    <div className="login100-pic js-tilt" style={{marginBottom:'20px'}}>
                                    <br/><br/><br/>
                                    <img src={scanlogo} alt="Login Icon" />
                                    <br/>
                                    </div>
                                    <div className="ordermessage runnermsg" style={{textAlign:'center',justifyContent:'center',fontWeight:'bold',fontSize:'2rem'}}>Steward Registration</div>
                                    <div>
                                    {
                                        message !== "" ? (
                                            <Alert color={alertcolor}>{message}</Alert>
                                        ):null
                                    }
                                    <Form id="addattendancefrm" name="addattendancefrm" onSubmit={this.handleAddSteward}>
                                        <FormGroup>
                                            <Label for="stewardid">Steward ID</Label>
                                            <Input type="number" name="stewardid" id="stewardid" placeholder="Steward ID" value={stewardid} onChange={this.handleUserInput} readOnly/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="runnername">Steward Name <span style={{color:'#ff0000',fontSize:'1.1rem'}}>(Required)</span></Label>
                                            <Input type="text" name="stewardname" id="stewardname" placeholder="Steward Name" value={stewardname} onChange={this.handleUserInput}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="mobilenumber">Mobile Number <span style={{color:'#000000',fontSize:'1.1rem'}}>(Optional)</span></Label>
                                            <Input type="number" name="mobilenumber" id="mobilenumber" placeholder="Mobile Number" value={mobilenumber} onChange={this.handleUserInput}/>
                                        </FormGroup>
                                        <FormGroup tag="fieldset">
                                            <legend style={{
                                                fontSize:'15px',
                                            }}>Ordering Type</legend>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="radio" name="tabletype" id="tabletype2" value="2" checked={Number(tabletype) === 2} onChange={this.handleUserInput}/>
                                                    &nbsp;Deliver to Self
                                                </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Label check>
                                                    <Input type="radio" name="tabletype" id="tabletype0" value="0" checked={Number(tabletype) === 0} onChange={this.handleUserInput}/>
                                                    &nbsp;All Tables
                                                </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </FormGroup>
                                        </FormGroup>
                                        <br />
                                        <div style={{display:'flex',justifyContent:'center'}}>
                                            <Button type="submit" color="primary" disabled={isprocessing}>Save Now</Button>{' '}{isprocessing ?<CircularProgress size={30} />:null}
                                            &nbsp;
                                            <Button color="secondary" onClick={()=>{
                                                this.props.history.push(`/login`);
                                            }}>Cancel</Button>
                                        </div>
                                    </Form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Fragment>
                );
            }}
            </ProductConsumer>
        )
	}
}