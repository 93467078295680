import React,{Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class ItemLoading extends Component{
    render() {

        return (
            <div>
                <div className="productblock">
                    <div className={`smallpreviewbox`}>
                        <div className="iteminfo">
                            <div className="iteminfoch">
                                <div className="iteminfochin">
                                    <h4 className="itemtitle"><Skeleton width={100} /></h4>
                                    <h4 className="itemtitle"><Skeleton width={150} /></h4>
                                    <div className="itemtagbox">
                                        <div className="itemtag">
                                            <Skeleton width={60} />
                                        </div>
                                    </div>
                                    <div className="pricebox priceboxmargin">
                                        <span className="offerprice"><Skeleton width={80} /></span>
                                    </div>
                                </div>
                            </div>
                            <p className="itemsummary"><Skeleton width={200} /></p>
                        </div>
                        <div className={`smallpreviewchildbox`}>
                            <div height={`8.5rem`} width={`8.5rem`} className={`smallimagebox previewbox2`}>
                                <div className="imagesepbox"></div>
                                <Skeleton width={90} height={90} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="productblock">
                    <div className={`smallpreviewbox`}>
                        <div className="iteminfo">
                            <div className="iteminfoch">
                                <div className="iteminfochin">
                                    <h4 className="itemtitle"><Skeleton width={100} /></h4>
                                    <h4 className="itemtitle"><Skeleton width={150} /></h4>
                                    <div className="itemtagbox">
                                        <div className="itemtag">
                                            <Skeleton width={60} />
                                        </div>
                                    </div>
                                    <div className="pricebox priceboxmargin">
                                        <span className="offerprice"><Skeleton width={80} /></span>
                                    </div>
                                </div>
                            </div>
                            <p className="itemsummary"><Skeleton width={200} /></p>
                        </div>
                        <div className={`smallpreviewchildbox`}>
                            <div height={`8.5rem`} width={`8.5rem`} className={`smallimagebox previewbox2`}>
                                <div className="imagesepbox"></div>
                                <Skeleton width={90} height={90} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="productblock">
                    <div className={`smallpreviewbox`}>
                        <div className="iteminfo">
                            <div className="iteminfoch">
                                <div className="iteminfochin">
                                    <h4 className="itemtitle"><Skeleton width={100} /></h4>
                                    <h4 className="itemtitle"><Skeleton width={150} /></h4>
                                    <div className="itemtagbox">
                                        <div className="itemtag">
                                            <Skeleton width={60} />
                                        </div>
                                    </div>
                                    <div className="pricebox priceboxmargin">
                                        <span className="offerprice"><Skeleton width={80} /></span>
                                    </div>
                                </div>
                            </div>
                            <p className="itemsummary"><Skeleton width={200} /></p>
                        </div>
                        <div className={`smallpreviewchildbox`}>
                            <div height={`8.5rem`} width={`8.5rem`} className={`smallimagebox previewbox2`}>
                                <div className="imagesepbox"></div>
                                <Skeleton width={90} height={90} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="productblock">
                    <div className={`smallpreviewbox`}>
                        <div className="iteminfo">
                            <div className="iteminfoch">
                                <div className="iteminfochin">
                                    <h4 className="itemtitle"><Skeleton width={100} /></h4>
                                    <h4 className="itemtitle"><Skeleton width={150} /></h4>
                                    <div className="itemtagbox">
                                        <div className="itemtag">
                                            <Skeleton width={60} />
                                        </div>
                                    </div>
                                    <div className="pricebox priceboxmargin">
                                        <span className="offerprice"><Skeleton width={80} /></span>
                                    </div>
                                </div>
                            </div>
                            <p className="itemsummary"><Skeleton width={200} /></p>
                        </div>
                        <div className={`smallpreviewchildbox`}>
                            <div height={`8.5rem`} width={`8.5rem`} className={`smallimagebox previewbox2`}>
                                <div className="imagesepbox"></div>
                                <Skeleton width={90} height={90} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}