import React,{Component,Fragment} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ProductConsumer, ProductContext } from '../Context/Product';
import Captainfooterbar from '../components/Captainfooterbar';
import { Button } from 'reactstrap';
import{QuickAddMenu, QuickEditMenu, QuickAddCategory, QuickEditCategory} from '../NewComponents';

export default class Addmenu extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount(){
        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
        let eventid      = "";

		if(kotedetail !== null && (Number(kotedetail.eventid) > 0 && kotedetail.eventid !== null))
		{
			if(Number(kotedetail.eventid) > 0)
			{
				eventid = kotedetail.eventid;
			}
			if(eventid < 1 || kotedetail.eventid === undefined || kotedetail.eventid === "")
			{
                Promise.all([localStorage.setItem("appe_koteurl",'/addmenu')])
                .then(() => {
                    this.props.history.push("/kotelogin");
                })
			}
			else
			{
				this.context.setKoteProducts('additem');
				/*this.context.GetEventMenu('addmenu');
				this.context.GetEventCategory('addmenu');*/
			}
		}
		else
		{
            Promise.all([localStorage.setItem("appe_koteurl",'/addmenu')])
            .then(() => {
                this.props.history.push("/kotelogin");
            })
		}

        this.context.resetZoneNumber();
    }

    goToManageMenu=()=>{
        this.props.history.push("/managemenu");
    }

    toggleDrawer = (side, open) => {
        this.setState({
          [side]: open,
        });
      };

	render(){

        return (
            <Fragment>
            <ProductConsumer>
            {(value) => {
                
                const {alleventcategory, newcategoryname, newitemname, isnewcategory, validcategoryid, categoryid, validnewcategoryname, validnewitemname, isfeaturedcategory, eventitemtypes, validitemtypeid, validnewitemnamehindi, newitemnamehindi, crop, croppedImageUrl, src, validmenuid, zonenumber, iscustomization, customization, menuid, googleimages, hasgoogleimages, issearchingiamge, modalstatus, allmenus} = value;

                const {handleUserInput, handleAddMenu, handleIsFeaturedChange, translateTextInHindi, onSelectFile, onImageLoaded, onCropComplete, onCropChange, addMoreCustomization, addMoreOption, handleCustomizationInput, handleOptionInput, searchImageFromGoogle, onSelectGoogleFile, clearGoogleImages, toggleItemManagementModel, viewEditMenuDetail, viewEditCategoryDetail} = value;

                return (
                    <div className="view-cart-wrapper">
                        <div className="local-cart-container selecttable1 engagedrunner stockmanagement" style={{maxHeight:'100vh'}}>
                            <div className="ordermessage runnermsg" style={{textAlign:'center'}}>Quick Add Items</div>
                            <br />
                            <form className="form-horizontal" id="form" name="form" onSubmit={handleAddMenu}>
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="menuid">Menu</label>
                                    <div className="col-sm-10 fullwidth" style={{display:'flex',justifyContent:'space-between'}}>
                                        <select name="menuid" id="menuid" className={`form-control ${validmenuid}`} value={menuid} onChange={handleUserInput}>
                                            <option value="">Select</option>
                                            {
                                                allmenus.map((menu)=>{
                                                    return (
                                                        <option key={menu.id} value={menu.id}>{menu.title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {
                                            Number(menuid) > 0 ? <button type="button" className="btn btn-default savebutton width45px menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',marginLeft:'5px'}} onClick={()=>{viewEditMenuDetail(menuid)}}>
                                            <i className="fa fa-edit"></i>
                                            </button>:null
                                        }
                                        <button type="button" className="btn btn-default savebutton width45px menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',width:'100px',marginLeft:'5px'}} onClick={()=>{toggleItemManagementModel(`addeventmenumodal`, !modalstatus.addeventmenumodal)}}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="categoryid">Category</label>
                                    <div className="col-sm-10 fullwidth" style={{display:'flex',justifyContent:'space-between'}}>
                                        <select name="categoryid" id="categoryid" className={`form-control ${validcategoryid}`} value={categoryid} onChange={handleUserInput}>
                                            <option value="">Select</option>
                                            {
                                                alleventcategory !== undefined && alleventcategory !== null && alleventcategory.map((category)=>{
                                                    return (
                                                        <option key={category.id} value={category.id}>{category.title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {
                                            Number(categoryid) > 0 ? <button type="button" className="btn btn-default savebutton width45px menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',marginLeft:'5px'}} onClick={()=>{viewEditCategoryDetail(categoryid)}}>
                                            <i className="fa fa-edit"></i>
                                            </button>:null
                                        }
                                        <button type="button" className="btn btn-default savebutton width45px menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',marginLeft:'5px'}} onClick={()=>toggleItemManagementModel(`addcategorymodal`, !modalstatus.addcategorymodal)}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                {
                                    isnewcategory ?(<Fragment>
                                    <div className="form-group">
                                        <label className="control-label col-sm-2 fullwidth" htmlFor="newcategoryname">Category Name</label>
                                        <div className="col-sm-10 fullwidth">
                                            <input type="text" className={`form-control ${validnewcategoryname}`} id="newcategoryname" name="newcategoryname" placeholder="Category Name" value={newcategoryname} onChange={handleUserInput}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-sm-10 fullwidth squared lefttext">
                                            <input type="checkbox" name={`isfeatured`} id={`isfeatured`} value="1" checked={isfeaturedcategory} onChange={handleIsFeaturedChange}/><label htmlFor={`isfeatured`}> Is Featured ?</label>
                                        </div>
                                    </div>
                                    </Fragment>):null
                                }
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="categoryid">Item Type</label>
                                    <div className="col-sm-10 fullwidth">
                                    <select name="itemtypeid" id="itemtypeid" className={`form-control ${validitemtypeid}`} onChange={handleUserInput}>
                                        <option value="">Select</option>
                                        {
                                            eventitemtypes.map((itemtype)=>{
                                                return (
                                                    <option key={itemtype.id} value={itemtype.id}>{itemtype.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="newitemname">Item Name</label>
                                    <div style={{display:'flex'}}>
                                        <div className="col-sm-6" style={{width:'60%'}}>
                                            <input type="text" className={`form-control ${validnewitemname}`} name="newitemname" id="newitemname" placeholder="Item Name" value={newitemname} onChange={handleUserInput}/>
                                        </div>
                                        <div className="col-sm-4" style={{width:'40%',padding:'0px 15px 0px 5px'}}>
                                            <button type="button" className="btn btn-default savebutton menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px'}} onClick={()=>{translateTextInHindi('add')}}>Translate</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="newitemnamehindi">Item Name (Hindi)</label>
                                    <div className="col-sm-10 fullwidth fullwidth">
                                        <input type="text" className={`form-control ${validnewitemnamehindi}`} name="newitemnamehindi" id="newitemnamehindi" placeholder="Item Name (Hindi)" value={newitemnamehindi} onChange={handleUserInput}/>
                                    </div>
                                </div>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <div style={{width:'50%',padding:'0px 15px 0px 5px'}}>
                                        <div className="form-group">
                                            <label className="control-label col-sm-2 fullwidth" htmlFor="imagefile">Upload image (jpg,jpeg)</label>
                                            <div className="col-sm-10 fullwidth fullwidth">
                                                <input type="file" name="imagefile" id="imagefile" accept="image/*" onChange={onSelectFile} style={{display:'block'}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{width:'50%'}}>
                                        <div className="form-group">
                                            <label className="control-label col-sm-2 fullwidth" htmlFor="imagefile">Fetch From Google</label>
                                            <div className="col-sm-10 fullwidth fullwidth googlecontronbtn">
                                                {
                                                    !hasgoogleimages ? (
                                                        <button type="button" className="btn btn-default savebutton menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',width:'70% !important'}} disabled={issearchingiamge} onClick={()=>{searchImageFromGoogle('additem')}}>{issearchingiamge ? `Searching...`:`Fetch`}</button>
                                                    ):``
                                                }
                                                {` `}
                                                {
                                                    hasgoogleimages && Object.keys(googleimages).length > 0 ? (<button type="button" className="btn btn-default savebutton menuitem" style={{height:'40px',padding:'8px !important',lineHeight:'1px',width:'70% !important'}} onClick={()=>{clearGoogleImages()}}>Cancel</button>):``
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !issearchingiamge && !src && Object.keys(googleimages).length > 0 ? (
                                        <div className='row'>
                                        {
                                            googleimages.map((imageres, gimgloop)=>{
                                                return(
                                                    <div className="col-sm-4" key={gimgloop} style={{textAlign:'center',marginBottom:'10px'}} onClick={()=>{
                                                        onSelectGoogleFile(imageres)
                                                    }}>
                                                        <img src={imageres.path} style={{width:'100%'}} alt={`Searched Item Preview`}/>
                                                        <div>{imageres.witdh} px X {imageres.height} px</div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                    ):``
                                }
                                <div>
                                    <div className="imagepreviewblock">{src && (<ReactCrop src={src} crop={crop} ruleOfThirds onImageLoaded={onImageLoaded} onComplete={onCropComplete} onChange={onCropChange} />)}</div>
                                    <div className="imagepreviewblock">{croppedImageUrl && (<img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />)}</div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="zonenumber">Counter Number</label>
                                    <div className="col-sm-10 fullwidth fullwidth">
                                        <input type="number" className={`form-control`} name="zonenumber" id="zonenumber" placeholder="Counter Number" value={zonenumber} onChange={handleUserInput} style={{width:'40%'}}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-2 fullwidth" htmlFor="iscustomization">Is Customizable?</label>
                                    <div className="col-sm-10 fullwidth squared lefttext">
                                        <input type="checkbox" name={`iscustomization`} id={`iscustomization`} value="1" checked={iscustomization} onChange={handleUserInput}/><label htmlFor={`iscustomization`}> Yes</label>
                                    </div>
                                </div>
                                {
                                    iscustomization ? (
                                        <div style={{width:'98%',margin:'auto'}}>
                                        {
                                            customization.map((optioncategory, categoryloop)=>{
                                                return(
                                                    <fieldset className="options" key={categoryloop}>
                                                        <legend className="optiontitle">Customization #{optioncategory.categoryindex}</legend>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-2 fullwidth" htmlFor={`CustomizationName${optioncategory.categoryindex}`}>Category Name</label>
                                                            <div className="col-sm-10 fullwidth fullwidth">
                                                                <input type="text" className={`form-control`} name={`CustomizationName${optioncategory.categoryindex}`} id={`CustomizationName${optioncategory.categoryindex}`} placeholder="Category Name" value={optioncategory.customizationname} onChange={(e)=>handleCustomizationInput(e, optioncategory.categoryindex, 'customizationname')}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-2 fullwidth" htmlFor={`type${optioncategory.categoryindex}_2`}>Type</label>
                                                            <div className="col-sm-10 fullwidth squared lefttext">
                                                                <input type="radio" name={`type${optioncategory.categoryindex}`} id={`type${optioncategory.categoryindex}_1`} value="1" onClick={(e)=>handleCustomizationInput(e, optioncategory.categoryindex, 'customizationtype')} defaultChecked={Number(optioncategory.customizationtype) === 1}/>
                                                                <label htmlFor={`type${optioncategory.categoryindex}_1`}>Choose One</label>
                                                                <input type="radio" name={`type${optioncategory.categoryindex}`} id={`type${optioncategory.categoryindex}_2`} value="2" onClick={(e)=>handleCustomizationInput(e, optioncategory.categoryindex, 'customizationtype')} defaultChecked={Number(optioncategory.customizationtype) === 2}/>
                                                                <label htmlFor={`type${optioncategory.categoryindex}_2`}>Choose Any</label>                      
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-2 fullwidth" htmlFor={`OrderBy${optioncategory.categoryindex}`}>Order By</label>
                                                            <div className="col-sm-10 fullwidth fullwidth">
                                                                <input type="number" className={`form-control`} name={`OrderBy${optioncategory.categoryindex}`} id={`OrderBy${optioncategory.categoryindex}`} placeholder="Order By" value={optioncategory.orderby} onChange={(e)=>handleCustomizationInput(e, optioncategory.categoryindex, 'orderby')} style={{width:'40%'}}/>
                                                            </div>
                                                        </div>
                                                        {
                                                            optioncategory.customoption.map((options, optionloop)=>{
                                                                return(
                                                                    <div className="form-group" key={optionloop}>
                                                                        <label className="control-label col-sm-2 fullwidth" htmlFor={`option${options.optionindex}`}>Option {options.optionindex}</label>
                                                                        <div className="col-sm-10 fullwidth fullwidth">
                                                                            <input type="text" className={`form-control`} name={`option${options.optionindex}`} id={`option${options.optionindex}`} placeholder="Option" value={options.option} onChange={(e)=>handleOptionInput(e, optioncategory.categoryindex, options.optionindex)}/>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <br />
                                                        <Button color="warning" size="sm" style={{fontSize:'1.2rem',padding:'.25rem .6rem'}} onClick={()=>addMoreOption(optioncategory.categoryindex)}>Add More Option</Button>
                                                    </fieldset>
                                                )
                                            })
                                        }
                                        <br />
                                        <Button color="warning" size="sm" style={{fontSize:'1.2rem',padding:'.25rem .6rem'}} onClick={()=>addMoreCustomization()}>Add More Customization</Button>
                                        </div>
                                    ):``
                                }
                                <br />
                                <div className="form-group">
                                    <div className="col-sm-offset-2 col-sm-10 fullwidth">
                                        <button type="submit" className="btn btn-default savebutton menuitem">Save Now</button>
                                    </div>
                                </div>
                                <br /><br />
                            </form>
                            <div className="quickmanagestockbox" onClick={this.goToManageMenu}><i className="fa fa-indent" aria-hidden="true"></i></div>
                        </div>
                    </div>
                );
            }}
            </ProductConsumer>
            <Captainfooterbar />
            <QuickAddMenu />
            <QuickEditMenu />
            <QuickAddCategory />
            <QuickEditCategory />
            </Fragment>
        );
	}
}