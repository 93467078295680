import React,{Component} from 'react';
import { ProductConsumer } from '../Context/Product';

import CartItem from './CartItem';

class Cartsummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){

    }

    render(){

        return (
            <ProductConsumer>
            {(value) => {

			const {cart, tabledetail}   = value;
			const {toggleCartSummary, clearCart}   = value;

				return (
					<div className="cartwrapper">
						<div className="cartheader">
							<div className="cartheading">
								<h3 className="cartheadingtitle"><i className="fa fa-map-marker" style={{color:'#34BD52',fontSize:'1.8rem'}} aria-hidden="true"></i>&nbsp;Delivery at <span style={{fontWeight:'600'}}>{tabledetail.title}</span></h3>
								<div style={{display:'flex',alignContent:'flex-end'}}>
									<button className="elxuhW iytXjE" style={{minHeight:'unset',minWidth:'unset',display:'inline'}} tabIndex="0" aria-disabled="false" onClick={()=>{
										clearCart();
									}}>
										<span tabIndex="-1" className="kwfqFr" style={{width:'60%',lineHeight:'30px',minHeight:'unset'}}>
											<span className="dkwpEa">Clear Cart</span>
										</span>
									</button>
									<span onClick={toggleCartSummary}><i style={{fontSize:'2.6rem'}} className="fa fa-angle-down" aria-hidden="true"></i></span>
								</div>
							</div>
						</div>
						<div className="kSIUYJ">
							{cart.map(item=>{
								return <CartItem key={item.tempcartid} itemdetail={item} />
							})}
						</div>
					</div>
				);
            }}
            </ProductConsumer>
        )
    }
}

export default Cartsummary;