import React,{Component,Fragment} from 'react';
import { ProductContext, ProductConsumer } from '../Context/Product';
import OrderTokenItemsList from './OrderTokenItemsList';

class Popuptokendetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            table: []
        };
    }

    static contextType = ProductContext;

    componentDidMount(){

        this.setState(()=>{
            return{
                table: JSON.parse(localStorage.getItem('tabledetail'))
            };
        });
    }

    render() {

        let className = '';
		
        if(this.props.isActiveDetail)
        {
            className += 'opened';
			document.body.classList.add("tokendetailopen");
        }
		else
		{
			document.body.classList.remove("tokendetailopen");
		}

        return (
            <Fragment>
                <div className={`popup ${className}`} onClick={this.props.closeOrderStatus}></div>
                <div className={`_13lkS ${className}`}>
                    <ProductConsumer>
                    {value=>{

                        const {tokenitemdetails, tokenitemummary, /*handleCancelRunnerOrderByCaptain,*/ releaseRunnerByID, toggleConfirm} = value;

                        if(tokenitemdetails.length > 0){
                            return(
                                <Fragment>
                                <div className="_2KabA nopadding">
                                    <div className="viewcarttitle" style={{fontWeight:'bold',width:'45%'}}>
                                        Runner # {tokenitemummary.runnerid}
                                    </div>
                                    <div style={{display:'flex',justifyContent:'space-between'}}>
                                        <div className="form-group cancelallpendingitem" onClick={()=>{releaseRunnerByID(tokenitemummary.runnerid, tokenitemummary.runnerid);this.props.closeOrderStatus();}} style={{width:'130px'}}>Release Runner</div>
                                        <div className="form-group cancelallpendingitem" onClick={()=>{toggleConfirm(tokenitemummary.runnerid, tokenitemummary.tokenno, 'Are you sure? you want cancel order', 'handleCancelRunnerOrderByCaptain')}}>Cancel Order</div>
                                    </div>
                                    <div className="closecartpopup" onClick={this.props.closeOrderStatus}></div>
                                </div>
                                <OrderTokenItemsList value={value} table={this.state.table}/>
                                </Fragment>
                            );
                        }
                        else{
                            return(
                                <Fragment>
                                <div className="_2KabA nopadding">
                                    <div className="viewcarttitle">
                                        Items Details : {tokenitemummary.seattype} - {tokenitemummary.seatnumber}
                                        <div className="viewcartsubtitle runnername">Runner # {tokenitemummary.runnerid}</div>
                                    </div>
                                    <div className="closecartpopup" onClick={this.props.closeOrderStatus}></div>
                                </div>
                                <div className="view-cart-wrapper">
                                    <div className="container orderedlist">
                                        <div className="local-cart-container noitem">No pending item found.</div>
                                    </div>
                                </div>
                                </Fragment>                                
                            );
                        }
                    }}
                    </ProductConsumer>
                </div>                
            </Fragment>            
        );
    }
}

export default Popuptokendetails;