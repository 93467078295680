import React,{Fragment, Component} from 'react';
import { ProductConsumer } from '../Context/Product';
import {
    UpTriangle,
    RightTriangle,
}  from '../constants';

import Cartsummary from './Cartsummary';
import {Button} from '@material-ui/core';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }

    render(){

        return (
            <ProductConsumer>
            {(value) => {

				const{cartTotalItem, placingorder, iscartopen, cartTotal, iscaptionlogin, isstewardlogin, miscdetail, isitemmarkdeliveredbyguest, recentdeliverrunnerid, canuseoption, canrepeatoption, canremoveoption, istableopen, isorderstatusopen, isactivenotdelivereditem, loggedintabledetail} = value;
				const{toggleCartSummary, checkRunnerStatusAndPlaceOrder, markOrderHasDeliveredByRunner, toggleOrderStatusToRaiseAComplaint, scrollToTop, setOrderTableAndAddOrderByTableSelection} = value;

				return(
					<Fragment>
						{
							Number(cartTotalItem) > 0 ? (
								<div className="hOPtIO">
									<section className={`iTfwVs ${iscartopen ? 'cYGtmD':''}`}>
									<div style={{display:'flex',justifyContent:'center',position:'relative'}}>
										<div className="dfUaKd" style={{
											position:'absolute',
											top:iscartopen ?'-6.5rem':'0px',
										}}>
											<div className="close-button" onClick={toggleCartSummary}></div>
											
										</div>
									</div>
									{
										miscdetail.servicestatus === 0 ? (
											<div className='servicedisablebox withcart'>Ordering is currently disabled due to heavy volume. Please check back soon, until then please serve yourself!</div>
										):null
									}
									{
										miscdetail.servicestatus === 1 && !isitemmarkdeliveredbyguest && recentdeliverrunnerid && !iscaptionlogin && !isstewardlogin ? (
											<div className='servicedisablebox orderdelivered withcart'>
												<div>Runner #{recentdeliverrunnerid} recently delivered order</div>
												<div style={{display:'flex', justifyContent:'space-between'}}>
													<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
														markOrderHasDeliveredByRunner()
													}}>OK</Button>
													&nbsp;&nbsp;
													<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
														toggleOrderStatusToRaiseAComplaint()
													}}>Raise A Complaint</Button>			
												</div>
											</div>
										):null
									}
								{
									iscartopen ? (
										<div className="ktQzRb">
											<div className="eidlGK" onClick={toggleCartSummary}>
												<div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
													<div className={`menulines`} style={{width:'2.5rem',height:'1.8rem'}}>
														<i className="fa fa-shopping-cart" style={{color:'#ec4f62',fontSize:'3rem'}} aria-hidden="true"></i>
													</div>
													<div className="jiJXCU">
														<h6 className="jDKpvz">{cartTotalItem} ITEMS</h6>
														<i className={`iconblk ${ iscartopen ? 'dTCpVC':'bKApuF' }`} size="12" color="#696969">
															<UpTriangle />
														</i>
													</div>
												</div>
												{
													cartTotal > 0 ? (
														<div className="kguiiK">
															<span className="hplwQp"><i className="fa fa-inr"></i>{cartTotal}</span>
															<span className="ibWilj">(plus taxes)</span>
														</div>
													):null
												}
											</div>
											<button className="elxuhW iytXjE" tabIndex="0" aria-disabled="false" onClick={()=>{
												if(!iscaptionlogin && !isstewardlogin)
												{
													checkRunnerStatusAndPlaceOrder();
												}
												else
												{
													setOrderTableAndAddOrderByTableSelection();
												}
											}}>
												<span tabIndex="-1" className={`kwfqFr blink`}>
													<span className="dkwpEa">{placingorder ? 'Processing...':'Continue'}</span>
													{!placingorder ? <RightTriangle />:null}
												</span>
											</button>
										</div>												
									):(
										<div className="ktQzRb">
											<div className="eidlGK" onClick={toggleCartSummary}>
												<div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
													<div className={`menulines`} style={{width:'2.5rem',height:'1.8rem'}}>
														<i className="fa fa-shopping-cart" style={{color:'#ec4f62',fontSize:'3rem'}} aria-hidden="true"></i>
													</div>
													<div className="jiJXCU">
														<h6 className="jDKpvz">{cartTotalItem} ITEMS</h6>
														<i className={`iconblk ${ iscartopen ? 'dTCpVC':'bKApuF' }`} size="12" color="#696969">
															<UpTriangle />
														</i>
													</div>
												</div>
											{
												cartTotal > 0 ? (
													<div className="kguiiK">
														<span className="hplwQp"><i className="fa fa-inr"></i>{cartTotal}</span>
														<span className="ibWilj">(plus taxes)</span>
													</div>
												):null
											}
											</div>
											<button className="elxuhW iytXjE" tabIndex="0" aria-disabled="false" onClick={()=>{
												if(!iscaptionlogin && !isstewardlogin)
												{
													checkRunnerStatusAndPlaceOrder();
												}
												else
												{
													setOrderTableAndAddOrderByTableSelection();
												}
											}}>
												<span tabIndex="-1" className={`kwfqFr blink`}>
													<span className="dkwpEa">{
														Number(loggedintabledetail.isdemoevent) > 0 ? (<span>{placingorder ? 'Processing...':'Order Now'}</span>):(<span>{placingorder ? 'Processing...':'Click to Order Now'}</span>)
													}</span>
													{!placingorder ? <RightTriangle />:null}
												</span>
											</button>
										</div>
									)
								}
								{
									iscartopen ? <Cartsummary />:null
								}
									</section>
								</div>
							):(<div>
								{
									miscdetail.servicestatus === 0 ? (
										<div className='servicedisablebox withoutcart'>Ordering is currently disabled due to heavy volume. Please check back soon, until then please serve yourself!</div>
									):null
								}
								{
									miscdetail.servicestatus === 1 && !isitemmarkdeliveredbyguest && recentdeliverrunnerid && !iscaptionlogin && !isstewardlogin ? (
										<div className='servicedisablebox orderdelivered withoutcart'>
											<div>Runner #{recentdeliverrunnerid} recently delivered order</div>
											<div style={{display:'flex', justifyContent:'space-between'}}>
												<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
													markOrderHasDeliveredByRunner()
												}}>OK</Button>
												&nbsp;&nbsp;
												<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
													toggleOrderStatusToRaiseAComplaint()
												}}>Raise A Complaint</Button>			
											</div>
										</div>
									):null
								}
							</div>)
						}
						{
							!iscartopen && !canuseoption && !canrepeatoption && !canremoveoption && !istableopen && !isorderstatusopen && !isactivenotdelivereditem ? (
								<button onClick={()=>{
									scrollToTop();
								}} id="backtotop" title="Go to top" className={Number(cartTotalItem) > 0 ? `menubtnwithcart ${miscdetail.servicestatus === 0 || (!isitemmarkdeliveredbyguest && recentdeliverrunnerid) ?`servicedisablemsg`:``}`:`menubtnwitoutcart ${miscdetail.servicestatus === 0 || (!isitemmarkdeliveredbyguest && recentdeliverrunnerid) ?`servicedisablemsg`:``}`}><i className="fa fa-arrow-up" aria-hidden="true" style={{fontSize:'25px'}}></i></button>
							):null
						}
					</Fragment>
				);
            }}
            </ProductConsumer>
        )
    }
}

export default Footer;