import React,{Component,Fragment} from 'react';
import logo from '../assets/images/oops.png';

export default class Default extends Component{
	render(){
		return (
		  <Fragment>
			<div className="loadingwrapper">
			  	<img src={logo} className="app-logo" alt="logo" />
				<div className="notfoundsubtitle">Food item not found</div>
			</div>
		  </Fragment>
		);
	}
}