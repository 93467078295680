import React,{Component} from 'react';

const ThemeContext = React.createContext();
//Provider
//Consumer
/*jshint esversion: 6 */

class ThemeProvider extends Component{

	state = {
		islargepreview:false,
		ismenuopen:false,
		canuseoption:false,
		iscaptionmenuopen:false,
	}

    toggleLargePreview=()=>{
        this.setState({
            islargepreview:!this.state.islargepreview
        });
    }

	toggleMenu=(ismenuopen)=>{
        this.setState({
            ismenuopen:ismenuopen
        },()=>{
			if(this.state.ismenuopen)
			{
				document.body.style.overflow = "hidden";
			}
			else
			{
				document.body.style.overflow = "auto";
			}
		});
    }

	scrollToView=(eventmenudivid, catdivid, eventmenus)=>{

		this.toggleMenu(!this.state.ismenuopen);

		let categoryitem	= 0;
		let eventmenuitem	= 0;

		let areatobeleft	= 55;

		if(eventmenudivid !== null && eventmenudivid !== "" && eventmenudivid !== undefined)
		{
			eventmenuitem	= document.getElementById(eventmenudivid).offsetTop;
		}

		if(catdivid !== null && catdivid !== "" && catdivid !== undefined)
		{
			categoryitem	= document.getElementById(catdivid).offsetTop;

			if(eventmenus > 1)
			{
				areatobeleft	= 100;
			}
		}

		const menuitem	= Number(eventmenuitem) + Number(categoryitem);

		window.scrollTo({
		  top: menuitem - Number(areatobeleft),
		  behavior: "smooth"
		});

		/*document.getElementById(`${divid}`).scrollIntoView({
			behavior: 'smooth', block: 'center'
		});
		setTimeout(() => {
			window.scrollBy(0, -10);
			}, 1000)
		document.getElementById(`${divid}`).scrollTop += 50;*/
    }

	toggleCaptionMenu=(iscaptionmenuopen)=>{
        this.setState({
            iscaptionmenuopen:iscaptionmenuopen
        },()=>{
			if(this.state.iscaptionmenuopen)
			{
				document.body.style.overflow = "hidden";
			}
			else
			{
				document.body.style.overflow = "auto";
			}
		});
    }

	scrollToViewCaptionItem=(divid)=>{

		this.toggleCaptionMenu(!this.state.iscaptionmenuopen);

		/*var menuitem = document.getElementById(divid).offsetTop;

		window.scrollTo({
		  top: menuitem - 60,
		  behavior: "smooth"
		});*/
    }

	toggleOption=()=>{
        this.setState({
            canuseoption:!this.state.canuseoption
        },()=>{
			if(this.state.canuseoption)
			{
				document.body.style.overflow = "hidden";
			}
			else
			{
				document.body.style.overflow = "auto";
			}
		});
    }

	devInArray=(needle, haystack)=> {
		var length = haystack.length;
		for(var i = 0; i < length; i++) {
			if(haystack[i] === needle) return true;
		}
		return false;
	}

	scrollToFilterWrapper=()=>{

		const banneritem	= document.getElementsByClassName(`bannerwrapper`)[0].offsetHeight;

		const areatobeleft	= 0;

		window.scrollTo({
			top: banneritem - Number(areatobeleft),
			behavior: "smooth"
		});

    }

	render(){
		return (
			<ThemeContext.Provider value={{
			...this.state,
				toggleLargePreview:this.toggleLargePreview,
				toggleMenu:this.toggleMenu,
				scrollToView:this.scrollToView,
				scrollToViewCaptionItem:this.scrollToViewCaptionItem,
				toggleOption:this.toggleOption,
				toggleCaptionMenu:this.toggleCaptionMenu,
				scrollToFilterWrapper:this.scrollToFilterWrapper,
			}}
			>
			{this.props.children}
			</ThemeContext.Provider>
		);
	}
}

const ThemeConsumer = ThemeContext.Consumer;

export {ThemeProvider, ThemeConsumer, ThemeContext};