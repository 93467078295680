import React,{Component,Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import OrderedTokenItem from './OrderedTokenItem';

class OrderTokenItemsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showstockout: false
        };
    }
    
    render() {

        const {tokenitemdetails}   = this.props.value;

        return(
            <Fragment>
                <div className="view-cart-wrapper">
                    <div className="container orderedlist">
                        <div className="local-cart-container confirmseatbox">
                            <div className="local-cart-container">
                            {
                                tokenitemdetails.map((order, orderindex)=>{
                                    return(
                                        <Fragment key={orderindex}>
                                            <div style={{background:'#e8e8e8',fontWeight:'bold',padding:'5px 0'}}>{order.seatname}</div>
                                            <ul className="cart-products-list">
                                            {order.details.map(item=>{
                                                return <OrderedTokenItem key={item.index} item={item} value={this.props.value} />
                                            })}
                                            </ul>
                                        </Fragment>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>            
        )
    }
}

export default withRouter(OrderTokenItemsList);