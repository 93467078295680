import React,{Component, Fragment} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import {ProductConsumer} from '../Context/Product';
import Skeleton from 'react-loading-skeleton';

class BannerSmall extends Component{

    constructor(props) {
        super(props);

        this.state = {
        	loaded: false,
        }
	}
	
	render(){
		return (
			<Fragment>
			<ProductConsumer>
			{(value) => {
				const {eventdetail, additionalimageurl, isSmallBannerLoaded, eventsmallbanner} = value;
				const {playStopVideo} = value;

				if(!isSmallBannerLoaded)
				{
					return <div className="bannerwrapper">
						<Skeleton height={170} className="bannerloadbox" />
					</div>
				}
				else if(eventdetail)
				{
					const {eventname,bannertitle,bannersubtitle,sliderinterval} = eventdetail;
					return (
						<div className="smallbannerwrapper">{
							<div className="bannerwrapper">
								{this.state.loaded ? false :
									<Skeleton height={85} />
								}
								<div className="flexcontainer" style={this.state.loaded ? {} : {display: 'none'}}>
								{eventsmallbanner ? (
									<Carousel showThumbs={false} autoPlay={true} interval={sliderinterval} infiniteLoop={true} showArrows={false} showIndicators={false} dynamicHeight={true} showStatus={false} onChange={playStopVideo}>
									{eventsmallbanner.map((banner) => {
                                        return(
                                            <div key={banner.id}>
                                                <img src={`${additionalimageurl}${banner.image}`} alt={eventname} onLoad={() => this.setState({loaded: true})}/>
                                                <div className="eventinfowrapper">
                                                    <div className="eventtitle">{bannertitle}</div>
                                                    <div className="eventdate">{bannersubtitle}</div>
                                                </div>
                                            </div>
                                        );
									})
									}
									</Carousel>
								):null}
								</div>
							</div>
						}</div>
					);
				}
			}}
			</ProductConsumer>
			</Fragment>
		);
	}
}

export default BannerSmall;