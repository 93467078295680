import React,{Component, Fragment} from 'react';
import {ProductConsumer} from '../Context/Product';
import ProductManage from './ProductManage';
import { FormGroup, Input, Label } from 'reactstrap';
import {CircularProgress} from '@material-ui/core';

export default class GroupProductListManage extends Component{

	render(){

        const {menuid, menuname, newcount, menuservicestatus, ismenuupdating} = this.props.menu;

        return(
            <ProductConsumer>
            {(value) => {

                const{allproducts, allitemcategories, allmenus} = value;
                const{viewEditCategoryDetail, handleCategoryServiceStatus, viewEditMenuDetail, handleMenuServiceStatus} = value;

                let tempCategories   = [];

                const tempcategories = [...allitemcategories];

                tempCategories	= tempcategories.filter(category => category.menuid === menuid);

                if(Number(Object.keys(tempCategories).length) > 0)
                {
                    return(
                        <Fragment>
                        {
                            menuname !== "" && menuname !== null && menuname !== "null" && Object.keys(allmenus).length > 1 ? (
                                <div className={`managemenu titlewrapper menu menuwrapper-${menuid}`} style={{justifyContent:'space-between',alignItems:'center',minHeight:'2rem'}}>
                                    <h3 style={{textAlign:'left',textDecoration:Number(menuservicestatus) === 3 ? `line-through`:``}}>{menuname}<span> - {newcount} Items</span></h3>
                                    <div style={{color:'#ffffff',display:'flex',alignItems:'center',minHeight:'20px',minWidth:'70%'}}>
                                        <FormGroup check>
                                            <Label check style={{textDecoration:Number(menuservicestatus) === 3 ? `line-through`:``}}>
                                                <Input type="radio" name={`menuservicestatus_${menuid}`} id={`menuservicestatus_${menuid}_0`} value="0" checked={Number(menuservicestatus) === 0} onChange={(e)=>handleMenuServiceStatus(e, menuid)}/>
                                                &nbsp;Default
                                            </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Label check style={{textDecoration:Number(menuservicestatus) === 3 ? `line-through`:``}}>
                                                <Input type="radio" name={`menuservicestatus_${menuid}`} id={`menuservicestatus_${menuid}_1`} value="1" checked={Number(menuservicestatus) === 1} onChange={(e)=>handleMenuServiceStatus(e, menuid)}/>
                                                &nbsp;Out of stock
                                            </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Label check style={{textDecoration:Number(menuservicestatus) === 3 ? `line-through`:``}}>
                                                <Input type="radio" name={`menuservicestatus_${menuid}`} id={`menuservicestatus_${menuid}_2`} value="2" checked={Number(menuservicestatus) === 2} onChange={(e)=>handleMenuServiceStatus(e, menuid)}/>
                                                &nbsp;Self Service
                                            </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Label check style={{textDecoration:Number(menuservicestatus) === 3 ? `line-through`:``}}>
                                                <Input type="radio" name={`menuservicestatus_${menuid}`} id={`menuservicestatus_${menuid}_3`} value="3" checked={Number(menuservicestatus) === 3} onChange={(e)=>handleMenuServiceStatus(e, menuid)}/>
                                                &nbsp;Inactive
                                            </Label>
                                        </FormGroup>
                                        &nbsp;&nbsp;
                                        {
                                            ismenuupdating ? <CircularProgress size={20} color={`inherit`}/>:null
                                        }
                                        &nbsp;&nbsp;
                                    </div>
                                    <i className="fa fa-edit" onClick={()=>{
                                        viewEditMenuDetail(menuid);
                                    }}></i>
                                </div>
                            ):``
                        }
                        {
                            tempCategories.map((category, categoryloop)=>{
                                let tempCatProducts   = [];

                                const tempproducts = [...allproducts];
                                
                                tempCatProducts     = tempproducts.filter(tempitem => tempitem.catid === category.id);

                                return(
                                    <Fragment key={categoryloop}>
                                        <div className={`managemenu titlewrapper categorywrapper-${category.id}`} style={{justifyContent:'space-between',alignItems:'center',minHeight:'5rem',backgroundColor:Number(category.categoryservicestatus) === 3 ? `#ff0000`:`#092147`}}>
                                            <h3 style={{textAlign:'left',textDecoration:Number(category.categoryservicestatus) === 3 ? `line-through`:``}}>{category.title}<span> - {category.count} Items</span></h3>
                                            <div style={{color:'#ffffff',display:'flex',alignItems:'center',minHeight:'35px',minWidth:'70%'}}>
                                                <FormGroup check>
                                                    <Label check style={{textDecoration:Number(category.categoryservicestatus) === 3 ? `line-through`:``}}>
                                                        <Input type="radio" name={`categoryservicestatus_${category.id}`} id={`categoryservicestatus_${category.id}_0`} value="0" checked={Number(category.categoryservicestatus) === 0} onChange={(e)=>handleCategoryServiceStatus(e, category.id)}/>
                                                        &nbsp;Default
                                                    </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <Label check style={{textDecoration:Number(category.categoryservicestatus) === 3 ? `line-through`:``}}>
                                                        <Input type="radio" name={`categoryservicestatus${category.id}`} id={`categoryservicestatus_${category.id}_1`} value="1" checked={Number(category.categoryservicestatus) === 1} onChange={(e)=>handleCategoryServiceStatus(e, category.id)}/>
                                                        &nbsp;Out of stock
                                                    </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <Label check style={{textDecoration:Number(category.categoryservicestatus) === 3 ? `line-through`:``}}>
                                                        <Input type="radio" name={`categoryservicestatus${category.id}`} id={`categoryservicestatus_${category.id}_2`} value="2" checked={Number(category.categoryservicestatus) === 2} onChange={(e)=>handleCategoryServiceStatus(e, category.id)}/>
                                                        &nbsp;Self Service
                                                    </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <Label check style={{textDecoration:Number(category.categoryservicestatus) === 3 ? `line-through`:``}}>
                                                        <Input type="radio" name={`categoryservicestatus${category.id}`} id={`categoryservicestatus_${category.id}_3`} value="3" checked={Number(category.categoryservicestatus) === 3} onChange={(e)=>handleCategoryServiceStatus(e, category.id)}/>
                                                        &nbsp;Inactive
                                                    </Label>
                                                </FormGroup>
                                                &nbsp;&nbsp;
                                                {
                                                    category.iscategoryupdating ? <CircularProgress size={30} color={`inherit`}/>:null
                                                }
                                                &nbsp;&nbsp;
                                            </div>
                                            {/*<div className="manageitem" style={{marginBottom:'0px',width:'50px',marginRight:'5px'}}>
                                            <input type="checkbox" name={`isactive_${category.id}`} id={`isactive_${category.id}`} checked={category.isactive} onChange={()=>markInactiveCategory(category.id)}/><label htmlFor={`isactive_${category.id}`} style={{marginBottom:'0px'}}>Is Active</label>
                                            </div>*/}
                                            <i className="fa fa-edit" onClick={()=>{viewEditCategoryDetail(category.id)}}></i>
                                        </div>
                                        <div className="product-listing-wrapper">
                                            <ul className="products-list">
                                            {
                                                tempCatProducts.map((product, productloop)=>{
                                                    return(
                                                        <ProductManage key={productloop} product={product} value={value} categoryid={category.id}/>
                                                    );
                                                })
                                            }
                                            </ul>
                                        </div>
                                    </Fragment>
                                )
                            })
                        }
                        </Fragment>
                    );
                }
            }}
            </ProductConsumer>
        );
	}
}