import React, { Component } from 'react';
import {ProductConsumer} from '../Context/Product';

class Searchfiltermanage extends Component {
    
    render() {
        return (
            <ProductConsumer>
			{(value) => {

                const {applySearchProductKote, clearProductSearchKote} = value;
                const {searchKeywordKote} = value;

                return (
                    <div className={`searchfilter managemenu`}>
                        <div className={`searchbox`}>
                        <input type="text" name="searchKeywordKote" value={searchKeywordKote} onChange={applySearchProductKote} placeholder="Search" autoComplete="off" />
                        <span className="closesearch">
                        {
                            searchKeywordKote !== "" ? (<span className="closesearchicon" onClick={()=>{clearProductSearchKote()}}></span>):null
                        }
                        </span>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                );
			}}
            </ProductConsumer>
        );
    }
}

export default Searchfiltermanage;