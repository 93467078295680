import React,{Component,Fragment} from 'react';
import { ProductContext } from '../Context/Product';
import { withRouter } from 'react-router-dom';
import OrderedItem from './OrderedItem';
import {Button} from '@material-ui/core';

class OrderItemsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showstockout: false
        };
    }

    static contextType = ProductContext;
    
    render() {

        const {orderitemdetails, showitemforcomplain, handleSaveNotDeliveredItemBulk}   = this.props.value;

        let tempDeliveredItems   = [];

        const temporderitemdetails  = [...orderitemdetails];

        if(showitemforcomplain)
        {
            tempDeliveredItems  = temporderitemdetails.filter(tempitem => tempitem.isdelivered === true && tempitem.isdelivered === true);
        }
        else
        {
            tempDeliveredItems  = temporderitemdetails;
        }

        return(
            <Fragment>
                <div className="view-cart-wrapper">
                    <div className="container orderedlist">
                        <div className={`local-cart-container ${showitemforcomplain ? `raisecomplainbox`:`confirmseatbox`}`}>
                            <ul className="cart-products-list">
                            {tempDeliveredItems.map(item=>{
                                return <OrderedItem key={item.index} item={item} value={this.props.value} showitemforcomplain={showitemforcomplain}/>
                            })}
                            </ul>
                        </div>
                        {
                            showitemforcomplain && Object.keys(tempDeliveredItems).length > 0 ? (
                                <div style={{display:'flex', justifyContent:'space-around'}}>
                                    <Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize',width:'100%',maxWidth:'unset'}} variant="contained" size="small" onClick={()=>{
                                        handleSaveNotDeliveredItemBulk(false)
                                    }}>Raise A Complaint</Button>
                                    &nbsp;&nbsp;
                                    <Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize',width:'100%',maxWidth:'unset'}} variant="contained" size="small" onClick={()=>{
                                        handleSaveNotDeliveredItemBulk(true)
                                    }}>Raise A Complaint & Place Order</Button>			
                                </div>
                            ):null
                        }
                    </div>
                </div>
            </Fragment>            
        )
    }
}

export default withRouter(OrderItemsList);