import React,{Component, Fragment} from 'react';

class KoteLiteOrderbatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            batchid:null,
            batchname:null,
            totalorders:0,
            isnotaccepted:true
        };
    }
    componentDidMount()
    {
        const { batchid, batchname, totalorders } = this.props.batch;

        this.setState({
            batchid : batchid,
            batchname: batchname,
            totalorders: totalorders,
            isnotaccepted: true
        });
    }
    acceptPendingBatch=(id,colorindex)=>{

        const { acceptbatchorders } = this.props;

        this.setState({
            isnotaccepted:false
        },()=>{
            acceptbatchorders(id,colorindex);
        });
    }
    render() {
        const { batchid, batchname, totalorders, colorindex } = this.props.batch;
        return(
            <Fragment>
                <div className="row">
                <div className="col-sm-6 recordset orderbatch">
                {
                    this.state.isnotaccepted ?(
                        <div onClick={()=>{this.acceptPendingBatch(batchid,colorindex)}} className="batchblock">
                            <span className={`batch batch${colorindex}`}>{batchname}</span>
                            <span className={`orders orders${colorindex}`}>Order - {totalorders}</span>
                        </div>
                    ):null
                }
                </div>
                </div>
            </Fragment>
        )
    }
}

export default KoteLiteOrderbatches;