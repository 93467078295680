import React,{Component,Fragment} from 'react';
import { ProductConsumer, ProductContext } from '../Context/Product';
import Captainfooterbar from '../components/Captainfooterbar';

export default class Managerunner extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {
            scanresult:'No result'
        };
    }
    componentDidMount(){

        let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));
        let eventid      = "";

		if(kotedetail)
		{
			if(Number(kotedetail.eventid) > 0)
			{
				eventid = kotedetail.eventid;
			}
			if(eventid < 1 || kotedetail.eventid === undefined || kotedetail.eventid === "")
			{
                Promise.all([localStorage.setItem("appe_koteurl",'/managerunner')])
                .then(() => {
                    this.props.history.push("/kotelogin");
                })
			}
			else
			{
				this.context.GetEventRunner('manage');
			}
		}
		else
		{
            Promise.all([localStorage.setItem("appe_koteurl",'/managerunner')])
            .then(() => {
                this.props.history.push("/kotelogin");
            })
		}
    }

    goToManageMenu=()=>{
        this.props.history.push("/managemenu");
    }

	render(){

        return (
            <Fragment>
            <ProductConsumer>
            {(value) => {
                
                const {alleventrunners,updateRunnerStatus} = value;

                return (
                    <div className="view-cart-wrapper">
                        <div className="local-cart-container selecttable engagedrunner stockmanagement">
                            <div className="ordermessage runnermsg">Manage Runner</div>
                            {
                                alleventrunners ? (<div className="product-listing-wrapper">
                                    <ul className="products-list">
                                    {
                                        alleventrunners.map((runner)=>{
                                            return (
                                                <li key={runner.index}>
                                                    <div className="product-item-row categorywrapper">
                                                        <div className="product-item-detail-wrapper">
                                                            <div className="product-title-wrapper clearfix">
                                                                <div className="product-title-english noicon manageitem">{runner.runnername}</div>
                                                            </div>
                                                            <div className="manageitemwrapper">
                                                                <div className="manageitem">
                                                                    <div className="managecategory">
                                                                        <div className="title">Is Suspended ?</div>
                                                                        <input type="checkbox" name={`issuspended_${runner.runnerid}`} id={`issuspended_${runner.runnerid}`} checked={runner.issuspended} onChange={()=>updateRunnerStatus(runner.runnerid)}/><label htmlFor={`issuspended_${runner.runnerid}`}>Is Suspended</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                                
                                                </li>
                                            )
                                          })
                                    }
                                    </ul>
                                </div>):null
                            }
                            <div className="quickmanagestockbox" onClick={this.goToManageMenu}><i className="fa fa-indent" aria-hidden="true"></i></div>
                        </div>
                    </div>
                );
            }}
            </ProductConsumer>
            <Captainfooterbar />
            </Fragment>
        );
	}
}