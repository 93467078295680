import React, { Component } from 'react';

class KoteLiteOrdereditem extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            catitems:[] 
        };
    }

    componentDidMount(){
        const{catitems} = this.props;

        let tempItems 	= [];

        let item;
        for(item in catitems)
        {
            const singleItem = catitems[item];

            tempItems = [...tempItems, singleItem];
        }

        this.setState({
            catitems:tempItems
        });
    }

    render() {

        /*console.log(this.state.catitems);*/

        return (
            <div className="row ordereditemwrapper nopadding">
            {
                this.state.catitems.map((item) => {
                    return (
                        <div key={item.index} className="ordereditem">
                            <span className={`itemname ${item.instockclass}`}>{item.product_name}</span> <span style={{lineHeight:'30px'}}>X {item.qty}</span>
                        </div>
                    )
                })
            }
            </div>
        );
    }
}

export default KoteLiteOrdereditem;