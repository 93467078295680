import React,{Component, Fragment} from 'react';
import { KoteContext } from '../Context/Kote';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import KoteLiteInProgressItem from './KoteLiteInProgressItem';
import closepopup from '../assets/images/closepopup.png';

class KoteLiteReassignTokenPopup extends Component {

    static contextType = KoteContext;

    constructor(props) {
        super(props);
        this.state = { 
            error : null,
            isLoaded : false,
            isrunnermatched:false,
            isfetchingdetail:false,
            orderdetail:[],
            itemdetails:[],
            runnerdetails:[],
            totalitems:0,
            requiredrunnernum:0,
            isRunnerPopActive:false,
            assigningrunnerid:'',
            assignedremovedrunner:[],
            hastokendetail:false,
            runnerid:'',
            searchtype:'runner',
            placeholder:'Enter Runner ID',
            tokenno:'',
            sendrunneronbreak:true,
        };

        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event){

        if(event.keyCode === 27) {
            //Do whatever when esc is pressed

            /*let runnerNum	= Object.keys(this.state.runnerdetails).length;

            if(runnerNum > 0)
            {
                this.saveOrder();
            }*/
            this.props.closePopup();
        }
        /*else if(event.keyCode === 65) {
            //Do whatever when a is pressed
            this.AddMoreRunner();
        }*/
        else if(event.keyCode === 83) {
            //Do whatever when s is pressed
            /*this.saveOrder();*/
        }
        else if(event.keyCode === 90) {
            //Do whatever when z is pressed
        }
        else if(event.keyCode === 65) {
            //Do whatever when a is pressed
            this.acceptOrder();
        }
    }

    componentDidMount(){
        /*this.getOrderDetail();*/
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    getOrderDetail = () => {

        if(Number(this.state.runnerid) < 1)
        {
			this.context.toastMessage(`Please ${this.state.placeholder}`,'error');
            return false;
        }

		let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'GetOrderDetailByRunner');
        bodyFormData.set('eventid', Number(kotedetail.eventid));
        bodyFormData.set('runnerid', this.state.runnerid);
        bodyFormData.set('searchtype', this.state.searchtype);

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
                const { itemdetails, runnerdetails, tokenno } = response.data.orderdetail;

                let tempItemDetails 	= [];

                let item;
                for(item in itemdetails)
                {
                    const singleItemDetail = itemdetails[item];
        
                    tempItemDetails = [...tempItemDetails, singleItemDetail];
                }

                this.setState({
                    isLoaded:true,
                    orderdetail:response.data.orderdetail,
                    isrunnermatched:response.data.orderdetail.isrunnermatched,
                    itemdetails:tempItemDetails,
                    runnerdetails:runnerdetails,
                    isfetchingdetail:false,
                    totalitems:response.data.orderdetail.totalitems,
                    requiredrunnernum:response.data.orderdetail.requiredrunnernum,
                    assignedremovedrunner:runnerdetails,
                    hastokendetail:true,
                    tokenno:tokenno,
                });
            }
            else
            {
                this.setState({
                    hastokendetail:false
                },()=>{
					this.context.toastMessage(response.data.msg,'error');
                })
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    saveOrder=(isreprint)=>{

        if(Number(this.state.assigningrunnerid) < 1)
        {
			this.context.toastMessage(`Please enter new runner id`,'error');
            
            return false;
        }
        
        const bodyFormData = new FormData();
        bodyFormData.set('Mode', 'AssignNewRunnerAndSave');
        bodyFormData.set('runnerid', this.state.runnerid);
        bodyFormData.set('eventid', this.state.orderdetail.event_id);
        bodyFormData.set('engagedrunnerid', this.state.orderdetail.engagedrunnerid);
        bodyFormData.set('orlo_order_id', this.state.orderdetail.orlo_order_id);
        bodyFormData.set('newrunnerid', this.state.assigningrunnerid);
        bodyFormData.set('tokenid', this.state.tokenno);
        bodyFormData.set('isreprint', Number(isreprint));
        bodyFormData.set('sendrunneronbreak', Number(this.state.sendrunneronbreak));

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+'order.php',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response=>{
            
            if(response.data.success === true)
            {
                this.props.closePopup();
                this.props.getOrders();
            }
            else
            {
                if(response.data.closeorder === true)
                {
                    this.props.closePopup();
                    this.props.getOrders();
                }
				this.context.toastMessage(response.data.msg,'error');
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }

    handleUserInput = (e) => {
      const name  = e.target.name;
      const value = e.target.value;

      if(name === "sendrunneronbreak")
      {
        this.setState({
            sendrunneronbreak:!this.state.sendrunneronbreak
        })
      }
      else
      {
        this.setState({
          [name]: value
        });
      }
    }

    handleChange=(e)=>{
        
        const name  = e.target.name;
        const value = e.target.value;

        let tempplaceholder = "Enter Token ID";

        this.setState({
            [name]: value
        },()=>{
            if(name === 'searchtype')
            {
                if(value === 'runner')
                {
                    tempplaceholder = "Enter Runner ID"
                }

                this.setState({
                    placeholder:tempplaceholder
                });
            }
            
        });
    }
    
    handleKeyPress=(event)=>{
        if(event.key === 'Enter')
        {
            if(Number(event.target.value) < 1 || event.target.value === undefined)
            {
				this.context.toastMessage(`Please enter runner id to assign`,'error');
            }
            else
            {
                this.setState({
                    assigningrunnerid:event.target.value
                },()=>{
                    this.AddMoreRunner();
                });
            }
        }
    }

    toggleRunnerPopup() {

        this.setState({
            isRunnerPopActive: !this.state.isRunnerPopActive
        });
    }

    closePopup=()=>{
        let runnerNum	= Object.keys(this.state.runnerdetails).length;

        if(runnerNum > 0)
        {
            //this.saveOrder();
        }
        this.props.closePopup();
    }

    render() {
        const {orderdetail, itemdetails, isfetchingdetail, hastokendetail} = this.state;

        const{sendrunneronbreak} = this.state;

        return (
            <Fragment>
            {
                this.props.isActive ?(
                    <div className="catalog__search search-modal">
                        <div className="search-modal__container container-fluid">
                            <div className="search-modal__header detailheader">
                                {
                                    hastokendetail ? (
                                        <Fragment>
                                            <div className="headingbox noborder nopadding">Order Detail :</div>{orderdetail.captionid?(<div className="headingbox">{orderdetail.captionname}</div>):null}
                                            <div className="headingbox">{orderdetail.orderseat}</div>
                                            <div className="headingbox">Order Number - {orderdetail.orlo_order_id}</div>
                                            <div className="headingbox">Batch Number - {orderdetail.batchname}</div>
                                            <div className="headingbox">Orlo ID - {orderdetail.orlo_id}</div>
                                            <div className="headingbox noborder">{orderdetail.time}</div>
                                            <br /><br />
                                            <div className="pendingtimetitle" style={{display:'inline'}}>({orderdetail.pendingtime})</div>
                                        </Fragment>
                                    ):null
                                }
                                <span className="closepopup" onClick={this.closePopup}><img src={closepopup} alt="close" /></span>
                            </div>
                            <div className="search-modal__body">
                                {
                                    !hastokendetail ? (
                                    <div className="orderedrunner">
                                        <div className="row nopadding manageorderwrapper">
                                            <div className="col-sm-10 nopadding runnerheading">Reassign Runner</div>
                                        </div>
                                        <div className="row" style={{marginTop:'10px'}}>
                                            <div className="col-sm-4">
                                                <input type="radio" name="searchtype" id="searchtype2" value="runner" onClick={this.handleChange} defaultChecked/>
                                                <label htmlFor="searchtype2">&nbsp;Search By Runner</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <input type="radio" name="searchtype" id="searchtype1" value="token" onClick={this.handleChange}/>
                                                <label htmlFor="searchtype1">&nbsp;Search By Token</label>
                                            </div>
                                        </div>
                                        <div className="row nopadding">
                                            <div className="col-sm-5 nopadding pr5 assign-runner-wrapper">
                                                <input type="number" name="runnerid" id="runnerid" className="assignrunnername" placeholder={this.state.placeholder} onChange={this.handleChange} value={this.state.runnerid} autoFocus disabled={isfetchingdetail}/>&nbsp;<button className="addmore-runner-btn go-btn" onClick={() =>{this.getOrderDetail()}} disabled={isfetchingdetail}>Go</button>
                                                {isfetchingdetail ?(<span className="font15">&nbsp;<i className="fa fa-spinner fa-spin"></i></span>):null}
                                                </div>
                                        </div>
                                    </div>
                                    ):(
                                        <div>
                                        {itemdetails ?(
                                            itemdetails.map((detail) => {
                                                return (<div key={detail.index} className="bottomborder">
                                                    <br />
                                                    <div className="row zeromargin greenbg orderedcategory">
                                                        <div className="col-sm-4 nopadding">
                                                            Token : {detail.tokenno} (Runner - {detail.orderrunner})
                                                        </div>
                                                    </div>
                                                    <KoteLiteInProgressItem key={detail.tokenno} tokenitems={detail.tokenitems}/>
                                                </div>)
                                            })
                                        ):null}
                                        <br /><br />
                                        {
                                            hastokendetail ? (
                                                <div className="orderedrunner">
                                                    <div className="row  nopadding manageorderwrapper">
                                                        <div className="col-sm-10 nopadding runnerheading">Assign Runner(s)</div>
                                                    </div>
                                                    <div className="row nopadding">
                                                        <div className="col-sm-5 nopadding pr5 assign-runner-wrapper">
                                                            <input type="number" name="assigningrunnerid" id="assigningrunnerid" className="assignrunnername" placeholder="Enter Runner To Assign" onChange={this.handleChange} value={this.state.assigningrunnerid} autoFocus />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <div className="col-sm-10 fullwidth squared lefttext" style={{paddingLeft:'0px'}}>
                                                            <input type="checkbox" name={`sendrunneronbreak`} id={`sendrunneronbreak`} value="1" checked={sendrunneronbreak} onChange={this.handleUserInput}/><label htmlFor={`sendrunneronbreak`}> Send Runner On Break</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ):null
                                        }
                                        </div>
                                    )
                                }
                            </div>
                            <div className="search-modal__footer">
                                {
                                    hastokendetail ? (
                                        <div className="rightbutton">
                                            <button className="print-order-btn" style={{marginRight:'5px'}} onClick={()=>{
                                                this.saveOrder(true);
                                            }}><i className="fa fa-save printorder" aria-hidden="true"></i>&nbsp;Save and Reprint</button>
                                            <button className="print-order-btn" onClick={()=>{
                                                this.saveOrder(false);
                                            }}><i className="fa fa-save printorder" aria-hidden="true"></i>&nbsp;Save</button>&nbsp;
                                        </div>
                                    ):null
                                }
                            </div>
                            <br />
                        </div>
                    </div>                    
                ):null
            }
            </Fragment>
        );
    }
}

export default withRouter(KoteLiteReassignTokenPopup);