import React,{Component} from 'react';
import { ProductContext } from '../Context/Product';
import axios from "axios";
import scanlogo from '../assets/images/scanlogo.png';
import {CircularProgress} from '@material-ui/core';

class Login extends Component {

  static contextType = ProductContext;

  constructor (props) {
    super(props);
    this.state = {
      processing: false,
      loginid: '',
      password: '',
      logintype:'table',
      tracktablet:true,
      showitemimages:true,
      showbanners:true,
      remarktype:'default',
      ordernotification:'1',
      tablename:'',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

	componentDidMount(){
    let tabledetail   = JSON.parse(localStorage.getItem('tabledetail'));
    let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
    let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));
    let menudetail    = JSON.parse(localStorage.getItem('orlo_menudetail'));

    let eventid         = "";
    let isfeedbacklogin = false;

    if(tabledetail || captiondetail|| stewarddetail)
    {
      if(tabledetail !== null && Number(tabledetail.eventid) > 0)
      {
        eventid		      = tabledetail.eventid;
        isfeedbacklogin = tabledetail.isfeedbacklogin;
      }
      else if(captiondetail !== null && Number(captiondetail.eventid) > 0)
      {
        eventid		= captiondetail.eventid;
      }
			else if(stewarddetail !== null && Number(stewarddetail.eventid) > 0)
			{
				eventid	= stewarddetail.eventid;
			}
      if(eventid > 0)
      {
        if(isfeedbacklogin)
        {
          this.props.history.push("/feedback");
        }
        else
        {
          if(menudetail !== null && menudetail !== undefined)
          {
            if((menudetail.menutype === 'all') || (menudetail.menutype === 'selected' && Object.keys(menudetail.eventmenus.filter(menu => menu.ischecked === true)).length > 0))
            {
              this.props.history.push("/menu");
            }
            else
            {
              this.props.history.push("/eventmenu");
            }
          }
          else
          {
            this.props.history.push("/eventmenu");
          }
        }
      }
    }
	}

  handleUserInput = (e) => {
    const name  = e.target.name;
    const value = e.target.value;

    const{logintype} = this.state;

    switch(name) {
      case "tracktablet":
        this.setState(()=>{
          return{
            tracktablet:!this.state.tracktablet
          }
        })
        break;
      case "showitemimages":
        this.setState(()=>{
          return{
            showitemimages:!this.state.showitemimages
          }
        })
        break;
      case "showbanners":
        this.setState(()=>{
          return{
            showbanners:!this.state.showbanners
          }
        })
        break;
      case "logintype":
        if(value !== logintype)
        {
          this.setState(()=>{
            return{
              [name]: value,
              loginid:'',
              password:'',
            }
          },()=>{
            if(this.state.logintype !== 'table')
            {
              this.setState({
                tablename:''
              })
            }

          })
        }
        break;
      default:
        this.setState(()=>{
          return{
            [name]: value
          }
        })
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const{loginid, password, logintype, tracktablet, showitemimages, showbanners, remarktype, tablename, ordernotification} = this.state;

    let message = "";

    if((loginid === "" || loginid === null || loginid === undefined) && message === "")
    {
      message = `Please enter your Login ID`;
    }
    if((password === "" || password === null || password === undefined) && message === "")
    {
      message = `Blank password not allowed`;
    }

    if((password !== "" && password !== null && password !== undefined && Number(password) !== Number(process.env.REACT_APP_PASSWORD)) && message === "")
    {
      message = `Password Incorrect`;
    }

    if(message !== "")
    {
      this.context.toastMessage(`${message}`,`error`);
      return; 
    }
    this.setState(()=>{
			return{
				processing:true
			}
		},()=>{
			setTimeout(() => {
				const bodyFormData = new FormData();
				bodyFormData.set('Mode', 'Login');
				bodyFormData.set('loginid', loginid);
				bodyFormData.set('logintype', logintype);
				bodyFormData.set('tracktablet', Number(tracktablet));
				bodyFormData.set('remarktype', remarktype);
				bodyFormData.set('tablename', tablename);

				axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}applogin.php?t=${Date.now()}`,
					data: bodyFormData,
					config: { headers: {'Content-Type': 'multipart/form-data' }}
				})
				.then(response=>{
		
					this.setState(()=>{
						return{
							processing:false,
							tablename:'',
						}
					},()=>{
						if(response.data.success !== true)
						{
              if(logintype === 'steward')
              {
                if(!response.data.isstewardmatch)
                {
                  this.props.history.push(`/addsteward/${loginid}`);
                }
                else
                {
                  this.context.toastMessage(`${response.data.msg}`,`error`);
                }
              }
              else
              {
                this.context.toastMessage(`${response.data.msg}`,`error`);
              }
						}
						else
						{
              const{logintype} = this.state;

              localStorage.setItem("isloggedin",true);

              localStorage.setItem("appe_kotedetail",null);
              localStorage.setItem("orlo_event_listed_ads",JSON.stringify([]));
              localStorage.setItem("tabledetail",JSON.stringify([]));
              localStorage.setItem("captiondetail",JSON.stringify([]));
              localStorage.setItem("stewarddetail",JSON.stringify([]));

              let logindetail  = response.data.logindetail;

              let newlogindetail  = {...logindetail, showitemimages:Number(showitemimages), showbanners:Number(showbanners), remarktype:remarktype, ordernotification:Number(ordernotification)};

              if(logintype === 'table')
              {
                this.context.clearCart();  

                localStorage.setItem("tabledetail",JSON.stringify(newlogindetail));
                localStorage.setItem("orlo_menudetail",null);

                this.context.setGuestUser();
      
                if(Number(response.data.menucount) > 1)
                {
                  this.props.history.push("/eventmenu");
                }
                else
                {
                  let menudata	= {menutype:'all', eventmenus:[]};
                  localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));
    
                  this.props.history.push("/menu");
                }
              }
              else if(logintype === 'feedback')
              {
                this.context.clearCart();  

                localStorage.setItem("tabledetail",JSON.stringify(newlogindetail));
                localStorage.setItem("orlo_menudetail",null);

                this.context.setGuestUser();
      
                let menudata	= {menutype:'all', eventmenus:[]};
                localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));
  
                this.props.history.push("/feedback");
              }
              else if(logintype === 'caption')
              {
                this.context.clearCart();  

                localStorage.setItem("captiondetail",JSON.stringify(newlogindetail));

                this.context.setGuestUser();

                let menudata	= {menutype:'all', eventmenus:[]};
                localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));
    
                /*this.props.history.push("/menu");*/
                this.props.history.push("/tableselection");
              }
              else if(logintype === 'steward')
              {
                this.context.clearCart();
                localStorage.setItem("stewarddetail",JSON.stringify(newlogindetail));
                localStorage.setItem("isloggedin",true);
                this.context.setGuestUser();
  
                let menudata	= {menutype:'all', eventmenus:[]};
                localStorage.setItem("orlo_menudetail",JSON.stringify(menudata));
  
                if(Number(newlogindetail.tabletype) === 2 || Object.keys(newlogindetail.stewardtable).length === 1)
                {
                  this.props.history.push("/menu");
                }
                else
                {
                  this.props.history.push("/tableselection");
                }
              }
						}
					})
				})
				.catch(function (response) {
					//handle error
					console.log(response);
				});
			}, 500);
		})
  }

  handleClick=()=>{
    this.props.history.push("/scan");
  }

  render() {

    const{processing, loginid, password, logintype, tracktablet, showitemimages, showbanners, tablename, remarktype, ordernotification} = this.state;
      
      return (
        <div className="limiter">
            <div className="row nomargin">
              <div className="col-sm nomargin">
                <span className="loginbtnwrapper width110"><span className="scanicon" onClick={()=>{this.handleClick()}}></span></span>
              </div>
            </div>
            <div className="container-login100 loginform">
                <div className="wrap-login100">
                  <div className="login100-pic js-tilt">
                    <img src={scanlogo} alt="Login Icon" />
                  </div>
                  <form className="loginform login100-form validate-form" id="loginform" name="loginform" onSubmit={this.handleSubmit}>
                    <div className="wrap-input100 squared">
                      <input type="radio" name="logintype" id="logintype2" value="table" onChange={this.handleUserInput} checked={logintype ==='table'}/>
                      <label htmlFor="logintype2">Table</label>
                      &nbsp;
                      <input type="radio" name="logintype" id="logintype1" value="caption" onChange={this.handleUserInput} checked={logintype ==='caption'}/>
                      <label htmlFor="logintype1">Captain</label>
                      &nbsp;
                      <input type="radio" name="logintype" id="steward" value="steward" onChange={this.handleUserInput} checked={logintype ==='steward'}/>
                      <label htmlFor="steward">Steward</label>
                      &nbsp;
                      <input type="radio" name="logintype" id="feedback" value="feedback" onChange={this.handleUserInput} checked={logintype ==='feedback'}/>
                      <label htmlFor="feedback">Feedback</label>
                    </div>
                    <div className="wrap-input100 validate-input">
                      <input type="number" className={`input100`} name="loginid" id="loginid" value={loginid} onChange={this.handleUserInput} placeholder="Login ID" autoFocus/>
                    </div>
                    <div className="wrap-input100 validate-input">
                      <input type="number" className={`input100 customepassword`} name="password" id="password" value={password} onChange={this.handleUserInput} placeholder="Password"/>
                    </div>
                    {
                      logintype === 'feedback' ? (
                        <div className="wrap-input100 validate-input">
                          <input type="text" className={`input100`} name="tablename" id="tablename" value={tablename} onChange={this.handleUserInput} placeholder="Station Name"/>
                        </div>
                      ):null
                    }
                    <div className="form-group">
                      <div className="col-sm-10 fullwidth squared lefttext" style={{paddingLeft:'0px'}}>
                      {
                        logintype === 'table' ? <input type="checkbox" name={`tracktablet`} id={`tracktablet`} value="1" checked={tracktablet} onChange={this.handleUserInput}/>:null
                      }
                      {
                        logintype === 'table' ? <label htmlFor={`tracktablet`}> Monitor Tablet</label>:null
                      }
                      {
                        logintype !== 'feedback' ? <input type="checkbox" name={`showitemimages`} id={`showitemimages`} value="1" checked={showitemimages} onChange={this.handleUserInput}/>:null
                      }
                      {
                        logintype !== 'feedback' ? <label htmlFor={`showitemimages`}> Show Item Images</label>:null
                      }
                        <input type="checkbox" name={`showbanners`} id={`showbanners`} value="1" checked={showbanners} onChange={this.handleUserInput}/><label htmlFor={`showbanners`}> Show Banners</label>
                      </div>
                    </div>
                    {
                      logintype === 'table' ? <h4 style={{fontWeight:'bold',marginBottom:'10px'}}>Remark Feature</h4>:``
                    }
                    {
                      logintype === 'table' ? (<div className="wrap-input100 squared" style={{display:'flex'}}>
                      <input type="radio" name="remarktype" id="remarktype0" value="default" onChange={this.handleUserInput} checked={remarktype ==='default'}/>
                      <label htmlFor="remarktype0">Default</label>
                      <input type="radio" name="remarktype" id="remarktype1" value="yes" onChange={this.handleUserInput} checked={remarktype ==='yes'}/>
                      <label htmlFor="remarktype1">Yes</label>
                      <input type="radio" name="remarktype" id="remarktype2" value="no" onChange={this.handleUserInput} checked={remarktype ==='no'}/>
                      <label htmlFor="remarktype2">No</label>
                      </div>):``
                    }
                    {
                      logintype === 'table' ? <h4 style={{fontWeight:'bold',marginBottom:'10px'}}>Order Notification</h4>:``
                    }
                    {
                      logintype === 'table' ? (<div className="wrap-input100 squared" style={{display:'flex'}}>
                      <input type="radio" name="ordernotification" id="ordernotification1" value="1" onChange={this.handleUserInput} checked={Number(ordernotification) === 1}/>
                      <label htmlFor="ordernotification1">Yes</label>
                      <input type="radio" name="ordernotification" id="ordernotification2" value="0" onChange={this.handleUserInput} checked={Number(ordernotification) === 0}/>
                      <label htmlFor="ordernotification2">No</label>
                      </div>):``
                    }
                    <br/>
                  <button type="submit" className="login100-form-btn" disabled={processing}>
                  {
                    processing ? <CircularProgress color='inherit' />:`Login`
                  }
                  </button>
                  </form>
                </div>
              </div>
        </div>
      );
  }
}

export default Login;