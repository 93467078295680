import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import {Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions/*, SwipeableDrawer*/} from '@material-ui/core';
import { ProductConsumer } from './Context/Product';
import {Switch, Route} from 'react-router-dom';
import {EventMenu, NewMenu, ManageMenu, DemoLogin, Scan, Login, Rescan, SplashScreen, Releaserunner, OrderThanks, Default, Addmenu, Editmenu, Managecategory, Managerunner, ReOrderThanks, Kotelogin, KoteDashboard, Addbanner, Editbanner, Managebanner, RunnerAttendance, PreviewLogin, Tableselection, Addsteward, Feedback} from './screens';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input/*, Label*/, Form, FormGroup } from 'reactstrap';

/*
import Syncdata from './components/Syncdata';
import SyncEvent from './components/SyncEvent';
import Flushdata from './components/Flushdata';
import Menu from './components/Menu';
import ScratchTest from './screens/ScratchTest';
import ScratchTest2 from './screens/ScratchTest2';
import Party from './screens/Party';
*/

function App() {

	return (
		<ProductConsumer>
		{(value)=>{

			const{isdialogopen, confirmmessage, confirmrecordid, confirmrecordid2, cbfunctionname, iscartclearingstarted/*, lefttimetoclearcart*/, additionalimageurl, islargepreview, largepreviewimg, largepreviewtitle, largepreviewsubtitle, /*miscdetail,*/ isorderremark, orderremark, isreleaserunnerpopup, searchrunnerid, tokenitemdetails, releasingrunner} = value;

			const{toggleConfirm, cancelOrderItem, cancelAllPendingItem, cancelRunnerOrderByID, handleCancelRunnerOrderByCaptain, cancelSelectedItem, checkRunnerStatusAndPlaceOrder, clearCart, toggleConfirmAndUpdateCartTime, toggleLargeProductPreview, toggleOrderRemark, handleUserInput, handleAddOrderRemark, toggleReleaseRunnerConfirmation, releaseRunner} = value;

			return(
				<div>
					<Switch>
						<Route exact path="/" component={SplashScreen} />
						<Route exact path="/kotelogin" component={Kotelogin} />
						<Route exact path="/scan" component={Scan} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/menu" component={NewMenu} />
						<Route exact path="/rescan" component={Rescan} />
						<Route exact path="/releaserunner" component={Releaserunner} />
						<Route exact path="/kotedashboard" component={KoteDashboard} />
						<Route exact path="/addmenu" component={Addmenu} />
						<Route exact path="/managemenu" component={ManageMenu} />
						<Route exact path="/managecategory" component={Managecategory} />
						<Route exact path="/runnerattendance" component={RunnerAttendance} />
						<Route exact path="/managerunner" component={Managerunner} />
						<Route exact path="/addbanner" component={Addbanner} />
						<Route exact path="/managebanner" component={Managebanner} />
						<Route exact path="/editbanner/:recordid" component={Editbanner} />
						<Route exact path="/editmenu/:itemid" component={Editmenu} />
						<Route exact path="/orderthanks/:orderid" component={OrderThanks} />
						<Route exact path="/reorderthanks/:orderid" component={ReOrderThanks} />
						<Route exact path="/demologin/:democode" component={DemoLogin} />
						<Route exact path="/preview/:democode" component={PreviewLogin} />
						<Route exact path="/eventmenu" component={EventMenu} />
						<Route exact path="/tableselection" component={Tableselection} />
						<Route exact path="/addsteward/:stewardid" component={Addsteward} />
						<Route exact path="/feedback" component={Feedback} />
						{/*<Route exact path="/syncdata" component={Syncdata} />
						<Route exact path="/syncevent" component={SyncEvent} />
						<Route exact path="/flushdata" component={Flushdata} />
						<Route exact path="/scratchtest" component={ScratchTest} />
						<Route exact path="/scratchtest2" component={ScratchTest2} />
						<Route exact path="/party" component={Party} />*/}
						<Route component={Default} />
					</Switch>
					<Dialog
						open={isdialogopen && !isorderremark}
						onClose={toggleConfirm}
						aria-labelledby="siteconfirm"
						aria-describedby="sitedescription"
						>
						<DialogTitle id="siteconfirm" style={{color:'#000000',fontWeight:'bold'}}>{iscartclearingstarted ? `Cart Inactivity Confirm`:`Confirm`}</DialogTitle>
						<DialogContent>
							<DialogContentText id="sitedescription" style={{color:'#000000'}}>
							{confirmmessage}
							</DialogContentText>
						</DialogContent>
						{
							iscartclearingstarted ? (<DialogActions>
								<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{checkRunnerStatusAndPlaceOrder()}} autoFocus>Place Order</Button>
								<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
									clearCart()
								}}>{`Clear Cart`}</Button>
								<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
									toggleConfirmAndUpdateCartTime()
								}}>{`Hide`}</Button>
							</DialogActions>):(<DialogActions>
								<Button color="secondary" style={{background:'#363636',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{toggleConfirm()}}>No</Button>
								<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
									if(cbfunctionname === 'cancelOrderItem')
									{
										cancelOrderItem(confirmrecordid)
									}
									else if(cbfunctionname === 'cancelAllPendingItem')
									{
										cancelAllPendingItem()
									}
									else if(cbfunctionname === 'cancelRunnerOrderByID')
									{
										cancelRunnerOrderByID(confirmrecordid)
									}
									else if(cbfunctionname === 'handleCancelRunnerOrderByCaptain')
									{
										handleCancelRunnerOrderByCaptain(confirmrecordid, confirmrecordid2)
									}
									else if(cbfunctionname === 'cancelSelectedItem')
									{
										cancelSelectedItem()
									}
								}} autoFocus>Yes</Button>
							</DialogActions>)
						}
					</Dialog>
                    <Modal isOpen={islargepreview} toggle={()=>{
							toggleLargeProductPreview();
						}} centered={true}>
                        <ModalHeader onClick={()=>{toggleLargeProductPreview()}} toggle={toggleLargeProductPreview}>
							<div>
								<div style={{fontWeight:'bold',fontSize:'15px'}}>{largepreviewtitle}</div>
								{
									largepreviewsubtitle ? <div style={{fontWeight:'normal',fontSize:'12px',lineHeight:'22px'}}>{largepreviewsubtitle}</div>:``
								}
							</div>
						</ModalHeader>
                        <ModalBody>
							<div onClick={()=>{
								toggleLargeProductPreview();
							}}><img alt={`Product Large Preview`} src={`${additionalimageurl}${largepreviewimg}`} style={{width:'100%'}}/></div>
                        </ModalBody>
                        <ModalFooter onClick={()=>{toggleLargeProductPreview()}}>
                            <Button color="secondary" onClick={toggleLargeProductPreview}>Close</Button>
                        </ModalFooter>
                    </Modal>
					<Modal isOpen={isorderremark ? true:false} toggle={()=>{
							toggleOrderRemark();
						}} onOpened={()=>{
							document.querySelector('#orderremark').focus();
						}} centered={true}>
                        <ModalHeader onClick={()=>{toggleOrderRemark()}} toggle={toggleOrderRemark}>
							<div>
								<div style={{fontWeight:'bold',fontSize:'15px'}}>Remark</div>
							</div>
						</ModalHeader>
                        <ModalBody>
							<div style={{paddingLeft:'15px', paddingRight:'15px', paddingTop:'15px'}}>
								<Form id="addattendancefrm" name="addattendancefrm" onSubmit={()=>{

								}}>
									<FormGroup>
										{/*<Label for="orderremark">Remark</Label>*/}
										<Input type="textarea" name="orderremark" id="orderremark" placeholder="Leave your order remark" value={orderremark} onChange={handleUserInput} style={{height:'100px'}} autoFocus/>
									</FormGroup>
									{/*<br />
									<Button type="submit" color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small">Place Order</Button>*/}
								</Form>
							</div>
                        </ModalBody>
                        <ModalFooter onClick={()=>{toggleOrderRemark()}}>
							<Button color="secondary" style={{background:'#363636',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{toggleOrderRemark()}}>Cancel</Button>
							<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
								handleAddOrderRemark();
							}} autoFocus>Place Order</Button>
                            {/*<Button color="secondary" onClick={toggleOrderRemark}>Close</Button>*/}
                        </ModalFooter>
                    </Modal>
					<Modal isOpen={isreleaserunnerpopup} toggle={()=>{
							toggleReleaseRunnerConfirmation();
						}} centered={true}>
                        <ModalHeader onClick={()=>{toggleReleaseRunnerConfirmation()}} toggle={toggleReleaseRunnerConfirmation}>
							<div>
								<div style={{fontWeight:'bold',fontSize:'15px'}}>Release Runner Confirmatin</div>
							</div>
						</ModalHeader>
                        <ModalBody>
							<div style={{paddingLeft:'15px', paddingRight:'15px', paddingTop:'15px'}}>
								<div className={`welcome-section`}>
									<div className={`hellotext`}>
										Release the following runner?
										<br/><br/>
										<div className='releasingrunnertext'>{searchrunnerid}</div>
									</div>
								</div>
								{
									tokenitemdetails && tokenitemdetails.map((singleresult, loop)=>{
										return(<div key={loop} style={{textAlign:'center'}}>Delivered at <span style={{color:'#ff0000'}}>{singleresult.seatname}</span> in <span style={{color:'#ff0000'}}>{singleresult.engagetime}</span> for <span style={{color:'#ff0000'}}>{Object.keys(singleresult.details).length}</span> items</div>)
									})
								}
							</div>
                        </ModalBody>
                        <ModalFooter onClick={()=>{toggleReleaseRunnerConfirmation()}}>
							<Button color="secondary" style={{background:'#363636',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{toggleReleaseRunnerConfirmation()}}>Cancel</Button>
							<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
								if(!releasingrunner)
								{
									releaseRunner();
								}
							}} autoFocus>{releasingrunner ? `Saving...`:`OK`}</Button>
                            {/*<Button color="secondary" onClick={toggleOrderRemark}>Close</Button>*/}
                        </ModalFooter>
                    </Modal>
                    {/*<SwipeableDrawer
                        anchor="bottom"
                        open={isorderremark && (miscdetail.isremarkavailable || miscdetail.isremarkavailablefortable) ? true:false}
                        onClose={()=>toggleOrderRemark()}
                        onOpen={()=>toggleOrderRemark()}
                        >
                        <div className="_2KabA nopadding">
                            <div className="viewcarttitle">
                                Remark
                            </div>
                            <div className="closecartpopup" onClick={()=>toggleOrderRemark()}></div>
                        </div>
                        <div style={{minHeight:'60vh', paddingLeft:'15px', paddingRight:'15px', paddingTop:'15px'}}>

                            <Form id="addattendancefrm" name="addattendancefrm" onSubmit={handleAddOrderRemark}>
                                <FormGroup>
                                    <Label for="orderremark">Remark</Label>
                                    <Input type="textarea" name="orderremark" id="orderremark" placeholder="Remark" value={orderremark} onChange={handleUserInput} style={{height:'100px'}}/>
                                </FormGroup>
                                <br />
                                <Button type="submit" color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small">Place Order</Button>
                            </Form>
                        </div>
                    </SwipeableDrawer>*/}
					<ToastContainer hideProgressBar={false} transition={Slide} />
				</div>
			);
		}}
		</ProductConsumer>
	)
}

export default App;