import React,{Component, Fragment} from 'react';
import { ProductConsumer, ProductContext } from '../../Context/Product';
import {CrossSearch}  from '../../constants';

export default class EggFilter extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }

	render(){

        return (
            <ProductConsumer>
            {(value) => {

                const{containegg} = value;
                const{handleContainEgg} = value;

                return (
                    <Fragment>
                        <div className={`newfilter ${containegg ?'activeegg':''}`} onClick={()=>handleContainEgg()}>
                            <div className={`veg containegg itemtypesymbol`} style={{
                                display:'flex',
                                position:'initial'
                            }}>
                                <div className={`roundvegtype containegg`}></div>
                            </div> 
                            &nbsp;Egg
                            {
                                containegg ? <CrossSearch />:null
                            }
                            
                        </div>
                        &nbsp;
                    </Fragment>
                );
            }}
            </ProductConsumer>
        );
	}
}