import React,{Component} from 'react';
import { ThemeConsumer } from '../Context/Theme';
import { ProductConsumer } from '../Context/Product';
import CaptionMenuList from './CaptionMenuList';

class CaptionMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount(){

    }

    render(){
        
        return (
            <ThemeConsumer>
            {(value) => {

                const{ iscaptionmenuopen } = value;
                const{ toggleCaptionMenu } = value;

                return (
                    <ProductConsumer>
                    {(value) => {

				        const{cartTotalItem, miscdetail, isitemmarkdeliveredbyguest, recentdeliverrunnerid} = value;

                        return (
                            <div className="categoryfilterbox menu-nav-fab-button">
                                <div className={Number(cartTotalItem) > 0 ? `menubtnwithcart caption ${miscdetail.servicestatus === 0 || (!isitemmarkdeliveredbyguest && recentdeliverrunnerid) ?`servicedisablemsg`:``}`:`menubtnwitoutcart caption ${miscdetail.servicestatus === 0 || (!isitemmarkdeliveredbyguest && recentdeliverrunnerid) ?`servicedisablemsg`:``}`}>
                                    <div className="filterbox">
                                        <div className={`${iscaptionmenuopen ?'openedmenu':'hiddenmenu'}`}>{iscaptionmenuopen ?<CaptionMenuList />:null}</div>
                                    </div>
                                    <button className="menubtn caption" onClick={()=>{
                                        toggleCaptionMenu(!iscaptionmenuopen);
                                    }}>
                                        <div className={`${iscaptionmenuopen ?'menuclose':'menulines'}`}>
                                            <span className="lines"></span>
                                            <span className="lines"></span>
                                            <span className="lines"></span>
                                        </div>
                                        <span className="menutitle">Captain Menu</span>
                                    </button>
                                </div>
                            </div>
                        );
                    }}
                    </ProductConsumer>
                )
                
            }}
            </ThemeConsumer>
        )
    }
}

export default CaptionMenu;