import React,{Component,Fragment} from 'react';
import KoteLiteOrderpopup from './KoteLiteOrderpopup';
import axios from "axios";
import { toast } from 'react-toastify';
import {Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions} from '@material-ui/core';

class KoteLiteAcceptedbatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            orderid:null,
            batchid:null,
            batchname:null,
            totalorders:0,
            orders:[],
            colorindex:'',
			isconfirmed:false,
			isdialogopen:false,
			confirmmessage:'',
			confirmrecordid:'',
			confirmispopup:false,
        };
    }

    componentDidMount(){
        this.listnewOrders();
    }

    componentWillReceiveProps() {
        this.listnewOrders();
    }

    listnewOrders = ()=>{
        const { batchid, batchname, totalorders, orders, colorindex } = this.props.acceptedbatchorder;

        let tempOrders 	= [];

        let order;
        for(order in orders)
        {
            const singleOrder = orders[order];

            tempOrders = [...tempOrders, singleOrder];
        }

        this.setState({
            batchid:batchid,
            batchname:batchname,
            totalorders:totalorders,
            orders:tempOrders,
            colorindex:colorindex
        });
    }

    sendOrderInPendingList = (orderid, ispopup) =>{

		let kotedetail   = JSON.parse(localStorage.getItem('appe_kotedetail'));

		const bodyFormData = new FormData();
		bodyFormData.set('Mode', 'CancelOrder');
		bodyFormData.set('kotid', Number(kotedetail.kotid));
		bodyFormData.set('eventid', Number(kotedetail.eventid));
		bodyFormData.set('id', orderid);
		bodyFormData.set('batchid', this.state.batchid);

		axios({
				method: 'post',
				url: process.env.REACT_APP_API_URL+'order.php',
				data: bodyFormData,
				config: { headers: {'Content-Type': 'multipart/form-data' }}
		})
		.then(response=>{

			this.setState(()=>{
				return{
					isdialogopen:false,
					isconfirmed:false,
					confirmmessage:'',
					confirmrecordid:'',
					confirmispopup:false,
				}
			})

			if(response.data.success === true)
			{
				if(ispopup === true)
				{
					this.togglePopup();
				}
				this.props.getOrders();
			}
			else
			{
				toast.error(`${response.data.msg}`, {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		});
    }

    togglePopup() {

        this.setState({
            isActive: !this.state.isActive
        });
    }

	toggleConfirm=(recordid, ispopup)=>{

		this.setState(()=>{
			return{
				isdialogopen:!this.state.isdialogopen,
				confirmmessage:'Are you sure? This order will be cancel',
				confirmrecordid:recordid,
				confirmispopup:ispopup,
			}
		},()=>{
			if(!this.state.isdialogopen)
			{
				this.setState(()=>{
					return{
						isconfirmed:false,
						confirmrecordid:'',
						confirmispopup:false,
						confirmmessage:'',
					}
				})
			}
		})

	}

    render() {

		const {/*isconfirmed,*/ isdialogopen, confirmmessage, confirmrecordid, confirmispopup} = this.state;

        return (
            <Fragment>
            <div className="row">
            <div key={this.state.batchid} className="col-sm-6 recordset">
                <div className="batchorder">
                    <div className={`acceptedbatchtitle acceptedbatchtitle${this.state.colorindex}`}><span className="acceptedbatch">{this.state.batchname}</span>&nbsp;&nbsp;Orders - {this.state.totalorders} <div className="rejectbatch" onClick={()=>{this.props.rejectbatchorders(this.state.batchid)}}>Reject</div></div>
                    <div>
                    {
                        this.state.orders.map((order) => {
                            return (
                                <div key={order.id} className="ordersummary"><span onClick={()=>{this.setState({orderid: order.orlo_order_id},this.togglePopup.bind(this))}}>{order.name}&nbsp;({order.totalitem})&nbsp;/&nbsp;{order.seattype}:&nbsp;<b>{order.seatnumber}</b></span>&nbsp;&nbsp;<i className="fa fa-times removeorder" onClick={()=>this.toggleConfirm(order.orlo_order_id, false)} title="Move Order To Pending Batch"></i></div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
            </div>
            {
                this.state.isActive?(<KoteLiteOrderpopup closePopup={this.togglePopup.bind(this)} getOrders={this.props.getOrders} isActive={this.state.isActive} orderid={this.state.orderid} sendOrderInPendingList={this.sendOrderInPendingList}/>):null
            }
			<Dialog
				open={isdialogopen}
				onClose={this.toggleConfirm}
				aria-labelledby="siteconfirm_batch"
				aria-describedby="sitedescription_batch"
				>
				<DialogTitle id="siteconfirm_batch" style={{color:'#000000',fontWeight:'bold'}}>{`Confirm`}</DialogTitle>
				<DialogContent>
					<DialogContentText id="sitedescription_batch" style={{color:'#000000'}}>
					{confirmmessage}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="secondary" style={{background:'#363636',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{this.toggleConfirm()}}>No</Button>
					<Button color="secondary" style={{background:'#ed5a6b',textTransform:'capitalize'}} variant="contained" size="small" onClick={()=>{
						this.sendOrderInPendingList(confirmrecordid, confirmispopup)
					}} autoFocus>Yes</Button>
				</DialogActions>
			</Dialog>
            </Fragment>
        );
    }
}

export default KoteLiteAcceptedbatch;