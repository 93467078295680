import React,{Component} from 'react';
import { ProductConsumer } from '../Context/Product';
import CancelIcon from '@material-ui/icons/Cancel';

class Outfordeliverymsg extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }

    render(){
        
        return (
            <ProductConsumer>
            {(value) => {

                const{showHideOutforDeliveryMessage} = value;
                const{outfordeliverymessage} = value;

                return (
                    <div style={{position:'fixed',bottom:'6.2rem', zIndex:'99',width:'100%'}}>
                        <div className='l-quote quote'>
                            <CancelIcon style={{position:'absolute',right:3,top:0,width:'50px',height:'50px',padding:'5px'}} onClick={()=>showHideOutforDeliveryMessage()}/>
                            <blockquote className="outfordeliver">
                                <p className="outfordeliver">{outfordeliverymessage}</p>
                            </blockquote>
                        </div>
                    </div>
                );
            }}
            </ProductConsumer>
        )                
    }
}

export default Outfordeliverymsg;