import React,{Fragment, Component} from 'react';
import { withRouter } from 'react-router-dom';
import { ProductContext, ProductConsumer } from '../Context/Product';
import { ThemeConsumer } from '../Context/Theme';
import SingleCategoryFilter from './SingleCategoryFilter';

class CategoryGroupFilter extends Component {

	static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }

    Logout=()=>{

		if(this.context.iscaptionlogin !== true && this.context.isstewardlogin !== true)
		{
			let promotlogout = prompt("Please enter event code to logout", "");

			if(promotlogout !== null)
			{
				if(Number(promotlogout) !== Number(process.env.REACT_APP_PASSWORD))
				{
					this.context.toastMessage(`Invalid Password`,'error');
				}
				else
				{
                    this.context.resetMenuSelection();
                    this.context.setLoggedOutGuestUser();

					localStorage.setItem("guestid",'');
					localStorage.setItem("tabledetail",null);
					localStorage.setItem("captiondetail",null);
					localStorage.setItem("stewarddetail",null);
					localStorage.setItem("isloggedin",false);
					localStorage.setItem("appe_kotedetail",null);
					localStorage.setItem("orlo_menudetail",null);
					localStorage.setItem("isautopilot",false);

					this.props.history.push("/login");
				}
			}
		}
		else
		{
            this.context.resetMenuSelection();
            this.context.setLoggedOutGuestUser();

			localStorage.setItem("guestid",'');
			localStorage.setItem("tabledetail",null);
			localStorage.setItem("captiondetail",null);
			localStorage.setItem("stewarddetail",null);
			localStorage.setItem("isloggedin",false);
			localStorage.setItem("appe_kotedetail",null);
            localStorage.setItem("orlo_menudetail",null);
            localStorage.setItem("isautopilot",false);

			this.props.history.push("/login");
		}
    }

    render(){

        return (
            <ThemeConsumer>
            {(themevalue) => {

                const{ ismenuopen } = themevalue;
                const{ toggleMenu, scrollToView } = themevalue;

                return (
                    <ProductConsumer>
                    {(value) => {

                        const{ isProductLoaded, itemcategories, isloggedin, loggedinname, eventmenus, isrefreshing, isdemoorderprocessing, loggedintabledetail } = value;
                        const{ refreshDemoEventData, processDemoEventData } = value;

                        return (
                            <Fragment>
                            {
                                isloggedin ? (
                                    <div className="singlecategorybox" style={{
                                        fontWeight:'bold',
                                        borderBottom:'1px solid #cccccc',
                                        position:'sticky',
                                        top:'1px',
                                        backgroundColor:'#ffffff',
                                    }} onClick={() =>{

                                        if(Number(loggedintabledetail.isdemoevent) < 1)
                                        {
                                            toggleMenu(!ismenuopen);
                                            this.Logout();
                                        }
                                    }}>
                                        <h6 title={`Logout`} color="#1C1C1C" className="singlecategorytitle" style={{
                                        fontWeight:'bold',
                                        fontSize:'1.8rem',
                                    }}>{loggedinname}{` `}</h6>
                                        <div color="#696969" className="singlecategoryqty" style={{
                                        fontSize:'2rem',
                                        color:'#ff0000',
                                        display:'flex',
                                        justifyContent:'space-between',
                                        minWidth:`${Number(loggedintabledetail.isdemoevent) > 0 ? `5rem`:`unset`}`,
                                        alignItems:'center'
                                    }}>
                                    {Number(loggedintabledetail.isdemoevent) > 0 ? (<span><i className={`fa fa-refresh${isrefreshing ? ` fa-spin`:``}`} aria-hidden="true" onClick={()=>refreshDemoEventData()}></i></span>):(<i className="fa fa-power-off" aria-hidden="true"></i>)}
                                    {Number(loggedintabledetail.isdemoevent) > 0 ? (<span><i className={isdemoorderprocessing ? `fa fa-refresh fa-spin`:`fa fa-tasks`} aria-hidden="true" title="Process Order" style={{color:'#363636'}} onClick={()=>processDemoEventData()}></i></span>):null}
                                    </div>
                                    </div>
                                ):null
                            }
                            {
                                isProductLoaded ? (
                                    <Fragment>
                                    {
                                        eventmenus.map((menu, menuloop)=>{
                                            let tempCategories   = [];

                                            const tempcategories = [...itemcategories];
                            
                                            tempCategories	= tempcategories.filter(category => category.menuid === menu.menuid);

                                            return(
                                                <div key={menuloop}>
                                                {
                                                    menu.menuname !== "" && menu.menuname !== null && menu.menuname !== "null" && Object.keys(eventmenus).length > 1 ? (
                                                        <div className="singlecategorybox" onClick={()=>{
                                                            scrollToView(`eventmenudiv${menu.menuid}`,'',Object.keys(eventmenus).length);
                                                        }} style={{justifyContent:'center',background:'#092147',padding:'.5rem'}} key={menuloop}><h6 title={menu.menuname} color="#1C1C1C" className="singlecategorytitle" style={{fontWeight:'bold',color:'#ffffff'}}>{menu.menuname}</h6></div>
                                                    ):null
                                                }
                                                {
                                                    tempCategories.map(category => {
                                                        return <SingleCategoryFilter key={category.id} category={category} title={`${category.title}`} quantity={`${category.newcount}`} divid={`filterdiv${category.id}`} eventmenuscount={Object.keys(eventmenus).length}/>
                                                    })
                                                }
                                                </div>
                                            )
                                        })
                                    }
                                    </Fragment>
                                ):null
                            }
                            </Fragment>
                        );
                        
                    }}
                    </ProductConsumer>
                )                
            }}
            </ThemeConsumer>
        )
    }
}

export default withRouter(CategoryGroupFilter);