import React,{Component} from 'react';
import { ThemeConsumer } from '../Context/Theme';
import { ProductConsumer } from '../Context/Product';
import CategoryGroupFilter from './CategoryGroupFilter';

class FilterMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {            
        };
    }
    componentDidMount(){

    }

    render(){
        
        return (
            <ThemeConsumer>
            {(value) => {


                const{ ismenuopen } = value;
                const{ toggleMenu } = value;

                return (
                    <ProductConsumer>
                    {(value) => {

				        const{cartTotalItem, miscdetail, isitemmarkdeliveredbyguest, recentdeliverrunnerid} = value;

                        return (
                            <div className="categoryfilterbox menu-nav-fab-button">
                                <div className={Number(cartTotalItem) > 0 ? `menubtnwithcart ${miscdetail.servicestatus === 0 || (!isitemmarkdeliveredbyguest && recentdeliverrunnerid) ?`servicedisablemsg`:``}`:`menubtnwitoutcart ${miscdetail.servicestatus === 0 || (!isitemmarkdeliveredbyguest && recentdeliverrunnerid) ?`servicedisablemsg`:``}`}>
                                    <div className="filterbox">
                                        <div className={`${ismenuopen ?'openedmenu':'hiddenmenu'}`}>{ismenuopen ?<CategoryGroupFilter />:null}</div>
                                    </div>
                                    <button className="menubtn" onClick={()=>{
                                        toggleMenu(!ismenuopen);
                                    }}>
                                        <div className={`${ismenuopen ?'menuclose':'menulines'}`}>
                                            <span className="lines"></span>
                                            <span className="lines"></span>
                                            <span className="lines"></span>
                                        </div>
                                        <span className="menutitle">Menu</span>
                                    </button>
                                </div>
                            </div>
                        );
                    }}
                    </ProductConsumer>
                )
                
            }}
            </ThemeConsumer>
        )
    }
}

export default FilterMenu;