import React,{Component} from 'react';
import { ProductConsumer, ProductContext } from '../../Context/Product';

export default class VegNonVegToggle extends Component{

    static contextType = ProductContext;

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount(){

    }

	render(){

        return (
            <ProductConsumer>
            {(value) => {
                const{isvegonly} = value;
                const{handleVegNonVeg} = value;

                return (
                    <div className="newfirsttogglebox">
                        <span className="tglspannew">
                        <input type="checkbox" name={`isvegonly`} id={`isvegonly`} className={`isvegonly`} onChange={()=>handleVegNonVeg()} checked={isvegonly}/><label htmlFor={`isvegonly`}>veg only</label>
                        </span>
                        <h6 className="vegonlytitle">veg only</h6>
                    </div>
                );
            }}
            </ProductConsumer>
        );
	}
}