import React,{Component} from 'react';
import animateScrollTo from 'animated-scroll-to';

import {ProductConsumer} from '../Context/Product';

export default class Category extends Component{

	scrollToProduct=(id)=>{
		animateScrollTo(document.querySelector('.categorywrapper-'+id));
		this.props.closePopup();
	}

	render(){

		const {id, title, newcount} = this.props.product;

		return (
			<ProductConsumer>
			{value=>(
            	<li className="clearfix" key={id} onClick={()=>{this.scrollToProduct(id)}}><div className="categorytitle_old">{title}</div><div className="categoryquantity">{newcount}</div></li>
			)}
			</ProductConsumer>			
		);
	}
}