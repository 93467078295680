import React,{ Fragment, Component } from 'react';
import { ProductConsumer } from '../Context/Product';
import OrderItemsList from './OrderItemsList';
import {CircularProgress} from '@material-ui/core';

class OrderStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showtablefilter:false,
            loggedininfo: {}
        };
    }

    componentDidMount(){
        let temploggedininfo    = {};
        let showtablefilter   = false;

        let captiondetail	= JSON.parse(localStorage.getItem('captiondetail'));
        let stewarddetail	= JSON.parse(localStorage.getItem('stewarddetail'));

        if(captiondetail || stewarddetail)
        {
            if(Number(stewarddetail.eventid) > 0)
            {
                temploggedininfo	= stewarddetail;
                if(Number(stewarddetail.tabletype) === 2)
                {
                    showtablefilter   = false;
                }
                else
                {
                    showtablefilter   = true;
                }
            }
            else if(Number(captiondetail.eventid) > 0)
            {
                temploggedininfo	= captiondetail;
                showtablefilter   = true;
            }
        }

        this.setState({
            loggedininfo:temploggedininfo,
            showtablefilter:showtablefilter,
        })
    }

    render(){

        const{showtablefilter} = this.state;

        return (
            <ProductConsumer>
            {(value) => {
                
                const{ orderitemdetails, iscaptionlogin, isstewardlogin, istableselected, cancancelitem, tabledetail, isorderstatusopen, tableid, engagedtableinfo, showitemforcomplain, miscdetail, isitemmarkdeliveredbyguest, isordereditemloaded, recentdeliverrunnerid } = value;
                const{ handleTableChange, toggleOrderStatus, toggleConfirm } = value;

                return (
                    <div className="hOPtIO">
                        <div className="fgjele modalWrapper" aria-hidden="false" role="dialog" style={{height:miscdetail.servicestatus === 1 && !isitemmarkdeliveredbyguest && recentdeliverrunnerid && !iscaptionlogin && !isstewardlogin ? `90%`:`100%`}}>
                            <div tabIndex="-1" className="hcdmGE" onClick={()=>{
                                toggleOrderStatus();
                            }}></div>
                            <div className="iBlUrl"></div>
                            <div mode="light" type="medium" tabIndex="0" className="gzwZLJ">
                                <div style={{display:'flex',justifyContent:'center',position:'relative'}}>
                                    <div className="dfUaKd" style={{
                                        position:'absolute',
                                        bottom:isorderstatusopen ?'4rem':'0px',
                                    }}>
                                        <div className="close-button" onClick={()=>{
                                            toggleOrderStatus();
                                        }}></div>
                                    </div>
                                </div>
                                <section className="eggzWm">
                                    <h2 mode="light" className="cInTtE">
                                    {showitemforcomplain ? `Raise A Complaint`:`Order Status`}
                                    {tabledetail.seatid !== "" && tabledetail.seatid !== undefined ? ` Table #${tabledetail.seatid} ${tabledetail.tablename !== "" && tabledetail.tablename !== undefined ? `(${tabledetail.tablename})`:``}`:``}
                                    </h2>
                                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                    {
                                        cancancelitem && !showitemforcomplain ? (<div className="form-group cancelallpendingitem" onClick={()=>{toggleConfirm('', '', 'Are you sure?', 'cancelAllPendingItem')}} style={{color:'#ffffff'}}>Cancel All</div>):null
                                    }
                                    <div className="form-group cancelallpendingitem" onClick={()=>{toggleOrderStatus()}} style={{color:'#ffffff',backgroundColor:'#ed5a6b'}}>Close</div>
                                    </div>
                                </section>
                                <section className="oRYSe">
                                    <div className="kCdFWE"></div>
                                    <div className="ItLLI selecttable" style={{
                                        maxHeight:showitemforcomplain ?`'35rem'`:`'25rem'`,
                                        minHeight:showitemforcomplain ?`'35rem'`:`'20rem'`,
                                        overflow:'auto',
                                    }}>
                                    {
                                        orderitemdetails.length > 0 ? (
                                            <Fragment>
                                            <div className="_2KabA nopadding" style={{
                                                background:'#ffffff',
                                                paddingLeft:'0px',
                                                paddingRight:'0px',
                                            }}>
                                                {
                                                    showtablefilter && (Number(iscaptionlogin) === 1 || Number(isstewardlogin) === 1) ? (
                                                        <Fragment>
                                                            <div className="form-group selecttablebox">
                                                                <div className="col-sm-10" style={{
                                                                    paddingLeft:'0px',
                                                                    paddingRight:'0px',
                                                                }}>
                                                                <select name="tableid" id="tableid" className={`form-control larg-text`} style={{
                                                                    fontSize:'1.5rem'
                                                                }} onChange={handleTableChange} value={tableid}>
                                                                    <option value="">Select Table</option>
                                                                    {
                                                                        engagedtableinfo.map((table)=>{
                                                                            return (
                                                                                <option key={table.id} value={table.code}>{table.title}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    ):null
                                                }
                                           </div>
                                            <div className="view-cart-wrapper">
                                                <div className="container orderedlist">
                                                <OrderItemsList value={value} table={this.state.table} showitemforcomplain={showitemforcomplain}/>
                                            </div>
                                            </div>
                                            </Fragment>                                                    
                                        ):(
                                            <Fragment>
                                            <div className="_2KabA nopadding" style={{
                                                background:'#ffffff',
                                                paddingLeft:'0px',
                                                paddingRight:'0px',
                                            }}>
                                            {
                                                showtablefilter && (Number(iscaptionlogin) === 1 || Number(isstewardlogin) === 1) ? (
                                                    <Fragment>
                                                        <div className="form-group selecttablebox">
                                                            <div className="col-sm-10">
                                                            <select name="tableid" id="tableid" className={`form-control larg-text`} style={{
                                                                fontSize:'1.5rem'
                                                            }} onChange={handleTableChange} value={tableid}>
                                                                <option value="">Select Table</option>
                                                                {
                                                                    engagedtableinfo.map((table)=>{
                                                                        return (
                                                                            <option key={table.id} value={table.code}>{table.title}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ):null
                                            }
                                            {
                                                cancancelitem ? (<div className="form-group cancelallpendingitem" onClick={()=>{toggleConfirm('', '', 'Are you sure?', 'cancelAllPendingItem')}}>Cancel All</div>):null
                                            }
                                            </div>
                                            <div className="view-cart-wrapper">
                                                <div className="container orderedlist">
                                                {
                                                    istableselected ? (<div className="local-cart-container noitem">
                                                    {
                                                        !isordereditemloaded ? <div><br/><br/><br/><CircularProgress /></div>:``
                                                    }
                                                    {
                                                        isordereditemloaded && Object.keys(orderitemdetails).length < 1 ? `No items in queue.`:``
                                                    }
                                                    </div>):(<div className="local-cart-container noitem">Please select a table.</div>)
                                                }
                                                </div>
                                            </div>
                                            </Fragment>
                                        )
                                    }
                                    </div>
                                </section>
                            </div>
                            <div tabIndex="0" className="ckiDDQ"></div>
                        </div>
                    </div>
                );
            }}
            </ProductConsumer>
        )
    }
}

export default OrderStatus;