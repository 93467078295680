import React,{Component} from 'react';
import { ProductConsumer } from '../Context/Product';

import {
	PinkRemove,
	PinkPlus,
}  from '../constants';

class CartItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){

    }

    render(){

        return (
            <ProductConsumer>
            {(value) => {

			const{id,title,count, isvegfood, showvegicon, iscustomization, itemoptions, tempcartid, ispricingavailable, total, containsegg} = this.props.itemdetail;

            var foodsymbolcolorborder   = "nonveg";
            var foodsymbolcolor         = "roundnonvegtype";
    
            if(isvegfood)
            {
                foodsymbolcolorborder   = "veg";
                foodsymbolcolor         = "roundvegtype";
            }

            if(isvegfood && containsegg)
            {
                foodsymbolcolorborder   = "veg containegg";
                foodsymbolcolor         = "roundvegtype containegg";
            }
    
            const{increment, incrementCustomOption, decrementCustomOption, decrement, removeItem} = value;

            return (
                <div className="cGXLsE">
                    <div className="eWvKll">
                        <div className="doKdVK">
                            <div className="fFhIkZ">
                                <div className="eNNUsK" style={{
                                    position:'relative'
                                }}>
                                    {
                                        showvegicon ? (
                                            <div className={`${foodsymbolcolorborder} itemtypesymbol`}>
                                                <div className={`${foodsymbolcolor}`}></div>
                                            </div>
                                        ):null
                                    }
                                    <h4 className="itemtitle">{title}</h4>
                                </div>
                                {
                                    itemoptions.map((optioncat, catloop)=>{
                                        
                                        if(optioncat.selected)
                                        {
                                            return(
                                                <div className="btxdAi" key={catloop}>
                                                    {optioncat.name} : {` `}
                                                    {
                                                        optioncat.options.map((option, optionloop)=>{

                                                            if(option.checked)
                                                            {
                                                                return <span key={optionloop} className="optionname small">{option.name}</span>
                                                            }
                                                            else
                                                            {
                                                                return null;
                                                            }

                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                        else
                                        {
                                            return null;
                                        }
                                    })
                                }
                            </div>
                            <div className="gzFKqN">
                                <div className="qtychild celxIj">
                                    <div style={{display:'flex',alignItems:'center'}}>
                                    <span className="offerprice" style={{fontSize:'2.4rem',color:'#ED5A6B'}} onClick={()=>removeItem(id)}><i className="fa fa-trash"></i></span>&nbsp;
                                    <div className="qtychildin" style={{
                                        border:'1px solid #ef5b6c',
                                        background:'#FFF6F7'
                                        }}>
                                        <div className="cMipmx huikLB">
                                        {
                                            iscustomization ? (
                                                <div className="hTzRFw" onClick={()=>decrementCustomOption(tempcartid)}>
                                                    <PinkRemove />
                                                </div>
                                            ):(
                                                <div className="hTzRFw" onClick={()=>decrement(id)}>
                                                    <PinkRemove />
                                                </div>
                                            )
                                        }
                                        <div className="iQCkqv" style={{
                                                background:'#FFF6F7'
                                            }}>
                                            <span className="qtytitle" style={{
                                                color:'#ef5b6c',
                                            }}>{count}</span>
                                        </div>
                                        {
                                            iscustomization ? (
                                                <div className="hTzRFw" onClick={()=>incrementCustomOption(tempcartid)}>
                                                    <PinkPlus />
                                                </div>
                                            ):(
                                                <div className="hTzRFw" onClick={()=>increment(id)}>
                                                    <PinkPlus />
                                                </div>
                                            )
                                        }
                                        </div>
                                    </div>
                                    </div>
                                    {
                                        iscustomization ? <span className="fuGMDN">customizable</span>:null
                                    }
                                </div>
                                {
                                    ispricingavailable && total > 0 ? (
                                        <div className="pricebox bfhSad">
                                            <span className="offerprice"><i className="fa fa-inr"></i> {total.toFixed(2)}</span>
                                        </div>
                                    ):null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                );
            }}
            </ProductConsumer>
        )
    }
}

export default CartItem;