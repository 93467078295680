import React from 'react';
import { Button } from 'reactstrap';

export default function OrderedItem({item, value, showitemforcomplain}) {

    const{itemname, itemhindiname, qty, status, statusclass, runner, runner_time, cancancelitem, detailid, canceledclass, isdelivered, product_id, hasoption, itemoption, isselectedforcomplain, pending_since} = item;

    const{viewNotDeliveredItemDetail, toggleConfirm, toggleNotDeliveredItemsSelection, reOrderItem}  = value;

    return (
        <li>
            <div className="product-item-row">
                <div className="product-item-detail-wrapper">
                    <div className="product-title-wrapper clearfix">
                        <div className={`product-title-english${canceledclass}`}>
                            {
                                showitemforcomplain ? (
                                    <input type='checkbox' name={`notdelivereditems_${detailid}`} id={`notdelivereditems_${detailid}`} checked={isselectedforcomplain} onChange={()=>{
                                        toggleNotDeliveredItemsSelection(detailid);
                                    }} style={{marginRight:'5px'}}/>
                                ):null
                            }
                            {
                                showitemforcomplain ? (<span onClick={()=>{
                                    toggleNotDeliveredItemsSelection(detailid);
                                }} style={{cursor:'pointer'}}>{itemname} X {qty}</span>):(<span>{itemname} X {qty}</span>)
                            }
                            {
                                item.hasoption ? (<div><span className="optioname" style={{
                                    color:'#000000'
                                }}>{
                                        item.itemoption.map((optioncat)=>{
                                            return( 
                                                <div key={optioncat.categoryid}><span style={{
                                                    color:'#000000',
                                                    marginLeft:'5px'
                                                }}><span style={{fontWeight:'bold',fontSize:'16px',textDecoration:'underline'}}>{optioncat.name} :</span>
                                                {` `}
                                                {
                                                    optioncat.itemoption.map((option)=>{
                                                        return(
                                                            <span key={option.optionid} className="optioname"
                                                            style={{
                                                                fontStyle:'italic',
                                                                color:'#000000',
                                                                fontWeight:'normal',
                                                            }}
                                                            >
                                                            {option.name}
                                                            </span>
                                                        )
                                                    })
                                                }
                                                </span></div>
                                            )
                                        })
                                }</span></div>):null
                            }
                            {
                                cancancelitem ? (<div className="cancelorder" onClick={()=>toggleConfirm(detailid, '', 'Are you sure?', 'cancelOrderItem')}>(Cancel Item)</div>):null
                            }
                            {
                                isdelivered && !showitemforcomplain ? (<div className="notdeliveredtitle">Not Delivered ? <br />( <span className="raisecomplainttxt" onClick={()=>viewNotDeliveredItemDetail(detailid, itemname, itemhindiname, qty, product_id, hasoption, itemoption)}>Raise A Complaint</span> )</div>):null
                            }
                            <div><Button type="button" color="danger" size="sm" style={{width:'80px',fontSize:'15px',padding:'5px'}} onClick={()=>{
                                reOrderItem(item);
                            }}>Reorder</Button></div>
                        </div>
                    </div>
                </div>
                <div className="product-status-wrapper">
                    <div className={`product-status ${statusclass}`}>
                    {status}
                    {
                        pending_since && !runner_time ?(<div className="runnername">{pending_since} ago</div>):null
                    }
                    {
                        runner_time ?(<div className="runnername">{runner_time} Ago</div>):null
                    }                    
                    {
                        runner ?(<div className="runnername">{runner}</div>):null
                    }
                    </div>
                </div>
            </div>
        </li>
    );
}