import React,{Component, Fragment} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import {ProductConsumer} from '../Context/Product';
import { ThemeConsumer } from '../Context/Theme';
import Skeleton from 'react-loading-skeleton';

class BannerNew extends Component{

    constructor(props) {
        super(props);

        this.state = {
        	loaded: false,
        }
	}
	
	render(){

        return (
            <ThemeConsumer>
            {(value) => {

				const{ scrollToFilterWrapper } = value;

				return (
					<Fragment>
					<ProductConsumer>
					{(value) => {
						const {eventdetail, isBannerLoaded, eventbanner, /*mediaserverurl,*/ additionalimageurl} = value;
						const {playStopVideo} = value;

						if(!isBannerLoaded)
						{
							return <div className="bannerwrapper">
								<Skeleton height={170} className="bannerloadbox" />
							</div>
						}
						else if(eventdetail)
						{
							const {eventname,bannertitle,bannersubtitle,sliderinterval} = eventdetail;
							return (
								<div>{
									<div className="bannerwrapper">
										{this.state.loaded ? false :
											<Skeleton height={170} />
										}
										<div className="flexcontainer" style={this.state.loaded ? {} : {display: 'none'}}>
										{eventbanner ? (
											<Carousel showThumbs={false} autoPlay={true} interval={sliderinterval} infiniteLoop={true} showArrows={false} showIndicators={false} dynamicHeight={true} showStatus={false} onChange={playStopVideo}>
											{eventbanner.map((banner) => {
												if(Number(banner.type) === 1)
												{
													return(
														<div key={banner.id} onClick={()=>{
															scrollToFilterWrapper();
														}}>
															<img src={`${additionalimageurl}${banner.image}`} alt={eventname} onLoad={() => this.setState({loaded: true})}/>
															<div className="eventinfowrapper">
																<div className="eventtitle">{bannertitle}</div>
																<div className="eventdate">{bannersubtitle}</div>
															</div>
														</div>
													);
												}
												else
												{
													return(
														<div key={banner.id}>
															<video
																loop
																muted
																autoPlay
																src={`${additionalimageurl}${banner.image}`}
																preload={'auto'}
																type={'video/mp4'}
																onLoadedData={() => {
																	this.setState({loaded: true});
																}} onClick={()=>{
																	scrollToFilterWrapper();
																}}>
															</video>
														</div>
													);
												}
											})
											}
											</Carousel>
										):null}
										</div>
									</div>
								}</div>
							);
						}
					}}
					</ProductConsumer>
					</Fragment>
				);

			}}
			</ThemeConsumer>
		)		
	}
}

export default BannerNew;